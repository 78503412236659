<template>
  <div style="width: 95%;margin: 0 auto">
    <v-row>
      <v-col class="mt-2" :cols="tab == 1 ? 4 : 2">
        <v-menu style="width: 292px !important;"
                ref="menu"
                v-model="menu"
                :close-on-content-click="false"
                :return-value.sync="date"
                transition="scale-transition"
                min-width="290px"
                offset-y
        >
          <template v-slot:activator="{ on, attrs }">
            <v-text-field
                v-model="dateRangeText"
                :label="$t('date')"
                prepend-icon="mdi-calendar"
                readonly
                v-bind="attrs"
                v-on="on"
                hide-details
            ></v-text-field>
          </template>
          <v-card style="padding:10px;width: 292px !important;">
            <strong>{{$t("select_span_meals_listing")}}</strong><br>
            {{$t("click_on_two_dates")}}<br>
            <v-date-picker v-model="date" range no-title scrollable>
              <v-spacer></v-spacer>
              <v-btn text color="primary" @click="menu = false">
                {{ $t("cancel") }}
              </v-btn>
              <v-btn
                  text
                  color="primary"
                  @click="
                $refs.menu.save(date);
                load();
              "
              >
                {{ $t("confirm") }}
              </v-btn>
            </v-date-picker>
          </v-card>
        </v-menu>
      </v-col>
      <v-col :cols="tab == 1 ? 4 : 8">
        <div class="mt-3" v-if="$store.state.user.companies[0].type == 'more_restaurants' || $store.state.company.type == 'more_restaurants'">
          <v-select
              v-model="more_restaurant"
              v-if="more_restaurants.length > 0"
              :items="more_restaurants"
              multiple
              item-text="name"
              item-value="id"
              :label="$t('restaurant')"
              @input="changeCompany"
              hide-details
          ></v-select>
        </div>
        <v-autocomplete class="mt-8"
            v-model="company"
            v-if="companies.length > 1"
            :items="companies"
            dense
            multiple
            @input="changeCompany()"
            item-text="name"
            item-value="id"
            :label="$t('select_branch')"
            clear
            chips
            small-chips
        >
          <v-btn
              color="primary"
              slot="prepend-item"
              @click="toogleAllCompanies()"
              small
              class="mx-4"
          >
            {{ $t("display_all") }}
          </v-btn>
        </v-autocomplete>
<!--        <v-select-->
<!--          v-model="company"-->
<!--          v-if="companies.length > 1"-->
<!--          :items="companies"-->
<!--          multiple-->
<!--          item-text="name"-->
<!--          item-value="id"-->
<!--          :label="$t('select_branch')"-->
<!--          @input="changeCompany"-->
<!--          hide-details-->
<!--        ></v-select>-->
      </v-col>
      <v-col v-if="tab == 1 && $store.state.user.type != 'company'" cols="4">
        <v-autocomplete style="padding-top: 13px;width: 55%"
            v-model="pretragaSmena"
            :items="smene"
            :label="$t('shifts')"
            item-text="comp any"
            item-value="id"
            clear
            chips
            small-chips
        ></v-autocomplete>
        <v-btn v-if="$store.state.user.type != 'company'" :style="'border-radius: 8px;box-shadow: none;text-transform: capitalize'" @click="print(printTable)" small class="mt-2">
          <v-icon style="color:rgb(251, 109, 59)" class="mr-2">mdi-printer</v-icon>
        </v-btn>
      </v-col>
      <v-col v-if="tab == 3 || tab == 2 && $store.state.user.type == 'company'" cols="2">
        <v-btn :title="translate('Извезите податке у Excel')" :style="'border-radius: 8px;background: none;color:rgba(14,105,19,0.56);box-shadow: none;text-transform: capitalize'"
               @click="exportToExcel('', '', '', '')" small class="mt-5">
          <v-icon style="color:rgba(14,105,19,0.56)" class="mr-2">mdi mdi-microsoft-excel</v-icon>
        </v-btn>
        <v-btn :style="'border-radius: 8px;border: 1px solid rgb(251, 109, 59);background: none;color: rgb(251, 109, 59);box-shadow: none;text-transform: capitalize'" @click="print(printTable)" small class="mt-5">
          <v-icon style="color:rgb(251, 109, 59)" class="mr-2">mdi-printer</v-icon>
        </v-btn>
      </v-col>
      <v-col v-if="tab == 0" cols="1">
        <v-row>
        <v-btn :title="translate('Извезите податке у Excel')" :style="'border-radius: 8px;background: none;color:rgba(14,105,19,0.56);box-shadow: none;text-transform: capitalize'"
               @click="exportToExcel('', '', '', '')" small class="mt-8">
          <v-icon style="color:rgba(14,105,19,0.56)" class="mr-2">mdi mdi-microsoft-excel</v-icon>
        </v-btn>
        <v-btn :style="'border-radius: 8px;background: none;color: rgb(251, 109, 59);box-shadow: none;text-transform: capitalize'" @click="print(printTable)" small class="mt-8">
          <v-icon style="color:rgb(251, 109, 59)">mdi-printer</v-icon>
        </v-btn>
        </v-row>
      </v-col>
      <v-card v-if="$store.state.company && $store.state.company.type !== 'group'" style="width: 100%" elevation="0">
        <v-tabs v-model="tab" background-color="rgb(251, 109, 59)" dark>
          <v-tab @click="printTable = item.print;selectExcel(item)" style="width: 33%" v-for="(item) in items" :key="item.tab">
            <v-icon class="mr-2">{{ item.icon }}</v-icon>
            {{ translate(item.tab) }}
          </v-tab>
        </v-tabs>
        <v-tabs-items class="mt-5" v-model="tab">
          <!-- PREGLED NARUDZBI-->
          <v-tab-item>
            <v-card v-if="$store.state.user.companies[0].type == 'more_restaurants' || $store.state.company.type == 'more_restaurants'" flat>
              <v-card-text>
                <template v-for="(m, i) in more_restaurants_data['orders']">
                  <div class="d-flex align-items-center mb-4" style="justify-content: space-between;">
                    <h2>{{ i }}</h2>
                  </div>
                  <template v-for="(meals, inx) in more_restaurants_data['orders'][i]">
                    <h3 class="mb-2"><v-icon small>mdi-calendar</v-icon> {{ formatDate(inx) }}</h3>
                    <v-data-table
                        :search="pretraga"
                        :item-key="inx"
                        id="table14"
                        :headers="headersG"
                        :items="meals"
                        class="elevation-1 my-4"
                        mobile-breakpoint="0"
                    >
                      <template v-for="header in headersG" v-slot:[`header.${header.value}`]="{ header }">
                        {{ translate(header.text) }}
                        <span v-if="header.text == 'Poručeno' || header.text == 'Поручено'" class="d-flex flex-wrap" style="margin-top: -14px;justify-content: space-between;text-align: right;font-size: 10px;font-weight: 700;width: 280px">
                          <span style="width: 40%"></span>
                          <span style="width: 25%;font-weight: 700;margin-right: -32px">
                            {{ $t('amount') }}
                            {{ $store.state.restaurant && $store.state.restaurant.settings && $store.state.restaurant.settings.price ? "(" + $store.state.restaurant.settings.price + ")" : '' }}
                          </span>
                        </span>
                      </template>
                      <template v-slot:item.num="{ item, index }">
                        <span v-if="!item.total_price">#</span>
                        {{ item.menu !== "УКУПНО" ? translate(item.orders_guest_more[0].id) : "" }}
                      </template>
                      <template v-slot:item.name="{ item, index }">
                        <span style="display: block" :key="index" @click="showUsers(item)">{{ (item.orders_guest_more[0].delivery_type == 3 ? capitalizeFirstLetter(item.name) : capitalizeFirstLetter(item.name).charAt(0)) + '. ' + capitalizeFirstLetter(item.lastname) }}</span>
                        <span style="font-size: 11px;display: block;color: #333">{{ item.phone }}</span>
                      </template>
                      <template v-slot:item.address="{ item }">
                        <span class="order-address" style="text-align: left;display: block;width: 100%">
                           <span class="d-flex align-center" v-if="item.orders_guest_more[0].delivery_type == 4">
                             <img style="display: block;text-align: center;padding-right: 5px" src="https://app.ordera.app/gallery/qr-menu.png" width="38" alt="">
                             QR {{ $t('table') }}
                          </span>
                          <span v-if="item.orders_guest_more[0].delivery_type == 3">
                            <span class="location d-flex align-center" style="font-size: 12px;text-align: left">
                              <span>
                                <v-icon v-if="item.orders_guest_more[item.orders_guest_more.length - 1].location" style="font-size: 23px;margin-right: 5px;color: #A9A9A9" small>mdi-cube-send </v-icon> {{ item.orders_guest_more[item.orders_guest_more.length - 1].location && item.orders_guest_more[item.orders_guest_more.length - 1].location.address ? item.orders_guest_more[item.orders_guest_more.length - 1].location.address.replace(', Bosnia and Herzegovina', '').replace(', Bosna i Hercegovina', '').replace(', Босна и Херцеговина', '') : '' }}
                                <span style="display: block" v-if="item.orders_guest_more[0].delivery_type == 3 && item.orders_guest_more[item.orders_guest_more.length - 1].location && item.orders_guest_more[item.orders_guest_more.length - 1].location.city">{{ translate(item.orders_guest_more[item.orders_guest_more.length - 1].location.city.name) }}</span>
                              </span>
                            </span>
                          </span>
                          <span class="d-flex align-center" v-if="item.orders_guest_more[0].delivery_type == 2">
                            <v-icon style="margin-right: 7px;text-align: left;margin-right: 5px;color: #8B0000">mdi-silverware-variant</v-icon>
                            {{ $t('meal_in_restaurant') }}
                          </span>
                          <span class="d-flex align-center" v-if="item.orders_guest_more[0].delivery_type == 1">
                             <v-icon style="margin-right: 7px;display: block;text-align: left;margin-right: 5px;color: #FFD700">mdi-gift</v-icon>
                            {{ $t('takeover') }}
                          </span>
                          <span v-if="item.orders_guest_more[0].delivery_type == 0">
                            <span class="location d-flex align-center" style="font-size: 12px;text-align: left">
                              <v-icon v-if="item.orders_guest_more[item.orders_guest_more.length - 1].location" style="font-size: 23px;margin-right: 5px;color: #4CAF50" small>mdi-truck-fast </v-icon> {{ item.orders_guest_more[item.orders_guest_more.length - 1].location && item.orders_guest_more[item.orders_guest_more.length - 1].location.address ? item.orders_guest_more[item.orders_guest_more.length - 1].location.address.replace(', Bosnia and Herzegovina', '').replace(', Bosna i Hercegovina', '').replace(', Босна и Херцеговина', '') : '' }}
                            </span>
                          </span>
                        </span>
                      </template>
                      <template v-slot:item.time="{ item }">
                        <span class="location">
                          {{ formatTime(item.orders_guest_more[0].delivery_time) }}
                        </span>
                      </template>
                      <template v-slot:item.state="{ item }">
                        <span  class="location">
                          <span style="color: red;display: inline-block;text-align: left" v-if="item.orders_guest_more[0].state && item.orders_guest_more[0].state == 'deleted'">Obrisan obrok</span>
                        </span>
                      </template>
                      <template v-slot:item.qty="{ item }">
                        <template v-if="orders.order_guest_more_transactions && orders.order_guest_more_transactions.length < 3" v-for="(orders,index) in item.orders_guest_more">
                          <template v-for="(order, ii) in orders.order_guest_more_transactions">
                            <div class="d-flex justify-content-center align-content-center w-100 all-orders">
                              <div :title="order.meals || order.food ? translate(order.meals ? order.meals.name : order.food.name) : ''" class="all-orders-item mt-2">
                                {{ order.quantity }}x <span class="text-uppercase">{{ order.meals || order.food ? translate(order.meals ? order.meals.name : order.food.name) : '' }}</span>
                              </div>
                              <div class="all-orders-qty mt-2">
                                {{ order.meals || order.food ? order.meals ? (order.meals.price * order.quantity).toFixed(2) : (order.food.price * order.quantity).toFixed(2) : '' }}
                              </div>
                            </div>
                            <div v-if="order.prilog.length" v-for="(prilog, i) in order.prilog">
                              <div v-if="prilog.quantity > 0 && prilog.price > 0" style="border-bottom: 1px dashed #cfcfcf;color:#696969" class="d-flex mt-2 justify-content-center align-content-center w-100">
                                <div style="width: 52%;font-size: 12px;padding-bottom: 2px;color: #818181">{{ prilog.quantity }}x {{ translate(prilog.food.name) }}</div>
                                <div style="width: 47%;text-align: right;font-size: 12px;padding-bottom: 2px">{{ (prilog.quantity * prilog.price).toFixed(2) }}</div>
                              </div>
                            </div>
                          </template>
                          <!-- DUGME ZA OPEN DIALOG-->
                          <div style="position:relative;">
                            <a v-if="! item.total_price" :title="$t('order_details')" @click.prevent="openDialogDetails(orders, item, inx)" style="display: block;text-align: left;position: absolute;top: 23px;text-decoration: none" href="">
                              <v-icon style="color: #fb6d3b;padding: 2px;border-radius: 6px">mdi-printer</v-icon> {{ $t('order_details')}}
                            </a>
                          </div>
                          <!-- CENA DOSTAVE-->
  <!--                            <div style="border-bottom: 1px solid #cfcfcf;" v-if="canSeeDelivery(item.orders_guest, item)" class="d-flex justify-content-center align-content-center w-100">-->
  <!--                              <div style="width: 30%;font-size: 12px;padding-bottom: 2px;font-weight: 500;">{{ $t('delivery')}}:</div>-->
  <!--                              <div style="width: 70%;text-align: right;font-size: 12px;padding-bottom: 2px;font-weight: 500">-->
  <!--                                + {{ $store.state.restaurant.settings.guest.restoran_delivery ? $store.state.restaurant.settings.guest.delivery_price : ''}}-->
  <!--                              </div>-->
  <!--                            </div>-->
                          <!-- CENA-->
                          <div class="d-flex justify-content-center py-4 align-content-center w-100">
                            <div style="width: 30%;font-size: 12px;padding-bottom: 2px;font-weight: 700;"></div>
                            <div style="width: 68%;text-align: right;font-size: 12px;padding-bottom: 2px;font-weight: 700">
                              <span v-if="! item.total_price">{{ priceMealsMoreRestaurants(item.orders_guest_more, item) }}</span>
                              <span v-else>{{ (item.price).toFixed(2) }}</span>
                            </div>
                          </div>
                        </template>
                        <template style="width: 100%" v-else>
                          <div v-if="item.orders_guest_more.length" class="all-order-else d-flex justify-content-center align-content-center w-100 all-orders">
                            <div class="all-orders-items">
                              <div v-if="item.orders_guest_more[0].order_guest_more_transactions && item.orders_guest_more[0].order_guest_more_transactions[0].meals || item.orders_guest_more[0].order_guest_more_transactions && item.orders_guest_more[0].order_guest_more_transactions[1].meals">
                                <p class="mb-0" style="font-size: 13px;">{{ item.orders_guest_more[0].order_guest_more_transactions[0].meals.name }} </p>
                                <p v-if="item.orders_guest_more[0].order_guest_more_transactions[1] && item.orders_guest_more[0].order_guest_more_transactions[1].meals" class="mb-0" style="font-size: 13px;">{{ item.orders_guest_more[0].order_guest_more_transactions[1].meals.name }} </p>
                                <p class="mb-0" style="font-size: 13px;"> ... </p>
                              </div>
                              <div class="all-orders-items" v-if="item.orders_guest_more[0].order_guest_more_transactions && item.orders_guest_more[0].order_guest_more_transactions[0].food || item.orders_guest_more[0].order_guest_more_transactions && item.orders_guest_more[0].order_guest_more_transactions[1].food">
                                <p v-if="item.orders_guest_more[0].order_guest_more_transactions[0].food" class="mb-0">{{ item.orders_guest_more[0].order_guest_more_transactions[0].quantity }}x {{ item.orders_guest_more[0].order_guest_more_transactions[0].food.name }} </p>
                                <p v-if="item.orders_guest_more[0].order_guest_more_transactions[1].food" class="mb-0">{{ item.orders_guest_more[0].order_guest_more_transactions[1].quantity }}x {{ item.orders_guest_more[0].order_guest_more_transactions[1].food.name }} </p>
                                <p class="mb-0"> ... </p>
                              </div>
                            </div>
                            <div class="all-orders-item-prilog">
                              <p class="mb-1">{{ item.orders_guest_more[0].order_guest_more_transactions && item.orders_guest_more[0].order_guest_more_transactions[0].meals || item.orders_guest_more[0].order_guest_more_transactions && item.orders_guest_more[0].order_guest_more_transactions[0].food ? item.orders_guest_more[0].order_guest_more_transactions[0].meals ? (item.orders_guest_more[0].order_guest_more_transactions[0].meals.price * item.orders_guest_more[0].order_guest_more_transactions[0].quantity).toFixed(2) : (item.orders_guest_more[0].order_guest_more_transactions[0].food.price * item.orders_guest_more[0].order_guest_more_transactions[0].quantity).toFixed(2) : '' }}</p>
                              <p class="mb-1">{{ item.orders_guest_more[0].order_guest_more_transactions && item.orders_guest_more[0].order_guest_more_transactions[1].meals || item.orders_guest_more[0].order_guest_more_transactions && item.orders_guest_more[0].order_guest_more_transactions[1].food ? item.orders_guest_more[0].order_guest_more_transactions[1].meals ? (item.orders_guest_more[0].order_guest_more_transactions[1].meals.price * item.orders_guest_more[0].order_guest_more_transactions[1].quantity).toFixed(2) : (item.orders_guest_more[0].order_guest_more_transactions[1].food.price * item.orders_guest_more[0].order_guest_more_transactions[1].quantity).toFixed(2) : '' }}</p>
                            </div>
                          </div>
                          <div style="position: relative">
                            <a :title="$t('order_details')" @click.prevent="openDialogDetails(orders, item, inx)" style="display: block;position: absolute;top: 15px;text-decoration: none" href="">
                              <v-icon style="color: #fb6d3b;padding: 2px;border-radius: 6px">mdi-printer</v-icon> {{ $t('order_details')}}
                            </a>
                          </div>
                          <!-- CENA DOSTAVE-->
<!--                            <div style="border-bottom: 1px solid #cfcfcf;" v-if="canSeeDelivery(item.orders_guest, item)" class="d-flex justify-content-center align-content-center w-100">-->
<!--                              <div style="width: 30%;font-size: 12px;padding-bottom: 2px;font-weight: 500;">{{ $t('delivery')}}:</div>-->
<!--                              <div style="width: 67%;text-align: right;font-size: 12px;padding-bottom: 2px;font-weight: 500">-->
<!--                                + {{ $store.state.restaurant.settings.guest.restoran_delivery ? $store.state.restaurant.settings.guest.delivery_price : ''}}-->
<!--                              </div>-->
<!--                            </div>-->
                          <!-- CENA -->
                          <div class="d-flex justify-content-center py-4 align-content-center w-100">
                            <div style="width: 30%;font-size: 12px;padding-bottom: 2px;font-weight: 700;"></div>
                            <div style="width: 67%;text-align: right;font-size: 12px;padding-bottom: 2px;font-weight: 700">{{ priceMealsMoreRestaurants(item.orders_guest_more, item) }}</div>
                          </div>
                        </template>
                      </template>
                    </v-data-table>
                  </template>
                </template>
              </v-card-text>
            </v-card>
            <v-card v-else id="table3" flat>
              <v-card-text>
                <div id="table4">
                  <v-data-table v-if="searchOrders.length != 0"
                    :search="searchO"
                    id="table1"
                    :headers="headers4"
                    :items="filterObrok"
                    class="elevation-1"
                    hide-default-footer
                    disable-pagination
                  >
                    <template
                      v-for="header in headers3"
                      v-slot:[`header.${header.value}`]="{ header }"
                    >
                      {{ $t(header.text) }}
                    </template>
                    <template v-slot:item.menu="{ item }">
                      <span v-if="item" @click="showMeal(item.id)"> {{ item.menu == "УКУПНО" ? $t("totalU"): translate(item.menu) }}</span>
                      <!--                      <span style="font-size: 11px" v-if="item.user">| Korisnik: {{ item.user }}</span>-->
                    </template>
                    <template v-slot:item.shift_one="{ item }">
                      <span v-if="item">{{ translate(item.shift_one) }}</span>
                    </template>
                    <template v-slot:item.shift_two="{ item }">
                      <span v-if="item">{{ translate(item.shift_two) }}</span>
                    </template>
                    <template v-slot:item.shift_three="{ item }">
                      <span v-if="item">{{ translate(item.shift_three) }}</span>
                    </template>
                    <template v-slot:item.total="{ item }">
                      <span v-if="item">{{ translate(item.total) }}</span>
                    </template>
                  </v-data-table>
                </div>
                <v-data-table v-if="searchOrders.length == 0"
                  :search="searchO"
                  id="table1"
                  :headers="headers4"
                  :items="filterObrok"
                  class="elevation-1"
                  hide-default-footer
                  disable-pagination
                >
                  <template
                    v-for="header in headers3"
                    v-slot:[`header.${header.value}`]="{ header }"
                  >
                    {{ $t(header.text) }}
                  </template>
                  <template v-slot:item.menu="{ item }">
                    <span v-if="item" @click="showMeal(item.id)">{{ item.menu == "УКУПНО" ? $t("totalU"): translate(item.menu) }}</span>
                  </template>
                  <template v-slot:item.shift_one="{ item }">
                    <span v-if="item">{{ translate(item.shift_one) }}</span>
                  </template>
                  <template v-slot:item.shift_two="{ item }">
                    <span v-if="item">{{ translate(item.shift_two) }}</span>
                  </template>
                  <template v-slot:item.shift_three="{ item }">
                    <span v-if="item">{{ translate(item.shift_three) }}</span>
                  </template>
                  <template v-slot:item.total="{ item }">
                    <span v-if="item">{{ translate(item.total) }}</span>
                  </template>
                </v-data-table>

              </v-card-text>
            </v-card>
          </v-tab-item>
          <!-- OBROK RADNIK -->
          <v-tab-item>
            <v-card id="table4" flat>
              <template v-for="(comapny, companyKey) in desserts4">
                <h2 :class="companyKey == 0 ? '' : 'mt-5'">{{translate(comapny.company) + $t("meals_and_employees") }}</h2>
                <v-simple-table dense style="border: 1px solid #cfcfcf;">
                  <template v-slot:default>
                    <thead>
                      <tr>
                        <th class="text-left">
                          {{ $t("meal") }}
                        </th>
                        <template v-if="$store.state.company.shift_one">
                          <th class="text-center">{{ $t("1st_shift") }}</th>
                        </template>
                        <template v-if="$store.state.company.shift_two">
                          <th class="text-center">{{ $t("2nd_shift") }}</th>
                        </template>
                        <template v-if="$store.state.company.shift_three">
                          <th class="text-center">{{ $t("3rd_shift") }}</th>
                        </template>
                        <th class="text-center">
                          {{ $t("total") }}
                        </th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr v-for="(item, inx) in meals[comapny.company]" :key="inx">
                        <td class="text-left" @click="showMeal(item.id)">
                          {{ translate(item["name"]) }}
                        </td>
                        <template v-if="$store.state.company.shift_one">
                          <td class="text-left">
                            <template v-if="item.name == 'УКУПНО'">
                              {{ item["users"]["1-smena"] }}
                            </template>
                            <template
                              v-else
                              v-for="user in item['users']['1-smena']"
                            >
                              <span @click="showUsers(user)">
                                {{
                                  translate(user.name) +
                                  " " +
                                  translate(user.lastname)
                                }}
                                {{
                                  user.quantity > 0
                                    ? " (" + user.quantity + ")"
                                    : "" + ", "
                                }}
                              </span>
                            </template>
                          </td>
                        </template>
                        <template v-if="$store.state.company.shift_two">
                          <td class="text-left">
                            <template v-if="item.name == 'УКУПНО'">
                              {{ item["users"]["2-smena"] }}
                            </template>
                            <template
                              v-else
                              v-for="user in item['users']['2-smena']"
                            >
                              <span @click="showUsers(user)">
                                {{
                                  translate(user.name) +
                                  " " +
                                  translate(user.lastname)
                                }}
                                {{
                                  user.quantity > 1
                                    ? " (" + user.quantity + ")"
                                    : "" + ", "
                                }}
                              </span>
                            </template>
                          </td>
                        </template>
                        <template v-if="$store.state.company.shift_three">
                          <td class="text-left">
                            <template v-if="item.name == 'УКУПНО'">
                              {{ item["users"]["3-smena"] }}
                            </template>
                            <template
                              v-else
                              v-for="user in item['users']['3-smena']"
                            >
                              <span @click="showUsers(user)">
                                {{
                                  translate(user.name) +
                                  " " +
                                  translate(user.lastname)
                                }}
                                {{
                                  user.quantity > 1
                                    ? " (" + user.quantity + ")"
                                    : "" + ", "
                                }}
                              </span>
                            </template>
                          </td>
                        </template>
                        <td class="text-center">
                          {{ item["total"] }}
                        </td>
                      </tr>
                    </tbody>
                  </template>
                </v-simple-table>
              </template>
            </v-card>
          </v-tab-item>
          <v-tab-item>
            <v-card id="table5" flat>
               <template v-for="(comapny, companyKey) in desserts4">
                <h2 :class="companyKey == 0 ? '' : 'mt-5'">{{ translate(comapny.company) + translate(": радници") }}</h2>
                <v-data-table style="border: 1px solid #cfcfcf;"
                :headers="headers2"
                :items="users[comapny.company]"
                sort-by="status"
                class="elevation-1"
                dense
                hide-default-footer
                disable-pagination
              >
                <template
                  v-for="header in headers2"
                  v-slot:[`header.${header.value}`]="{ header }"
                >
                  {{ translate(header.text) }}
                </template>
                <template
                  v-for="values in headers2"
                  v-slot:[`item.${values.value}`]="{ item }"
                >
                  {{ translate(item[values.value]) }}
                </template>
                <template
                  v-for="values in headers2"
                  v-slot:item.num="{ item, index }"
                >
                  {{ index + 1 }}
                </template>
                <template v-slot:item.action="{ item }">
                  <a v-if="item.name != 'УКУПНО:'" href="" @click.prevent="openDialogReportUser1(item)">
                    <v-icon style="font-size: 17px" color="rgb(251, 109, 59)">mdi-format-list-bulleted</v-icon>
                  </a>
                </template>
              </v-data-table>
               </template>
            </v-card>
          </v-tab-item>
          <v-tab-item v-if="show_rep">
            <v-card id="table1">
              <template v-for="(comapny, companyKey) in desserts4">
                <v-data-table
                  :headers="headers3"
                  :items="comapny.foods"
                  class="elevation-1"
                  hide-default-footer
                  disable-pagination
                >
                  <template
                    v-for="header in headers3"
                    v-slot:[`header.${header.value}`]="{ header }"
                  >
                    {{ translate(header.text) }}
                  </template>
                  <template
                    v-for="values in headers3"
                    v-slot:[`item.${values.value}`]="{ item }"
                  >
                    {{ translate(item[values.value]) }}
                  </template>

                  <template v-slot:item.shift_one="{item}">
                    {{item.shift_one_payed}}/{{item.shift_one}}
                  </template>
                  <template v-slot:item.shift_two="{item}">
                    {{item.shift_two_payed}}/{{item.shift_two}}
                  </template>
                  <template v-slot:item.shift_three="{item}">
                   {{item.shift_three_payed}}/{{item.shift_three}}
                  </template>
                  <template v-slot:item.total="{item}">
                    {{item.total_payed}}/{{item.total}}
                  </template>
                  <template v-slot:item.menu="{ item }">
                    <span @click="showMeal(item.id)">{{translate(item.name) }}</span>
                  </template>
                  <template v-slot:item.num="{ item, index }">
                    {{ item.name!== "УКУПНО" ? index + 1 : "" }}
                  </template>
                </v-data-table>
              </template>
            </v-card>
          </v-tab-item>
          <!-- NARUCENO -->
          <v-tab-item v-if="show_orders">
            <v-card flat id="table36">
              <v-card-text style="display: flex;flex-wrap: wrap">
                <v-autocomplete style="width: 70%"
                                v-model="searchN"
                                :items="desserts4.filter((i) => i.company)"
                                dense
                                filled
                                :label="$t('select_company')"
                                item-value="id"
                                item-text="company"
                                @change="$store.state.user.companies[0].type == 'more_restaurants' || $store.state.company.type == 'more_restaurants' ? findMealsNarudzbeMoreRestaurants() : findMealsNarudzbe()"
                                clear
                                chips
                                small-chips
                ></v-autocomplete>
                <v-autocomplete style="width: 25%;margin-left: 10px"
                                v-model="searchByShift"
                                :items="Object.keys(narudzbe)"
                                dense
                                filled
                                :label="$t('shifts')"
                                item-text="company"
                                item-value="id"
                                clear
                                chips
                                small-chips
                ></v-autocomplete>
              </v-card-text>
              <div id="table34" style="display: flex;flex-wrap: wrap;">
                <div :style="Object.keys(searcByShift).length > 1 ? 'width: 32%;' : 'width: 100%;'" v-for="(items, inx) in searcByShift">
                  <p class="text-center font-weight-bold">
                    {{ inx == 1 ? $t('shift_one') : inx == 2 ? $t('shift_two') : $t('shift_three') }} (<v-icon>mdi-user</v-icon> {{ Object.keys(items).length }})
                  </p>
                  <div :style="Object.keys(searcByShift).length > 1 ? '' : 'display:flex;flex-wrap:wrap;'" >
                    <template v-for="(item, index) in items">
                      <div :style="Object.keys(searcByShift).length > 1 ? '' : 'width: 32%;'" style="padding: 20px;border:1px solid #cfcfcf;border-radius: 5px;">
                        <p :title="'ID:' + item[Object.keys(item)[0]][0].user_id" class="text-center pt-2">
                          <span style="color: #919191">#{{ item[Object.keys(item)[0]][0].order_id }}</span>
                          <span class="font-weight-bold pt-2"> {{ index }}</span>
                        </p>
                        <template v-for="(i, inx) in item">
                          <p :title="'ID:' + item[inx][0].meal_id">{{ item[inx][0].quantity }}<span style="color: #a4a4a4">x</span> {{ inx }}</p>
                        </template>
                      </div>
                    </template>
                  </div>
                </div>
              </div>
            </v-card>
          </v-tab-item>
          <v-tab-item v-if="show_orders">
            <v-card flat>
              <v-card-text>
                <v-data-table
                    id="table5"
                    :headers="headers1"
                    :items="total || []"
                    :items-per-page="-1"
                    class="elevation-1"
                    hide-default-footer
                    disable-pagination
                    mobile-breakpoint="0"
                >
                  <template v-for="header in headers1" v-slot:[`header.${header.value}`]="{ header }">
                    {{ translate(header.text) }}
                  </template>
                  <template v-for="values in headers1" v-slot:[`item.${values.value}`]="{ item }">
                    <span :title="values.value == 'total' ? $t('ordered_1') + '/' + $t('taken') : ''">{{ translate(item[values.value]) }}</span>
                    <a v-if="values.value == 'month'" @click.prevent="openDialogReportFirm(item)">
                      <v-icon style="font-size: 17px" color="primary">mdi-format-list-bulleted</v-icon>
                    </a>
                  </template>
                  <template v-for="values in headers1" v-slot:item.num="{ item, index }">
                    {{ item.menu !== "УКУПНО" ? index + 1 : "" }}
                  </template>
                </v-data-table>
              </v-card-text>
            </v-card>
          </v-tab-item>
          <!-- RESTORANI-->
          <v-tab-item>
            <v-card flat>
              <v-card-text>
                <v-data-table
                    id="table9"
                    :headers="headersK"
                    :items="childRestorans"
                    :items-per-page="-1"
                    class="elevation-1"
                    hide-default-footer
                    disable-pagination
                    mobile-breakpoint="0"
                >
                  <template v-for="header in headers" v-slot:[`header.${header.value}`]="{ header }">
                    {{ translate(header.text) }}
                  </template>
                  <template v-for="values in headers" v-slot:[`item.${values.value}`]="{ item }">
                    {{ translate(item[values.value]) }}
                  </template>
                  <template v-slot:item.num="{ item, index }">
                    {{ item.order_day == 'totalU' ? '' : index + 1 }}
                  </template>
                  <template v-slot:item.order_day="{ item, index }">
                    {{ item.order_day == 'totalU' ? $t('totalU') : item.order_day }}
                  </template>
                  <template v-slot:item.user="{ item, index }">
                    {{ item.username }} {{ item.lastname }}
                  </template>
                  <template v-slot:item.restaurant_name="{ item }">
                    {{ translate(item.restaurant_name) }}
                  </template>
                  <template v-slot:item.total_quantity="{ item }">
                    {{ translate(item.total_quantity) }}
                  </template>
                </v-data-table>
              </v-card-text>
            </v-card>
          </v-tab-item>

        </v-tabs-items>
      </v-card>
      <v-col v-else cols="12">
        <group-company-reports :date="date"></group-company-reports>
      </v-col>
    </v-row>

    <v-dialog v-model="dialog" max-width="60%" scrollable>
      <v-card>
        <v-card-text>
          <v-card elevation="0">
            <v-card-title>
              <v-spacer></v-spacer>
              <v-btn icon @click="dialog = false">
                <v-icon x-large>mdi-close</v-icon>
              </v-btn>
            </v-card-title>
            <v-card-text class="mb-4">
              <v-row>
                <v-col cols="12" sm="6">
                  <v-img
                    :src="
                      selectedMeal.image
                        ? conf.apiUrlBase +
                          '/gallery/' +
                          $store.state.company.restaurant_id +
                          '/medium/' +
                          selectedMeal.image.name
                        : 'https://cdn.vuetifyjs.com/images/cards/cooking.png'
                    "
                  >
                    <template v-slot:placeholder>
                      <v-row
                        class="fill-height ma-0"
                        align="center"
                        justify="center"
                      >
                        <v-progress-circular
                          indeterminate
                          color="grey lighten-5"
                        ></v-progress-circular>
                      </v-row>
                    </template>
                  </v-img>
                </v-col>
                <v-col cols="12" sm="6" class="ml-sm-n16 my-sm-2 mt-sm-16">
                  <v-row align="end" class="fill-height">
                    <v-card elevation="0" class="pa-5">
                      <v-card-title
                        ><h3>
                          {{ translate(selectedMeal.name) }}
                        </h3></v-card-title
                      >

                      <v-card-text class="mb-4">
                        <v-row no-gutters>
                          <template v-for="(eat, i) in selectedMeal.foods">
                            <v-col cols="12" class="font-weight-bold pt-2">
                              <v-row no-gutters justify="space-between">
                                <span><h3>{{ translate(eat.name) }}</h3></span>
                              </v-row>
                            </v-col>
                            <v-col cols="12">{{
                              translate(eat.description)
                            }}</v-col>
                          </template>
                        </v-row>
                      </v-card-text>
                    </v-card>
                  </v-row>
                </v-col>
              </v-row>
            </v-card-text>
          </v-card>
        </v-card-text>
      </v-card>
    </v-dialog>
    <v-dialog v-model="dialog2" max-width="60%" scrollable>
      <v-card>
        <v-card-text>
          <v-card elevation="0">
            <v-card-title>
              <v-spacer></v-spacer>
              <v-btn icon @click="dialog2 = false">
                <v-icon x-large>mdi-close</v-icon>
              </v-btn>
            </v-card-title>
            <v-card-text class="mb-4">
              <v-row>
                <v-col cols="12" sm="6">
                  <v-img
                    :src="
                      selectedUser.image_path
                        ? conf.apiUrlBase +
                          '/gallery/' +
                          selectedUser.image_path
                        : 'https://st3.depositphotos.com/13159112/17145/v/600/depositphotos_171453724-stock-illustration-default-avatar-profile-icon-grey.jpg'
                    "
                  >
                    <template v-slot:placeholder>
                      <v-row
                        class="fill-height ma-0"
                        align="center"
                        justify="center"
                      >
                        <v-progress-circular
                          indeterminate
                          color="grey lighten-5"
                        ></v-progress-circular>
                      </v-row>
                    </template>
                  </v-img>
                </v-col>
                <v-col cols="12" sm="6" class="ml-sm-n16 my-sm-2 mt-sm-16">
                  <v-row align="end" class="fill-height">
                    <v-card elevation="0" class="pa-5">
                      <v-card-title
                        ><h3>
                          {{
                            translate(selectedUser.name) +
                            " " +
                            translate(selectedUser.lastname)+
                            ' ('+selectedUser.id+')'
                          }}
                        </h3></v-card-title
                      >

                      <v-card-text class="mb-4">
                        <v-row no-gutters>
                          <v-col cols="12" class="font-weight-bold pt-2">
                            <v-row no-gutters justify="space-between">
                              <span
                                ><h3>{{ selectedUser.email }}</h3></span
                              >
                            </v-row>
                          </v-col>
                          <v-col cols="12"
                            >{{ $t("select_notifications") }}
                            {{
                              selectedUser.notifications
                                ? translate(selectedUser.notifications)
                                : $t("not_available")
                            }}</v-col
                          >
                          <v-col cols="12"> {{ selectedUser.phone }}</v-col>
                          <v-col cols="12">
                            {{ selectedUser.external_code }}</v-col
                          >
                          <v-col cols="12">
                            {{ selectedUser.address }}
                          </v-col>
                          <v-col cols="12">
                            {{
                              selectedUser.is_transferable
                                ? $t("can_change_location")
                                : ""
                            }}
                          </v-col>
                        </v-row>
                      </v-card-text>
                    </v-card>
                  </v-row>
                </v-col>
              </v-row>
            </v-card-text>
          </v-card>
        </v-card-text>
      </v-card>
    </v-dialog>
    <v-dialog v-model="dialogReport" max-width="90%" scrollable class="dialogReport">
      <v-container style="padding-bottom: 13px !important; height: 100% !important;max-width: 100% !important;">
        <v-row>
          <v-col cols="12" style="padding:0">
            <v-card>
              <div style="background-color: rgb(255, 248, 245);box-shadow: 0 2px 4px -1px rgba(0, 0, 0, 0.2);padding: 10px;display: flex;align-content: center;height: 64px">
                <div style="display: flex;align-items: center;width: 40%">
                  <img src="https://restorani.bitlab.host/img/o.png" alt="image" style="width: 54px;">
                  <div style="font-size: 15px;font-weight: 700;padding-left: 20px">{{ userReportItem.name }} {{ userReportItem.lastname }}, ID {{ userReportItem.id }}</div>
                </div>
                <v-btn style="position: absolute;right: 10px;top: 10px;" icon @click="dialogReport = false">
                  <v-icon x-medium>mdi-close</v-icon>
                </v-btn>
                <v-card-text align="center" class="title" style="width: 50%;text-align: left;display: flex;align-content: center;margin-top: -7px">
                  <span style='color:#fb6d3b'>
                    <v-icon style='color:#fb6d3b' small>mdi-calendar</v-icon> {{ monthName }} {{ year }}.
                  </span>
                </v-card-text>
              </div>
              <v-card-title style="padding: 6px 0 18px 0;display: flex">
                <div style="width: 60%;display: flex">
                  <v-dialog
                      ref="dialog"
                      v-model="modal"
                      :return-value.sync="date1"
                      persistent
                      width="290px"
                  >
                    <template v-slot:activator="{ on, attrs }">
                      <v-text-field  style="margin: 40px 0;padding: 0 10px"
                         v-model="date1"
                         label="Picker in dialog"
                         prepend-inner-icon="mdi-calendar"
                         readonly
                         v-bind="attrs"
                         v-on="on"
                         hide-details
                         class="px-2"
                      ></v-text-field>
                    </template>
                    <v-date-picker v-model="date1" type="month" scrollable>
                      <v-spacer></v-spacer>
                      <v-btn text color="primary" @click="modal = false">
                        Cancel
                      </v-btn>
                      <v-btn text color="primary" @click="$refs.dialog.save(date1), loadReport(userReportItem)">
                        OK
                      </v-btn>
                    </v-date-picker>
                  </v-dialog>
                  <v-text-field style="margin: 40px 0;padding: 0 10px"
                    v-model="searchR"
                    prepend-inner-icon="mdi-magnify"
                    label="Search"
                    single-line
                    hide-details
                    class="px-2"
                  ></v-text-field>
                </div>
                <div style="width: 40%;display: flex;align-content: center">
                  <v-checkbox style="padding: 0 10px"
                    :label="translate('Prikaži sve')"
                    hide-details
                    v-model="allDay"
                    @change="filterDay"
                  >
                  </v-checkbox>
                  <v-btn :title="translate('Извезите податке у Excel')" :style="'border-radius: 8px;background: none;color:rgba(14,105,19,0.56);box-shadow: none;text-transform: capitalize'"
                         @click="exportToExcel(reportSearch, userReportItem, true, '')" small class="mt-1">
                    <v-icon style="color:rgba(14,105,19,0.56)" class="mr-2">mdi mdi-microsoft-excel</v-icon>
                  </v-btn>
                </div>
              </v-card-title>
              <v-simple-table id="table222" class="py-2">
                <template v-slot:default>
                  <thead>
                  <tr>
                    <th class="text-left">{{ $t('date')}}</th>
                    <th class="text-left">{{  $t('shifts') }}</th>
                  </tr>
                  </thead>
                  <tbody>
                  <template v-for="item in reportSearch">
                    <tr>
                      <td>{{item.day}}</td>
                      <td>
                        <table width="100%">
                          <tr v-if="item.shiftOne && item.shiftOne.length">
                            <th class="px-4" width="200px" style="background: #f7f7f7">{{ $t('1st_shift')}}</th>
                            <td class="px-4">
                              <table width="100%">
                                <template v-for="menu in item.shiftOne">
                                  <tr>
                                    <td class="py-2" width="80%">{{menu.meal_name}}</td>
                                    <td class="py-2" align="center">{{menu.quantity}}</td>
                                  </tr>
                                </template>
                              </table>
                            </td>
                          </tr>
                          <tr v-if="item.shiftTwo && item.shiftTwo.length">
                            <th class="px-4" width="200px" style="background: #f7f7f7">{{ $t('2nd_shift')}}</th>
                            <td class="px-4">
                              <table width="100%">
                                <template v-for="menu in item.shiftTwo">
                                  <tr>
                                    <td class="py-2" width="80%">{{menu.meal_name}}</td>
                                    <td class="py-2" align="center">{{menu.quantity}}</td>
                                  </tr>
                                </template>
                              </table>
                            </td>
                          </tr>
                          <tr v-if="item.shiftThree && item.shiftThree.length">
                            <th class="px-4" width="200px" style="background: #f7f7f7">{{ $t('3rd_shift')}}</th>
                            <td class="px-4">
                              <table width="100%">
                                <template v-for="menu in item.shiftThree">
                                  <tr>
                                    <td class="py-2" width="80%">{{menu.meal_name}}</td>
                                    <td class="py-2" align="center">{{menu.quantity}}</td>
                                  </tr>
                                </template>
                              </table>
                            </td>
                          </tr>
                        </table>
                      </td>
                    </tr>
                  </template>
                  <tr>
                    <td class="title">{{ $t('totalU')}}:</td>
                    <td class="title" align="end">
                      <span class="mr-15 pr-4">{{ total3 }}</span>
                    </td>
                  </tr>
                  </tbody>
                </template>
              </v-simple-table>
            </v-card>
          </v-col>
        </v-row>
      </v-container>
    </v-dialog>
    <v-dialog v-model="dialogFirm" max-width="97%" scrollable class="dialogReport">
      <v-container style="padding-bottom: 13px !important; height: 100% !important;max-width: 100% !important;">
        <v-row>
          <v-col cols="12" style="padding:0">
            <v-card>
              <div style="background-color: rgb(255, 248, 245);box-shadow: 0 2px 4px -1px rgba(0, 0, 0, 0.2);padding: 10px;display: flex;align-content: center;height: 64px">
                <div style="display: flex;align-items: center;width: 40%">
                  <img src="https://restorani.bitlab.host/img/o.png" alt="image" style="width: 54px;">
                  <div style="font-size: 15px;font-weight: 700;padding-left: 20px">{{ firmReportItem.menu }}, ID
                    {{ firmReportItem.id }}
                  </div>
                </div>
                <v-btn style="position: absolute;right: 10px;top: 10px;" icon @click="dialogFirm = false">
                  <v-icon x-medium>mdi-close</v-icon>
                </v-btn>
                <v-card-text align="center" class="title" style="width: 50%;text-align: left;display: flex;align-content: center;margin-top: -7px">
                  <span><span v-if="ordered_meals">{{ $t('ordered_12') }}</span> <span v-if="taken_meals && ordered_meals">/</span> <span v-if="taken_meals"> {{ $t('taken1') }}</span> {{ $t('meals').toLowerCase() }}</span>
                  <span style='color:#fb6d3b;margin-left: auto'>
                    <v-icon style='color:#fb6d3b' small>mdi-calendar</v-icon> {{ monthName }} {{ year }}.
                  </span>
                </v-card-text>
              </div>
              <v-card-title style="padding: 6px 0 3px 0;display: flex">
                <div style="width: 45%;display: flex">
                  <v-dialog
                      ref="dialog"
                      v-model="modal"
                      :return-value.sync="date1"
                      persistent
                      width="290px"
                  >
                    <template v-slot:activator="{ on, attrs }">
                      <v-text-field style="margin: 40px 0;padding: 0 10px"
                                    v-model="date1"
                                    label="Picker in dialog"
                                    prepend-inner-icon="mdi-calendar"
                                    readonly
                                    v-bind="attrs"
                                    v-on="on"
                                    hide-details
                                    class="px-2"
                      ></v-text-field>
                    </template>
                    <v-date-picker v-model="date1" type="month" scrollable>
                      <v-spacer></v-spacer>
                      <v-btn text color="primary" @click="modal = false">
                        Cancel
                      </v-btn>
                      <v-btn text color="primary" @click="$refs.dialog.save(date1), loadReportFirm(firmReportItem)">
                        OK
                      </v-btn>
                    </v-date-picker>
                  </v-dialog>
                  <v-text-field style="margin: 40px 0;padding: 0 10px"
                                v-model="searchR"
                                prepend-inner-icon="mdi-magnify"
                                label="Search"
                                single-line
                                hide-details
                                class="px-2"
                  ></v-text-field>
                </div>
                <div style="width: 55%;display: flex;align-content: center">
                  <v-checkbox style="padding: 0 5px;"
                              label="Prikaži sve dane"
                              hide-details
                              v-model="allDayFirm"
                              @change="filterDayFirm"
                  >
                  </v-checkbox>
                  <v-checkbox style="padding: 0 5px;"
                              :label="$t('ordered_1')"
                              hide-details
                              v-model="ordered_meals"
                              @change="filterDayFirm"
                  >
                  </v-checkbox>
                  <v-checkbox v-if="firmReportItem.settings && firmReportItem.settings.can_see_ordered || $store.state.user.type == 'restaurant'" style="padding: 0 5px;"
                              :label="$t('taken')"
                              hide-details
                              v-model="taken_meals"
                              @change="filterDayFirm"
                  >
                  </v-checkbox>
                  <v-btn style="margin-left: auto" :title="translate('Извезите податке у Excel')" :loading="loading" :style="'border-radius: 8px;background: none;color:rgba(14,105,19,0.56);box-shadow: none;text-transform: capitalize'"
                         @click="customExcelFile = filterFirmsReport;exportToExcel(filterFirmsReport, '', headersFi, true)" small class="mt-2">
                    <v-icon style="color:rgba(14,105,19,0.56)" class="mr-2">mdi mdi-microsoft-excel</v-icon>
                  </v-btn>
                  <v-btn :title="translate('Штампајте податке')" style="margin: 10px 20px;padding: 10px;" @click="printTable = 'table222'; printDialog = true"
                         :style="'border-radius: 8px;background: none;color: ' + $store.state.company.restaurant.settings.topbar.background + ';box-shadow: none;text-transform: capitalize'"
                         small class="mt-2">
                    <v-icon :style="'color:' + $store.state.company.restaurant.settings.topbar.background" class="mr-2">
                      mdi-printer
                    </v-icon>
                    <span id="stampaj">
                    </span>
                  </v-btn>
                </div>
              </v-card-title>
              <v-data-table
                  :search="pretraga"
                  id="table222"
                  :headers="headersFi"
                  :items="filterFirmsReport"
                  :items-per-page="-1"
                  class="elevation-1"
                  hide-default-footer
                  disable-pagination
              >
                <template v-for="header in headersFi" v-slot:[`header.${header.value}`]="{ header }">
                  <span v-if="header.value !== 'shiftOneP' && header.value !== 'shiftTwoP' && header.value !== 'shiftThreeP' && header.value !== 'totalP' && header.value !== 'diff'">
                    <span v-if="header.value === 'shiftOne' && firmReportItem.shift_one1 !== 0 && ordered_meals">{{ $t(header.text) }}</span>
                    <span v-else-if="header.value === 'shiftTwo' && firmReportItem.shift_two2 !== 0 && ordered_meals">{{ $t(header.text) }}</span>
                    <span v-else-if="header.value === 'shiftThree' && firmReportItem.shift_three3 !== 0 && ordered_meals">{{ $t(header.text) }}</span>
                    <span v-else-if="header.value !== 'totalP' &&  header.value !== 'diff' && header.value !== 'shiftOne' && header.value !== 'shiftTwo' && header.value !== 'shiftThree' && ordered_meals">{{ $t(header.text) }}</span>
                    <span v-else-if="header.value === 'day'">{{ $t(header.text) }}</span>
                    <span v-if="header.value === 'shiftOne' && firmReportItem.shift_one1 !== 0 && ordered_meals"><br>{{ $t('ordered_1') }}</span>
                    <span v-if="header.value === 'shiftTwo' && firmReportItem.shift_two2 !== 0 && ordered_meals"><br>{{ $t('ordered_1') }}</span>
                    <span v-if="header.value === 'shiftThree' && firmReportItem.shift_three3 !== 0 && ordered_meals"><br>{{ $t('ordered_1') }}</span>
                    <span style="font-weight: 900;color:#000" v-if="header.value === 'total' && ordered_meals"><br>{{ $t('ordered_1') }}</span>
                  </span>
                  <span v-else>
                    <span v-if="ordered_meals && taken_meals && header.value === 'diff'">{{ $t(header.text) }}</span>
                    <span v-if="taken_meals && header.value !== 'diff'">
<!--                      {{ $t(header.text) }}-->
                      <span v-if="header.value === 'shiftOneP' && firmReportItem.shift_one1 !== 0">{{ $t(header.text) }}</span>
                      <span v-else-if="header.value === 'shiftTwoP' && firmReportItem.shift_two2 !== 0">{{ $t(header.text) }}</span>
                      <span v-else-if="header.value === 'shiftThreeP' && firmReportItem.shift_three3 !== 0">{{ $t(header.text) }}</span>
                      <span v-else-if="header.value === 'day' || header.value === 'totalP'">{{ $t(header.text) }}</span>
                      <span v-if="header.value === 'shiftOneP' && firmReportItem.shift_one1 !== 0"><br>{{ $t('taken') }}</span>
                      <span v-if="header.value === 'shiftTwoP' && firmReportItem.shift_two2 !== 0"><br>{{ $t('taken') }}</span>
                      <span v-if="header.value === 'shiftThreeP' && firmReportItem.shift_three3 !== 0"><br>{{ $t('taken') }}</span>
                      <span style="font-weight: 900;color:#000" v-if="header.value === 'totalP'"><br>{{ $t('taken') }}</span>
                    </span>
                  </span>
                </template>
                <template v-for="values in headersFi" v-slot:[`item.${values.value}`]="{ item }">
                  {{ $t(item[values.value]) }}
                </template>
                <template v-slot:item.day="{ item }">
                  <span v-if="item.day == 'UKUPNO' || item.day == 'УКУПНО' && taken_meals && firmReportItem.shift_one1 !== 0" style="font-weight: 700">{{item.day }}:</span>
                  <span v-else>{{ item.day }}</span>
                </template>
                <template v-slot:item.shiftOne="{ item }">
                  <span v-if="item.day == 'UKUPNO' || item.day == 'УКУПНО'" style="font-weight: 700;">
                    <span v-if="firmReportItem.shift_one1 !== 0 && ordered_meals">{{ item.shiftOne }}</span>
                  </span>
                  <span v-else>
                    <span v-if="firmReportItem.shift_one1 !== 0 && ordered_meals">
                      {{ item.shiftOne }}
                    </span>
                  </span>
                </template>
                <template v-slot:item.shiftOneP="{ item }">
                  <span v-if="item.day == 'UKUPNO' || item.day == 'УКУПНО'" style="font-weight: 700;">
                    <span v-if="taken_meals && firmReportItem.shift_one1 !== 0">{{ item.shiftOneP }}</span>
                  </span>
                  <span v-else>
                    <span v-if="taken_meals && firmReportItem.shift_one1 !== 0">{{ item.shiftOneP }}</span>
                  </span>
                </template>
                <template v-slot:item.shiftTwo="{ item }">
                  <span v-if="item.day == 'UKUPNO' || item.day == 'УКУПНО'" style="font-weight: 700;">
                    <span v-if="firmReportItem.shift_two2 !== 0 && ordered_meals">{{ item.shiftTwo }}</span>
                  </span>
                  <span v-else>
                    <span v-if="firmReportItem.shift_two2 !== 0 && ordered_meals">{{ item.shiftTwo }}</span>
                  </span>
                </template>
                <template v-slot:item.shiftTwoP="{ item }">
                  <span v-if="item.day == 'UKUPNO' || item.day == 'УКУПНО'" style="font-weight: 700;">
                    <span v-if="taken_meals && firmReportItem.shift_two2 !== 0">{{ item.shiftTwoP }}</span>
                  </span>
                  <span v-else>
                    <span v-if="taken_meals && firmReportItem.shift_two2 !== 0">{{ item.shiftTwoP }}</span>
                  </span>
                </template>
                <template v-slot:item.shiftThree="{ item }">
                  <span v-if="(item.day == 'UKUPNO' || item.day == 'УКУПНО')" style="font-weight: 700;">
                    <span v-if="firmReportItem.shift_three3 !== 0 && ordered_meals">{{ item.shiftThree }}</span>
                  </span>
                  <span v-else>
                     <span v-if="firmReportItem.shift_three3 !== 0 && ordered_meals">{{ item.shiftThree }}</span>
                  </span>
                </template>
                <template v-slot:item.shiftThreeP="{ item }">
                  <span v-if="item.day == 'UKUPNO' || item.day == 'УКУПНО'" style="font-weight: 700;">
                    <span v-if="taken_meals && firmReportItem.shift_three3 !== 0">{{ item.shiftThreeP }}</span>
                  </span>
                  <span v-else>
                    <span v-if="taken_meals && firmReportItem.shift_three3 !== 0">{{ item.shiftThreeP }}</span>
                  </span>
                </template>
                <template v-slot:item.total="{ item }">
                  <span style="font-weight: 700">
                    <span v-if="ordered_meals">{{ item.total }}</span>
                  </span>
                </template>
                <template v-slot:item.totalP="{ item }">
                  <span style="font-weight: 700">
                    <span v-if="taken_meals">{{ item.totalP }}</span>
                  </span>
                </template>
                <template v-slot:item.diff="{ item }">
                  <span style="font-weight: 700">
                    <span v-if="taken_meals && ordered_meals">
                      <span v-if="item.totalP != 0">{{ item.total-item.totalP }}</span>
                      <span v-else>0</span>
                    </span>
                  </span>
                </template>
              </v-data-table>
            </v-card>
          </v-col>
        </v-row>
      </v-container>
    </v-dialog>
    <v-dialog v-model="printDialog" max-width="600">
      <v-card>
        <v-card-title>Podesavanje Margina</v-card-title>
        <v-card-text>
          <v-row>
            <v-col cols="12">
              <v-row>
                <v-col cols="6">
                  <v-text-field label="Font Size" hide-details outlined dense v-model="margin.size" suffix="px"></v-text-field>
                </v-col>
              </v-row>
            </v-col>
            <v-col cols="6">
              <v-text-field label="Top" hide-details outlined dense v-model="margin.top" suffix="px"></v-text-field>
            </v-col>
            <v-col cols="6">
              <v-text-field label="Bottom" hide-details outlined dense v-model="margin.bottom" suffix="px"></v-text-field>
            </v-col>
            <v-col cols="6">
              <v-text-field label="Left" hide-details outlined dense v-model="margin.left" suffix="px"></v-text-field>
            </v-col>
            <v-col cols="6">
              <v-text-field label="Right" hide-details outlined dense v-model="margin.right" suffix="px"></v-text-field>
            </v-col>
          </v-row>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn outlined @click="printDialog = false">Close</v-btn>
          <v-btn @click="print(printTable)" color="primary">Print</v-btn>
          <v-spacer></v-spacer>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <v-dialog v-model="dialog_detail" :max-width="ordersClass" class="p-2">
      <v-card style="position: relative" v-if="dialog_detail || detail_data_all">
        <v-btn text style="padding: 0 12px;min-width: 30px;border-radius: 25px;position: absolute;top: 11px;color: #333 !important;right: 5px;border: 1px solid #888888" @click="dialog_detail = false">
          {{ translate("X") }}
        </v-btn>
        <v-btn style="position: absolute;top: 7px;right: 40px" @click="print('table18')" :style="'border-radius: 8px;background: none;box-shadow: none;text-transform: capitalize'" small class="mt-2">
          <span :title=" $t('print')" ><v-icon class="mr-2">mdi-printer</v-icon></span>
        </v-btn>
        <v-col cols="12" id="table18">
          <h3 class="mt-1 text-center" style="font-size: 15px;font-weight: 400;text-align: center">
            <span style="display: block;margin-bottom: 5px;position: absolute;left: 4px;top:3px"><img style="width: 50px;" :src="'https://restorani.bitlab.host/img/o.png'" alt="image"/></span>
            <span id="ord_num" style="font-size: 30px;padding: 0 40px;margin-top: 6px;display: inline-block">#{{ detail_data.id }}</span>
          </h3>
          <div class="mt-3 px-4 mx-2" style="width: 95%;border-bottom: 1px dashed #cfcfcf;"></div>
          <h2 class="ml-3 mt-3 text-center" style="font-size: 15px;font-weight: 400">
            {{ firstLetter(detail_data_all.name) }} {{ detail_data.delivery_type == 3 ? capitalizeFirstLetter(detail_data_all.lastname) : capitalizeFirstLetter(detail_data_all.lastname) }}
            <span class="ml-3 mb-0 text-center" style="font-size: 15px;"> <v-icon>mdi-phone</v-icon> {{ detail_data_all.phone }}</span>
            <span class="ml-3 mb-0 text-center" v-if="$store.state.user.type == 'admin'"><v-icon>mdi-email</v-icon>  {{ detail_data_all.email }}</span>
          </h2>
          <p class="ml-3 mt-0 mb-0 text-center" v-if="detail_data_all.orders_guest_more[0].delivery_type == 0 && detail_data.location && detail_data.location.address" style="font-size: 15px;">
            <v-icon>mdi mdi-map-marker</v-icon> {{ detail_data.location ? translate(detail_data.location.address).replace(', Bosnia and Herzegovina', '').replace(', Bosna i Hercegovina', '').replace(', Босна и Херцеговина', '') : '' }}
            <span v-if="detail_data.location.firm">{{ $t('firm')}}: {{ detail_data.location ? translate(detail_data.location.firm) : '' }} </span>
            <span v-if="detail_data.location.city"> {{ detail_data.location.city ? '| ' + translate(detail_data.location.city.name) : '' }}</span>
          </p>
          <p class="ml-3 mt-0 mb-0 text-center" v-if="detail_data_all.orders_guest_more[0].delivery_type == 0 || detail_data_all.orders_guest_more[0].delivery_type == 3 && detail_data.location" style="font-size: 15px;">
            <span class="ml-3 mb-0 text-center" v-if="detail_data.delivery_type == 3" style="font-size: 15px;"> {{ detail_data.location ? translate(detail_data.location.address) : '' }}{{ detail_data.location && detail_data.location.city ? ', ' + translate(detail_data.location.city.name) : ''  }}</span>
            <span v-if="detail_data.location && detail_data.location.apartment">{{ $t('apartment')}}: {{ detail_data.location && detail_data.location.apartment != null ? detail_data.location.apartment : '' }}</span>
            <span v-if="detail_data.location && detail_data.location.floor"> {{ $t('floor')}}: {{ detail_data.location ? detail_data.location.floor : '' }}</span>
          </p>
          <p class="ml-3 mt-0 mb-0 text-center font15">
            {{ $t('ordered_1')}}: {{ formatDateTime(detail_data.created_at) }}
            <span v-if="detail_data_all.orders_guest_more[0].delivery_type != 3" class="font-weight-bold"> | {{ $t('for') }}: {{ formatDate(detail_data_date) }} {{ formatTime(detail_data.delivery_time) }}</span>
          </p>
          <v-card-text>
            <template v-for="order in detail_data.order_guest_more_transactions">
              <div :style="order.meals || order.food ? 'border-bottom: 1px dashed #cfcfcf;border-top: 1px dashed #cfcfcf;padding-top: 5px' : ''" style="width: 100%;" class="d-flex justify-content-center align-content-center w-100">
                <div :title="order.meals || order.food ? translate(order.meals ? order.meals.name : order.food.name) : ''" style="width: 75%;padding-bottom: 5px;font-size: 15px;color: #000000;font-weight: 500">
                  {{ order.meals || order.food ? order.quantity + 'x ' : '' }}
                  <span style="text-transform:uppercase;">{{ order.meals || order.food ? translate(order.meals ? order.meals.name : order.food.name) : '' }}</span>
                </div>
                <div class="font15" style="width: 25%;text-align: right;padding-bottom: 2px">
                  {{ order.meals || order.food ? order.meals ? (order.meals.price * order.quantity).toFixed(2) : (order.food.price * order.quantity).toFixed(2) : '' }}
                </div>
              </div>
              <div v-if="order.prilog.length" v-for="prilog in order.prilog">
                <div style="color:#696969;width: 100%;text-transform:lowercase" class="d-flex justify-content-center align-content-center">
                  <div style="width: 56%;font-size: 12px;padding-bottom: 2px;color: #818181"> {{ prilog.quantity }}x {{ translate(prilog.food.name) }}</div>
                  <div class="font15" style="width: 45%;text-align: right;padding-bottom: 2px">{{ (prilog.quantity * prilog.price).toFixed(2) }}</div>
                </div>
              </div>
            </template>
            <div class="d-flex justify-content-center align-content-center" style="border-top: 1px dashed #cfcfcf">
              <div style="width: 55%;font-size: 15px;padding-bottom: 2px;font-weight: 700;">{{ $t('totalU')}} {{ $store.state.restaurant && $store.state.restaurant.settings && $store.state.restaurant.settings.price ? "(" + $store.state.restaurant.settings.price + ")" : '' }}:</div>
              <div v-if="detail_data_all" class="font15" style="width: 45%;text-align: right;padding-bottom: 2px;font-weight: 700">
                {{ priceMealsMoreRestaurants(detail_data_all.orders_guest_more, detail_data) }}
              </div>
            </div>
          </v-card-text>
        </v-col>
      </v-card>
    </v-dialog>
    <v-dialog v-model="errorToManyAttempts" max-width="600">
      <v-card class="text-center">
        <v-card-title style="width: 100%">{{ translate('Превише захтева')}}</v-card-title>
        <v-card-text>
          <p>{{ translate('Превише захтева је послато на сервер, молимо вас сачекајте један минут па пробајте поново') }}</p>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn outlined @click="errorToManyAttempts = false">{{ $t('close')}}</v-btn>
          <v-spacer></v-spacer>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import axios from "../plugins/axios";
import moment from "moment";
import conf from "../config";
import groupCompanyReports from '../components/groupCompany/reports'
import {capitalize} from "vuetify-loader/lib/util";

export default {
  components: {groupCompanyReports},
  data() {
    return {
      moment: moment,
      allDay: false,
      printDialog: false,
      dialogFirm: false,
      loading: false,
      pretragaSmena: '',
      searchO: '',
      searchOrders: [],
      smene: [],
      searchN: '',
      narudzbe: '',
      conf: conf,
      date: [new Date().toISOString().substr(0, 10)],
      date1: new Date().toISOString().substr(0, 10),
      menu: false,
      search: "",
      searchByShift: "",
      report: null,
      changes: null,
      dialog: false,
      printTable: 'table3',
      margin: {
        top: 100,
        bottom: 100,
        left: 100,
        right: 100,
        size: 16
      },
      meals: null,
      users: [],
      tab: null,
      selCompany:null,
      dialog2: false,
      modal: false,
      companies:[],
      company: [],
      all_companies: '',
      more_restaurant: '',
      more_restaurants: '',
      selectedMeal: {
        foods: [],
        name: "",
        image: {
          name: "",
        },
      },
      selectedUser: {
        name: "",
        lastname: "",
        image_path: "",
      },
      items: [
        { id: 1, tab: this.$t("meals"), content: "Tab 1 Content", print: "table3", icon: "mdi-clipboard-list-outline",},
        { id: 2, tab: "Оброк-радници", content: "Tab 2 Content", print: "table4", icon: "mdi-food" },
        { id: 3, tab: "Радници", content: "Tab 3 Content", print: "table5", icon: "mdi-account-settings",},
      ],
      headers: [
        {text: "Id", align: "start", value: "id",},
        {text: "Име", align: "start", value: "name",},
        {text: "Презиме", align: "start", value: "lastname",},
        {text: "Долази из", align: "start", value: "from",},
        {text: "Долази у", align: "end", value: "to",},
      ],
      headers1: [
        {text: this.$t('r_b'), value: "num",},
        {text: this.$t('grocery'), value: "menu"},
        {text: this.$t('1st_shift'), value: "shift_one"},
        {text: this.$t('2nd_shift'), value: "shift_two"},
        {text: this.$t('3rd_shift'), value: "shift_three"},
        {text: this.$t('total'), value: "total"},
        {text: this.$t('months'), value: "month"},
      ],
      headers2: [
        {text: "R.Br.", value: "num",},
        {text: this.$t('name'), value: "name",},
        {text: this.$t('last_name'), value: "lastname",},
        {text: this.$t('ordered'), value: "order_count",},
        {text: this.$t('report'), value: "action",},
      ],
      headers3: [
        {
          text: "Р.Бр.",
          value: "num",
        },
        { text: "Оброк", value: "menu" },
        { text: "Смјена 1", value: "shift_one" },
        { text: "Смјена 2", value: "shift_two" },
        { text: "Смјенa 3", value: "shift_three" },
        { text: "Укупно", value: "total" },
      ],
      headers4: [
        { text: this.$t('meals'), value: "menu" },
        { text: this.$t('shift_one'), value: "shift_one" },
        { text: this.$t('shift_two'), value: "shift_two" },
        { text: this.$t('shift_three'), value: "shift_three" },
        { text: this.$t('total'), value: "total" },
      ],
      headersK: [
        {text: this.$t('r_b'), value: "num",},
        {text: this.$t('date'), value: "order_day",},
        {text: this.$t('restaurant'), value: "restaurant_name"},
        {text: this.$t('users'), value: "user",},
        {text: this.$t('meal_num'), value: "total_quantity"},
        {text: this.$t('price'), value: "total_amount"},
        {text: this.$t('user_pay'), value: "total_amount"},
        {text: this.$t('firm_pay'), value: "total_amount"},
      ],
      headersG: [
        { text: this.$t('ID'), value: "id", sortable: true},
        { text: this.$t('r_b'), value: "num", sortable: true},
        { text: this.$t('name_surname'), value: "name", sortable: true },
        { text: this.$t('address'), value: "address", sortable: true },
        { text: this.$t('time'), value: "time", sortable: true },
        { text: this.$t('ordered'), value: "qty", sortable: false },
        { text: this.$t('status'), value: "state", sortable: true },
      ],
      headersFi: [
        {text: this.$t('day'), value: "day"},
        {text: this.$t('1st_shift'), value: "shiftOne", align: 'center'},
        {text: this.$t('1st_shift'), value: "shiftOneP", align: 'center'},
        {text: this.$t('2nd_shift'), value: "shiftTwo", align: 'center'},
        {text: this.$t('2nd_shift'), value: "shiftTwoP", align: 'center'},
        {text: this.$t('3rd_shift'), value: "shiftThree", align: 'center'},
        {text: this.$t('3rd_shift'), value: "shiftThreeP", align: 'center'},
        {text: this.$t('total'), value: "total", align: 'center'},
        {text: this.$t('total'), value: "totalP", align: 'center'},
        {text: this.$t('difference'), value: "diff", align: 'center'},
      ],
      pretraga: '',
      dessertsReport: [],
      dessertsR: [],
      dessertsReportAll: [],
      dialogReport: false,
      userReportItem: '',
      searchR: '',
      show_orders: false,
      show_rep: false,
      can_see_ordered: false,
      excelData: '',
      excelHeading: '',
      excelTab: '',
      excelDataUsers: '',
      childRestorans: '',
      restaurant_ids: [],
      more_restaurants_data: '',
      dialog_detail: false,
      detail_data: '',
      detail_data_all: '',
      detail_data_date: '',
      total: [],
      firmReports: '',
      firmsReportsAll: '',
      allDayFirm: false,
      firmReportItem: '',
      taken_meals: false,
      errorToManyAttempts: false,
      ordered_meals: true,
        customExcelFile: ''
    };
  },
  watch: {
    pretragaSmena() {
      this.$root.$emit('pretragaSmena', this.pretragaSmena)
    },
    company(old, news) {
      if(this.company.length < 2) {
        this.headers2.splice(3,0,{text: this.$t('shifts'), value: "shift",},)
      } else {
        this.headers2.filter((item, index) => {
          if (item.value == 'shift') {
            this.headers2.splice(index, 1)
          }
        })
      }
    },
  },
  created() {
    this.$root.$on('changedTab', (tab) => {
      this.tab = tab
    })
    this.$root.$on('smene', (smene) => {
      this.smene = smene
      this.pretragaSmena = smene[0]
    })
    this.$root.$on('openDialog', (data) => {
      this.userReportItem = data
      // this.dialogReport = true
    })
    // this.company.id = this.$store.state.company.id;
    // this.company.name = this.$store.state.company;
    this.company.push({
      'id': this.$store.state.company.id,
      'name': this.$store.state.company.name
    })
    // axios.get("/companies/list/" + this.company[0].id).then((res)=> {
    //   res.data.forEach(el => {
    //     this.companies.push(
    //       {
    //         'id': el[0],
    //         'name':el[1]
    //       }
    //     );
    //   })
    // })
    let comp = []
    this.company.filter(item => {
      comp.push(item.id)
    })
    axios.get("/companies/lists?companies=" + comp).then((res)=> {
      res.data.forEach(el => {
        this.companies.push({
          'id': el[0],
          'name':el[1]
        });
      })
    })

    if(this.$store.state.company.checkReport) {
      this.show_rep = true
        this.items.push({ tab: "Преузети оброци", content: "Tab 4 Content", print: 'table1', icon: "mdi-check-underline-circle" })
    }
    if(this.$store.state.company.settings && typeof this.$store.state.company.settings === "string" && JSON.parse(this.$store.state.company.settings).can_see_report) {
      this.show_orders = true
      this.items.push({ id: 4, tab: 'НАРУЧЕНО', content: "Tab 35 Content", print: "table36", icon: "mdi-grid",},)
    } else if (this.$store.state.company.settings && this.$store.state.company.settings.can_see_report) {
      this.show_orders = true
      this.items.push({ id: 4, tab: 'НАРУЧЕНО', content: "Tab 35 Content", print: "table36", icon: "mdi-grid",},)
    }
    if(this.$store.state.company.settings && typeof this.$store.state.company.settings === "string" && JSON.parse(this.$store.state.company.settings).can_see_ordered) {
      this.can_see_ordered = true
      this.headers2.splice(4,0,{text: this.$t('taken'), value: "order_payed",},)
    } else
    if(this.$store.state.company.settings && this.$store.state.company.settings.can_see_ordered) {
      this.can_see_ordered = true
      this.headers2.splice(4,0,{text: this.$t('taken'), value: "order_payed",},)
    }
    if (this.$store.state.company.type == 'more_restaurants') {
      this.items.push(
          {id: 5, tab: "Rесторани", content: "Tab 4 Content", print: "table6", icon: "mdi-silverware-fork-knife",},
      )
    }
    if(this.$store.state.company.checkReport) {
      this.show_rep = true
      this.items.push({ tab: "ПРЕУЗЕТО", content: "Tab 7 Content", print: 'table6', icon: "mdi-check-underline-circle" })
    }
    this.load();
  },
  methods: {
    toogleAllCompanies() {
      if (this.company.length > 0) {
        this.company = []
      } else if (this.company.length == 0) {
        this.company = []
        this.companies.filter(item => {
          this.company.push(item.id)
        })
        this.changeCompany()
      }
    },
    loadReportFirm(firmReportItem) {
      if (this.select_view == 2) {
        this.kategorije = ''
        this.kategorije1 = ''
        axios.post('/user/food-orders-by-month', {
          month: this.month,
          year: this.year,
          user_id: firmReportItem.id
        }).then(res => {
          this.dessertsRF = res.data
          let categories = this.groupBy(res.data, 'category_name')
          let datum = this.groupBy(res.data, 'date')
          let podaci = {}
          let ukupnoPorudzbina = {}
          let data = {}
          for (let date in datum) {
            let dan = moment(date).format('D')
            data[dan] = {}
            ukupnoPorudzbina[dan] = {}
            podaci[dan] = this.groupBy(datum[date], 'shift')
            for (let shift in podaci[dan]) {
              data[dan][shift] = {}
              ukupnoPorudzbina[dan][shift] = {}
              let sortirano = Object.keys(categories).slice().sort((a, b) => a.localeCompare(b))
              //podaci[dan][shift].slice().sort((a, b) => a.category_name.localeCompare(b.category_name))
              sortirano.filter(category => {
                data[dan][shift][category] = []
              })
              podaci[dan][shift].filter(it => {
                if (data[dan][shift][it.category_name]) {
                  data[dan][shift][it.category_name].push(it)
                }
              })
              for (let cat in data[dan][shift]) {
                ukupnoPorudzbina[dan][shift] = this.groupBy(data[dan][shift][cat], 'order_food_id')
              }
            }
          }
          this.ukupnoPorudzbina = ukupnoPorudzbina
          this.kategorije = Object.keys(categories).slice().sort((a, b) => a.localeCompare(b))
          this.kategorije1 = data
          this.report3F()
        }).finally(() => {
          this.loading = false
        })
      } else {
        axios.get(
            "/reports/daily/restaurant/" +
            this.$store.state.restaurant.id +
            "/firms-reports?date=" +
            moment(this.date1[0]).format("YYYY-MM-DD") +
            "&date2=" +
            moment(this.date1[1]).format("YYYY-MM-DD") + '&company=' + firmReportItem.id + '&year=' + this.year + '&month=' + this.month
        ).then((res) => {
          this.firmReports = res.data
        });
      }
    },
    report3Firm() {
      this.firmsReportsAll = {}
      for (let day = 1; day <= this.daysInMonth; day++) {
        let checks = Object.keys(this.firmReports).filter(item => {
          return item === moment(this.year + '-' + this.month + '-' + day).format('YYYY-MM-DD')
        })
        if (checks.length) {
          let dd = moment(this.year + '-' + this.month + '-' + day).format('YYYY-MM-DD')
          this.firmsReportsAll[dd] = []
          this.firmReports[checks].forEach(check => {
            if (check.shift === 1) {
              this.firmsReportsAll[dd].push(check)
            } else if (check.shift === 2) {
              this.firmsReportsAll[dd].push(check)
            } else if (check.shift === 3) {
              this.firmsReportsAll[dd].push(check)
            }
          })
        } else {
          let d = moment(this.year + '-' + this.month + '-' + day).format('YYYY-MM-DD')
          this.firmsReportsAll[d] = []
        }
      }
      // this.dessertsReportAll = [...this.firmReports]
      this.filterDayFirm()
    },
    filterDayFirm() {
      if (this.allDayFirm) {
        this.firmReports = this.firmsReportsAll
      } else {
        this.firmReports = this.firmReportsAll
      }
      this.$forceUpdate()
    },
    openDialogReportFirm(item) {
      let settingsObject = ''
      axios.get(
          "/reports/daily/restaurant/" +
          this.$store.state.company.restaurant.id +
          "/firms-reports?date=" +
          moment(this.date1[0]).format("YYYY-MM-DD") +
          "&date2=" +
          moment(this.date1[1]).format("YYYY-MM-DD") + '&company=' + item.id + '&year=' + this.year + '&month=' + this.month
      ).then((res) => {
        this.firmReports = res.data
        let company_settings = Object.values(res.data);
        let settingsString = company_settings[0][0].settings;
        settingsObject = JSON.parse(JSON.parse(settingsString));
        item.settings = settingsObject
        item.shift_one1 = company_settings[0][0].shift_one
        item.shift_two2 = company_settings[0][0].shift_two
        item.shift_three3 = company_settings[0][0].shift_three
        this.firmReportsAll = res.data
        this.report3Firm()
      });

      this.dialogFirm = true
      this.firmReportItem = item
      this.printTable = 'table15'
    },
    priceMeals(meal,item) {
      if (!meal.total) {
        let total = 0;
        let totalP = 0;
        meal.filter(or => {
          if (or.order_guest_transactions) {
            or.order_guest_transactions.filter(m => {
              if (m.prilog && m.prilog.length) {
                m.prilog.filter(pr => {
                  totalP += (parseFloat(pr.price) * pr.quantity)
                })
              }
              if (m.meals && m.meals.price) {
                total += (parseFloat(m.meals.price) * m.quantity)
              } else if (m.food && m.food.price) {
                total += (parseFloat(m.food.price) * m.quantity)
              }
            })
          }
        })
        // if(this.$store.state.restaurant.settings.guest.restoran_delivery && this.canSeeDelivery(meal, item)) {
        //   let deliveryPrice = this.$store.state.restaurant.settings.guest.delivery_price ? this.$store.state.restaurant.settings.guest.delivery_price : 0
        //   return total + totalP + parseFloat(deliveryPrice)
        // }
        return (total + totalP).toFixed(2)
      }
    },
    priceMealsMoreRestaurants(meal,item) {
      if (!meal.total) {
        let total = 0;
        let totalP = 0;
        meal.filter(or => {
          if (or.order_guest_more_transactions) {
            or.order_guest_more_transactions.filter(m => {
              if (m.prilog && m.prilog.length) {
                m.prilog.filter(pr => {
                  totalP += (parseFloat(pr.price) * pr.quantity)
                })
              }
              if (m.meals && m.meals.price) {
                total += (parseFloat(m.meals.price) * m.quantity)
              } else if (m.food && m.food.price) {
                total += (parseFloat(m.food.price) * m.quantity)
              }
            })
          }
        })
        // if(this.$store.state.restaurant.settings.guest.restoran_delivery && this.canSeeDelivery(meal, item)) {
        //   let deliveryPrice = this.$store.state.restaurant.settings.guest.delivery_price ? this.$store.state.restaurant.settings.guest.delivery_price : 0
        //   return total + totalP + parseFloat(deliveryPrice)
        // }
        return (total + totalP).toFixed(2)
      }
    },
    openDialogDetails(orders, data, datum) {
      this.dialog_detail = true
      this.detail_data = orders
      this.detail_data_all = data
      this.detail_data_date = datum
    },
    selectExcel(tab) {
      let heading = []
      if (tab.id == 1) {
        this.headers4.filter(item => {
          heading.push(this.$t(item.value))
        })
        this.excelTab = this.translate('Преглед наруџби')
        this.excelHeading = heading
        let data = this.filterObrok
        if (data.length) {
          data.forEach(item => {
            if (item) {
              delete item['id']
            }
          })
        }
        this.excelData = data
      }
      if (tab.id == 3) {
        this.headers2.filter(item => {
          if (item.value != 'action') {
            heading.push(item.text)
          }
        })
        this.excelTab = this.translate('Преглед корисника')
        this.excelHeading = heading
        if (this.excelDataUsers) {
          this.excelData = this.excelDataUsers
        }
        if (this.excelData) {
          this.excelData.forEach(item => {
            delete item.ordered
            delete item.user
            if (item.id == 0) {
              item.shift = ''
              delete item.total
            }
          })
        }
      }
    },
    exportToExcel(exportFile, firm, custom, customFirm) {
      let data = this.excelData
      let companyName = firm ? firm.name + ' ' + firm.lastname + ' ID: ' + firm.id : this.$store.state.company.name
      let heading = this.excelHeading
      if (this.tab == 2) {
        data = this.excelDataUsers
      }
      if (this.tab == 0) {
        heading = []
        this.headers4.filter(item => {
          heading.push(this.$t(item.text))
        })
        this.filterObrok.forEach(item => {
          delete item['id']
        })
        data = this.filterObrok
      }
      if (custom) {
        heading = []
        heading.push(this.$t('date'))
        heading.push(this.$t('shifts'))
        heading.push(this.$t('meals'))
        heading.push(this.$t('quantity'))
      }

      if (exportFile && exportFile != '') {
        let podaci = []
        let total = 0
        exportFile.filter(item => {
          if (item.shiftOne && item.shiftOne.length) {
            item.shiftOne.filter(items => {
              podaci.push({
                'day': item.day,
                'shifts': this.$t('shift_one'),
                'meals': items.meal_name,
                'total':  items.quantity
              })
              total += items.quantity
            })
          }
          if (item.shiftTwo && item.shiftTwo.length) {
            item.shiftTwo.filter(items => {
              podaci.push({
                'day': item.day,
                'shift': this.$t('shift_two'),
                'meals': items.meal_name,
                'total':  items.quantity
              })
              total += items.quantity
            })
          }
          if (item.shiftThree && item.shiftThree.length) {
            item.shiftThree.filter(items => {
              podaci.push({
                'day': item.day,
                'shift': this.$t('shift_two'),
                'meals': items.meal_name,
                'total':  items.quantity
              })
              total += items.quantity
            })
          }
        })
        podaci.push({
          'day': this.$t('total'),
          'shift': '',
          'meals': '',
          'total': total
        })
        data = podaci
      }
      if (custom && customFirm) {
        let podaci = []
        let podaci1 = []
        let podaci2 = []
        let test = custom
        test.filter(header => {
          if (header.value == 'shiftOneP' || header.value == 'shiftTwoP' || header.value == 'shiftThreeP' || header.value == 'totalP') {
            podaci.push(this.$t(header.text) + ' ' + this.$t('taken'))
            podaci1.push(this.$t(header.text) + ' ' + this.$t('taken'))
          } else if (header.value == 'shiftOne' || header.value == 'shiftTwo' || header.value == 'shiftThree' || header.value == 'total') {
            podaci.push(this.$t(header.text) + ' ' + this.$t('ordered_1'))
            podaci1.push(this.$t(header.text) + ' ' + this.$t('ordered_1'))
          } else {
            podaci.push(this.$t(header.text))
            podaci1.push(this.$t(header.text))
          }
        })
        if (this.ordered_meals) {
          test.filter(item => {
            if (item.value == 'day' || item.value == "shiftOne" || item.value == 'shiftTwo' || item.value == 'shiftThree' || item.value == 'total') {
              podaci2.push(this.$t(item.text) + ' ' + this.$t('ordered_1'))
            }
          })
        }
        if (this.taken_meals) {
          test.filter(item1 => {
            if (item1.value == 'day' || item1.value == 'shiftOneP' || item1.value == 'shiftTwoP' || item1.value == 'shiftThreeP' || item1.value == 'totalP') {
              podaci2.push(this.$t(item1.text) + ' ' + this.$t('taken'))
            }
          })
        }
        heading = podaci2
        if (this.ordered_meals && this.taken_meals) {
          heading = podaci1
        }
      }
      if (exportFile && customFirm) {
        let prepData = []
        exportFile.filter(item => {
          item.diff = item.total - item.totalP
        })
        if (this.ordered_meals) {
          exportFile.filter(item => {
            if (item.day || item.shiftOne || item.shiftTwo || item.shiftThree || item.total) {
              prepData.push({
                'day': item.day,
                'shiftOne': item.shiftOne,
                'shiftTwo': item.shiftTwo,
                'shiftThree': item.shiftThree,
                'total': item.total,
              })
            }
          })
        }
        if (this.taken_meals) {
          exportFile.filter(item1 => {
            if (item1.day || item1.shiftOneP || item1.shiftTwoP || item1.shiftThreeP || item1.totalP) {
              prepData.push({
                'day': item1.day,
                'shiftOne': item1.shiftOneP,
                'shiftTwo': item1.shiftTwoP,
                'shiftThree': item1.shiftThreeP,
                'total': item1.totalP,
              })
            }
          })
        }
        data = prepData
        if (this.ordered_meals && this.taken_meals) {
          data = exportFile
        }
        // data = exportFile
      }
      let restaurantName = this.$store.state.company.name
      let datum = this.date[0]
      let tab = this.excelTab
      axios.post('/reports/export-excel', {
        data: data,
        firm: companyName.length >= 1 ? companyName : companyName.toUpperCase(),
        heading: heading,
        restaurant: restaurantName.toUpperCase(),
        date: datum,
        tab: tab
      }, { responseType: 'blob' })
        .then(response => {
          const url = window.URL.createObjectURL(new Blob([response.data]));
          const link = document.createElement('a');
          link.href = url;
          link.setAttribute('download', (companyName ? companyName.length > 2 ? 'report' : companyName : restaurantName) +'.xlsx');
          document.body.appendChild(link);
          link.click();
          // Clean up
          window.URL.revokeObjectURL(url);
          document.body.removeChild(link);
        })
        .catch(error => {
          console.error("There was an error downloading the file!", error);
        });
    },
    firstLetter(lastname) {
      if (lastname) {
        return capitalize(lastname[0]) + '.'
      }
    },
    formatDate(date) {
      return moment(date).format('DD.MM.')
    },
    formatDateTime(date) {
      return moment(date).format('DD.MM. hh:mm')
    },
    findMealsNarudzbe() {
      let niz = []
      if (Array.isArray(this.searchN)) {
        niz = this.searchN
      } else {
        niz.push(this.searchN)
      }
      this.narudzbe = ''
      axios.get(
          "/reports/daily/company/" +
          niz.join() +
          "/userlist?date=" +
          moment(this.date[0]).format("YYYY-MM-DD") +
          "&date2=" +
          moment(this.date[1]).format("YYYY-MM-DD")
      )
        .then((res) => {
          let t = {}
          let korisnici = []
          for(let i in res.data) {
            if(i == 'company') {
              t[res.data.company.name] = res.data
            } else
            if(res.data[i].company) {
              t[res.data[i].company.name] = res.data[i]
            }

            if(res.data[i].meals) {
              res.data[i].meals.filter(meal => {
                if (meal.name != 'УКУПНО') {
                  if (meal.users['1-smena']) {
                    meal.users['1-smena'].filter(user => {
                      korisnici.push({
                        'meal_name': meal.name,
                        'company_id': res.data[i].company.id,
                        'meal_id': meal.id,
                        'quantity': user.quantity,
                        'shift': 1,
                        'name': user.name,
                        'lastname': user.lastname,
                        'user_id': user.id,
                        'order_id': meal.order_id
                      })
                    })
                  }
                  if (meal.users['2-smena']) {
                    meal.users['2-smena'].filter(user => {
                      korisnici.push({
                        'meal_name': meal.name,
                        'company_id': res.data[i].company.id,
                        'meal_id': meal.id,
                        'quantity': user.quantity,
                        'shift': 2,
                        'name': user.name,
                        'lastname': user.lastname,
                        'user_id': user.id,
                        'order_id': meal.order_id
                      })
                    })
                  }
                  if (meal.users['3-smena']) {
                    meal.users['3-smena'].filter(user => {
                      korisnici.push({
                        'meal_name': meal.name,
                        'company_id': res.data[i].company.id,
                        'meal_id': meal.id,
                        'quantity': user.quantity,
                        'shift': 3,
                        'name': user.name,
                        'lastname': user.lastname,
                        'user_id': user.id,
                        'order_id': meal.order_id
                      })
                    })
                  }
                }
              })
            }
          }

          let podaci = []
          let smena = this.groupBy(korisnici, 'shift')
          let priprema = {}
          let pr = {}
          for (let i in smena) {
            priprema[i] = this.groupBy(smena[i], 'user_id')
            pr[i] = {}
            for (let n in priprema[i]) {
              // pr[i][priprema[i][n][0].name + ' ' + priprema[i][n][0].lastname] = priprema[i][n]
              pr[i][priprema[i][n][0].name + ' ' + priprema[i][n][0].lastname] = this.groupBy(priprema[i][n], 'meal_name')
            }
          }
          this.narudzbe = pr
          this.searchByShift = Object.keys(this.narudzbe)[0]
        });
    },
    findMealsNarudzbeMoreRestaurants() {
      let niz = []
      if (Array.isArray(this.searchN)) {
        niz = this.searchN
      } else {
        niz.push(this.searchN)
      }
      this.narudzbe = ''
      axios.get(
          "/reports/daily/more-restaurants-company/" +
          niz.join() +
          "/userlist?date=" +
          moment(this.date[0]).format("YYYY-MM-DD") +
          "&date2=" +
          moment(this.date[1]).format("YYYY-MM-DD")
      )
        .then((res) => {
          let t = {}
          let korisnici = []
          for(let i in res.data) {
            if(i == 'company') {
              t[res.data.company.name] = res.data
            } else
            if(res.data[i].company) {
              t[res.data[i].company.name] = res.data[i]
            }

            if(res.data[i].meals) {
              res.data[i].meals.filter(meal => {
                if (meal.name != 'УКУПНО') {
                  if (meal.users['1-smena']) {
                    meal.users['1-smena'].filter(user => {
                      korisnici.push({
                        'meal_name': meal.name,
                        'company_id': res.data[i].company.id,
                        'meal_id': meal.id,
                        'quantity': user.quantity,
                        'shift': 1,
                        'name': user.name,
                        'lastname': user.lastname,
                        'user_id': user.id,
                        'order_id': meal.order_id
                      })
                    })
                  }
                  if (meal.users['2-smena']) {
                    meal.users['2-smena'].filter(user => {
                      korisnici.push({
                        'meal_name': meal.name,
                        'company_id': res.data[i].company.id,
                        'meal_id': meal.id,
                        'quantity': user.quantity,
                        'shift': 2,
                        'name': user.name,
                        'lastname': user.lastname,
                        'user_id': user.id,
                        'order_id': meal.order_id
                      })
                    })
                  }
                  if (meal.users['3-smena']) {
                    meal.users['3-smena'].filter(user => {
                      korisnici.push({
                        'meal_name': meal.name,
                        'company_id': res.data[i].company.id,
                        'meal_id': meal.id,
                        'quantity': user.quantity,
                        'shift': 3,
                        'name': user.name,
                        'lastname': user.lastname,
                        'user_id': user.id,
                        'order_id': meal.order_id
                      })
                    })
                  }
                }
              })
            }
          }

          let podaci = []
          let smena = this.groupBy(korisnici, 'shift')
          let priprema = {}
          let pr = {}
          for (let i in smena) {
            priprema[i] = this.groupBy(smena[i], 'user_id')
            pr[i] = {}
            for (let n in priprema[i]) {
              // pr[i][priprema[i][n][0].name + ' ' + priprema[i][n][0].lastname] = priprema[i][n]
              pr[i][priprema[i][n][0].name + ' ' + priprema[i][n][0].lastname] = this.groupBy(priprema[i][n], 'meal_name')
            }
          }
          this.narudzbe = pr
          this.searchByShift = Object.keys(this.narudzbe)[0]
        });
    },
    groupBy(array, key){
      const result = {}
      array.forEach(item => {
        if (!result[item[key]]){
          result[item[key]] = []
        }
        result[item[key]].push(item)
      })
      return result
    },
    filterDay() {
      if(this.allDay) {
        this.dessertsReport = [...this.dessertsReportAll]
      }
      else {
        this.dessertsReport = this.dessertsReportAll.filter(item => {
          if(item.shiftOne && item.shiftOne.length) {
            return item
          }
          if(item.shiftTwo && item.shiftTwo.length) {
            return item
          }
          if(item.shiftThree && item.shiftThree.length) {
            return item
          }
        })
      }
      this.$forceUpdate()
    },
    loadReport(item) {
      this.loading = true
      axios.post('/user/orders-by-month', {
        month: this.month,
        year: this.year,
        user_id: item.id
      }).then(res => {
        this.dessertsR = res.data
        this.report3()
      }).finally(() => {
        this.loading = false
      })
    },
    report3() {
      this.dessertsReport = []
      for(let day = 1; day <= this.daysInMonth; day++) {
        let checks = this.dessertsR.filter(item => {
          return item.date === moment(this.year + '-' + this.month + '-' + day).format('YYYY-MM-DD')
        })
        if(checks.length) {
          checks.forEach(check => {
            let index = this.dessertsReport.findIndex(item => item.day === day)
            if(check.shift === 1) {
              if(index > 0) {
                if(!this.dessertsReport[index].shiftOne) this.dessertsReport[index].shiftOne = []
                this.dessertsReport[index].shiftOne.push(check)
              }
              else {
                this.dessertsReport.push({day: day, shiftOne: [check]})
              }
            }
            else if (check.shift === 2) {
              if(index > 0) {
                if(!this.dessertsReport[index].shiftTwo) this.dessertsReport[index].shiftTwo = []
                this.dessertsReport[index].shiftTwo.push(check)
              }
              else {
                this.dessertsReport.push({day: day, shiftTwo: [check]})
              }
            }
            else if (check.shift === 3) {
              if(index > 0) {
                if(!this.dessertsReport[index].shiftThree) this.dessertsReport[index].shiftThree = []
                this.dessertsReport[index].shiftThree.push(check)
              }
              else {
                this.dessertsReport.push({day: day, shiftThree: [check]})
              }
            }
          })
        }
        else {
          this.dessertsReport.push({day, shiftOne: [], shiftTwo: [], shiftThree: []})
        }
      }
      this.dessertsReportAll = [...this.dessertsReport]
      this.filterDay()
    },
    openDialogReportUser1(item) {
      this.dialogReport = true
      this.userReportItem = item
      this.printTable = 'table11'
      this.loadReport(item)
    },
    async changeCompany(){
      if (this.$store.state.company.type == 'more_restaurants') {
        this.restaurant_ids = []
        // axios.get('/companies/' + this.$store.state.company.id  + '/get-restaurants-for-worker-more-restaurants').then(({data}) => {
        await axios.get('/companies/' + this.$store.state.company.id  + '/show-restaurants?restaurant=' + this.more_restaurant).then(({data}) => {
          this.more_restaurants = data
          data.filter(item => {
            this.restaurant_ids.push(item.id)
          })
        })
        let podaci = {}
        podaci['company_id'] = [this.$store.state.company.id]
        podaci['restaurants'] = this.more_restaurant
        podaci['date'] = moment(this.date[0]).format("YYYY-MM-DD")
        podaci['date2'] = moment(this.date[1]).format("YYYY-MM-DD")
        await axios.post('/reports/daily/restaurant/more-restaurants-orders', podaci).then(({data}) => {
          this.more_restaurants_data = data
        })
        await axios.get("/reports/daily/restaurant/total-restaurant-orders?date=" + moment(this.date[0]).format("YYYY-MM-DD") +
            "&date2=" + moment(this.date[1]).format("YYYY-MM-DD") + "&restaurants=" + this.more_restaurant
        ).then((data) => {
          this.childRestorans = data.data
        })
        let com = [];
        this.company.filter(item => {
          if (item instanceof Object) {
            com.push(item.id)
          } else {
            com.push(item)
          }
        })
        let data = {}
        data['date'] = moment(this.date[0]).format("YYYY-MM-DD")
        data['date2'] = moment(this.date[1]).format("YYYY-MM-DD")
        data['company_id'] = com
        data['restaurants'] = this.more_restaurant
        await axios.post("/reports/daily/company-news-more-restaurants", data).then((res) => {
          this.report = res.data;
        });

        await axios.post("/reports/daily/company-more-restaurant/userlists", data).then((res) => {
          let jela = {}
          let korisnici = {}
          let sve_kompanije = {}
          if (res.data) {
            for (let i in res.data) {
              jela[i] = res.data[i].meals
              korisnici[i] = res.data[i].users
              sve_kompanije[i] = res.data[i].company
            }
          }
          this.meals = jela;
          this.users = korisnici;
          this.excelDataUsers = this.users[this.$store.state.company.name]
          this.excelDataUsers.forEach(item => {
            delete item.ordered
            delete item.user
            if (item.id == 0) {
              item.shift = ''
              delete item.total
            }
          })
          this.all_companies = sve_kompanije;
        });
        this.$forceUpdate()
      } else {
        this.load()
      }
    },
    showMeal(id) {
      axios.get("/meals/" + id).then((res) => {
        this.selectedMeal = res.data;
        this.dialog = true;
      });
    },
    showUsers(user) {
      this.selectedUser = user;
      this.dialog2 = true;
    },
    formatTime(time) {
      if(time) {
        let formatedTime = time.split(':')
        return formatedTime[0] + ':' + formatedTime[1]
      }
    },
    capitalizeFirstLetter(str) {
      return str ? str.charAt(0).toUpperCase() + str.slice(1) : '';
    },
    getReport() {
      let companies = []
      this.company.filter(item => {
        if (item.id) {
          companies.push(item.id)
        } else {
          companies.push(item)
        }
      })
      this.errorToManyAttempts = false
      const startAt = moment(this.date[0]).format("YYYY-MM-DD")
      const endAt = moment(this.date[1]).format("YYYY-MM-DD")
      const url =
          '/reports/daily/company/' + companies +
          '/food-korisnici?date='+  startAt +
          '&date2=' +  endAt

      axios.get(url).then((res) => {
        let order_count_total = 0;
        let order_payed_total = 0;
        let order_meals_total = 0;

        res.data.users.forEach(user => {
          order_count_total += user.order_count
          order_payed_total += user.order_payed
          order_meals_total += user.meals
        })
        res.data.users.push({
          'id': 'УКУПНО:',
          'order_count': order_count_total,
          'order_payed': order_payed_total,
          'meals': order_meals_total
        })

        this.$root.$emit('getNewCompany', res.data.users)
      }).catch((error) => {
       console.log(error.response.data.message)
       if (error.response.status == 429) {
         this.errorToManyAttempts = true
       }
       setTimeout(() => {
         this.errorToManyAttempts = false
       }, 8000)
      });
    },
    async load() {
      if (this.date.length == 1) {
        this.date.push(this.date[0]);
      }
      if (this.date[0] > this.date[1])[this.date[0], this.date[1]] = [this.date[1], this.date[0]];

      // axios
      //   .get(
      //     "/reports/daily/company-new/" +
      //       this.company[0].id +
      //       "?date=" +
      //       moment(this.date[0]).format("YYYY-MM-DD")+
      //       "&date2="+
      //       moment(this.date[1]).format("YYYY-MM-DD")
      //   )
      //   .then((res) => {
      //     this.report = res.data;
      //   });
      let com = [];
      this.company.filter(item => {
        if (item instanceof Object) {
          com.push(item.id)
        } else {
          com.push(item)
        }
      })
      let data = {}
      data['date'] = moment(this.date[0]).format("YYYY-MM-DD")
      data['date2'] = moment(this.date[1]).format("YYYY-MM-DD")
      data['company_id'] = com
      // axios.post("/reports/daily/company-news", data).then((res) => {
      axios.get("/reports/daily/restaurant/" + this.$store.state.company.restaurant.id + "/obrok-report?company=" + com + "&date=" +moment(this.date[0]).format("YYYY-MM-DD") +
          "&date2=" +
          moment(this.date[1]).format("YYYY-MM-DD")).then((res) => {
        this.report = res.data;
      });

      axios.post("/reports/daily/company/locations", data).then((res) => {
        this.changes = res.data;
      });

      axios.get(
          "/reports/daily/total/" +
          this.$store.state.company.restaurant.id +
          "?date=" +
          moment(this.date[0]).format("YYYY-MM-DD") +
          "&date2=" +
          moment(this.date[1]).format("YYYY-MM-DD")
      ).then((res) => {
        this.total = res.data;
        this.total.filter(item => {
          item.name = item.menu
        })
      })
      // axios
      //   .get(
      //     "/reports/daily/company/" +
      //       this.company[0].id +
      //       "/userlist?date=" +
      //       moment(this.date[0]).format("YYYY-MM-DD")+
      //       "&date2="+
      //       moment(this.date[1]).format("YYYY-MM-DD")
      //   )
      //   .then((res) => {
      //     this.meals = res.data.meals;
      //     this.users = res.data.users;
      //   });
      axios.post("/reports/daily/company/userlists", data).then((res) => {
        let jela = {}
        let korisnici = {}
        let sve_kompanije = {}
        if (res.data) {
          for (let i in res.data) {
            jela[i] = res.data[i].meals
            korisnici[i] = res.data[i].users
            sve_kompanije[i] = res.data[i].company
          }
        }
        this.meals = jela;
        this.users = korisnici;
        this.excelDataUsers = this.users[this.$store.state.company.name]
        this.excelDataUsers.forEach(item => {
          delete item.ordered
          delete item.user
          if (item.id == 0) {
            item.shift = ''
            delete item.total
          }
        })
        this.all_companies = sve_kompanije;
      });
      if (this.$store.state.company.type == 'more_restaurants') {
        this.restaurant_ids = []
        // axios.get('/companies/' + this.$store.state.company.id  + '/get-restaurants-for-worker-more-restaurants').then(({data}) => {
        await axios.get('/companies/' + this.$store.state.company.id  + '/show-restaurants').then(({data}) => {
          this.more_restaurants = data
          data.filter(item => {
            this.restaurant_ids.push(item.id)
          })
        })
        let podaci = {}
        podaci['company_id'] = [this.$store.state.company.id]
        podaci['restaurants'] = this.restaurant_ids
        podaci['date'] = moment(this.date[0]).format("YYYY-MM-DD")
        podaci['date2'] = moment(this.date[1]).format("YYYY-MM-DD")
        await axios.post('/reports/daily/restaurant/more-restaurants-orders', podaci).then(({data}) => {
          this.more_restaurants_data = data
        })
        await axios.get("/reports/daily/restaurant/total-restaurant-orders?date=" + moment(this.date[0]).format("YYYY-MM-DD") +
            "&date2=" + moment(this.date[1]).format("YYYY-MM-DD") + "&restaurants=" + this.restaurant_ids
        ).then((data) => {
          this.childRestorans = data.data
        })

        let data = {}
        data['date'] = moment(this.date[0]).format("YYYY-MM-DD")
        data['date2'] = moment(this.date[1]).format("YYYY-MM-DD")
        data['company_id'] = com
        await axios.post("/reports/daily/company-news-more-restaurants", data).then((res) => {
          this.report = res.data;
        });

        await axios.post("/reports/daily/company-more-restaurant/userlists", data).then((res) => {
          let jela = {}
          let korisnici = {}
          let sve_kompanije = {}
          if (res.data) {
            for (let i in res.data) {
              jela[i] = res.data[i].meals
              korisnici[i] = res.data[i].users
              sve_kompanije[i] = res.data[i].company
            }
          }
          this.meals = jela;
          this.users = korisnici;
          this.excelDataUsers = this.users[this.$store.state.company.name]
          this.excelDataUsers.forEach(item => {
            delete item.ordered
            delete item.user
            if (item.id == 0) {
              item.shift = ''
              delete item.total
            }
          })
          this.all_companies = sve_kompanije;
        });
      }
      this.getReport()
    },
    print(id, type = false) {
        const prtHtml = document.getElementById(id).innerHTML;
        let stylesHtml = "";
        for (const node of [
          ...document.querySelectorAll('link[rel="stylesheet"], style'),
        ]) {
          stylesHtml += node.outerHTML;
        }
        const WinPrint = window.open(
            "",
            "",
            "left=100,top=0,width=800,height=900,toolbar=0,scrollbars=0,status=0"
        );

        WinPrint.document.write(
            `<!DOCTYPE html>
          <html>
            <head>
              ${stylesHtml}
              <style>
             table {
              font-family: arial, sans-serif;
              border-collapse: collapse;
              width: 100%;
            }

            td, th {
              border: 1px solid #dddddd;
              text-align: left;
              padding: 8px;
            }

            tr:nth-child(even) {
              background-color: #f1f1f1;
            }
            </style>

            </head>
            <body>
              ${this.$store.state.company.restaurant.name}: ${this.name}   ${moment(this.date[0]).format("DD.MM.YYYY.") + '-' + moment(this.date[1]).format("DD.MM.YYYY.")}
              <strong>${prtHtml}</strong>
            </body>
          </html>`);

        // WinPrint.document.close();
        // WinPrint.focus();
        // WinPrint.print();
        // WinPrint.close();

        WinPrint.document.close();
        WinPrint.focus();

        WinPrint.onload = function () {
          WinPrint.print();
          WinPrint.close();
        }

    },
  },
  computed: {
    ordersClass() {
      if (window.innerWidth <= 768) {
        return '95%';
      }
      else if (window.innerWidth <= 820) {
        return '75%';
      }
      else if (window.innerWidth <= 1024) {
        return '45%';
      } else {
        return '47%';
      }
    },
    filterFirmsReport() {
      let arr = {}
      let arr1 = {}
      for (let i in this.firmReports) {
        arr[i.split('-')[2]] = {}
        arr1[i.split('-')[2]] = {}

        arr[i.split('-')[2]]['shiftOne'] = []
        arr[i.split('-')[2]]['shiftTwo'] = []
        arr[i.split('-')[2]]['shiftThree'] = []
        arr[i.split('-')[2]]['shiftOneP'] = []
        arr[i.split('-')[2]]['shiftTwoP'] = []
        arr[i.split('-')[2]]['shiftThreeP'] = []

        arr1[i.split('-')[2]]['shiftOne'] = []
        arr1[i.split('-')[2]]['shiftTwo'] = []
        arr1[i.split('-')[2]]['shiftThree'] = []
        if (this.firmReports[i]) {
          this.firmReports[i].filter(item => {
            if (item.quantity > 0) {
              if (item.shift == 1) {
                arr[i.split('-')[2]]['shiftOne'].push(item)
              }
              if (item.shift == 2) {
                arr[i.split('-')[2]]['shiftTwo'].push(item)
              }
              if (item.shift == 3) {
                arr[i.split('-')[2]]['shiftThree'].push(item)
              }
            }

            if (item.quantity_payed > 0) {
              if (item.shift == 1) {
                arr1[i.split('-')[2]]['shiftOne'].push(item)
                arr[i.split('-')[2]]['shiftOneP'].push(item)
              }
              if (item.shift == 2) {
                arr1[i.split('-')[2]]['shiftTwo'].push(item)
                arr[i.split('-')[2]]['shiftTwoP'].push(item)
              }
              if (item.shift == 3) {
                arr1[i.split('-')[2]]['shiftThree'].push(item)
                arr[i.split('-')[2]]['shiftThreeP'].push(item)
              }
            }
          })
        }
      }
      for (let n in arr) {
        arr[n].shiftOne = arr[n].shiftOne.length
        arr[n].shiftOneP = arr[n].shiftOneP.length
        arr[n].shiftTwo = arr[n].shiftTwo.length
        arr[n].shiftTwoP = arr[n].shiftTwoP.length
        arr[n].shiftThree = arr[n].shiftThree.length
        arr[n].shiftThreeP = arr[n].shiftThreeP.length
        arr[n].total = arr[n].shiftOne + arr[n].shiftTwo + arr[n].shiftThree
        arr[n].totalP = arr[n].shiftOneP + arr[n].shiftTwoP + arr[n].shiftThreeP
      }
      for (let m in arr1) {
        arr1[m].shiftOne = arr1[m].shiftOne.length
        arr1[m].shiftTwo = arr1[m].shiftTwo.length
        arr1[m].shiftThree = arr1[m].shiftThree.length
        arr1[m].total = arr1[m].shiftOne + arr1[m].shiftTwo + arr1[m].shiftThree
      }
      let arr2 = []
      for (let m in arr) {
        arr2.push({
          'day': m + '.',
          'shiftOne': arr[m].shiftOne,
          'shiftOneP': arr[m].shiftOneP,
          'shiftTwo': arr[m].shiftTwo,
          'shiftTwoP': arr[m].shiftTwoP,
          'shiftThree': arr[m].shiftThree,
          'shiftThreeP': arr[m].shiftThreeP,
          'total': arr[m].total,
          'totalP': arr[m].totalP,
        })
      }

      arr2.sort((a, b) => {
        return a.day - b.day;
      });
      let shiftOneT = 0
      let shiftTwoT = 0
      let shiftThreeT = 0
      let shiftOneP = 0
      let shiftTwoP = 0
      let shiftThreeP = 0
      arr2.filter(item => {
        shiftOneT += item.shiftOne
        shiftTwoT += item.shiftTwo
        shiftThreeT += item.shiftThree
        shiftOneP += item.shiftOneP
        shiftTwoP += item.shiftTwoP
        shiftThreeP += item.shiftThreeP
      })
      arr2.push({
        'day': this.$t('totalU'),
        'shiftOne': shiftOneT,
        'shiftOneP': shiftOneP,
        'shiftTwo': shiftTwoT,
        'shiftTwoP': shiftTwoP,
        'shiftThree': shiftThreeT,
        'shiftThreeP': shiftThreeP,
        'total': shiftOneT + shiftTwoT + shiftThreeT,
        'totalP': shiftOneP + shiftTwoP + shiftThreeP,
      })

      return arr2
    },
    filterObrok() {
      let arr2 = []
      this.desserts4.filter((company) => {
        company.foods.filter((food,ind) => {
          arr2.push({
            'id': food.id,
            'name': food.name,
            'shift_one': food.shift_one,
            'shift_two': food.shift_two,
            'shift_three': food.shift_three,
            'total': food.total
          })
        })
      })
      let newArr = this.groupBy(arr2, 'name')
      let name = ''
      let id = ''
      let finalArray = []
      for (let i in newArr) {
        let shift_one = 0
        let shift_two = 0
        let shift_three = 0
        let totalN = 0
        newArr[i].filter((item) => {
          if (item.name == i) {
            name = i;
            id = item.id;
            shift_one += item.shift_one;
            shift_two += item.shift_two;
            shift_three += item.shift_three;
            totalN += item.total;
          }
        })
        finalArray.push({
          'id': id,
          'menu': name,
          'shift_one': shift_one,
          'shift_two': shift_two,
          'shift_three': shift_three,
          'total': totalN,
        })
      }
      // console.log(finalArray)
      let result = [...finalArray];

      const index = result.findIndex(e => e.menu === 'УКУПНО')
      result.push(result.splice(index, 1)[0])
      return result
    },
    searcByShift() {
      let byShift = {}
      if (this.searchByShift) {
        byShift[this.searchByShift] = {}
        byShift[this.searchByShift] = this.narudzbe[this.searchByShift]
      }
      return byShift
    },
    daysInMonth() {
      return this.moment().month(this.month).daysInMonth()
    },
    reportSearch() {
      let data = []
      if (this.searchR != '') {
        this.dessertsReport.filter(rep => {
          if (rep.shiftOne && rep.shiftOne.length > 0) {
            rep.shiftOne.filter(item => {
              if (item.meal_name.toLowerCase().includes(this.searchR.toLowerCase())) {
                data.push(rep)
              }
            })
          }
          if (rep.shiftTwo && rep.shiftTwo.length > 0) {
            rep.shiftTwo.filter(item2 => {
              if (item2.meal_name.toLowerCase().includes(this.searchR.toLowerCase())) {
                data.push(rep)
              }
            })
          }
          if (rep.shiftThree && rep.shiftThree.length > 0) {
            rep.shiftThree.filter(item3 => {
              if (item3.meal_name.toLowerCase().includes(this.searchR.toLowerCase())) {
                data.push(rep)
              }
            })
          }
        })
      }

      return data.length ? data : this.dessertsReport
    },
    total3() {
      let total = 0
      this.dessertsReport.forEach(item => {
        if(item.shiftOne && item.shiftOne.length) {
          item.shiftOne.forEach(menu => {
            total += menu.quantity
          })
        }
        if(item.shiftTwo && item.shiftTwo.length) {
          item.shiftTwo.forEach(menu => {
            total += menu.quantity
          })
        }
        if(item.shiftThree && item.shiftThree.length) {
          item.shiftThree.forEach(menu => {
            total += menu.quantity
          })
        }
      })
      return total
    },
    monthName() {
      return this.moment(this.month, "M").format('MMMM').toUpperCase()
    },
    month() {
      return parseInt(this.date1.split('-')[1])
    },
    year() {
      return parseInt(this.date1.split('-')[0])
    },
    dateRangeText () {
      if(this.date.length==1||this.date[0]==this.date[1]){
        return moment(this.date[0]).format("DD.MM.YYYY");
      }
      return moment(this.date[0]).format("DD.MM.YYYY")+' - '
            +
      moment(this.date[1]).format("DD.MM.YYYY");

    },
    desserts4() {
      let arr2 = [];
      if (this.report) {
        for (let company in this.report) {
          arr2.push({ company: company, foods: [] });
          for (let shift in this.report[company]) {
            for (let shift2 in this.report[company][shift]) {
              let index = arr2.findIndex((i) => i.company === company);
              let index2 = arr2[index].foods.findIndex((i) => i.name === shift2);
              if (index2 > -1) {
                arr2[index].foods[index2].shift_one += shift === "1-smena" ? this.report[company][shift][shift2].quantity : 0;
                arr2[index].foods[index2].shift_one_payed += shift === "1-smena" ? this.report[company][shift][shift2].quantity_payed : 0;
                arr2[index].foods[index2].shift_two += shift === "2-smena" ? this.report[company][shift][shift2].quantity : 0;
                arr2[index].foods[index2].shift_two_payed += shift === "2-smena" ? this.report[company][shift][shift2].quantity_payed : 0;
                (arr2[index].foods[index2].shift_three += shift === "3-smena" ? this.report[company][shift][shift2].quantity : 0),
                (arr2[index].foods[index2].shift_three_payed += shift === "3-smena" ? this.report[company][shift][shift2].quantity_payed : 0),
                (arr2[index].foods[index2].total += this.report[company][shift][shift2].quantity),
                (arr2[index].foods[index2].total_payed += this.report[company][shift][shift2].quantity_payed);
              } else {
                arr2[index].foods.push({
                  name: shift2,
                  id: this.report[company][shift][shift2].id,
                  shift_one: shift === "1-smena" ? this.report[company][shift][shift2].quantity : 0,
                  shift_one_payed: shift === "1-smena" ? this.report[company][shift][shift2].quantity_payed : 0,
                  shift_two: shift === "2-smena" ? this.report[company][shift][shift2].quantity : 0,
                  shift_two_payed: shift === "2-smena" ? this.report[company][shift][shift2].quantity_payed : 0,
                  shift_three: shift === "3-smena" ? this.report[company][shift][shift2].quantity : 0,
                  shift_three_payed: shift === "3-smena" ? this.report[company][shift][shift2].quantity_payed : 0,
                  total: this.report[company][shift][shift2].quantity,
                  total_payed: this.report[company][shift][shift2].quantity_payed,
                });
              }
            }
          }
        }
        arr2.forEach((company, index) => {
          company.foods.push({
            name: "УКУПНО",
            shift_one: company.foods.reduce((sum, item) => {
              return sum + item.shift_one;
            }, 0),
            shift_one_payed: company.foods.reduce((sum, item) => {
              return sum + item.shift_one_payed;
            }, 0),
            shift_two: company.foods.reduce((sum, item) => {
              return sum + item.shift_two;
            }, 0),
            shift_two_payed: company.foods.reduce((sum, item) => {
              return sum + item.shift_two_payed;
            }, 0),
            shift_three: company.foods.reduce((sum, item) => {
              return sum + item.shift_three;
            }, 0),
            shift_three_payed: company.foods.reduce((sum, item) => {
              return sum + item.shift_three_payed;
            }, 0),
            total:
              company.foods.reduce((sum, item) => {
                return sum + item.shift_one;
              }, 0) +
              company.foods.reduce((sum, item) => {
                return sum + item.shift_two;
              }, 0) +
              company.foods.reduce((sum, item) => {
                return sum + item.shift_three;
              }, 0),
            total_payed:
              company.foods.reduce((sum, item) => {
                return sum + item.shift_one_payed;
              }, 0) +
              company.foods.reduce((sum, item) => {
                return sum + item.shift_two_payed;
              }, 0) +
              company.foods.reduce((sum, item) => {
                return sum + item.shift_three_payed;
              }, 0),
          });
        });
      }

      return arr2;
    },
  },
};
</script>
