<template>
  <v-container class="fill-height login" fluid>
    <v-row align="center" justify="start" class="login-box">
      <v-col cols="12">
        <v-card class="elevation-12" max-width="480" style="padding: 40px; border-radius: 12px">
            <v-row
              no-gutters
              class="mb-3"
            >
              <v-toolbar-title class="font-weight-bold">{{ $t("apply") }}</v-toolbar-title>
              <v-spacer></v-spacer>
              <v-menu offset-y>
                <template v-slot:activator="{ on, attrs }">
                  <v-btn
                    color="#fb6d3b1a"
                    v-bind="attrs"
                    v-on="on"
                    elevation="0"
                    style="color: #FB6D3B"
                  >
                    {{ langList.find(i => i.code === $i18n.locale) ? langList.find(i => i.code === $i18n.locale).name : '' }}
                  </v-btn>
                </template>
                <v-list-item-group v-model="$i18n.locale" @change="setLang">
                  <v-list>
                    <v-list-item
                      v-for="(item, index) in langList"
                      :key="index"
                      :value="item.code"
                    >
                      <v-list-item-title>{{ item.name }}</v-list-item-title>
                    </v-list-item>
                  </v-list>
                </v-list-item-group>
              </v-menu>

            </v-row>
            <v-row no-gutters class="pb-4">
              <v-col cols="12">
                <qrcode-stream @decode="onDecode" v-if="showQR"></qrcode-stream>
                <v-form v-else>
                    <div class="subtitle-2">{{$t('email_or_user_name')}}</div>
                    <v-text-field
                      v-model="email"
                      name="login"
                      type="text"
                      outlined
                      hide-details
                      style="border: 1px solid #00000019; border-radius: 12px"
                      class="mb-5"
                    ></v-text-field>

                    <div class="subtitle-2">{{$t('password')}}</div>
                    <v-text-field
                      v-model="password"
                      id="password"
                      name="password"
                      type="password"
                      hide-details
                      outlined
                      style="border: 1px solid #00000019; border-radius: 12px"
                    ></v-text-field>
                    <router-link :to="{name: 'zaboravljena-lozinka'}" style="color: #FB6D3B; font-size: 12px" class="pt-2">
                      {{ $t("forgoten_password")}}
                    </router-link>

                    <v-autocomplete
                        clearable
                        v-if="sites.length"
                        :items="sites"
                        :label="$t('select_restaurant')"
                        prepend-icon="mdi-lock"
                        item-text="name"
                        item-value="id"
                        return-object
                        hide-details
                        @change="$store.commit('SAVE_RESTAURANT', $event)"
                    ></v-autocomplete>
                    <v-alert
                      dense
                      outlined
                      type="error"
                      :style="failed ? 'visibility: visible;display:block' : 'visibility: hidden;display:none'"
                      class="mt-4"
                    >
                       {{$t('wrong_password')}}
                    </v-alert>
                </v-form>
              </v-col>
            </v-row>
            <v-row no-gutters style="margin-top: 10px">
                <v-btn color="#FB6D3B" class="mb-2" height="50" style="border-radius: 12px" dark block v-if="sites.length" @click="$router.push({name: routeName.name})">
                  {{translate('Одабери')}}
                </v-btn>
                <v-btn color="#FB6D3B" class="mb-2" height="50" style="border-radius: 12px" dark block @click="login" v-else :loading="loading">{{$t('sign_up')}}</v-btn>
                <v-btn color="#FB6D3B" class="my-2" height="50" style="border-radius: 12px" dark block outlined @click="showQR = !showQR">{{ $t("apply_over_qr")}}</v-btn>
                <div class="text-right" style="width: 100%;display: flex;justify-content: space-between;align-items: center">
                  <img style="width: 30px;" :src="'https://restorani.bitlab.host/img/o.png'" alt="image"/>
                  <a @click.prevent="dialogClearCache = true" style="font-size: 30px;text-decoration: none;color: rgb(251, 109, 59)" href="">...</a>
                </div>
             </v-row>
          </v-card>
      </v-col>
    </v-row>
    <v-dialog v-model="dialogRegister" max-width="710px">
      <v-card style="margin: 0 !important; min-height: 450px">
        <v-card-title style="background: #FB6D3B" class="position-relative">
          <p class="mb-0">
            <img class="card-img-top " width="18%" :src="'https://app.restorani.bitlab.host/gallery/ordera-beli.png'" alt="Logo">
          </p>
        </v-card-title>
        <v-row style="margin: 0 !important;">
          <div class="text-center" style="width: 100%">
            <span style="font-size: 12px !important;text-align: center !important;display: block" class="headline">
              {{ translate("Да би могли поручивати о властитом трошку:") }}
            </span>
            <span class="headline" style="font-size: 19px !important;display: block;margin-top: -9px">
              {{ translate("Региструјте се. То је брзо и лако.") }}
            </span>
            <v-btn color="#FB6D3B" text style="position: absolute;top: 5px;color: white !important;right: 5px" @click="dialogRegisterClose">
              {{ translate("X") }}
            </v-btn>
          </div>
          <v-col cols="6" style="position:relative;">
            <v-text-field
                v-model="addUser.name"
                :label="translate('* Име')"
            ></v-text-field>
            <p style="position:absolute; top: 37px; right: 20px; color: white; font-size: 10px;background: #c02626;padding: 2px 6px;border-radius: 22px;" v-if="errors.ime">X</p>
          </v-col>
          <v-col cols="6" style="position:relative;">
            <v-text-field
                v-model="addUser.lastname"
                :label="translate('* Презиме')"
            ></v-text-field>
            <p style="position:absolute; top: 37px; right: 20px; color: white; font-size: 10px;background: #c02626;padding: 2px 6px;border-radius: 22px;" v-if="errors.prezime">X</p>
          </v-col>
        </v-row>
        <v-row style="margin: 0 !important;">
          <v-col cols="6" style="position:relative;">
            <v-text-field
                v-model="addUser.email"
                :label="translate('* Email')"
            ></v-text-field>
            <p style="position:absolute; top: 37px; right: 20px; color: white; font-size: 10px;background: #c02626;padding: 2px 6px;border-radius: 22px;" v-if="errors.email">X</p>
            <p style="position:absolute; top: 37px; right: 40px; color: red; font-size: 10px;padding: 2px 6px;" v-if="errors.email">Email већ постоји</p>
          </v-col>
          <v-col cols="6" style="position:relative;">
            <v-text-field
                v-model="addUser.password"
                :label="translate('* Шифра')"
                type="password"
            ></v-text-field>
            <p style="position:absolute; top: 37px; right: 20px; color: white; font-size: 10px;background: #c02626;padding: 2px 6px;border-radius: 22px;" v-if="errors.password">X</p>
          </v-col>
        </v-row>
        <v-row style="margin: 0 !important;">
          <v-col cols="6" style="position: relative">
            <v-text-field
                v-model="addUser.phone"
                type="number"
                :label="translate('* Телефон')"
            ></v-text-field>
            <p style="position:absolute; top: 37px; right: 20px; color: white; font-size: 10px;background: #c02626;padding: 2px 6px;border-radius: 22px;" v-if="errors.telefon">X</p>
          </v-col>
          <v-col cols="6">
            <v-text-field
                v-model="addUser.address"
                :label="translate('Адреса')"
            ></v-text-field>
          </v-col>
        </v-row>
<!--              <v-col cols="12">-->
<!--&lt;!&ndash;                <v-text-field&ndash;&gt;-->
<!--&lt;!&ndash;                    v-model="addUser.drzava"&ndash;&gt;-->
<!--&lt;!&ndash;                    :label="translate('Држава')"&ndash;&gt;-->
<!--&lt;!&ndash;                ></v-text-field>&ndash;&gt;-->
<!--                <v-row>-->
<!--                  <v-col cols="6">-->
<!--                    <v-select-->
<!--                        v-model="addUser.drzava"-->
<!--                        :items="[{name:'Република Српска',id:1}, {name:'Србија',id:2}]"-->
<!--                        :label="translate('Држава')"-->
<!--                        item-text="name"-->
<!--                        item-id="id"-->
<!--                        return-object-->
<!--                    ></v-select>-->
<!--                  </v-col>-->
<!--                  <v-col cols="6">-->
<!--                    <v-select-->
<!--                        v-model="addUser.grad"-->
<!--                        :items="[{name:'Република Српска',id:1}, {name:'Србија',id:2}]"-->
<!--                        :label="translate('Град')"-->
<!--                        item-text="name"-->
<!--                        item-id="id"-->
<!--                        return-object-->
<!--                    ></v-select>-->
<!--                  </v-col>-->
<!--                </v-row>-->
<!--              </v-col>-->
<!--              <v-col cols="12" class="mb-0 pb-0">-->
<!--                <p class="font-weight-bold">Локација за доставу:</p>-->
<!--              </v-col>-->
<!--              <v-col cols="12">-->
<!--                <v-row>-->
<!--                  <v-col cols="6">-->
<!--                    <v-text-field-->
<!--                        v-model="addUser.lat"-->
<!--                        :label="translate('Латитуда')"-->
<!--                        type="number"-->
<!--                    ></v-text-field>-->
<!--                  </v-col>-->
<!--                  <v-col cols="6">-->
<!--                    <v-text-field-->
<!--                        v-model="addUser.long"-->
<!--                        :label="translate('Лонгитуда')"-->
<!--                        type="number"-->
<!--                    ></v-text-field>-->
<!--                  </v-col>-->
<!--                </v-row>-->
<!--              </v-col>-->
<!--              <v-card-actions style="text-align: center !important; position: inherit !important;">-->
          <div class="pb-7 text-center">
            <v-btn color="success" dark @click="addNewUser">
              {{ translate("Региструј се") }}
            </v-btn>
          </div>
<!--              </v-card-actions>-->
      </v-card>
    </v-dialog>
    <v-dialog v-model="dialogRestoran" max-width="60%" class="p-2">
      <v-card>
        <v-card-title style="background: #FB6D3B" class="position-relative">
          <img class="card-img-top " width="15%" src="https://app.restorani.bitlab.host/gallery/ordera-beli.png" alt="Logo">
          <v-btn color="primary" text style="position: absolute;top: 5px;color: white !important;right: 5px" @click="dialogRestoranClose">
            {{ translate("X") }}
          </v-btn>
        </v-card-title>
        <v-col cols="12">
          <p class="text-center">{{ $t("select_restaurant") }}</p>
<!--                <v-col cols="4">-->
<!--                  <v-select-->
<!--                      v-model="city"-->
<!--                      :items="cities"-->
<!--                      :label="'Град '"-->
<!--                      item-text="name"-->
<!--                      item-id="id"-->
<!--                      return-object-->
<!--                  ></v-select>-->
<!--                </v-col>-->
          <v-row class="align-content-center justify-content-center">
<!--                  <v-col style="cursor: pointer" @click="selectRes(res)" v-for="res in allRestorants" cols="4" class="text-center">-->
<!--                    {{ res.name }}-->
<!--                    <div class="w-100">-->
<!--                      <img width="70" :src="res.settings ?  conf.apiUrlBase + '/gallery/' + res.id + '/' + res.settings.logo : ''" alt="">-->
<!--                    </div>-->
<!--                  </v-col>-->
              <div class="card text-center" style="width: 33%;padding: 10px 5px;" v-for="res in allRestorants">
                <div style="box-shadow: 0 1px 14px 2px #cfcfcf;border-radius: 7px;height: 230px;position: relative;">
                  <img onerror="javascript:this.src='https://app.restorani.bitlab.host/gallery/no-image-restaurant.jpg'" style="cursor: pointer" @click="selectRes(res)" class="card-img-top " width="100%" height="75%" :src="res.settings ?  conf.apiUrlBase + '/gallery/' + res.id + '/' + res.settings.logo : ''" alt="Logo">
                  <div class="card-body">
                    <h5 class="card-title">{{ res.name }}</h5>
                    <p style="font-size: 11px;" class="card-text mb-0">
                      <span>{{ res.address }}</span>
                    </p>
                    <p @click="selectRes(res)" style="font-size: 11px;cursor: pointer" class="card-text mb-0 font-weight-bold">
                      <span>{{ res.city ? res.city.name : '' }}</span>
<!--                            <a @click="selectRes(res)" style="font-size: 10px;margin-left: 10px" href="#" class="btn-primary">Изабери</a>-->
                    </p>
                  </div>
                </div>
              </div>
<!--                  <div class="card text-center" style="width: 32%" v-for="res in allRestorants">-->
<!--                    <img style="cursor: pointer" @click="selectRes(res)" class="card-img-top " width="100" :src="res.settings ?  conf.apiUrlBase + '/gallery/' + res.id + '/' + res.settings.logo : ''" alt="Logo">-->
<!--                    <div class="card-body">-->
<!--                      <h5 class="card-title">{{ res.name }}</h5>-->
<!--                      <p style="font-size: 11px;" class="card-text mb-0">-->
<!--                        <span>{{ res.address }}</span>-->
<!--                      </p>-->
<!--                      <p style="font-size: 11px;" class="card-text mb-0 font-weight-bold">-->
<!--                        <span>{{ res.city ? res.city.name : '' }}</span>-->
<!--                      </p>-->
<!--                      <a @click="selectRes(res)" style="font-size: 10px;" href="#" class="btn-primary">Изабери</a>-->
<!--                    </div>-->
<!--                  </div>-->

          </v-row>
        </v-col>
        <br>
        <br>
      </v-card>
    </v-dialog>
    <v-dialog v-model="dialogUserError" max-width="500px" class="p-2">
      <v-card>
        <v-card-title class="error position-relative">
        <span class="headline white--text"
        >{{ translate("Грешка") }}</span
        >
          <v-btn color="primary" text style="position: absolute;top: 5px;color: white !important;right: 5px" @click="dialogUserError = false">
            {{ translate("X") }}
          </v-btn>
        </v-card-title>
        <v-col cols="12">
          <p>{{ emailError }}</p>
        </v-col>
        <div class="pb-7 text-center">
          <v-btn color="gray" dark @click="dialogUserError = false">
            {{ $t("close") }}
          </v-btn>
        </div>
      </v-card>
    </v-dialog>
    <v-dialog v-model="dialogClearCache" max-width="500px"  class="p-2">
      <v-card style="border-radius: 12px !important;">
        <v-card-title style="background: none" class="position-relative">
<!--        <span class="headline" style="font-size: 1.25rem;"></span>-->
          <v-btn color="primary" text style="position: absolute;top: 5px;color: black !important;right: 5px" @click="dialogClearCache = false">
           <v-icon class="mdi mdi-close"></v-icon>
          </v-btn>
        </v-card-title>
        <div class="pb-7 mt-0 pl-7 text-left">
<!--          <v-btn id="clearCacheButton" style="text-transform: unset !important;" color="rgb(251, 109, 59)" dark @click="clearCache()">-->
<!--            {{ translate("Очисти кеш / Преузмите нови верзију ") }}-->
<!--          </v-btn>-->
          <v-icon>mdi mdi-help-circle-outline</v-icon> {{ $t('support') }} <br>
          <a href="https://help.ordera.app" target="_blank" style="text-decoration: none;text-align: left;color: rgb(251, 109, 59)">
            help.ordera.app
          </a> <br> <br>
          <v-icon>mdi mdi-food</v-icon> {{ $t("order_from_home") }} <br>
          <a href="https://ordera.app" target="_blank" style="text-decoration: none;text-align: left;color: rgb(251, 109, 59)">
            ordera.app
          </a> <br>
          <a href="https://topli-obrok.ordera.app" target="_blank" style="text-decoration: none;text-align: left;color: rgb(251, 109, 59)">topli-obrok.ordera.app</a>
          <br> <br>
          <v-icon>mdi mdi-menu</v-icon> {{ translate('Дигитални мени') }} <br>
          <a href="https://konobar.ordera.app/info" target="_blank" style="text-decoration: none;text-align: left;color: rgb(251, 109, 59)">
            konobar.ordera.app/info
          </a> <br> <br>
          <v-icon>mdi mdi-domain</v-icon> {{ translate('Постани партнер Ордере') }} <br>
          <a href="https://partner.ordera.app" target="_blank" style="text-decoration: none;text-align: left;color: rgb(251, 109, 59)">
            partner.ordera.app
          </a>
          <!--          <v-btn color="gray" dark @click="dialogClearCache = false">-->
<!--            {{ $t("close") }}-->
<!--          </v-btn>-->
        </div>
      </v-card>
    </v-dialog>
  </v-container>
</template>

<script>
    //import config from '../../config'
    import axios from '../plugins/axios'
    import { QrcodeStream } from 'vue-qrcode-reader'
    import conf from '../config'
    import setLang from "../mixins/setLang";

    export default {
        mixins: [setLang],
        components: {
            QrcodeStream
        },
        data() {
            return {
                city: '',
                conf: conf,
                restoran_id: '',
                dialogRegister: false,
                dialogRestoran: false,
                dialogClearCache: false,
                dialogUserError: false,
                email: null,
                password: null,
                sites: [],
                loading: false,
                failed: false,
                showQR: false,
                routeName: '',
                calculatedQr: {
                  arr: [],
                  status: true,
                  json: null
                },
                addUser: {
                  name: '',
                  lastname: '',
                  email: '',
                  phone: '',
                  drzava: '',
                  grad: '',
                  password: '',
                  address: '',
                  lat: '',
                  long: '',
                  type: '6'
                },
                errors: {
                  name: false,
                  lastname: false,
                  email: false,
                  password: false,
                  phone: false,
                },
                allRestorants: '',
                emailError: '',
                newUser: '',
                domain: window.location.origin.split('//')[1],
                countries: '',
                cities: [],
                awaitingSearch: false,
                emailExists: false,
                langList: [
                  // {code: 'sr', name: 'Српски - ћирилица'},
                  // {code: 'sr-lat', name: 'Srpski - Latinica'},
                  {code: 'sr', name: 'Српски'},
                  {code: 'rs-lat', name: 'Latinica'},
                  {code: 'hr', name: 'Hrvatski'},
                  {code: 'bs', name: 'Bosanski'},
                  {code: 'en', name: 'English'},
                  {code: 'mk', name: 'Македонски'},
                  {code: 'si', name: 'Slovenščina', cir_to_lat: 0},
                ]
            }
        },

      created() {
          this.getAllRestorans()
          this.getCountry()
          this.getLang()
        console.log(this.$i18n.locale)
      },
      methods: {
        clearCache() {
          // document.getElementById('clearCacheButton').addEventListener('click', function () {
          //   if ('caches' in window && 'serviceWorker' in navigator) {
          //     // Dobijanje trenutnog manifesta PWA
          //     navigator.serviceWorker.getRegistration().then(function (registration) {
          //       if (registration && registration.active && registration.active.scriptURL) {
          //         const manifestUrl = registration.active.scriptURL + '/../manifest.json';
          //
          //         // Pravljenje zahteva za manifestom
          //         fetch(manifestUrl)
          //           .then(response => response.json())
          //           .then(manifest => {
          //             const cacheName = manifest.short_name || manifest.name;
          //             // Brisanje keša
          //             caches.keys().then(function (cacheNames) {
          //               cacheNames.forEach(function (cacheNameToDelete) {
          //                 if (cacheNameToDelete === cacheName) {
          //                   caches.delete(cacheNameToDelete).then(function () {
          //                     alert(`Keš '${cacheName}' je uspešno obrisan.`);
          //                   }).catch(function (error) {
          //                     console.error(`Došlo je do greške prilikom brisanja keša '${cacheNameToDelete}':`, error);
          //                   });
          //                 }
          //               });
          //             });
          //           })
          //           .catch(function (error) {
          //             console.error('Došlo je do greške prilikom dobijanja manifesta:', error);
          //           });
          //       } else {
          //         alert('Nema dostupnog servisnog radnika ili manifesta PWA.');
          //       }
          //     });
          //   } else {
          //     alert('Vaš pregledač ne podržava keširanje ili servisne radnike.');
          //   }
          // });
          // window.location.reload(true)
          if (navigator.serviceWorker && navigator.serviceWorker.controller) {
            navigator.serviceWorker.controller.postMessage({ command: 'clearCache' });
          }
          else {
            console.log('sw')
          }

        },
        hasGroupGoodMenu(companies) {
          if (companies && companies.length && companies[0].type === 'group_food') {
            return true
          }
        },
        hasGroupMenu(companies) {
            if (companies && companies.length && companies[0].type === 'group') {
              return true
            }
          },
          getCountry() {
            axios.get('/restaurants/countries-all').then(({data}) => {
              this.countries = data
            })
          },
           selectRes(res) {
             let data = {};
             data['user_id'] = this.newUser.data.id
             data['restoran_id'] = res.id
             axios.post('/choose-restaurant', data).then(({data}) => {
               if(data.data) {
                 this.dialogRestoran = false;
                 this.login2(this.addUser.email, this.addUser.password)
               }
             })
           },
           getAllRestorans() {
             axios.get('/all-restaurants').then(({data}) => {
               this.allRestorants = data.data
               this.allRestorants.filter(res => {
                 this.countries.filter(con => {
                   if (con.city) {
                     con.city.filter(city => {
                       if (city.id == res.city_id) {
                         this.cities.push(city)
                       }
                     })
                   }
                 })
               })
             })
           },
          addNewUser() {
             let userSave = true;
             if (this.addUser.name == '') {
               userSave = false;
               this.errors.name = true
             } else {
               this.errors.name = false
               userSave = true;
             }
             if (this.addUser.lastname == '') {
               userSave = false
               this.errors.lastname = true
             } else {
               this.errors.lastname = false
               userSave = true;
             }
             if (this.addUser.email == '') {
               userSave = false
               this.errors.email = true
             } else {
               this.errors.email = false
               userSave = true;
             }
             if (this.addUser.password == '') {
               userSave = false
               this.errors.password = true
             } else {
               this.errors.password = false
               userSave = true;
             }
            if (this.addUser.phone == '') {
              userSave = false
              this.errors.phone = true
            } else {
              this.errors.phone = false
              userSave = true;
            }
             if (userSave) {
               this.newUser = ''
               axios.post('/user/add-new-user', this.addUser).then(({data}) => {
                 if (data.data && data.status == "success") {
                   if (data.data.type == 'guest') {
                     this.loginGuest(this.addUser.email, this.addUser.password)
                     this.dialogRestoran = false;
                   } else {
                     this.dialogRestoran = true;
                   }
                   this.dialogRegister = false;
                   this.newUser = data
                   this.addUser.name = ''
                   this.addUser.lastname = ''
                   this.addUser.email = ''
                   this.addUser.phone = ''
                   this.addUser.drzava = ''
                   this.addUser.grad = ''
                   this.addUser.password = ''
                   this.addUser.address = ''
                   this.addUser.lat = ''
                   this.addUser.long = ''
                   this.addUser.type = ''
                 }
               }).catch(error => {
                 if (error.response.data.status == "failed") {
                   this.dialogUserError = true
                   this.emailError = error.response.data.message
                 }
               })
             }
          },
          loginGuest(username,password) {
            axios.post('/user/login', {
              username:  username,
              password: password
            }).then(res => {
              localStorage.setItem('restoran-token', res.data.access_token)
              axios.get('/user/me').then(res => {
                this.sites = res.data.restaurants
                this.$store.commit('SAVE_USER', res.data)
                if(res.data.restaurants && res.data.restaurants.length) {
                  this.$store.commit('SAVE_RESTAURANT', res.data.restaurants[0])
                  this.$store.commit('SAVE_COMPANY', null)
                  this.$store.commit('SAVE_COMPANIES', null)
                }
                if(res.data.companies && res.data.companies.length) {
                  this.$store.commit('SAVE_COMPANY', res.data.companies[0])
                  this.$store.commit('SAVE_COMPANIES', res.data.companies)
                  this.$store.commit('SAVE_RESTAURANT', null)
                }

                this.loading = false;
                this.routeName = this.$router.options.routes.find(i => i.permission.includes(res.data.type))
                if(res.data.companies.length && res.data.companies[0].is_delivery){
                  this.$router.push({name:'delivery'})
                }
                else if(this.sites.length === 1) {
                  setTimeout(() => {
                    this.$store.commit('SAVE_RESTAURANT', this.sites[0])
                    this.$router.push({name: this.routeName.name})
                  }, 300)
                }
                else this.$router.push({name: 'restorani-guest' })
               })
              }).catch(() => {
                this.loading = false;
                this.failed = true;
             })
          },
          dialogRestoranClose() {
            this.dialogRestoran = false;
          },
          chooseRestoran() {
             let data = {};
             data['user'] = this.newUser.data.id
             data['restoran'] = this.restoran_id.id
             axios.post('/choose-restaurant', data).then(({data}) => {
                if(data.data) {
                  this.dialogRestoran = false;
                  this.login2(this.addUser.email, this.addUser.password)
                }
             })
          },
          registerUser() {
            this.dialogRegister = true
          },
          dialogRegisterClose() {
            this.dialogRegister = false;
            this.addUser.name = '';
            this.addUser.lastname = '';
            this.addUser.email = '';
            this.addUser.phone = '';
            this.addUser.drzava = '';
            this.addUser.grad = '';
            this.addUser.password = '';
            this.addUser.address = '';
            this.addUser.lat = '';
            this.addUser.long=  '';
            this.errors.name = false
            this.errors.lastname = false
            this.errors.email = false
            this.errors.password = false
            this.errors.phone = false
          },
          onDecode (decodedString) {
              let secure = JSON.parse(decodedString).secure
              axios.post('/user/login/hash', {
                  secure: secure
              }).then(res => {

                  localStorage.setItem('restoran-token', res.data.data.token)

                  axios.get('/user/me').then(res => {
                      this.sites = res.data.restaurants
                      this.$store.commit('SAVE_USER', res.data)
                      if(res.data.restaurants.length) {
                          this.$store.commit('SAVE_RESTAURANT', res.data.restaurants[0])
                          this.$store.commit('SAVE_COMPANY', null)
                          this.$store.commit('SAVE_COMPANIES', null)
                      }
                      if(res.data.companies.length) {
                          this.$store.commit('SAVE_COMPANY', res.data.companies[0])
                          this.$store.commit('SAVE_COMPANIES', res.data.companies)
                          this.$store.commit('SAVE_RESTAURANT', null)
                      }

                      this.loading = false;
                      this.failed = false;

                      if(this.hasGroupMenu(res.data.companies) && this.$store.state.user.type === 'company') {
                        this.$router.push({name:'category-menu-group'})
                      }
                      else if(this.hasGroupMenu(res.data.companies) && this.$store.state.user.type != 'recipient') {
                        this.$router.push({name:'category-menu'})
                      }
                      else if(res.data.companies.length&&res.data.companies.is_delivery) {
                          this.$router.push({name:'delivery'})
                      }
                      else {
                        this.$router.push({name: 'home'})
                      }
                  })

              })
          },
          login() {
              this.loading = true;
              axios.post('/user/login', {
                  username:  this.email,
                  password: this.password
              }).then(res => {
                  localStorage.setItem('restoran-token', res.data.access_token)

                  axios.get('/user/me').then(res => {
                      this.sites = res.data.restaurants
                      this.$store.commit('SAVE_USER', res.data)
                      if(res.data.restaurants && res.data.restaurants.length) {
                          this.$store.commit('SAVE_RESTAURANT', res.data.restaurants[0])
                          this.$store.commit('SAVE_COMPANY', null)
                          this.$store.commit('SAVE_COMPANIES', null)
                      }
                      if(res.data.companies && res.data.companies.length) {
                          this.$store.commit('SAVE_COMPANY', res.data.companies[0])
                          this.$store.commit('SAVE_COMPANIES', res.data.companies)
                          this.$store.commit('SAVE_RESTAURANT', null)
                      }

                      this.loading = false;
                      this.routeName = this.$router.options.routes.find(i => i.permission.includes(res.data.type))
                      if(this.hasGroupGoodMenu(res.data.companies)) {
                        this.$router.push({name:'category-menu-add'})
                        return
                      }
                      if(this.hasGroupMenu(res.data.companies) && this.$store.state.user.type === 'company') {
                        this.$router.push({name:'category-menu-group'})
                        return
                      }
                      // ako je kompanija type - 'group' i ako je user nije tip 'recipient' onda ga posalji na category-menu u suprotnom ga posalji na 'naplatu'
                      if(this.hasGroupMenu(res.data.companies) && this.$store.state.user.type != 'recipient') {
                        this.$router.push({name:'category-menu'})
                        return
                      }
                      if (this.$store.state.user.type == 'recipient') {
                        this.$router.push({name:'naplata'})
                        return
                      }
                      if(res.data.companies && res.data.companies.length && res.data.companies[0].is_delivery){
                          this.$router.push({name:'delivery'})
                      }
                      else if(this.sites.length === 1) {
                        setTimeout(() => {
                          this.$store.commit('SAVE_RESTAURANT', this.sites[0])
                          this.$router.push({name: this.routeName.name})
                        }, 300)
                      }
                      else this.$router.push({name: this.routeName.name})
                  })
              }).catch(() => {
                  this.loading = false;
                  this.failed = true;
              })
          },
          login2(username,password) {
            this.loading = true;
            axios.post('/user/login', {
              username:  username,
              password: password
            }).then(res => {
              localStorage.setItem('restoran-token', res.data.access_token)

              axios.get('/user/me').then(res => {
                this.sites = res.data.restaurants
                this.$store.commit('SAVE_USER', res.data)
                if(res.data.restaurants && res.data.restaurants.length) {
                  this.$store.commit('SAVE_RESTAURANT', res.data.restaurants[0])
                  this.$store.commit('SAVE_COMPANY', null)
                  this.$store.commit('SAVE_COMPANIES', null)
                }
                if(res.data.companies && res.data.companies.length) {
                  this.$store.commit('SAVE_COMPANY', res.data.companies[0])
                  this.$store.commit('SAVE_COMPANIES', res.data.companies)
                  this.$store.commit('SAVE_RESTAURANT', null)
                }

                this.loading = false;
                this.routeName = this.$router.options.routes.find(i => i.permission.includes(res.data.type))
                if(res.data.companies.length && res.data.companies[0].is_delivery){
                  this.$router.push({name:'delivery'})
                }
                else if(this.sites.length === 1) {
                  setTimeout(() => {
                    this.$store.commit('SAVE_RESTAURANT', this.sites[0])
                    this.$router.push({name: this.routeName.name})
                  }, 300)
                }
                else this.$router.push({name: this.routeName.name})
              })
            }).catch(() => {
              this.loading = false;
              this.failed = true;
            })
          },

        },
        watch: {
          'calculatedQr.status'() {
            if (typeof JSON.parse(this.calculatedQr.json) !== 'number') {
              this.onDecode(this.calculatedQr.json)
            }
          },
          'addUser.email': function(val) {
            if (!this.awaitingSearch) {
              this.errors.email = false
              setTimeout(() => {
               axios.post('/user/check-email', {email: this.addUser.email}).then(({data}) => {
                 if (data) {
                  this.errors.email = true
                 } else {
                   console.log('gresa')
                 }
               })
                this.awaitingSearch = false;
              }, 3500); // 2.5 sec delay
            }
            this.awaitingSearch = true;
          }
        },
        mounted() {
          let _calculatedQr = this.calculatedQr

          document.addEventListener('keypress', function(event) {
            if(event.keyCode === 123) {
              _calculatedQr.status = true
              _calculatedQr.json = null
              _calculatedQr.arr = []
            }
            if(_calculatedQr.status) {
              if(event.keyCode !== 13)_calculatedQr.arr.push(event.key)
              if(event.keyCode === 13) {
                _calculatedQr.json = _calculatedQr.arr.join('')
                _calculatedQr.status = false
              }
            }

          }, true);
        },
        beforeDestroy() {
          document.removeEventListener("keypress", function(event) {
            alert('izbrisan eventlistener')
          });
        }
    }
</script>

<style>
    .login {
        background-image: linear-gradient(0deg, rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0.5)) , url('../assets/bg-home.jpg');
        background-size: cover;
    }

    .login-box {
      margin-left: 100px;
      margin-top: 48px;
    }

    @media(max-width: 767px) {
      .v-input input, .veci-font {
        font-size: 16px !important;
      }
      .login-box {
        margin-left: 0px;
        margin-top: 0px;
      }
    }
</style>
