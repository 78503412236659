<template>
  <v-card>
    <div style="width: 95%;margin: 10px auto">
      <v-row>
      <v-col>
        <v-menu
            ref="menu"
            v-model="menu"
            :close-on-content-click="false"
            :return-value.sync="date"
            transition="scale-transition"
            offset-y
            max-width="290px"
        >
          <template v-slot:activator="{ on, attrs }">
            <v-text-field
                v-model="date"
                style="max-width: 290px"
                :label="$t('inital_date')"
                prepend-icon="mdi-calendar"
                readonly
                v-bind="attrs"
                v-on="on"
                hide-details
            ></v-text-field>
          </template>
          <v-date-picker v-model="date" no-title scrollable>
            <v-spacer></v-spacer>
            <v-btn style="border-radius: 8px" text color="primary" @click="menu = false">
              {{ $t("cancel") }}
            </v-btn>
            <v-btn style="border-radius: 8px"
                   text
                   color="primary"
                   @click="
                  $refs.menu.save(date);
                  load();
                "
            >
              {{ $t("confirm") }}
            </v-btn>
          </v-date-picker>
        </v-menu>
      </v-col>
      <v-col>
        <v-menu
            ref="menu2"
            v-model="menu2"
            :close-on-content-click="false"
            :return-value.sync="date2"
            transition="scale-transition"
            offset-y
            max-width="290px"
            min-width="290px"
        >
          <template v-slot:activator="{ on, attrs }">
            <v-text-field
                v-model="date2"
                :label="$t('ending_date')"
                prepend-icon="mdi-calendar"
                readonly
                style="max-width: 290px"
                v-bind="attrs"
                v-on="on"
                hide-details
            ></v-text-field>
          </template>
          <v-date-picker v-model="date2" no-title scrollable>
            <v-spacer></v-spacer>
            <v-btn text color="primary" @click="menu2 = false">
              Cancel
            </v-btn>
            <v-btn
                text
                color="primary"
                @click="
                    $refs.menu2.save(date2);
                    load();
                  "
            >
              OK
            </v-btn>
          </v-date-picker>
        </v-menu>
      </v-col>
      <v-col>
        <v-text-field
            v-model="search"
            append-icon="mdi-magnify"
            :label="$t('search')"
            single-line
            hide-details
        ></v-text-field>
      </v-col>
      <v-col>
        <v-select
            v-model="byMeal"
            :items="[
                { text: $t('per_meal'), val: 1 },
                { text: $t('total'), val: 0 },
              ]"
            item-text="text"
            item-value="val"
            style="max-width: 290px"
            :label="$t('type_of_review')"
        ></v-select>
      </v-col>
      <v-col>
        <v-btn :style="'border-radius: 8px;border: 1px solid ' + $store.state.restaurant.settings.topbar.background + ';background: none;color: ' + $store.state.restaurant.settings.topbar.background + ';box-shadow: none;text-transform: capitalize'" small text class="mt-5"
               @click="print(printTable)">
          <v-icon class="mr-2">mdi-printer</v-icon
          >{{ $t("print") }}
        </v-btn>
      </v-col>
      </v-row>
      <v-tabs v-model="tab" align-with-title background-color="primary" dark>
        <v-tabs-slider color="white"></v-tabs-slider>
        <v-tab  @click="printTable = item;" v-for="item in items" :key="item">
          {{ $t(item) }}
        </v-tab>
      </v-tabs>
      <v-tabs-items v-model="tab">
        <v-tab-item>
          <v-row class="ma-5">
            <v-col cols="12">
              <v-row>
                <template v-if="byMeal" v-for="(items, i) in report">
                  <v-col cols="12">
                    <v-row id="tr" class="pl-5">
                      <h3 class="headline_tab">{{ translate('Требовање') }}</h3>
                      <v-col cols="12">
                        <h2 class="mt-3 nar">{{ $t("order_date") }}: {{ formatDate(date) }} - {{ formatDate(date2) }}</h2>
                      </v-col>
                      <v-col class="firme" cols="12">
                        <v-autocomplete style="font-size: 10px !important;"
                          v-model="search1"
                          :items="Object.keys(items)"
                          dense
                          filled
                          multiple
                          :label="$t('meal')"
                          item-text="name"
                          clear
                          chips
                          small-chips
                        >
                          <v-btn
                            color="primary"
                            slot="prepend-item"
                            @click="toogleMeals()"
                            small
                            class="mx-4">
                            {{ $t("display_all") }}
                          </v-btn>
                        </v-autocomplete>
                      </v-col>
                      <template v-if="search1.length > 0" v-for="(meal, ii) in search1">
                        <div style="width: 100%">
                          <strong class="naslov">
                              {{ translate(meal.toUpperCase()) }}:
                              {{ items[meal].quantity }} <span class="text-decoration-underline">{{$t("pieces") }}</span>
                          </strong><br />
                          <v-data-table
                            :headers="headers2"
                            :items="items[meal].groceries"
                            :items-per-page="-1"
                            :search="search"
                          >
                            <template v-for="header in headers2" v-slot:[`header.${header.value}`]="{ header }">
                              {{ $t(header.text) }}
                            </template>
                            <template v-for="values in headers2" v-slot:[`item.${values.value}`]="{ item }">
                              {{ translate(item[values.value]) }}
                            </template>
                            <template v-slot:item.quantityTotal="{ item }">
                              {{ Math.round((item.quantity * items[meal].quantity + Number.EPSILON) * 1e10) / 1e10 }}
                            </template>
                          </v-data-table>
                        </div>
                      </template>
                      <template v-else-if="search1.length == 0" v-for="(meal, ii) in items">
                        <v-col id="tr" cols="12" class="pt-4" style="width: 100%">
                          <strong>{{ translate(ii.toUpperCase()) }}: {{ meal.quantity }} <span class="text-decoration-underline">{{$t("pieces") }}</span> </strong><br />
                          <v-data-table
                                  :headers="headers2"
                                  :items="meal.groceries"
                                  :items-per-page="-1"
                                  :search="search"
                          >
                            <template v-for="header in headers2" v-slot:[`header.${header.value}`]="{ header }">
                                {{ $t(header.text) }}
                            </template>
                            <template v-for="values in headers2" v-slot:[`item.${values.value}`]="{ item }">
                                {{ translate(item[values.value]) }}
                            </template>
                            <template v-slot:item.quantityTotal="{ item }">
                                {{ Math.round((item.quantity * meal.quantity + Number.EPSILON) * 1e10) / 1e10 }}
                            </template>
                          </v-data-table>
                        </v-col>
                      </template>
                    </v-row>
                  </v-col>
                </template>
                <v-col id="tr" v-else cols="12" class="pt-4">
                  <h1 class="text-center">{{ $t("total") }}</h1>
                  <h4 class="text-center">
                      {{$t("total_groceries_for_selected_period") }}
                  </h4>
                  <br />
                  <v-data-table
                          :headers="headers3"
                          :items="total"
                          :search="search"
                          :items-per-page="15"
                          class="elevation-1"
                  >
                    <template v-for="header in headers3" v-slot:[`header.${header.value}`]="{ header }">
                      <span style="display: flex;align-items: center">
                        {{ $t(header.text) }}
                        <span v-if="header.value == 'action'">
                          <v-checkbox @change="selectAllGroceries" hide-details v-model="all_items"></v-checkbox>
                        </span>
                      </span>
                    </template>
                    <template v-for="values in headers2" v-slot:[`item.${values.value}`]="{ item }">
                        {{ translate(item[values.value]) }}
                    </template>
                    <template v-slot:item.action="{ item }">
                      <v-checkbox hide-details v-model="item.checked"></v-checkbox>
                    </template>
                    <template v-slot:item.quantity="{ item }">
                        {{Math.round((item.quantity + Number.EPSILON) * 1e10) / 1e10 }}
                    </template>
                    <template v-for="values in headers" v-slot:item.num="{ item, index }">
                        {{ index + 1 }}
                    </template>
                  </v-data-table>

                  <v-col cols="12" v-if="total && total.length">
                      <v-btn color="primary" block @click="takeOff">{{$t("give_out") }}</v-btn>
                  </v-col>
                </v-col>
  <!--              <v-tabs v-model="tab1" background-color="primary" dark>-->
  <!--                <v-tab v-for="(item, ind) in items1" :key="ind">-->
  <!--                  <span>{{ $t(item.text) }}</span>-->
  <!--                </v-tab>-->
  <!--              </v-tabs>-->
  <!--                <v-tabs-items id="izv" v-model="tab1">-->
  <!--                  <v-tab-item>-->
  <!--                    <v-card flat>-->
  <!--                      <v-card-text>-->
  <!--                       -->
  <!--                      </v-card-text>-->
  <!--                    </v-card>-->
  <!--                  </v-tab-item>-->
  <!--                  <v-tab-item>-->
  <!--                    -->
  <!--                  </v-tab-item>-->
  <!--                </v-tabs-items>-->
              </v-row>
            </v-col>
          </v-row>
        </v-tab-item>
        <v-tab-item class="fill-height">
          <v-row class="pa-5">
            <v-col id="st_mag" cols="12" sm="12">
              <h3 class="headline_tab headline_mag">{{ translate('Sтање магацина') }}</h3>
              <v-data-table
                :headers="headers"
                :items="desserts"
                :search="search"
                :items-per-page="15"
                sort-by="calories"
                class="elevation-1"
              >
                <template
                  v-for="header in headers"
                  v-slot:[`header.${header.value}`]="{ header }"
                >
                  {{ $t(header.text) }}
                </template>
                <template
                  v-for="values in headers"
                  v-slot:[`item.${values.value}`]="{ item }"
                >
                  {{ translate(item[values.value]) }}
                </template>
                <template v-slot:top>
                  <v-toolbar flat>
                    <v-spacer></v-spacer>
                    <v-dialog v-model="dialog" max-width="500px">
                     <!--  <template v-slot:activator="{ on, attrs }">
                        <v-btn
                          color="primary"
                          dark
                          class="mb-2"
                          v-bind="attrs"
                          v-on="on"
                        >
                          <v-icon>mdi-plus</v-icon>
                        </v-btn>
                      </template> -->
                      <v-card>
                        <v-card-title>
                          <span class="headline">{{ translate(formTitle) }}</span>
                        </v-card-title>

                        <v-card-text>
                          <v-container>
                            <v-row>
                              <v-col cols='auto'>
                                {{$t('grocery')}}: {{editedItem.name}}
                              </v-col>
                              <v-col cols='auto'>
                                {{$t('warehouse_code')}}: {{editedItem.code}}
                              </v-col>
                            </v-row>
                            <v-row>
                              <v-col cols='auto'>
                                <strong>{{$t('in_stock')}}: {{editedItem.total}}</strong>
                              </v-col>
                            </v-row>
                            <v-row>
                              <v-col cols="12" sm="6" md="6">
                                <v-text-field
                                  type="number"
                                  v-model="editedItem.amount"
                                  :label="$t('new_entry')"
                                ></v-text-field>
                              </v-col>
                            </v-row>
                          </v-container>
                        </v-card-text>

                        <v-card-actions>
                          <v-spacer></v-spacer>
                          <v-btn color="blue darken-1" text @click="close">
                            {{ $t("cancel") }}
                          </v-btn>
                          <v-btn color="blue darken-1" text @click="save">
                            {{ translate("save") }}
                          </v-btn>
                        </v-card-actions>
                      </v-card>
                    </v-dialog>
                    <v-dialog v-model="dialogIzlaz" max-width="500px">
                      <v-card>
                        <v-card-title>
                          <span class="headline">{{ translate("Лагер / Магацин") }}</span>
                        </v-card-title>

                        <v-card-text>
                          <v-container>
                            <v-row>
                              <v-col cols='auto'>
                                {{$t('grocery')}}: {{editedItem.name}}
                              </v-col>
                              <v-col cols='auto'>
                                {{$t('warehouse_code')}}: {{editedItem.code}}
                              </v-col>
                            </v-row>
                            <v-row>
                              <v-col cols='auto'>
                                <strong>{{$t('in_stock')}}: {{editedItem.total}}</strong>
                              </v-col>
                            </v-row>
                            <v-row>
                              <v-col cols="12" sm="6" md="6">
                                <v-text-field
                                    type="number"
                                    v-model="editedItem.amount_off"
                                    :label="translate('Одузми количину')"
                                ></v-text-field>
                              </v-col>
                            </v-row>
                          </v-container>
                        </v-card-text>

                        <v-card-actions>
                          <v-spacer></v-spacer>
                          <v-btn color="blue darken-1" text @click="closeIzlaz">
                            {{ $t("cancel") }}
                          </v-btn>
                          <v-btn color="blue darken-1" text @click="save">
                            {{ translate("save") }}
                          </v-btn>
                        </v-card-actions>
                      </v-card>
                    </v-dialog>
                    <v-dialog v-model="dialogUlaz" max-width="500px">
                      <v-card>
                        <v-card-title>
                          <span class="headline">{{ translate("Магацин / Лагер") }}</span>
                        </v-card-title>

                        <v-card-text>
                          <v-container>
                            <v-row>
                              <v-col cols='auto'>
                                {{$t('grocery')}}: {{editedItem.name}}
                              </v-col>
                              <v-col cols='auto'>
                                {{$t('warehouse_code')}}: {{editedItem.code}}
                              </v-col>
                            </v-row>
                            <v-row>
                              <v-col cols='auto'>
                                <strong>{{$t('in_stock')}}: {{editedItem.total}}</strong>
                              </v-col>
                            </v-row>
                            <v-row>
                              <v-col cols="12" sm="6" md="6">
                                <v-text-field
                                    type="number"
                                    v-model="editedItem.amount_in"
                                    :label="translate('Додај количину')"
                                ></v-text-field>
                              </v-col>
                            </v-row>
                          </v-container>
                        </v-card-text>

                        <v-card-actions>
                          <v-spacer></v-spacer>
                          <v-btn color="blue darken-1" text @click="closeUlaz">
                            {{ $t("cancel") }}
                          </v-btn>
                          <v-btn color="blue darken-1" text @click="save">
                            {{ translate("save") }}
                          </v-btn>
                        </v-card-actions>
                      </v-card>
                    </v-dialog>
                  </v-toolbar>
                </template>

                <template v-slot:item.actions="{ item }">
                  <v-icon :title="translate('Улаз робе на лагер')" small class="mr-2" @click="editItemUlaz(item)">
                    mdi-plus
                  </v-icon>
                  <v-icon :title="translate('Излаз робе са лагера')" small class="mr-2" @click="editItemIzlaz(item)">
                    mdi-minus
                  </v-icon>
                  <v-icon small class="mr-2" @click="editItem(item)">
                    mdi-pencil-plus
                  </v-icon>
                </template>
              </v-data-table>
            </v-col>
          </v-row>
        </v-tab-item>
      </v-tabs-items>
      <v-dialog v-model="give_out" max-width="80%" scrollable>
        <v-card>
          <v-card-text>
            <v-card elevation="0">
              <v-card-title>
                <div style="font-size: 15px;font-weight: 700;">{{$t("give_out") }}</div>
                <v-spacer></v-spacer>
                <v-btn icon @click="give_out = false">
                  <v-icon x-large>mdi-close</v-icon>
                </v-btn>
              </v-card-title>
              <v-card-text class="mb-4">
                <v-row>
                  <v-col cols="4">
                    <v-menu
                        ref="menu"
                        v-model="set_menu"
                        :close-on-content-click="false"
                        :return-value.sync="set_date"
                        transition="scale-transition"
                        offset-y
                    >
                      <template v-slot:activator="{ on, attrs }">
                        <v-text-field
                            v-model="set_date"
                            style="max-width: 290px"
                            :label="$t('date')"
                            prepend-icon="mdi-calendar"
                            readonly
                            v-bind="attrs"
                            v-on="on"
                            hide-details
                        ></v-text-field>
                      </template>
                      <v-date-picker v-model="set_date" no-title scrollable>
                        <v-spacer></v-spacer>
                        <v-btn style="border-radius: 8px" text color="primary" @click="set_menu = false">
                          {{ $t("cancel") }}
                        </v-btn>
                        <v-btn style="border-radius: 8px" text color="primary" @click="$refs.menu.save(set_date);">
                          {{ $t("confirm") }}
                        </v-btn>
                      </v-date-picker>
                    </v-menu>
                  </v-col>
                  <v-col cols="4">
                   <v-select class="mt-3 py-3"
                                              v-model="user_give_out"
                                              :items="warehouse_users"
                                              hide-details
                                              dense
                                              :label="$t('give_out_goods')"
                                              item-value="id"
                                              :item-text="getItemText"
                    ></v-select>
                   <v-select class="mt-3 py-3"
                                                 v-model="user_give_in"
                                                 :items="warehouse_users"
                                                 hide-details
                                                 dense
                                                 :item-text="getItemText"
                                                 :label="$t('give_in_goods')"
                                                 item-value="id"
                      ></v-select>
                  </v-col>
                  <v-col cols="4">
                    <v-textarea
                        v-model="warehouse_note"
                        :label="$t('note')"
                        class="px-1"
                        rows="4"
                        auto-grow
                    ></v-textarea>
                  </v-col>
              </v-row>
                <v-row>
                  <v-data-table
                    :headers="headers4"
                    :items="selected_groceries"
                    :search="search"
                    :items-per-page="15"
                    class="elevation-1"
                  >
                    <template v-for="header in headers4" v-slot:[`header.${header.value}`]="{ header }">
                      {{ $t(header.text) }}
                    </template>
                    <template v-for="values in headers2" v-slot:[`item.${values.value}`]="{ item }">
                      {{ translate(item[values.value]) }}
                    </template>
                    <template v-slot:item.edit="{ item }">
                      <v-text-field style="width: 70%;margin-left: auto"
                          v-model="item.amount"
                          type="number"
                          :label="$t('give_out')"
                          class="px-1"
                      ></v-text-field>
                    </template>
                    <template v-slot:item.quantity="{ item }">
                      {{Math.round((item.quantity + Number.EPSILON) * 1e10) / 1e10 }}
                    </template>
                    <template v-for="values in headers" v-slot:item.num="{ item, index }">
                      {{ index + 1 }}
                    </template>
                  </v-data-table>
                </v-row>
              </v-card-text>
              <v-card-actions>
                <v-spacer></v-spacer>
                <v-btn color="blue darken-1" text @click="give_out = false">{{$t('cancel')}}</v-btn>
                <v-btn color="primary" x-large class="px-9 font-weight-black" text @click="saveGiveOut()">{{$t('give_out')}}</v-btn>
                <v-spacer></v-spacer>
              </v-card-actions>
            </v-card>
          </v-card-text>
        </v-card>
      </v-dialog>
    </div>
  </v-card>
</template>

<script>
import axios from "../plugins/axios";
import moment from "moment";

export default {
  data() {
    return {
      printTable: 'tr',
      search: null,
      search1: [],
      pretraga: null,
      moment: moment,
      date: new Date().toISOString().substr(0, 10),
      set_date: new Date().toISOString().substr(0, 10),
      menu: false,
      set_menu: false,
      give_out: false,
      byMeal:1,
      date2: new Date().toISOString().substr(0, 10),
      menu2: false,
      report: null,
      tab: null,
      tab1: null,
      items: ['tr', 'st_mag'],
      user_give_out: '',
      user_give_in: '',
      warehouse_note: '',
      all_items: false,
      dialog: false,
      dialogIzlaz: false,
      dialogUlaz: false,
      dialogPopis: false,
      dialogDelete: false,
      headers: [
        { text: "Id", value: "id", align: "start" },
        { text: "item_code", value: "code", align: "start" },
        { text: "name", value: "name",},
        { text: "total", value: "total" },
        { text: "q", value: "measure", sortable: false },
        { text: "actions", value: "actions", sortable: false },

      ],
      headers2: [
        { text: "item_code", value: "code" },
        { text: "grocery", value: "name" },
        { text: "nor", value: "quantity", align: "end" },
        { text: "total", value: "quantityTotal", align: "end" },
        { text: "q", value: "measure", align: "end" },
      ],
      headers3: [
        { text: "r_b", value: "num", align: "start" },
        { text: "choose", value: "action", align: "start" },
        { text: "item_code", value: "code" },
        { text: "title", value: "name" },
        { text: "quantity", value: "quantity", align: "start" },
        { text: "q", value: "measure", align: "start" },
      ],
      headers4: [
        { text: "item_code", value: "code" },
        { text: "title", value: "name" },
        { text: "current_quantity", value: "quantity", align: "end" },
        { text: "issued_quantity", value: "edit", align: "end" },
        { text: "q", value: "measure", align: "end" },
      ],
      desserts: [],
      editedIndex: -1,
      editedItem: {
        name: "",
        total: 0,
        amount_in: 0,
        amount_off: 0,
      },
      defaultItem: {
        name: "",
        total: 0,
        amount_in: 0,
        amount_off: 0,
      },
      items1: [
        { text: 'per_meal', val: 1 },
        { text: 'total', val: 0 },
      ],
      selected_groceries: [],
      warehouse_users: ''
    };
  },
  created() {
    this.loadGrocery();
    this.getWarehouseUsers();
    this.load();
  },
  methods: {
    selectAllGroceries() {
      if (this.all_items) {
        this.total.filter(item => {
          item.checked = true
        })
      }
      if (! this.all_items) {
        this.total.filter(item => {
          item.checked = false
        })
      }
    },
    getItemText(item) {
      return this.translate(`${item.name} ${item.lastname}`) + ' (' + this.$t(item.type) + ')';
    },
    getWarehouseUsers() {
      axios.post('/warehouse/get-warehouse-users', {restaurant_id: this.$store.state.restaurant.id}).then(({data}) => {
        if (data) {
          this.warehouse_users = data;
        }
      })
    },
    saveGiveOut() {
      let podaci = {}
      podaci['document_type'] = 'exit'
      podaci['document_date'] = this.set_date
      podaci['restaurant_id'] = this.$store.state.restaurant.id
      podaci['created_by'] = this.$store.state.user.id
      podaci['dispatched_by'] = this.user_give_out
      podaci['processed_by'] = null
      podaci['received_by'] = this.user_give_in
      podaci['notes'] = this.warehouse_note
      podaci['groceries'] = this.selected_groceries
      axios.post("/warehouse/save-groceries-warehouse", podaci).then(({data}) => {
        this.selected_groceries = data;
      });
      this.give_out = false
      this.loadGrocery();
    },
    formatDate(date) {
      return moment(date).format("DD.MM.YYYY.")
    },

    toogleMeals() {
      if (this.search1.length > 0) {
          this.search1 = []
      } else
      if (this.search1.length == 0) {
        let arr = []
        this.search1 = {}
        for(let it in this.report) {
          this.search1[it] = {}
          for (let n in this.report[it]) {
            arr.push(n)
            // arr[it].push(this.report[it][n])
            // this.search1[it][n] = arr
          }
        }
          this.search1 = arr
      }
    },

    loadGrocery() {
      axios.get("/groceries?warehouse").then((res) => {
        this.desserts = res.data;
      });
    },

    takeOff() {
      this.selected_groceries = []
      this.give_out = true
      this.total.filter(item => {
        if (item.checked) {
          item.amount = item.quantity
          this.selected_groceries.push(item)
        }
      })
      // this.total.forEach((item) => {
      //   axios
      //     .post("/warehouse", { amount: -item.quantity, grocery_id: item.id })
      //     .then((res) => {
      //       console.log(res);
      //     });
      // });
      //
      // this.loadGrocery();
    },

    load() {
      axios
        .get(
          "/reports/warehouse/daily/restaurant/" +
            this.$store.state.restaurant.id +
            "?date=" +
            moment(this.date).format("YYYY-MM-DD") +
            "&dateTo=" +
            moment(this.date2).format("YYYY-MM-DD")
        )
        .then((res) => {
          let arr = [];
          this.report = res.data;
          for (let i in res.data) {
              for(let n in res.data[i]) {
                  arr.push(n)
              }
          }
          this.report = res.data;
          this.search1 = arr;
        });
    },

    editItem(item) {
      this.editedIndex = this.desserts.indexOf(item);
      this.editedItem = Object.assign({}, item);
      this.dialog = true;
    },

    editItemUlaz(item) {
      this.editedIndex = this.desserts.indexOf(item);
      this.editedItem = Object.assign({}, item);
      this.dialogUlaz = true;
    },

    editItemIzlaz(item) {
      this.editedIndex = this.desserts.indexOf(item);
      this.editedItem = Object.assign({}, item);
      this.dialogIzlaz = true;
    },

    close() {
      this.dialog = false;
      this.$nextTick(() => {
        this.editedItem = Object.assign({}, this.defaultItem);
        this.editedIndex = -1;
      });
    },

    closeIzlaz() {
      this.dialogIzlaz = false;
      this.$nextTick(() => {
        this.editedItem = Object.assign({}, this.defaultItem);
        this.editedIndex = -1;
      });
    },

    closeUlaz() {
      this.dialogUlaz = false;
      this.$nextTick(() => {
        this.editedItem = Object.assign({}, this.defaultItem);
        this.editedIndex = -1;
      });
    },

    closePopis() {
      this.dialogPopis = false;
      this.$nextTick(() => {
        this.editedItem = Object.assign({}, this.defaultItem);
        this.editedIndex = -1;
      });
    },

    print(id) {
      const prtHtml = document.getElementById(id).innerHTML;
      let stylesHtml = "";
      for (const node of [
        ...document.querySelectorAll('link[rel="stylesheet"], style'),
      ]) {
        stylesHtml += node.outerHTML;
      }
      // Get the current date
      const currentDate = moment().format("DD.MM.YYYY HH:mm:ss");
      // Open the print window
      const WinPrint = window.open(
        "",
        "",
        "left=0,top=0,width=800,height=900,toolbar=0,scrollbars=0,status=0"
      );

      WinPrint.document.write(`<!DOCTYPE html>
                                    <html>
                                      <head>
                                        ${stylesHtml}
                                        <style>
                                          table {
                                          font-family: arial, sans-serif;
                                          border-collapse: collapse;
                                          width: 100%;
                                          }
                                          td, th {
                                          border: 1px solid #dddddd;
                                          text-align: left;
                                          padding: 8px;
                                          }
                                          tr:nth-child(even) {
                                          background-color: #f1f1f1;
                                          }
                                          /* Hide the footer when printing */
                                            @media print {
                                              .v-data-footer, .nar, .firme {
                                                display: none;
                                              }
                                              .naslov {
                                                display: block;
                                                margin-bottom: 1px;
                                                margin-top: 20px;
                                              }
                                              .headline_tab {
                                                display: block !important;
                                              }
                                              .headline_mag {
                                                margin-bottom: -40px !important;
                                              }
                                              #st_mag div.v-data-table > div.v-data-table__wrapper {
                                                margin-top: -50px !important;
                                              }
                                              #st_mag header {
                                                margin-bottom: -82px !important;
                                              }
                                            }
                                          </style>
                                      </head>
                                      <body>
                                       <div class="print-date" style="margin-top: 20px; text-align: left;font-weight: 700">
                                           ${ this.$t("order_date") }: ${ this.formatDate(this.date) } - ${ this.formatDate(this.date2) }</h2>
                                        </div> <br>
                                        ${prtHtml}
                                        <div class="print-date" style="margin-top: 20px; text-align: left;font-weight: 700">
                                          ${this.translate('Датум и време креирања извештаја')}: ${currentDate}
                                        </div>
                                      </body>
                                    </html>`);

      WinPrint.document.close();
      WinPrint.focus();
      WinPrint.print();
      WinPrint.close();
    },

    save() {
      this.editedItem.grocery_id = this.editedItem.id;
      let _editedItem = this.editedItem;
      let _editedIndex = this.editedIndex;
      axios.post("/warehouse", this.editedItem).then((res) => {
        Object.assign(this.desserts[_editedIndex], _editedItem);
        this.close();
      });
    },
  },
  computed: {
    total() {
      if (this.report) {
        let a = [];
        for (let item in this.report) {
          for (let item2 in this.report[item]) {
            this.report[item][item2]["groceries"].forEach((grocery) => {
              let index = a.findIndex((i) => i.name === grocery.name);
              if (index > -1) {
                a[index] = {
                  quantity:
                    a[index]["quantity"] +
                    this.report[item][item2]["quantity"] * grocery.quantity,
                  measure: grocery["measure"],
                  name: grocery.name,
                  code: grocery.code,
                  id: grocery.id,
                  checked: false,
                };
              } else {
                a.push({
                  quantity:
                    this.report[item][item2]["quantity"] * grocery.quantity,
                  measure: this.report[item][item2].groceries.find(
                    (i) => i.name === grocery.name
                  )["measure"],
                  code: grocery.code,
                  name: grocery.name,
                  id: grocery.id,
                  checked: false,
                });
              }
            });
          }
        }

        return a;
      }
    },
    formTitle() {
      return this.editedIndex === -1 ? this.$t('add_warehouse') : this.$t('edit_warehouse');
    },
  },
  watch: {
    date() {
      this.date2 = this.date;
    },
    dialog(val) {
      val || this.close();
    },
    dialogDelete(val) {
      val || this.closeDelete();
    },
  },
};
</script>
<style>
.headline_tab {
  display: none;
}
</style>