<template>
  <div style="width: 95%;margin: 10px auto">
    <v-data-table
            :headers="headers"
            :items="desserts"
            :search="search"
            sort-by="calories"
            class="elevation-1"
            dense
            show-expand
    >
        <template v-for="values in headers" v-slot:[`item.${values.value}`]="{ item }">
            {{translate(item[values.value])}}
        </template>
        <template v-for="header in headers" v-slot:[`header.${header.value}`]="{ header }"  >
                {{$t(header.text)}}
          </template>
        <template v-slot:item.data-table-expand="{item, isExpanded, expand}">
            <template v-if="item.children && item.children.length">
                <v-btn icon @click="expand(true)" v-if="!isExpanded">
                    <v-icon>mdi-chevron-down</v-icon>
                </v-btn>
                <v-btn icon @click="expand(false)" v-else>
                    <v-icon>mdi-chevron-up</v-icon>
                </v-btn>
            </template>
        </template>
        <template v-slot:top>
          <v-toolbar
              flat
          >
            <v-toolbar-title>{{ $t('grocery_list') }}</v-toolbar-title>
            <v-divider
                class="mx-4"
                inset
                vertical
            ></v-divider>
            <v-spacer></v-spacer>
            <v-text-field
                class="pr-4 pb-2"
                v-model="search"
                append-icon="mdi-magnify"
                :label="$t('search')"
                single-line
                hide-details
                dense
            ></v-text-field>
            <v-btn v-if="$store.state.restaurant.parent"
                :style="'margin-top: -8px;border-radius: 8px;border: 1px solid ' + $store.state.restaurant.settings.topbar.background + ';background: none;color: ' + $store.state.restaurant.settings.topbar.background + ';box-shadow: none;text-transform:unset'"
                dark
                class="mb-2 mr-2"
                small
                @click.prevent="dialogN = true"
            >
              {{ $t('clone') }} {{ $store.state.restaurant.parent ? $store.state.restaurant.parent.name : '' }}
            </v-btn>
            <v-dialog v-model="dialogN" max-width="100%" scrollable style="margin: 0">
              <v-card>
                <v-card-title class="primary" style="display: flex;justify-content: space-between">
                  <span class="headline white--text"> {{ $t('clone') }} {{ $store.state.restaurant.parent ? $store.state.restaurant.parent.name : '' }}</span>
                  <a @click.prevent="dialogN = false"
                     style="font-size: 14px !important; border: 1px solid #fff;padding: 1px 13px;border-radius: 19px"
                     class="headline white--text">x</a>
                </v-card-title>
                <v-card-text>
                  <v-text-field style="width: 50%;margin-left: auto"
                    class="py-2"
                    v-model="search1"
                    append-icon="mdi-magnify"
                    :label="$t('search')"
                    single-line
                    hide-details
                    dense
                  ></v-text-field>
                  <v-data-table dense :headers="headersN" class="grey lighten-4" :search="search1" style="height: 100%" :items="parentGroceries">
                    <template v-for="header in headersN" v-slot:[`header.${header.value}`]="{ header }">
                      <span v-if="header.text == 'mark'">
                        <input id="checkbox_id" type="checkbox" v-model="select" @change.prevent="selectAll()">
                        <label for="checkbox_id">{{ $t('mark') }}</label>
                      </span>
                      <span v-else>{{ $t(header.text) }}</span>
                    </template>
                    <template v-slot:item.actions="{ item, index }">
                      <span v-if="! prepData[item.id]">
                        <input type="checkbox" v-model="item.selected">
                      </span>
                      <span v-else style="font-size: 13px;color: #9a9a9a">Klonirano</span>
                    </template>
                  </v-data-table>
                </v-card-text>

                <v-card-actions>
                  <v-spacer></v-spacer>
                  <v-btn color="primary px-5" text @click="dialogN = false">
                    {{ $t('quit') }}
                  </v-btn>
                  <v-btn style="border-radius: 10px" color="primary px-5" dark @click="saveClone">
                    {{ translate('Клонирај') }}
                  </v-btn>
                </v-card-actions>
              </v-card>
            </v-dialog>
            <v-dialog v-model="dialog" max-width="600px" scrollable>
                <template v-slot:activator="{ on, attrs }">
                    <v-btn :style="'margin-top: -8px;border-radius: 8px;border: 1px solid ' + $store.state.restaurant.settings.topbar.background + ';background: none;color: ' + $store.state.restaurant.settings.topbar.background + ';box-shadow: none;text-transform: capitalize'"
                            dark
                            class="mb-2"
                            v-bind="attrs"
                            v-on="on"
                            small
                    >
                    <v-icon>mdi-plus</v-icon>
                        {{$t('add_grocery')}}
                    </v-btn>
                </template>
                <v-card>
                    <v-card-title class="primary">
                        <span class="headline white--text">{{ formTitle }}</span>
                    </v-card-title>

                    <v-card-text>
                        <v-container>
                            <v-row>
                                <v-col cols="12">
                                    <v-text-field
                                      v-model="editedItem.name"
                                      :label="'*' + $t('title')"
                                    ></v-text-field>
                                  <p class="greska" v-if="errorName">{{ $t('name_field_mandatory') }}</p>
                                </v-col>
                                <v-row class="px-3">
                                  <v-col cols="6">
                                      <v-text-field
                                              v-model="editedItem.code"
                                              :label="$t('item_code')"
                                      ></v-text-field>
                                     <p class="greska" v-if="errorCode">{{ $t('code_field_mandatory') }}</p>
                                  </v-col>
                                  <v-col cols="6">
                                      <v-select class="mt-3 py-3"
                                        v-model="editedItem.measure"
                                        :items="[
                                          {code: 1, name: 'kg'},
                                          {code: 2, name: 'l'},
                                          {code: 3, name: 'kom'},

                                        ]"
                                        hide-details
                                        dense
                                        :label="$t('unit_of_measurment')"
                                        item-value="name"
                                        item-text="name"
                                      ></v-select>
<!--                                      <v-text-field-->
<!--                                              v-model="editedItem.measure"-->
<!--                                              :label="$t('unit_of_measurment')"-->
<!--                                      ></v-text-field>-->
                                     <p class="greska" v-if="errorMeasure">{{ $t('unit_of_measurment_field_mandatory') }}</p>
                                  </v-col>
                                </v-row>
                                <v-col cols="12">
                                  <v-text-field
                                      type="number"
                                      v-model="editedItem.min_quantity"
                                      :label="$t('minimal_qty')"
                                  ></v-text-field>
                                  <p style="margin-top: -15px;padding: 0; font-size: 10px">{{ translate('Минимална количина за допуну залиха') }}</p>
                                  <p class="greska" v-if="errorCode">{{ $t('code_field_mandatory') }}</p>
                                </v-col>
                            </v-row>
                        </v-container>
                    </v-card-text>

                    <v-card-actions>
                        <v-spacer></v-spacer>
                        <v-btn color="primary px-5" text @click="close">
                          {{ $t('quit') }}
                        </v-btn>
                        <v-btn style="border-radius: 10px" color="primary px-5" dark @click="save">
                            {{$t('save')}}
                        </v-btn>
                    </v-card-actions>
                </v-card>
            </v-dialog>
            <v-dialog v-model="dialogDelete" max-width="500px">
                    <v-card>
                        <v-card-title class="headline">{{$t('delete_confirmation')}}</v-card-title>
                        <v-card-actions>
                            <v-spacer></v-spacer>
                            <v-btn color="blue darken-1" text @click="closeDelete">{{$t('cancel')}}</v-btn>
                            <v-btn color="blue darken-1" text @click="deleteItemConfirm">{{$t('confirm')}}</v-btn>
                            <v-spacer></v-spacer>
                        </v-card-actions>
                    </v-card>
                </v-dialog>
          </v-toolbar>
        </template>
        <template v-slot:expanded-item="{item, isExpanded, expand}">
            <template v-if="item.children">
                <td :colspan="headers.length" class="px-0">
                    <v-data-table
                            dense
                            :headers="headers"
                            class="grey lighten-4"
                            :items="item.children || []"
                            hide-default-footer
                            hide-default-header>
                        <template v-slot:item.actions="{ item, index }">
                            <v-icon
                                    small
                                    class="mr-2"
                                    @click="editItem(item)"
                            >
                                mdi-pencil
                            </v-icon>
                            <v-icon
                                    small
                                    @click="deleteItem(item)"
                            >
                                mdi-delete
                            </v-icon>
                        </template>
                    </v-data-table>
                </td>
            </template>
        </template>
        <template v-slot:item.actions="{ item }">
            <v-icon
                    small
                    class="mr-2"
                    @click="editItem(item)"
            >
                mdi-pencil
            </v-icon>
            <v-icon
                    small
                    @click="deleteItem(item)"
            >
                mdi-delete
            </v-icon>
        </template>
        <template v-slot:no-data>
            <v-btn
                :style="'border-radius: 8px;border: 1px solid ' + $store.state.restaurant.settings.topbar.background + ';background: none;color: ' + $store.state.restaurant.settings.topbar.background + ';box-shadow: none;text-transform: capitalize'"
                    @click="initialize"
            >
                {{$t('resets')}}
            </v-btn>
        </template>
    </v-data-table>
  </div>
</template>

<script>
    import axios from '../plugins/axios'

    export default {
        data: () => ({
            dialog: false,
            dialogN: false,
            dialogDelete: false,
            search:'',
            search1:'',
            errorName: false,
            errorMeasure: false,
            errorCode: false,
            select: false,
            headers: [
                {text: 'id', align: 'id', value: 'id',},
                {text: 'title', align: 'start', value: 'name',},
                {text: 'code', align: 'start', value: 'code',},
                {text: 'q', align: 'start', value: 'measure', sortable: false},
                { text: 'actions', align: 'center', value: 'actions', sortable: false },
                { text: '', value: 'data-table-expand' },
            ],
            headersN: [
              { text: 'mark', align: 'center', value: 'actions', sortable: false },
              {text: 'title', align: 'start', value: 'name',},
              {text: 'q', align: 'start', value: 'measure', sortable: false},
            ],
            desserts: [],
            editedIndex: -1,
            editedParentIndex: -1,
            editedItem: {
                name: '',
                measure: '',
                code:'',
                min_quantity: ''
            },
            defaultItem: {
                name: '',
                measure: '',
                code:'',
                min_quantity: '',
            },
            parentGroceries: []
        }),

        computed: {
            formTitle () {
                return this.editedIndex === -1 ? this.$t('new_grocery') : this.$t('change_grocery')
            },
            prepData() {
              let items = {}
              this.desserts.filter(item => {
                items[item.clone_grocery_id] = item.clone_grocery_id ? true : false
              })
              return items
            }
        },

        watch: {
            dialog (val) {
                val || this.close()
            },
            dialogN (val) {
               val || this.close()
            },
            dialogDelete (val) {
                val || this.closeDelete()
            },
        },

        created () {
           if (this.$store.state.restaurant.parent_id) {
            this.getParentGroceries()
           }

            this.initialize()
        },

        methods: {
            saveClone() {
              let list = {}
              list['items'] = []
              list['restoran'] = this.$store.state.restaurant.id
              this.parentGroceries.filter(item => {
                if (item.selected) {
                  item.restoran = this.$store.state.restaurant.id
                  list['items'].push(item)
                }
              })
              console.log(list['items'])
              axios.post('/groceries/add-parent-groceries', {'clone': list}).then(({data}) => {
                if(data) {
                  this.initialize()
                  this.dialogN = false
                  this.search1 = ''
                }
              })
            },
            selectAll() {
              if (this.select) {
                this.parentGroceries.filter(item => {
                  item.selected = true
                })
              } else {
                this.parentGroceries.filter(item => {
                  item.selected = false
                })
              }

            },
            getParentGroceries() {
                axios.post('/groceries/get-parent-groceries', {'restaurant': this.$store.state.restaurant.parent_id}).then(({data}) => {
                  data.filter(item => {
                    item.selected = false
                  })
                  this.parentGroceries = data
                })
            },
            initialize () {
                axios.get('/groceries').then(res => {
                    this.desserts = res.data
                })
            },

            editItem (item) {
                if(item.parent_id) {
                    this.editedIndex = this.desserts.findIndex(i => i.id === item.parent_id)
                    this.editedParentIndex = this.desserts[this.editedIndex].children.findIndex(i => i.id = item.id)
                }
                else {
                    this.editedIndex = this.desserts.indexOf(item)
                }

                this.editedItem = Object.assign({}, item)
                this.dialog = true
            },

            deleteItem (item) {
                if(item.parent_id) {
                    this.editedIndex = this.desserts.findIndex(i => i.id === item.parent_id)
                    this.editedParentIndex = this.desserts[this.editedIndex].children.findIndex(i => i.id = item.id)
                }
                else {
                    this.editedIndex = this.desserts.indexOf(item)
                }
                this.editedItem = Object.assign({}, item)
                this.dialogDelete = true
            },

            deleteItemConfirm () {
                axios.delete('/groceries/' + this.editedItem.id).then(res => {
                    if(this.editedParentIndex > -1) {
                        this.desserts[this.editedIndex].children.splice(this.editedParentIndex, 1)
                    }
                    else {
                        this.desserts.splice(this.editedIndex, 1)
                    }
                    this.closeDelete()
                })
            },

            close () {
                this.dialog = false
                this.$nextTick(() => {
                    this.editedItem = Object.assign({}, this.defaultItem)
                    this.editedIndex = -1
                    this.editedParentIndex = -1
                    this.errorName = false
                    this.errorMeasure = false
                    this.errorCode = false
                })
            },

            closeDelete () {
                this.dialogDelete = false
                this.$nextTick(() => {
                    this.editedItem = Object.assign({}, this.defaultItem)
                    this.editedIndex = -1
                    this.editedParentIndex = -1
                })
            },

            save () {
                this.editedItem.parent_id = this.editedItem.parent ? this.editedItem.parent.id : 0
                this.errorName = false
                this.errorMeasure = false
                this.errorCode = false
                if (this.editedIndex > -1 || this.editedParentIndex > -1) {

                    let _editedIndex = this.editedIndex
                    let _editedParentIndex = this.editedParentIndex
                    let _editedItem = this.editedItem

                    axios.put('/groceries/' + this.editedItem.id, this.editedItem).then(res => {

                        if(_editedParentIndex > -1) {
                            Object.assign(this.desserts[_editedIndex].children[_editedParentIndex], _editedItem)
                        }
                        else {
                            Object.assign(this.desserts[_editedIndex], _editedItem)
                        }
                    })
                    this.close()
                } else {
                    let _editedItem = this.editedItem
                    if (this.editedItem.name == '') {
                      this.errorName = true
                    }
                    if (this.editedItem.measure == '') {
                      this.errorMeasure = true
                    }
                    // if (this.editedItem.code == '') {
                    //   this.errorCode = true
                    // }
                    if (! this.errorName && ! this.errorMeasure) {
                      axios.post('/groceries', this.editedItem).then(res => {
                        _editedItem.id = res.data.id
                        if(_editedItem.parent_id) {
                          let i = this.desserts.findIndex(i => i.id === _editedItem.parent_id)
                          this.desserts[i].children.push(_editedItem)
                        }
                        else{
                          this.desserts.push(_editedItem)
                        }
                      })
                      this.close()
                    } else {
                      console.log('Molimo vas popunite sva polja')
                    }
                }
            },
        },
    }
</script>
<style scoped>
.greska {
  color: #c20606;
  font-size: 10px;
}
</style>