<template>
  <div class="container">
    <div style="display: flex">
      <v-toolbar-title class="mt-6">{{ $t('messages') }}</v-toolbar-title>
      <v-divider class="mx-4" inset vertical></v-divider>
    </div>
    <div class="mt-9">
      <v-tabs
          v-model="tab"
          bg-color="primary"
      >
        <v-tab value="one">{{ $t('messages') }}</v-tab>
        <v-tab value="two">FCM</v-tab>
      </v-tabs>
      <v-card-text>
        <v-tabs-items v-model="tab">
          <v-tab-item>
            <div style="display: flex;flex-wrap: wrap;margin-bottom: 25px">
              <div style="width: 35%">
                <h5 class="mb-2">Kreiraj poruku:</h5>
                <textarea style="border-bottom: 1px solid #cfcfcf;padding: 8px" v-model="poruka" id="" cols="46" rows="5" placeholder="Vasa poruku..."></textarea>
                <br>
                <v-btn outlined style="border-radius: 9px" color="orange darken-4" small class="mr-2" @click="saveMessage">
                  {{ $t("save") }}
                </v-btn>
              </div>
              <div style="width: 65%;padding-left: 20px">
                <h5 class="mb-2">Sve poruke:</h5>
                <p v-for="(poruka, index) in sve_poruke" style="display: flex;justify-content: space-between;border-bottom: 1px solid #cfcfcf">
                  <span>{{ index + 1 }}. {{ poruka.message }}</span>
                  <span>
                <a href="" @click.prevent="editPoruka(poruka)" style="margin-right: 12px"> <v-icon small>mdi-pencil</v-icon></a>
                <a href="" @click.prevent="deletePoruka(poruka)"><v-icon small style="color: red">mdi-delete</v-icon></a>
              </span>
                </p>
              </div>
            </div>
          </v-tab-item>
          <v-tab-item>
            <FCMNotifications></FCMNotifications>
          </v-tab-item>
        </v-tabs-items>
      </v-card-text>



<!--      <div style="display: flex;flex-wrap: wrap;align-content: center">-->
<!--        <v-select style="width: 26%"-->
<!--                  :label="$t('select_restaurant')"-->
<!--                  v-model="restoran"-->
<!--                  :items="restorani"-->
<!--                  hide-details-->
<!--                  outlined-->
<!--                  @change="sveKompanijeOdRestorana"-->
<!--                  dense-->
<!--                  item-value="id"-->
<!--                  item-text="name"-->
<!--        ></v-select>-->
<!--        <v-select style="width: 26%;margin-left: 10px"-->
<!--                  :label="$t('select_company')"-->
<!--                  v-model="kompanija"-->
<!--                  :items="kompanije"-->
<!--                  @change="sviZaposleniUKompaniji"-->
<!--                  hide-details-->
<!--                  outlined-->
<!--                  dense-->
<!--                  item-value="id"-->
<!--                  item-text="name"-->
<!--        ></v-select>-->
<!--        <v-select style="width: 26%;margin-left: 10px"-->
<!--                  :label="$t('users')"-->
<!--                  v-model="korisnik"-->
<!--                  :items="korisnici"-->
<!--                  hide-details-->
<!--                  outlined-->
<!--                  multiple-->
<!--                  dense-->
<!--                  item-value="id"-->
<!--                  :item-text="item => item.name + ' ' + item.lastname"-->
<!--        ></v-select>-->
<!--        <div style="width: 19%;margin-left: 10px">-->
<!--        <v-btn-->
<!--               color="rgb(251, 109, 59)"-->
<!--               style="color: white;padding: 19px 40px"-->
<!--               class=""-->
<!--               small-->
<!--               @click="">-->
<!--          {{ $t("save") }}-->
<!--        </v-btn>-->
<!--        </div>-->
<!--      </div>-->
    </div>
    <v-dialog v-model="dialog" max-width="600px" scrollable>
      <v-card>
        <v-card-text>
          <v-card elevation="0">
            <v-card-title>
              <v-spacer></v-spacer>
              <v-btn icon @click="dialog = false">
                <v-icon x-large>mdi-close</v-icon>
              </v-btn>
            </v-card-title>
            <v-card-text>
              <v-row>
                <v-card elevation="0" class="pa-2">
                  <v-card-title>
                    <h3>Izmena</h3>
                  </v-card-title>

                  <v-card-text class="mb-4">
                    <textarea name="" v-model="edit_poruka.message" style="border-bottom: 1px solid #cfcfcf" cols="70" rows="5"></textarea>
                    <label for="" style="color: #000;padding-left: 0;margin-left: 0">Verzije</label>
                    <div style="display: flex;flex-wrap: wrap">
                      <input type="text" style="border: 1px solid #cfcfcf;width: 49%;padding: 5px" placeholder="App version min">
                      <input type="text"style="border: 1px solid #cfcfcf;;width: 49%;margin-left: 5px;padding: 5px" placeholder="App version max">
                    </div>
                    <div class="text-right mt-3">
                      <v-btn outlined style="border-radius: 9px" color="orange darken-4" small class="mr-2" @click="editMessage">
                        {{ $t("edit") }}
                      </v-btn>
                    </div>
                  </v-card-text>
                </v-card>
              </v-row>
            </v-card-text>
          </v-card>
        </v-card-text>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import axios from "../plugins/axios";
import FCMNotifications from "./FcmNotification.vue";
import conf from "../config";

export default {
  data() {
    return {
      conf: conf,
      dialog: false,
      poruka: '',
      sve_poruke: '',
      restorani: [],
      restoran: '',
      kompanije: [],
      kompanija: '',
      korisnici: [],
      korisnik: [],
      edit_poruka: '',
      tab: null
    };
  },

  created() {
    this.load()
  },

  components: {
    FCMNotifications,
  },

  methods: {
    load() {
      axios.get('/poruke/sve-poruke').then(({data}) => {
        this.sve_poruke = data['poruke']
        this.restorani = data['restorani']
      })
    },
    editPoruka(poruka) {
      this.dialog = true
      this.edit_poruka = poruka
    },
    // sveKompanijeOdRestorana() {
    //   this.kompanije = []
    //   axios.post('/poruke/sve-kompanije-od-restorana', {
    //     'id': this.restoran
    //   }).then(({data}) => {
    //     this.kompanije = data
    //   })
    // },
    // sviZaposleniUKompaniji() {
    //   this.korisnici = []
    //   this.kompanije.filter(item => {
    //     if (item.id == this.kompanija) {
    //       this.korisnici = item.users
    //     }
    //   })
      // axios.post('/poruke/svi-korisnici-u-kompaniji', {
      //   'id': this.kompanija
      // }).then(({data}) => {
      //   this.korisnici = data
      // })
    // },
    saveMessage() {
      if (this.poruka != '') {
        axios.post('/poruke/sacuvaj-poruku', {
          'message': this.poruka
        }).then(({data}) => {
          if (data) {
            this.sve_poruke.push(data.data)
            this.poruka = ''
          }
        })
      }
    },
    editMessage() {
      axios.post('/poruke/izmeni-poruku', {
        'poruka': this.edit_poruka
      }).then(({data}) => {
        if(data) {
          this.sve_poruke.filter(item => {
            if (item.id == data.id) {
              item = data
            }
          })
          this.dialog = false
        }
      })
    },
    deletePoruka(poruka) {
      axios.post('/poruke/obrisi-poruku', {
        'message': poruka
      }).then(({data}) => {
        if(data) {
          this.sve_poruke.filter((item,index) => {
            if (item.id == poruka.id) {
              this.sve_poruke.splice(index, 1)
            }
          })
          this.dialog = false
        }
      })
    }
  },
};
</script>

<style scoped>
label {
  display: inline-block;
  color: white;
  padding: 0.5rem;
  font-family: sans-serif;
  border-radius: 0.3rem;
  cursor: pointer;
  margin-top: 1rem;
}

@media (max-width: 767px) {
  .col-sm-4 {
    max-width: 33.333%;
  }
}
</style>