<template>
  <div style="width: 95%;margin: 20px auto">
    <div :class="windowSize.x < 550 ? 'block telefon' : 'row telefon-row'">
      <v-col sm="4" md="4" lg="3" class="phone-margin">
        <v-menu
            ref="menu"
            v-model="menu"
            :close-on-content-click="false"
            :return-value.sync="date"
            transition="scale-transition"
            offset-y
            max-width="290px"
            min-width="290px"
        >
          <template v-slot:activator="{ on, attrs }">
            <v-text-field
                v-model="dateRangeText"
                :label="$t('date')"
                prepend-icon="mdi-calendar"
                readonly
                v-bind="attrs"
                v-on="on"
                hide-details
            ></v-text-field>
          </template>
          <v-card>
            <strong>{{ $t("select_span_meals_listing") }}</strong>
            <br/>
            {{ $t("click_on_two_dates") }}<br/>
            <v-date-picker v-model="date" range no-title scrollable>
              <v-spacer></v-spacer>
              <v-btn text color="primary" @click="menu = false"> Cancel</v-btn>
              <v-btn
                  text
                  color="primary"
                  @click="
                  $refs.menu.save(date);
                  load();
                "
              >
                OK
              </v-btn>
            </v-date-picker>
          </v-card>
        </v-menu>
      </v-col>
      <v-col col="8" md="6" class="d-flex px-2">
        <!--  GRUPE JELA-->
        <v-col sm="6" md="6" lg="6">
          <v-autocomplete class="mt-3"
            v-model="select_view"
            :items="[
              { name: $t('meal_in_meals'), id: 1 },
              { name: $t('meal_in_groops'), id: 2 },
              { name: translate('Фирме које храни ресторан'), id: 3 },
              // { name: $t('all_meals'), id: 3 }
            ]"
            dense
            @input="changeView"
            :label="$t('view')"
            item-text="name"
            item-value="id"
            clear
            chips
            small-chips
          >
          </v-autocomplete>
        </v-col>
        <!-- PRETRAGA-->
        <v-col sm="5" md="6" class="phone-margin responsive-margin">
          <v-text-field
              class="pt-2"
              v-model="pretraga"
              append-icon="mdi-magnify"
              :label="$t('search')"
              single-line
              hide-details
              dense
          ></v-text-field>
        </v-col>
      </v-col>
      <!-- STAMPA DODATNI OBROK-->
      <v-col sm="2" md="2" lg="3" class="my-6 button-margin dodatna-margina px-3">
        <div :class="windowSize.x > 1023 ? 'd-block' : 'row'" class="row">
          <span style="width: 46%;display: inline-block;text-align: center">
            <v-btn :title="$t('print')" :loading="loading" :style="'border-radius: 8px;background: none;color: ' + $store.state.restaurant.settings.topbar.background + ';box-shadow: none;text-transform: capitalize'"
                           @click="printDialog = true" small class="mt-2">
              <v-icon :style="'color:' + $store.state.restaurant.settings.topbar.background ? $store.state.restaurant.settings.topbar.background : 'rgb(251, 109, 59)'" class="mr-2">mdi-printer</v-icon>
            <!--            <span :class="windowSize.x > 1023 ? 'd-block' : 'd-none'" id="stampaj">-->
            <!--              {{ $t("print") }}-->
            <!--            </span>-->
            </v-btn>
            <v-btn v-if="tab == 0 || tab == 1 || tab == 2 || tab == 6 || tab == 7" :title="translate('Извезите податке у Excel')" :loading="loading" :style="'border-radius: 8px;background: none;color:rgba(14,105,19,0.56);box-shadow: none;text-transform: capitalize'"
                   @click="exportToExcel('', search3 != '' ? search3 : '', '', '')" small class="mt-2">
              <v-icon style="color:rgba(14,105,19,0.56)" class="mr-2">mdi mdi-microsoft-excel</v-icon>
            </v-btn>
          </span>
          <v-btn v-if="date[0] == date[1] && select_view != 2" @click.prevent="tab = 4,canSeeAddMeal = true"
             style="width: 47%"
             :style="'border-radius: 8px;border: 1px solid ' + $store.state.restaurant.settings.topbar.background + ';background: none;color: ' + $store.state.restaurant.settings.topbar.background + ';box-shadow: none;text-transform: capitalize'"
             small class="mt-2 ml-2">
            <v-icon :style="'color:' + $store.state.restaurant.settings.topbar.background ? $store.state.restaurant.settings.topbar.background : 'rgb(251, 109, 59)'">
              mdi-plus
            </v-icon>
            <span :class="windowSize.x > 1023 ? 'd-block' : 'd-none'">{{ $t("add_meals2") }}</span>
          </v-btn>
        </div>
      </v-col>
    </div>
    <!-- FIRME KOJE HRANI RESTORAN more_restaurants tip -->
    <v-row v-if="select_view == 3">
      <v-card style="width: 100%" elevation="0">
        <v-tabs v-model="tab" background-color="primary" dark>
          <v-tab @click="printTable = item.print;name = item.tab;setItemsForExcel(index);selectAllFirms(item.tab);" v-for="(item, index) in items2" :key="item.tab">
            <v-icon style="margin-right: 7px">{{ item.icon }}</v-icon>
            {{ translate(item.tab) }}
          </v-tab>
        </v-tabs>
        <v-tabs-items id="izv" v-model="tab">
          <v-tab-item>
            <v-card flat>
              <v-card-text>
                <v-data-table
                    :search="pretraga"
                    id="table3"
                    :headers="headers"
                    :items="desserts3"
                    :items-per-page="-1"
                    class="elevation-1"
                    hide-default-footer
                    disable-pagination
                    mobile-breakpoint="0"
                >
                  <template
                      v-for="header in headers"
                      v-slot:[`header.${header.value}`]="{ header }"
                  >
                    {{ translate(header.text) }}
                  </template>
                  <template
                      v-for="values in headers"
                      v-slot:[`item.${values.value}`]="{ item }"
                  >
                    <span :title="'ID: ' + item.id">{{ translate(item[values.value]) }}</span>
                  </template>
                  <template v-slot:item.num="{ item, index }">
                    {{ item.menu !== "УКУПНО" ? index + 1 : "" }}
                  </template>

                  <template v-slot:item.shift_one="{ item }">
                    {{ translate(item.shift_one.toFixed(5)) }}
                  </template>
                  <template v-slot:item.shift_two="{ item }">
                    {{ translate(item.shift_two.toFixed(5)) }}
                  </template>
                  <template v-slot:item.shift_three="{ item }">
                    {{ translate(item.shift_three.toFixed(5)) }}
                  </template>
                  <template v-slot:item.total="{ item }">
                    {{ translate(item.total.toFixed(5)) }}
                  </template>
                </v-data-table>
              </v-card-text>
            </v-card>
          </v-tab-item>
          <!-- JELA-->
          <v-tab-item>
            <v-card>
              <v-card-text>
                <v-data-table
                    id="table7"
                    :headers="headersJ"
                    :items="desserts2MoreRestaurnats"
                    class="elevation-1"
                    hide-default-footer
                    disable-pagination
                    mobile-breakpoint="0"
                >
                  <template
                      v-for="header in headersJ"
                      v-slot:[`header.${header.value}`]="{ header }"
                  >
                    {{ translate(header.text) }}
                  </template>
                  <template
                      v-for="values in headersJ"
                      v-slot:[`item.${values.value}`]="{ item }"
                  >
                    <span v-if="item" :title="'ID: ' + item.id">{{ translate(item[values.value]) }}</span>
                  </template>
                  <template
                      v-for="values in headersJ"
                      v-slot:item.num="{ item, index }"
                  >
                    {{ item.menu !== "УКУПНО" ? index + 1 : "" }}
                  </template>
                  <template v-slot:item.menu="{item}">
                    <span v-if="item" :title="'ID: ' + item.id">{{item.menu == "УКУПНО" ? $t("totalU") : translate(item.menu) }}</span>
                  </template>
                </v-data-table>
              </v-card-text>
            </v-card>
          </v-tab-item>
          <!-- OBROCI-->
          <v-tab-item>
            <v-card flat>
              <v-card-text>
                <v-autocomplete
                    v-model="search4"
                    :items="companiesMoreRestaurants"
                    dense
                    filled
                    multiple
                    @input="obrokMoreRestaurnats"
                    :label="$t('select_company')"
                    :item-text="getItemText"
                    clear
                    chips
                    small-chips
                >
                  <v-btn
                      color="primary"
                      slot="prepend-item"
                      @click="toogleFirmsObrociMoreRestaurants()"
                      small
                      class="mx-4"
                  >
                    {{ $t("display_all") }}
                  </v-btn>
                </v-autocomplete>
                <!--                :items="filterMealsTotal"-->
                <div id="table4">
                  <v-data-table v-if="obroci.length && search4.length != 0"
                                :search="pretraga"
                                id="table1"
                                :headers="headersM"
                                :items="filterObrokMoreRestaurants"
                                class="elevation-1"
                                hide-default-footer
                                disable-pagination
                                mobile-breakpoint="0"
                  >
                    <template
                        v-for="header in headersM"
                        v-slot:[`header.${header.value}`]="{ header }"
                    >
                      {{ $t(header.text) }}
                    </template>
                    <template v-slot:item.menu="{ item }">
                      <span v-if="item" :title="'ID: ' + item.id" @click="showMeal(item.id)"> {{item.menu == "УКУПНО" ? $t("totalU") : translate(item.menu) }}</span>
                    </template>
                    <template v-slot:item.shift_one="{ item }">
                      <span v-if="item">{{ translate(item.shift_one) }}</span>
                    </template>
                    <template v-slot:item.shift_two="{ item }">
                      <span v-if="item">{{ translate(item.shift_two) }}</span>
                    </template>
                    <template v-slot:item.shift_three="{ item }">
                      <span v-if="item">{{ translate(item.shift_three) }}</span>
                    </template>
                    <template v-slot:item.total="{ item }">
                      <span v-if="item">{{ translate(item.total) }}</span>
                    </template>
                    <template v-slot:item.num="{ item, index }">
                      {{ item && item.menu && item.menu !== "УКУПНО" ? index + 1 : "" }}
                    </template>
                  </v-data-table>
                </div>
              </v-card-text>
            </v-card>
          </v-tab-item>
          <!-- НАРУЧЕНО-->
          <v-tab-item>
            <v-card flat v-if="tab == 3">
              <v-card-text style="display: flex;flex-wrap: wrap">
                <v-autocomplete style="width: 70%"
                                v-model="searchN"
                                :items="companiesMoreRestaurants"
                                dense
                                filled
                                :label="$t('select_company')"
                                item-value="id"
                                :item-text="getItemText"
                                @change="findMealsNarudzbeMoreRestaurants()"
                                clear
                                chips
                                small-chips
                ></v-autocomplete>
                <v-autocomplete style="width: 25%;margin-left: 10px"
                                v-model="searchByShift"
                                :items="Object.keys(narudzbe)"
                                dense
                                filled
                                :label="$t('shifts')"
                                item-text="company"
                                item-value="id"
                                clear
                                chips
                                small-chips
                ></v-autocomplete>
              </v-card-text>
              <div id="table34" style="display: flex;flex-wrap: wrap;">
                <div :style="Object.keys(searcByShift).length > 1 ? 'width: 32%;' : 'width: 100%;'"
                     v-for="(items, inx) in searcByShift">
                  <p class="text-center font-weight-bold">
                    {{ inx == 1 ? $t('shift_one') : inx == 2 ? $t('shift_two') : $t('shift_three') }} (<v-icon>mdi mdi-account</v-icon>{{ items ? Object.keys(items).length : '' }})
                  </p>
                  <div :style="Object.keys(searcByShift).length > 1 ? '' : 'display:flex;flex-wrap:wrap;'">
                    <template v-for="(item, index) in items">
                      <div :class="Object.keys(searcByShift).length > 1 ? '1' : 'third-width'" style="padding: 20px;border:1px solid #cfcfcf;border-radius: 5px;">
                        <p :title="'ID:' + item[Object.keys(item)[0]][0].user_id" class="text-center pt-2">
                          <span style="color: #919191">#{{ item[Object.keys(item)[0]][0].order_id }}</span>
                          <span class="font-weight-bold pt-2"> {{translate(item[Object.keys(item)[0]][0].name + ' ' + item[Object.keys(item)[0]][0].lastname) }}</span>
                        </p>
                        <template v-for="(i, inx) in item">
                          <p :title="'ID:' + item[inx][0].meal_id">{{ item[inx][0].quantity }}<span style="color: #a4a4a4">x</span> {{ inx }}</p>
                        </template>
                      </div>
                    </template>
                  </div>
                </div>
              </div>
            </v-card>
          </v-tab-item>
          <!-- PAKOVANJE-->
          <v-tab-item>
            <v-card flat>
              <v-card-text>
                <v-autocomplete
                    v-model="search"
                    :items="companiesMoreRestaurants"
                    dense
                    filled
                    multiple
                    :label="$t('select_company')"
                    :item-text="getItemText"
                    clear
                    chips
                    small-chips
                >
                  <v-btn
                      color="primary"
                      slot="prepend-item"
                      @click="toogleFirms()"
                      small
                      class="mx-4">
                    {{ $t("display_all") }}
                  </v-btn>
                </v-autocomplete>
                <div >
                  <template v-for="(comapny, companyKey) in search && search.length > 0 ? dessertsMoreRestaurnats.filter((i) => search.includes(translate(i.company))) : dessertsMoreRestaurnats" dense>
                    <h2 class="text-center position-relative mt-5 mb-2" style="display: flex;flex-wrap: wrap">
                      <span class="firm-margin headline-width">{{ translate(comapny.company) }}</span>
<!--                      <v-btn style="font-size: 11px" @click.prevent="addNewMeal(comapny, $store.state.restaurant.id)"-->
<!--                             v-if="canSeeAddMeal && date[0] == date[1]"-->
<!--                             :style="'border-radius: 8px;border: 1px solid ' + $store.state.restaurant.settings.topbar.background + ';background: none;color: ' + $store.state.restaurant.settings.topbar.background + ';box-shadow: none;text-transform: capitalize'"-->
<!--                             small class="mb-1 no-print subheadline-width">-->
<!--                        <v-icon :style="'color:' + $store.state.restaurant.settings.topbar.background ? $store.state.restaurant.settings.topbar.background : 'rgb(251, 109, 59)'">-->
<!--                          mdi-plus-->
<!--                        </v-icon>-->
<!--                        {{ $t("add_meals2") }}-->
<!--                      </v-btn>-->
                    </h2>
                    <v-data-table id="table4"
                      :headers="headersM1"
                      :items="comapny.foods"
                      class="elevation-1"
                      hide-default-footer
                      :search="pretraga"
                      disable-pagination
                      mobile-breakpoint="0"
                    >
                      <template v-for="header in headersM1" v-slot:[`header.${header.value}`]="{ header }">
                        {{ $t(header.text) }}
                      </template>
                      <template v-for="values in headersM1" v-slot:[`item.${values.value}`]="{ item }">
                        {{ translate(item[values.value]) }}
                      </template>
                      <template v-slot:item.menu="{ item }">
                        <span @click="showMeal(item.id)">{{item.name == "УКУПНО" ? $t("totalU") : translate(item.name) }}</span>
                      </template>
                      <template v-slot:item.num="{ item, index }">
                        {{ item && item.name && item.name !== "УКУПНО" ? index + 1 : "" }}
                      </template>
                    </v-data-table>
                  </template>
                </div>
              </v-card-text>
            </v-card>
          </v-tab-item>
          <!-- PORUCIOCI-->
          <v-tab-item>
            <v-card flat v-if="tab == 5">
              <!-- <v-btn
              @click="print('table4', true)"
              color="primary"
              small
              class="mt-2"
              ><v-icon>mdi-printer</v-icon>{{ translate("Штампај") }}</v-btn
            > -->
              <v-card-text>
                <v-autocomplete
                    v-model="search2"
                    :items="companiesMoreRestaurants"
                    multiple
                    dense
                    filled
                    @input="findMealsMoreRestaurnats"
                    :label="$t('select_company')"
                    item-value="id"
                    :item-text="getItemText"
                    clear
                    chips
                    small-chips
                >
                  <v-btn
                      color="primary"
                      slot="prepend-item"
                      @click="toggle"
                      small
                      class="mx-4"
                  >
                    {{ $t("display_all") }}
                  </v-btn>
                  <v-divider slot="prepend-item" class="mt-2"/>
                </v-autocomplete>

                <template v-for="(meal, i) in meals">
                  <h3>{{ i }}</h3>
                  <v-data-table
                      :search="pretraga"
                      :id="'table6_' + i"
                      :headers="headersP"
                      :items="meal.meals"
                      class="elevation-1 my-4 test_klasa"
                      hide-default-footer
                      disable-pagination
                      mobile-breakpoint="0"
                  >
                    <template v-for="header in headersP" v-slot:[`header.${header.value}`]="{ header }">
                      {{header.value == 'num' || header.value == 'name' || header.value == 'total' ? translate(header.text) : '' }} {{header.value == 'shift_one' && meal.company.shift_one == 1 ? translate(header.text) : header.value == 'shift_two' && meal.company.shift_two == 2 ? translate(header.text) : header.value == 'shift_three' && meal.company.shift_three == 3 ? translate(header.text) : '' }}
                    </template>
                    <template v-slot:item.menu="{ item }">
                      <span @click="showMeal(item.id)">{{item.name == "УКУПНО" ? $t("totalU") : translate(item.name) }}</span>
                    </template>
                    <template v-slot:item.name="{ item }">
                      <span :title="'ID: ' + item.id">{{item.name == "УКУПНО" ? $t("totalU") : translate(item.name) }}</span>
                    </template>
                    <template v-slot:item.shift_one="{ item }">
                      <template v-if="item.name == 'УКУПНО'">
                        <span class="font-weight-bold">{{ meal.company.shift_one == 1 ? item["users"]["1-smena"] : '' }}</span>
                      </template>
                      <template v-else v-for="user in item['users']['1-smena']">
                        <span @click="showUsers(user)" style="cursor: pointer">
                          <span v-if="user.name == 'Обрисан'" style="color: #c20606">
                            {{ user.id }} <span style="font-size: 10px">{{ translate(user.name.toLowerCase()) }}:</span>
                            {{ user.quantity >= 1 ? " " + user.quantity + ", " : "," }}
                          </span>
                          <span v-else>
                              {{ translate(user.name) + " " + translate(user.lastname) }}:
                              <span v-if="user.quantity >= 1">  {{user.quantity >= 1 ? " " + user.quantity + ", " : "," }} </span>
                              <span v-else>0</span>
                          </span>
                        </span>
                      </template>
                      <span v-if="item&&item['users']&&item['users']['1-smena']&&item.name != 'УКУПНО'" style="display: block;font-size: 13px;margin-top: 5px">
                        {{ $t('total') }}:
                        <span style="font-weight: 700">
                          <span>{{totalUserQtu(item['users']['1-smena']) != 0 ? totalUserQtu(item['users']['1-smena']) : 0 }}</span>
                        </span>
                      </span>
                    </template>
                    <template v-slot:item.shift_two="{ item }">
                      <template v-if="item.name == 'УКУПНО'">
                        <span class="font-weight-bold">{{meal.company.shift_two == 2 ? item["users"]["2-smena"] : '' }}</span>
                      </template>
                      <template v-else v-for="(user, i) in item['users']['2-smena']">
                        <span @click="showUsers(user)">
                          <span v-if="user.name == 'Обрисан'" style="color: #c20606">
                            {{ user.id }}
                            <span style="font-size: 10px">{{ translate(user.name.toLowerCase()) }}:</span>
                             {{ user.quantity >= 1 ? "" + user.quantity + ", " : "," }}
                          </span>
                          <span v-else>{{translate(user.name) + " " + translate(user.lastname) }}: {{ user.quantity >= 1 ? "" + user.quantity + ", " : "," }}</span>
                        </span>
                      </template>
                      <span v-if="item&&item['users']&&item['users']['2-smena']&&item.name != 'УКУПНО'" style="display: block;font-size: 13px;margin-top: 5px">
                         {{ $t('total') }}:
                        <span style="font-weight: 700">
                            <span v-for="tot in item['users']['2-smena']" v-if="tot.quantity == 0">
                            <span>0</span>
                          </span>
                          <span>{{totalUserQtu(item['users']['2-smena']) != 0 ? totalUserQtu(item['users']['2-smena']) : '' }}</span>
                        </span>
                      </span>
                    </template>
                    <template v-slot:item.shift_three="{ item }">
                      <template v-if="item.name == 'УКУПНО'">
                        <span class="font-weight-bold">{{meal.company.shift_three == 3 ? item["users"]["3-smena"] : '' }}</span>
                      </template>
                      <template v-else v-for="(user, i) in item['users']['3-smena']">
                        <span @click="showUsers(user)">
                         <span v-if="user.name == 'Обрисан'" style="color: #c20606">
                            {{ user.id }}
                            <span style="font-size: 10px">{{ translate(user.name.toLowerCase()) }}:</span>
                            {{ user.quantity >= 1 ? " " + user.quantity + ", " : "," }}
                          </span>
                          <span v-else>{{translate(user.name) + " " + translate(user.lastname) }}: {{ user.quantity >= 1 ? " " + user.quantity + ", " : "," }}</span>
                        </span>
                      </template>
                      <span v-if="item&&item['users']&&item['users']['3-smena']&&item.name != 'УКУПНО'" style="display: block;font-size: 13px;margin-top: 5px">
                         {{ $t('total') }}:
                        <span style="font-weight: 700">
                          <span v-for="tot in item['users']['3-smena']" v-if="tot.quantity == 0">
                            <span>0</span>
                          </span>
                          <span>{{totalUserQtu(item['users']['3-smena']) != 0 ? totalUserQtu(item['users']['3-smena']) : '' }}</span>
                        </span>
                      </span>
                    </template>
                    <template v-slot:item.total="{ item }">
                      <template>
                        <span class="font-weight-bold">{{ item.total }}</span>
                      </template>
                    </template>
                    <template v-for="values in headers" v-slot:item.num="{ item, index }">
                      {{ item.name !== "УКУПНО" ? index + 1 : "" }}
                    </template>
                  </v-data-table>
                </template>
              </v-card-text>
            </v-card>
          </v-tab-item>
          <!-- PREUZETO-->
          <v-tab-item>
            <v-card flat>
              <v-card-text>
                <v-data-table
                    :search="pretraga"
                    id="table5"
                    :headers="headers1"
                    :items="total"
                    :items-per-page="-1"
                    class="elevation-1"
                    hide-default-footer
                    disable-pagination
                    mobile-breakpoint="0"
                >
                  <template v-for="header in headers1" v-slot:[`header.${header.value}`]="{ header }">
                    {{ translate(header.text) }}
                  </template>
                  <template v-for="values in headers1" v-slot:[`item.${values.value}`]="{ item }">
                    <span :title="values.value == 'total' ? $t('ordered_1') + '/' + $t('taken') : ''">{{ translate(item[values.value]) }}</span>
                    <a v-if="values.value == 'month'" @click.prevent="openDialogReportFirmMoreRestaurants(item)">
                      <v-icon style="font-size: 17px" color="primary">mdi-format-list-bulleted</v-icon>
                    </a>
                  </template>
                  <template v-for="values in headers1" v-slot:item.num="{ item, index }">
                    {{ item.menu !== "УКУПНО" ? index + 1 : "" }}
                  </template>
                </v-data-table>
              </v-card-text>
            </v-card>
          </v-tab-item>
          <!-- KORISNICI -->
          <v-tab-item>
            <v-card flat>
              <v-card-text>
                <v-autocomplete
                    v-model="search3"
                    :items="companiesMoreRestaurants"
                    dense
                    filled
                    @input="findUsersMoreRestaurants()"
                    :label="$t('select_company')"
                    :item-text="getItemText"
                    item-value="id"
                    clear
                    chips
                    small-chips
                ></v-autocomplete>
              </v-card-text>
              <v-data-table v-if="search3"
                  :headers="headersU"
                  :items="users"
                  sort-by="status"
                  id="table7"
                  class="elevation-1"
                  dense
                  hide-default-footer
                  disable-pagination
                  mobile-breakpoint="0"
                  :search="pretraga"
              >
                <template v-for="header in headersU" v-slot:[`header.${header.value}`]="{ header }">
                  {{ translate(header.text) }}
                </template>
                <template v-for="values in headersU" v-slot:[`item.${values.value}`]="{ item }">
                  <span :title="values.value == 'order_count' ? translate('Поручено оброка') : ''">{{
                      translate(item[values.value])
                    }}</span>
                </template>
                <template v-slot:item.id="{ item }">
                  <span v-if="item.id == 'УКУПНО:'">{{ $t("totalU") }}</span>
                  <span v-else @click.prevent="showUsers(item)">{{ item.id }} </span>
                </template>
                <template v-for="values in headersU" v-slot:item.num="{ item, index }">
                  {{ item.id == 'УКУПНО' ? '' : index + 1 }}
                </template>
                <template v-slot:item.shift_one="{ item }">
                  <span>{{item && item.shift_one ? item.shift_one : ''}}</span>
                </template>
                <template v-slot:item.shift="{ item }">
                  <span v-if="date[0] == date[1]">{{ item && item.shift ? Number.isInteger(item.shift) ? item.shift : item.shift.replace('-smena', '') : '' }}</span>
                </template>
                <template v-slot:item.date="{ item }">
                  <span :title="translate('Датум, време наручивања')" v-if="date[0] == date[1]">
<!--                    {{ item.id != 'УКУПНО:' && item.order_count > 0 ? moment(item.date).format('DD.MM.  HH:mm') : ''}}-->
                    {{ item.id != 'УКУПНО:' && item.order_count > 0 ? item.date : '' }}
                  </span>
                </template>
                <template v-slot:item.action="{ item }">
                  <a :title="$t('months_report')" v-if="item.id != 'УКУПНО:'" href=""
                     @click.prevent="openDialogReportUser(item, select_view == 2 ? 'food' : '')">
                    <v-icon style="font-size: 17px" color="primary">mdi-format-list-bulleted</v-icon>
                  </a>
                </template>
              </v-data-table>
            </v-card>
          </v-tab-item>
          <!-- PREUZETI OBROCI -->
          <v-tab-item>
            <v-card flat>
              <v-card-text>
                <v-autocomplete
                    v-model="search"
                    :items="companiesMoreRestaurants"
                    dense
                    filled
                    multiple
                    :label="$t('select_company')"
                    :item-text="getItemText"
                    clear
                    chips
                    small-chips
                >
                  <v-btn
                      color="primary"
                      slot="prepend-item"
                      @click="toogleFirms()"
                      small
                      class="mx-4"
                  >
                    {{ $t("display_all") }}
                  </v-btn>
                </v-autocomplete>
                <div id="table8">
                  <template
                      v-for="(comapny, companyKey) in search && search.length > 0 ? dessertsMoreRestaurnats.filter((i) => search.includes(translate(i.company))) : dessertsMoreRestaurnats"
                      dense
                  >
                    <h2 class="text-center mt-5 mb-2">
                      {{ translate(comapny.company) }}
                    </h2>
                    <v-data-table
                        :search="pretraga"
                        id="table4"
                        :headers="headersM"
                        :items="comapny.foods"
                        class="elevation-1"
                        hide-default-footer
                        disable-pagination
                        mobile-breakpoint="0"
                    >
                      <template
                          v-for="header in headersM"
                          v-slot:[`header.${header.value}`]="{ header }"
                      >
                        {{ $t(header.text) }}
                      </template>
                      <template v-for="values in headersM" v-slot:[`item.${values.value}`]="{ item }">
                        {{ translate(item[values.value]) }}
                      </template>

                      <template v-slot:item.shift_one="{item}">
                        {{ item.shift_one_payed }}/{{ item.shift_one }}
                      </template>
                      <template v-slot:item.shift_two="{item}">
                        {{ item.shift_two_payed }}/{{ item.shift_two }}
                      </template>
                      <template v-slot:item.shift_three="{item}">
                        {{ item.shift_three_payed }}/{{ item.shift_three }}
                      </template>
                      <template v-slot:item.total="{item}">
                        {{ item.total_payed }}/{{ item.total }}
                      </template>
                      <template v-slot:item.menu="{ item }">
                        <span @click="showMeal(item.id)">{{item.name == "УКУПНО" ? $t("totalU") : translate(item.name) }}</span>
                      </template>
                      <template v-slot:item.num="{ item, index }">
                        {{ item.name !== "УКУПНО" ? index + 1 : "" }}
                      </template>
                    </v-data-table>
                  </template>
                </div>
              </v-card-text>
            </v-card>
          </v-tab-item>
          <v-tab-item>
            <v-card flat>
              <v-card-text>
                <v-autocomplete
                    v-model="search5"
                    :items="companiesMoreRestaurants"
                    dense
                    filled
                    multiple
                    @input="reservedMeals"
                    :label="$t('select_company')"
                    item-text="company"
                    item-value="id"
                    clear
                    chips
                    small-chips
                >
                  <v-btn
                      color="primary"
                      slot="prepend-item"
                      @click="toogleFirmsReserve()"
                      small
                      class="mx-4"
                  >
                    {{ $t("display_all") }}
                  </v-btn>
                </v-autocomplete>
                <div id="table9">
                  <template
                      v-for="(comapny, companyKey) in search5 && search5.length > 0
                      ? desserts6.filter((i) => search5.includes(i.company))
                      : desserts6"
                      dense
                  >
                    <h2 class="text-center mt-5 mb-2">
                      {{ translate(comapny.company) }}
                    </h2>
                    <v-data-table
                        :search="pretraga"
                        id="table9"
                        :headers="headersS"
                        :items="comapny.foods"
                        class="elevation-1"
                        hide-default-footer
                        disable-pagination
                        mobile-breakpoint="0"
                    >
                      <template
                          v-for="header in headersS"
                          v-slot:[`header.${header.value}`]="{ header }"
                      >
                        {{ translate(header.text) }}
                      </template>
                      <template
                          v-for="values in headersS"
                          v-slot:[`item.${values.value}`]="{ item }"
                      >
                        {{ translate(item[values.value]) }}
                      </template>
                      <template v-slot:item.menu="{ item }">
                        <span @click="showMeal(item.id)">{{translate(item.name) }}</span>
                      </template>
                      <template v-slot:item.num="{ item, index }">
                        {{ item.name !== "УКУПНО" ? index + 1 : "" }}
                      </template>
                    </v-data-table>
                    <!--                    <v-data-table-->
                    <!--                        :search="pretraga"-->
                    <!--                        id="table1"-->
                    <!--                        :headers="headersS"-->
                    <!--                        :items="filterReservedMeals"-->
                    <!--                        class="elevation-1"-->
                    <!--                        hide-default-footer-->
                    <!--                        disable-pagination-->
                    <!--                    >-->
                    <!--                      <template-->
                    <!--                          v-for="header in headers"-->
                    <!--                          v-slot:[`header.${header.value}`]="{ header }"-->
                    <!--                      >-->
                    <!--                        {{ translate(header.text) }}-->
                    <!--                      </template>-->
                    <!--                      <template v-slot:item.num="{ item, index }">-->
                    <!--                        {{ item.name !== "УКУПНО" ? index + 1 : "" }}-->
                    <!--                      </template>-->
                    <!--                      <template v-slot:item.firm="{ item }">-->
                    <!--                        {{ translate(item.firm) }}-->
                    <!--                      </template>-->
                    <!--                      <template v-slot:item.shift_one="{ item }">-->
                    <!--                        {{ translate(item.shift_one) }}-->
                    <!--                      </template>-->
                    <!--                      <template v-slot:item.shift_two="{ item }">-->
                    <!--                        {{ translate(item.shift_two) }}-->
                    <!--                      </template>-->
                    <!--                      <template v-slot:item.shift_three="{ item }">-->
                    <!--                        {{ translate(item.shift_three) }}-->
                    <!--                      </template>-->
                    <!--                      <template v-slot:item.total="{ item }">-->
                    <!--                        {{ translate(item.total) }}-->
                    <!--                      </template>-->
                    <!--                    </v-data-table>-->
                  </template>
                </div>
              </v-card-text>
            </v-card>
          </v-tab-item>
          <v-tab-item>
            <v-card flat>
              <v-card-text>
                <v-data-table
                    :search="pretraga"
                    id="table9"
                    :headers="headersN"
                    :items="noContractDelivery"
                    class="table-striped elevation-1 my-4"
                    hide-default-footer
                    mobile-breakpoint="0"
                    disable-pagination>
                  <template
                      v-for="header in headersN"
                      v-slot:[`header.${header.value}`]="{ header }">
                    {{ translate(header.text) }}
                  </template>
                  <template v-slot:item.num="{ item, index }">
                    {{ item.name !== "УКУПНО" ? index + 1 : "" }}
                  </template>
                  <template v-slot:item.time="{ item }">
                    <template v-if="item.name == 'УКУПНО'">{{ $t('total') }}</template>
                    <template v-else v-for="(meal,index) in item.order_guest_transactions">
                      <template v-if="index == 0">
                        <span>{{ meal.date }}</span>
                      </template>
                    </template>
                  </template>
                  <template v-slot:item.name="{ item }">
                    <template v-for="meal in item.order_guest_transactions">
                      <span :title="'ID: ' + meal.id">{{ translate(meal.meals.name) }}</span> <br>
                    </template>
                  </template>
                  <template v-slot:item.price="{ item }">
                    <template v-for="meal in item.order_guest_transactions">
                      <template>
                        {{ meal.price }}
                      </template>
                      <br>
                    </template>
                  </template>
                  <template v-slot:item.quantity="{ item }">
                    <template v-if="item.quantity">
                      {{ item.quantity }}
                    </template>
                    <template v-else v-for="meal in item.order_guest_transactions">
                      <template>
                        {{ meal.quantity }}
                      </template>
                      <br>
                    </template>
                  </template>
                  <template v-slot:item.qty="{ item }">
                    <template v-if="item.qty">
                      {{ item.qty }}
                    </template>
                    <template v-else>
                      {{ priceDelivery(item.order_guest_transactions) }}
                    </template>
                  </template>
                </v-data-table>
              </v-card-text>
            </v-card>
          </v-tab-item>
          <!-- RESTORANI-->
          <v-tab-item>
            <v-card flat>
              <v-card-text>
                <v-data-table
                    :search="pretraga"
                    id="table9"
                    :headers="headersK"
                    :items="childRestorans"
                    :items-per-page="-1"
                    class="elevation-1"
                    hide-default-footer
                    disable-pagination
                    mobile-breakpoint="0"
                >
                  <template v-for="header in headers" v-slot:[`header.${header.value}`]="{ header }">
                    {{ translate(header.text) }}
                  </template>
                  <template v-for="values in headers" v-slot:[`item.${values.value}`]="{ item }">
                    {{ translate(item[values.value]) }}
                  </template>
                  <template v-slot:item.num="{ item, index }">
                    {{ item && item.name && item.name !== "УКУПНО" ? index + 1 : "" }}
                  </template>
                  <template v-slot:item.restoran="{ item }">
                    {{ translate(item.name) }}
                  </template>
                  <template v-slot:item.meal_num="{ item }">
                    <p style="text-align: right;width: 90px">
                      {{ translate(item.count) }}
                    </p>
                  </template>
                </v-data-table>
              </v-card-text>
            </v-card>
          </v-tab-item>
        </v-tabs-items>
      </v-card>
    </v-row>
    <!-- JELA IZ GRUPE -->
    <v-row v-if="select_view == 2">
      <v-card style="width: 100%" elevation="0">
        <v-tabs v-model="tab" background-color="primary" dark>
          <v-tab @click="printTable = item.print;name = item.tab;setItemsForExcel(index);selectAllFirms(item.tab);" v-for="(item, index) in items1" :key="item.tab + index">
            <v-icon style="margin-right: 7px">{{ item.icon }}</v-icon>
            {{ translate(item.tab) }}
          </v-tab>
        </v-tabs>

        <v-tabs-items id="izv" v-model="tab">
          <!-- JELA-->
          <v-tab-item>
            <v-card>
              <v-card-text>
                <v-col cols="12">
                  <v-autocomplete v-if="select_view == 2"
                                  v-model="searchFoodCompany"
                                  :items="foodCompanies"
                                  dense
                                  filled
                                  :item-text="getItemText"
                                  item-value="id"
                                  multiple
                                  @input="filterFoodByCompany"
                                  :label="$t('select_company')"
                                  clear
                                  chips
                                  small-chips
                  >
                    <v-btn color="primary" slot="prepend-item" @click="toogleAllFoods()" small class="mx-4">
                      {{ $t("display_all") }}
                    </v-btn>
                  </v-autocomplete>
                </v-col>
                <v-data-table
                    :search="pretraga"
                    id="table7"
                    :headers="headersJ"
                    :items="select_view == 2 ? dessertsFood : desserts2"
                    class="elevation-1"
                    hide-default-footer
                    disable-pagination
                    mobile-breakpoint="0"
                >
                  <template
                      v-for="header in headersJ"
                      v-slot:[`header.${header.value}`]="{ header }"
                  >
                    {{ translate(header.text) }}
                  </template>
                  <template
                      v-for="values in headersJ"
                      v-slot:[`item.${values.value}`]="{ item }"
                  >
                    <span v-if="item" :title="'ID: ' + item.id">{{ translate(item[values.value]) }}</span>
                  </template>
                  <template
                      v-for="values in headersJ"
                      v-slot:item.num="{ item, index }"
                  >
                    {{ item.menu !== "УКУПНО" ? index + 1 : "" }}
                  </template>
                  <template v-slot:item.menu="{item}">
                    <span v-if="item" :title="'ID: ' + item.id">{{item.menu == "УКУПНО" ? $t("totalU") : translate(item.menu) }}</span>
                  </template>
                </v-data-table>
              </v-card-text>
            </v-card>
          </v-tab-item>
          <!-- KORISNICI / PREUZETO (ako je select_view = 2)-->
          <v-tab-item>
            <v-card flat>
              <v-card-text>
                <v-autocomplete
                    v-model="search3"
                    :items="select_view == 2 ? foodCompanies : desserts4.filter((i) => i.company)"
                    dense
                    filled
                    @input="findUsers(select_view == 2 ? 'food': '')"
                    :label="$t('select_company')"
                    :item-text="getItemText"
                    item-value="id"
                    clear
                    chips
                    small-chips
                ></v-autocomplete>
              </v-card-text>
              <v-data-table v-if="search3"
                            :headers="headersU"
                            :items="users"
                            sort-by="status"
                            id="table7"
                            class="elevation-1"
                            dense
                            hide-default-footer
                            disable-pagination
                            mobile-breakpoint="0"
                            :search="pretraga"
              >
                <template v-for="header in headersU" v-slot:[`header.${header.value}`]="{ header }">
                  {{ translate(header.text) }}
                </template>
                <template v-for="values in headersU" v-slot:[`item.${values.value}`]="{ item }">
                  <span :title="values.value == 'order_count' ? translate('Поручено оброка') : ''">{{
                      translate(item[values.value])
                    }}</span>
                </template>
                <template v-slot:item.id="{ item }">
                  <span v-if="item.id == 'УКУПНО:'">{{ $t("totalU") }}</span>
                  <span v-else @click.prevent="showUsers(item)">{{ item.id }} </span>
                </template>
                <template v-for="values in headersU" v-slot:item.num="{ item, index }">
                  {{ item.id == 'УКУПНО' ? '' : index + 1 }}
                </template>
                <template v-slot:item.shift="{ item }">
                  <span v-if="date[0] == date[1]">{{item && item.shift ? Number.isInteger(item.shift) ? item.shift : item.shift.replace('-smena', '') : ''}}</span>
                </template>
                <template v-slot:item.date="{ item }">
                  <span :title="translate('Датум, време наручивања')" v-if="date[0] == date[1]">
<!--                    {{ item.id != 'УКУПНО:' && item.order_count > 0 ? moment(item.date).format('DD.MM.  HH:mm') : ''}}-->
                    {{ item.id != 'УКУПНО:' && item.order_count > 0 ? item.date : '' }}
                  </span>
                </template>
                <template v-slot:item.action="{ item }">
                  <a :title="$t('months_report')" v-if="item.id != 'УКУПНО:'" href=""
                     @click.prevent="openDialogReportUser(item, select_view == 2 ? 'food' : '')">
                    <v-icon style="font-size: 17px" color="primary">mdi-format-list-bulleted</v-icon>
                  </a>
                </template>
              </v-data-table>
            </v-card>
          </v-tab-item>
          <!-- НАРУЧЕНО-->
          <v-tab-item>
            <p v-if="date[0] != date[1] && select_view == 2 && tab == 2" class="my-3 text-center py-3 font-weight-black alert alert-danger" style="border-radius:8px;color: #721c24;background-color: #f8d7da;border-color: #f5c6cb;" role="alert">
              {{ $t('daily_report') }}
            </p>
            <v-card v-else flat id="table33">
              <v-card-text style="display: flex;flex-wrap: wrap">
                <v-autocomplete style="width: 70%" v-if="tab == 2"
                                v-model="searchFoodFirm"
                                :items="select_view == 2 ? foodCompanies : desserts4.filter((i) => i.company)"
                                dense
                                filled
                                :label="$t('select_company')"
                                item-text="company"
                                item-value="id"
                                :item-text="getItemText"
                                @change="chooseFoodFirm"
                                clear
                                chips
                                small-chips
                ></v-autocomplete>
                <v-autocomplete style="width: 25%;margin-left: 10px"
                                v-model="searchFoodShift" v-if="tab == 2"
                                :items="Object.keys(filterFoodByCompanyOrders)"
                                dense
                                filled
                                :label="$t('shifts')"
                                item-text="company"
                                item-value="id"
                                clear
                                chips
                                small-chips
                ></v-autocomplete>
              </v-card-text>
              <div style="display: flex;flex-wrap: wrap;" v-if="searchFoodShift != '' && tab == 2">
                <div :class="Object.keys(searchFoodsByShift).length > 1 ? 'third-width' : 'full-width'" v-for="(items, inx) in searchFoodsByShift">
                  <p class="text-center font-weight-bold">
                    {{ inx == 1 ? $t('shift_one') : inx == 2 ? $t('shift_two') : $t('shift_three') }}
                    ({{ Object.keys(items).length }})
                    <span v-if="obrokKojiNijeZaTajDan != ''"><v-icon style="color: red">mdi mdi-alert-octagon-outline</v-icon></span>
                  </p>
                  <div :style="Object.keys(searchFoodsByShift).length > 1 ? '' : 'display:flex;flex-wrap:wrap;'">
                    <template v-for="(item, index) in items">
                      <div :class="Object.keys(searchFoodsByShift).length > 1 ? '1' : 'third-width'" style="padding: 20px;border:1px solid #cfcfcf;border-radius: 6px;">
                        <p :title="'ID:' + item[0].user_id" class="text-center pt-2">
                          <span style="color: #919191">#{{ item[0].order_food_id }}</span>
                          <span class="font-weight-bold pt-2"> {{translate(item[0].name) + ' ' + translate(item[0].lastname) }}</span>
                        </p>
                        <div v-for="(i, ind) in item">
                          <p :style="! menu_oborci.includes(i.food_id) ? 'color:red' : ''" :title="'ID: ' + i.food_id">
                            {{ i.quantity }}<span style="color: #a4a4a4">x</span> {{ translate(i.meal_name) }}
                            <span v-if="! menu_oborci.includes(i.food_id)">
                            <a href="#" @click.prevent="removeFood(i, inx, index, ind)">
                              <v-icon style="color: red;margin-left: 5px;font-size: 19px">mdi-trash-can</v-icon>
                            </a>
                          </span>
                          </p>
                        </div>
                      </div>
                    </template>
                  </div>
                </div>
              </div>
            </v-card>
          </v-tab-item>
          <!-- PORUCIOCI ako je jela iz grupe -->
          <v-tab-item>
            <v-card flat>
              <!-- <v-btn
              @click="print('table4', true)"
              color="primary"
              small
              class="mt-2"
              ><v-icon>mdi-printer</v-icon>{{ translate("Штампај") }}</v-btn
            > -->
              <v-card-text>
                <v-autocomplete v-if="tab == 3"
                                v-model="search2"
                                :items="foodCompanies"
                                multiple
                                dense
                                filled
                                @input="findFoods"
                                :label="$t('select_company')"
                                item-value="id"
                                :item-text="getItemText"
                                clear
                                chips
                                small-chips
                >
                  <v-btn
                      color="primary"
                      slot="prepend-item"
                      @click="toggle"
                      small
                      class="mx-4"
                  >
                    {{ $t("display_all") }}
                  </v-btn>
                  <v-divider slot="prepend-item" class="mt-2"/>
                </v-autocomplete>

                <template v-if="tab == 3" v-for="(meal, i) in meals">
                  <h3>{{ translate(i) }}</h3>
                  <v-data-table
                      :search="pretraga"
                      :id="'table6_' + i"
                      :headers="headersP"
                      :items="meal.meals"
                      class="elevation-1 my-4 test_klasa"
                      hide-default-footer
                      disable-pagination
                      mobile-breakpoint="0"
                  >
                    <template v-for="header in headersP" v-slot:[`header.${header.value}`]="{ header }">
                      {{header.value == 'num' || header.value == 'name' || header.value == 'total' ? translate(header.text) : '' }} {{header.value == 'shift_one' && meal.company.shift_one == 1 ? translate(header.text) : header.value == 'shift_two' && meal.company.shift_two == 2 ? translate(header.text) : header.value == 'shift_three' && meal.company.shift_three == 3 ? translate(header.text) : '' }}
                    </template>
                    <template v-slot:item.menu="{ item }">
                      <span @click="showMeal(item.id)">{{item.name == "УКУПНО" ? $t("totalU") : translate(item.name)}}</span>
                    </template>
                    <template v-slot:item.name="{ item }">
                      <span :title="'ID: ' + item.id">{{item.name == "УКУПНО" ? $t("totalU") : translate(item.name) }}</span>
                    </template>
                    <template v-slot:item.shift_one="{ item }">
                      <template v-if="item.name == 'УКУПНО'">
                        <span class="font-weight-bold">{{ meal.company.shift_one == 1 ? item["users"]["1-smena"] : '' }}</span>
                      </template>
                      <template v-else v-for="user in item['users']['1-smena']">
                        <span @click="showUsers(user)" style="cursor: pointer">
                          <span v-if="user.name == 'Обрисан'" style="color: #c20606">
                            {{ user.id }} <span style="font-size: 10px">{{translate(user.name.toLowerCase())}}: </span>
                            {{ user.quantity >= 1 ? " " + user.quantity + ", " : "," }}
                          </span>
                          <span v-else>
                              {{ translate(user.name) + " " + translate(user.lastname) }}:
                              <span v-if="user.quantity >= 1">   {{user.quantity >= 1 ? " " + user.quantity + ", " : "," }} </span>
                              <span v-else>0</span>
                          </span>
                        </span>
                      </template>
                      <span v-if="item&&item['users']&&item['users']['1-smena']&&item.name != 'УКУПНО'" style="display: block;font-size: 13px;margin-top: 5px">
                        {{ $t('total') }}:
                        <span style="font-weight: 700">
                          <span>{{totalUserQtu(item['users']['1-smena']) != 0 ? totalUserQtu(item['users']['1-smena']) : 0 }}</span>
                        </span>
                      </span>
                    </template>
                    <template v-slot:item.shift_two="{ item }">
                      <template v-if="item.name == 'УКУПНО'">
                        <span class="font-weight-bold">{{meal.company.shift_two == 2 ? item["users"]["2-smena"] : '' }}</span>
                      </template>
                      <template v-else v-for="(user, i) in item['users']['2-smena']">
                        <span @click="showUsers(user)">
                          <span v-if="user.name == 'Обрисан'" style="color: #c20606">
                            {{ user.id }}
                            <span style="font-size: 10px">{{ translate(user.name.toLowerCase()) }}: </span>
                             {{ user.quantity >= 1 ? "" + user.quantity + ", " : "," }}
                          </span>
                          <span v-else>{{ translate(user.name) + " " + translate(user.lastname) }}:
                            {{ user.quantity >= 1 ? "" + user.quantity + ", " : "," }}
                          </span>
                        </span>
                      </template>
                      <span v-if="item&&item['users']&&item['users']['2-smena']&&item.name != 'УКУПНО'" style="display: block;font-size: 13px;margin-top: 5px">
                         {{ $t('total') }}:
                        <span style="font-weight: 700">
                            <span v-for="tot in item['users']['2-smena']" v-if="tot.quantity == 0">
                            <span>0</span>
                          </span>
                          <span>{{totalUserQtu(item['users']['2-smena']) != 0 ? totalUserQtu(item['users']['2-smena']) : '' }}</span>
                        </span>
                      </span>
                    </template>
                    <template v-slot:item.shift_three="{ item }">
                      <template v-if="item.name == 'УКУПНО'">
                        <span class="font-weight-bold">{{meal.company.shift_three == 3 ? item["users"]["3-smena"] : '' }}</span>
                      </template>
                      <template v-else v-for="(user, i) in item['users']['3-smena']">
                        <span @click="showUsers(user)">
                         <span v-if="user.name == 'Обрисан'" style="color: #c20606">
                            {{ user.id }}
                            <span style="font-size: 10px">{{ translate(user.name.toLowerCase()) }}: </span>
                            {{ user.quantity >= 1 ? " " + user.quantity + ", " : "," }}
                          </span>
                          <span v-else>{{ translate(user.name) + " " + translate(user.lastname) }}:
                            {{ user.quantity >= 1 ? " " + user.quantity + ", " : "," }}
                          </span>
                        </span>
                      </template>
                      <span v-if="item&&item['users']&&item['users']['3-smena']&&item.name != 'УКУПНО'" style="display: block;font-size: 13px;margin-top: 5px">
                         {{ $t('total') }}:
                        <span style="font-weight: 700">
                            <span v-for="tot in item['users']['3-smena']" v-if="tot.quantity == 0">
                            <span>0</span>
                          </span>
                          <span>{{totalUserQtu(item['users']['3-smena']) != 0 ? totalUserQtu(item['users']['3-smena']) : '' }}</span>
                        </span>
                      </span>
                    </template>
                    <template v-slot:item.total="{ item }">
                      <template>
                        <span class="font-weight-bold">{{ item.total }}</span>
                      </template>
                    </template>
                    <template v-for="values in headers" v-slot:item.num="{ item, index }">
                      {{ item.name !== "УКУПНО" ? index + 1 : "" }}
                    </template>
                  </v-data-table>
                </template>
              </v-card-text>
            </v-card>
          </v-tab-item>
          <!-- Narucioci II ako je jela iz grupe -->
          <v-tab-item>
            <p v-if="date[0] != date[1] && select_view == 2 && tab == 4" class="my-3 text-center py-3 font-weight-black alert alert-danger" style="border-radius:8px;color: #721c24;background-color: #f8d7da;border-color: #f5c6cb;" role="alert">
              {{ $t('daily_report') }}
            </p>
            <v-card v-else flat id="table555">
              <v-card-text style="display: flex;flex-wrap: wrap">
                <v-autocomplete style="width: 65%"
                                v-model="search8"
                                v-if="tab == 4"
                                :items="foodCompanies"
                                dense
                                filled
                                :label="$t('select_company')"
                                :item-text="getItemText"
                                item-value="id"
                                @change="findUsersByCompany()"
                                clear
                                chips
                                small-chips
                ></v-autocomplete>
                <v-autocomplete style="width: 25%;margin-left: 10px" v-if="tab == 4"
                                v-model="searchByShiftNewReport"
                                :items="smeneZaNoviIzvestaj"
                                dense
                                filled
                                :label="$t('shifts')"
                                item-text="shift"
                                item-value="id"
                                clear
                                chips
                                small-chips
                ></v-autocomplete>
              </v-card-text>
              <v-simple-table class="py-2" v-if="narudzbe2 && search8 && tab == 4">
                <template v-slot:default>
                  <thead>
                  <tr>
                    <th style="border-right: 1px solid #cfcfcf;">{{ $t('name') + ' ' + $t('last_name') }}</th>
                    <th style="border-right: 1px solid #cfcfcf;">{{ $t('date') }} - {{ $t('time') }}</th>
                    <!--                      <th v-for="category in categories[foodCompanies.find(id => id.id == search8).company]" :key="category" style="border-right: 1px solid #cfcfcf">-->
                    <th v-for="category in kategorija" :key="category" style="border-right: 1px solid #cfcfcf">
                      {{ translate(category) }}
                    </th>
                    <th>{{ $t('note') }}</th>
                  </tr>
                  </thead>
                  <tbody>
                  <tr v-for="(dayData, day) in filterByShiftsNarudzbe2" :key="day" style="border-bottom: 1px solid #cfcfcf">
                    <td :title="'User ID: ' + day" style="border-right: 1px solid #cfcfcf;width: 15%">
                      {{narudzbe2.find(id => id.user_id == day) ? translate(narudzbe2.find(id => id.user_id == day).name) : '' }}
                      {{narudzbe2.find(id => id.user_id == day) ? translate(narudzbe2.find(id => id.user_id == day).lastname) : '' }}
                    </td>
                    <td :title="translate('Време поруџбе')" style="border-right: 1px solid #cfcfcf;width: 11%">{{narudzbe2.find(id => id.user_id == day) ? formatDateFood(narudzbe2.find(id => id.user_id == day).created_at) : '' }}
                    </td>
                    <template v-for="(shiftData, shift) in dayData">
                      <td v-for="category in dayData[shift]" style="border-right: 1px solid #cfcfcf;width: 11%">
                        <span :title="'ID: ' + meal.food_id" v-for="meal in category">
                          <span class="font-weight-bold">{{ meal.quantity }}x </span>
                          {{ translate(meal.meal_name) }}<br>
                        </span>
                      </td>
                    </template>
                    <td> {{narudzbe2.find(id => id.user_id == day) ? translate(narudzbe2.find(id => id.user_id == day).note) : '' }}
                    </td>
                  </tr>
                  </tbody>
                </template>
              </v-simple-table>
            </v-card>
          </v-tab-item>
          <!-- RESTORANI-->
          <v-tab-item>
            <v-card flat>
              <v-card-text>
                <v-data-table
                    :search="pretraga"
                    id="table9"
                    :headers="headersK"
                    :items="childRestorans"
                    :items-per-page="-1"
                    class="elevation-1"
                    hide-default-footer
                    disable-pagination
                    mobile-breakpoint="0"
                >
                  <template v-for="header in headers" v-slot:[`header.${header.value}`]="{ header }">
                    {{ translate(header.text) }}
                  </template>
                  <template v-for="values in headers" v-slot:[`item.${values.value}`]="{ item }">
                    {{ translate(item[values.value]) }}
                  </template>
                  <template v-slot:item.num="{ item, index }">
                    {{ item && item.name && item.name !== "УКУПНО" ? index + 1 : "" }}
                  </template>
                  <template v-slot:item.restoran="{ item }">
                    {{ translate(item.name) }}
                  </template>
                  <template v-slot:item.meal_num="{ item }">
                    <p style="text-align: right;width: 90px">
                      {{ translate(item.count) }}
                    </p>
                  </template>
                </v-data-table>
              </v-card-text>
            </v-card>
          </v-tab-item>
        </v-tabs-items>
      </v-card>
    </v-row>
    <!-- JELA U OBROCIMA-->
    <v-row v-if="select_view == 1">
      <v-card style="width: 100%" elevation="0">
        <v-tabs v-model="tab" background-color="primary" dark>
          <v-tab @click="printTable = item.print;name = item.tab;setItemsForExcel(index);selectAllFirms(item.tab);" v-for="(item, index) in items" :key="item.tab + index">
            <v-icon style="margin-right: 7px">{{ item.icon }}</v-icon>
            {{ translate(item.tab) }}
          </v-tab>
        </v-tabs>

        <v-tabs-items id="izv" v-model="tab">
          <v-tab-item v-if="select_view != 2">
            <v-card flat>
              <!-- <v-btn
              @click="print('table3')"
              color="primary"
              small
              class="mt-2"
              ><v-icon>mdi-printer</v-icon>{{ translate("Штампај") }}</v-btn
            > -->
              <v-card-text>
                <v-data-table
                    :search="pretraga"
                    id="table3"
                    :headers="headers"
                    :items="desserts3"
                    :items-per-page="-1"
                    class="elevation-1"
                    hide-default-footer
                    disable-pagination
                    mobile-breakpoint="0"
                >
                  <template
                      v-for="header in headers"
                      v-slot:[`header.${header.value}`]="{ header }"
                  >
                    {{ translate(header.text) }}
                  </template>
                  <template
                      v-for="values in headers"
                      v-slot:[`item.${values.value}`]="{ item }"
                  >
                    <span :title="'ID: ' + item.id">{{ translate(item[values.value]) }}</span>
                  </template>
                  <template v-slot:item.num="{ item, index }">
                    {{ item.menu !== "УКУПНО" ? index + 1 : "" }}
                  </template>

                  <template v-slot:item.shift_one="{ item }">
                    {{ translate(item.shift_one.toFixed(5)) }}
                  </template>
                  <template v-slot:item.shift_two="{ item }">
                    {{ translate(item.shift_two.toFixed(5)) }}
                  </template>
                  <template v-slot:item.shift_three="{ item }">
                    {{ translate(item.shift_three.toFixed(5)) }}
                  </template>
                  <template v-slot:item.total="{ item }">
                    {{ translate(item.total.toFixed(5)) }}
                  </template>
                </v-data-table>
              </v-card-text>
            </v-card>
          </v-tab-item>
          <!-- JELA-->
          <v-tab-item>
            <v-card>
              <v-card-text>
                <v-col cols="12">
                  <v-autocomplete v-if="select_view == 2"
                    v-model="searchFoodCompany"
                    :items="foodCompanies"
                    dense
                    filled
                    :item-text="getItemText"
                    item-value="id"
                    multiple
                    @input="filterFoodByCompany"
                    :label="$t('select_company')"
                    clear
                    chips
                    small-chips
                  >
                    <v-btn color="primary" slot="prepend-item" @click="toogleAllFoods()" small class="mx-4">
                      {{ $t("display_all") }}
                    </v-btn>
                  </v-autocomplete>
                </v-col>
                <v-data-table
                    :search="pretraga"
                    id="table7"
                    :headers="headersJ"
                    :items="select_view == 2 ? dessertsFood : desserts2"
                    class="elevation-1"
                    hide-default-footer
                    disable-pagination
                    mobile-breakpoint="0"
                >
                  <template
                      v-for="header in headersJ"
                      v-slot:[`header.${header.value}`]="{ header }"
                  >
                    {{ translate(header.text) }}
                  </template>
                  <template
                      v-for="values in headersJ"
                      v-slot:[`item.${values.value}`]="{ item }"
                  >
                    <span v-if="item" :title="'ID: ' + item.id">{{ translate(item[values.value]) }}</span>
                  </template>
                  <template
                      v-for="values in headersJ"
                      v-slot:item.num="{ item, index }"
                  >
                    {{ item.menu !== "УКУПНО" ? index + 1 : "" }}
                  </template>
                  <template v-slot:item.menu="{item}">
                    <span v-if="item" :title="'ID: ' + item.id">{{item.menu == "УКУПНО" ? $t("totalU") : translate(item.menu) }}</span>
                  </template>
                </v-data-table>
              </v-card-text>
            </v-card>
          </v-tab-item>
          <!-- OBROCI-->
          <v-tab-item v-if="select_view != 2">
            <v-card flat>
              <v-card-text>
                <v-autocomplete
                    v-model="search4"
                    :items="desserts4.filter((i) => translate(i.company))"
                    dense
                    filled
                    multiple
                    @input="obrok"
                    :label="$t('select_company')"
                    :item-text="getItemText"
                    item-value="id"
                    clear
                    chips
                    small-chips
                >
                  <v-btn
                      color="primary"
                      slot="prepend-item"
                      @click="toogleFirmsObroci()"
                      small
                      class="mx-4"
                  >
                    {{ $t("display_all") }}
                  </v-btn>
                </v-autocomplete>
                <!--                :items="filterMealsTotal"-->
                <div id="table4">
                  <v-data-table v-if="obroci.length && search4.length != 0"
                    :search="pretraga"
                    id="table1"
                    :headers="headersM"
                    :items="filterObrok"
                    class="elevation-1"
                    hide-default-footer
                    disable-pagination
                    mobile-breakpoint="0"
                  >
                    <template
                        v-for="header in headersM"
                        v-slot:[`header.${header.value}`]="{ header }"
                    >
                      {{ $t(header.text) }}
                    </template>
                    <template v-slot:item.menu="{ item }">
                      <span v-if="item" :title="'ID: ' + item.id" @click="showMeal(item.id)"> {{item.menu == "УКУПНО" ? $t("totalU") : translate(item.menu) }}</span>
                    </template>
                    <template v-slot:item.shift_one="{ item }">
                      <span v-if="item">{{ translate(item.shift_one) }}</span>
                    </template>
                    <template v-slot:item.shift_two="{ item }">
                      <span v-if="item">{{ translate(item.shift_two) }}</span>
                    </template>
                    <template v-slot:item.shift_three="{ item }">
                      <span v-if="item">{{ translate(item.shift_three) }}</span>
                    </template>
                    <template v-slot:item.total="{ item }">
                      <span v-if="item">{{ translate(item.total) }}</span>
                    </template>
                    <template v-slot:item.num="{ item, index }">
                      {{ item && item.menu && item.menu !== "УКУПНО" ? index + 1 : "" }}
                    </template>
                  </v-data-table>
                </div>
<!--                <v-data-table v-if="search4.length == 0"-->
<!--                              :search="pretraga"-->
<!--                              id="table1"-->
<!--                              :headers="headersM"-->
<!--                              :items="desserts"-->
<!--                              class="elevation-1"-->
<!--                              hide-default-footer-->
<!--                              disable-pagination-->
<!--                              mobile-breakpoint="0"-->
<!--                >-->
<!--                  <template-->
<!--                      v-for="header in headersM"-->
<!--                      v-slot:[`header.${header.value}`]="{ header }"-->
<!--                  >-->
<!--                    {{ $t(header.text) }}-->
<!--                  </template>-->
<!--                  <template v-slot:item.menu="{ item }">-->
<!--                    <span v-if="item" :title="'ID: ' + item.id " @click="showMeal(item.id)">{{item.menu == "УКУПНО" ? $t("totalU") : translate(item.menu) }}</span>-->
<!--                  </template>-->
<!--                  <template v-slot:item.shift_one="{ item }">-->
<!--                    <span v-if="item">{{ translate(item.shift_one) }}</span>-->
<!--                  </template>-->
<!--                  <template v-slot:item.shift_two="{ item }">-->
<!--                    <span v-if="item">{{ translate(item.shift_two) }}</span>-->
<!--                  </template>-->
<!--                  <template v-slot:item.shift_three="{ item }">-->
<!--                    <span v-if="item">{{ translate(item.shift_three) }}</span>-->
<!--                  </template>-->
<!--                  <template v-slot:item.total="{ item }">-->
<!--                    <span v-if="item">{{ translate(item.total) }}</span>-->
<!--                  </template>-->
<!--                  <template v-slot:item.num="{ item, index }">-->
<!--                    {{ item.menu !== "УКУПНО" ? index + 1 : "" }}-->
<!--                  </template>-->
<!--                </v-data-table>-->
              </v-card-text>
            </v-card>
          </v-tab-item>
          <!-- НАРУЧЕНО-->
          <v-tab-item :style="select_view == 2 ? 'display:none' : 'display:block'" v-if="select_view != 2">
            <v-card flat v-if="tab == 3">
              <v-card-text style="display: flex;flex-wrap: wrap">
                <v-autocomplete style="width: 70%"
                                v-model="searchN"
                                :items="desserts4.filter((i) => i.company)"
                                dense
                                filled
                                :label="$t('select_company')"
                                item-value="id"
                                :item-text="getItemText"
                                @change="findMealsNarudzbe()"
                                clear
                                chips
                                small-chips
                ></v-autocomplete>
                <v-autocomplete style="width: 25%;margin-left: 10px"
                  v-model="searchByShift"
                  :items="Object.keys(narudzbe)"
                  dense
                  filled
                  :label="$t('shifts')"
                  item-text="company"
                  item-value="id"
                  clear
                  chips
                  small-chips
                ></v-autocomplete>
              </v-card-text>
              <div id="table34" style="display: flex;flex-wrap: wrap;">
                <div :style="Object.keys(searcByShift).length > 1 ? 'width: 32%;' : 'width: 100%;'"
                     v-for="(items, inx) in searcByShift">
                  <p class="text-center font-weight-bold">
                    {{ inx == 1 ? $t('shift_one') : inx == 2 ? $t('shift_two') : $t('shift_three') }} (<v-icon>mdi mdi-account</v-icon>{{ items ? Object.keys(items).length : '' }})
                  </p>
                  <div :style="Object.keys(searcByShift).length > 1 ? '' : 'display:flex;flex-wrap:wrap;'">
                    <template v-for="(item, index) in items">
                      <div :class="Object.keys(searcByShift).length > 1 ? '1' : 'third-width'" style="padding: 20px;border:1px solid #cfcfcf;border-radius: 5px;">
                        <p :title="'ID:' + item[Object.keys(item)[0]][0].user_id" class="text-center pt-2">
                          <span style="color: #919191">#{{ item[Object.keys(item)[0]][0].order_id }}</span>
                          <span class="font-weight-bold pt-2"> {{translate(item[Object.keys(item)[0]][0].name + ' ' + item[Object.keys(item)[0]][0].lastname) }}</span>
                        </p>
                        <template v-for="(i, inx) in item">
                          <p :title="'ID:' + item[inx][0].meal_id">{{ item[inx][0].quantity }}<span style="color: #a4a4a4">x</span> {{ inx }}</p>
                        </template>
                      </div>
                    </template>
                  </div>
                </div>
              </div>
            </v-card>
          </v-tab-item>
          <!-- PAKOVANJE-->
          <v-tab-item v-if="select_view != 2">
            <v-card flat>
              <!-- <v-btn
              @click="print('table4', true)"
              color="primary"
              small
              class="mt-2"
              ><v-icon>mdi-printer</v-icon>{{ translate("Штампај") }}</v-btn
            > -->
              <v-card-text>
                <v-autocomplete
                    v-model="search"
                    :items="select_view == 2 ? foodCompanies : desserts4.filter((i) => i.company)"
                    dense
                    filled
                    multiple
                    :label="$t('select_company')"
                    :item-text="getItemText"
                    clear
                    chips
                    small-chips
                >
                  <v-btn
                      color="primary"
                      slot="prepend-item"
                      @click="toogleFirms()"
                      small
                      class="mx-4">
                    {{ $t("display_all") }}
                  </v-btn>
                </v-autocomplete>
                <div >
                  <template v-for="(comapny, companyKey) in search && search.length > 0 ? (companyFood.length ? companyFood.filter((i) => search.includes(i.company)) : desserts4.filter((i) => search.includes(translate(i.company)))) : (companyFood.length ? companyFood : desserts4)" dense>
                    <h2 class="text-center position-relative mt-5 mb-2" style="display: flex;flex-wrap: wrap">
                      <span class="firm-margin headline-width">{{ translate(comapny.company) }}</span>
                      <v-btn style="font-size: 11px" @click.prevent="addNewMeal(comapny, $store.state.restaurant.id)"
                             v-if="canSeeAddMeal && date[0] == date[1]"
                             :style="'border-radius: 8px;border: 1px solid ' + $store.state.restaurant.settings.topbar.background + ';background: none;color: ' + $store.state.restaurant.settings.topbar.background + ';box-shadow: none;text-transform: capitalize'"
                             small class="mb-1 no-print subheadline-width">
                        <v-icon :style="'color:' + $store.state.restaurant.settings.topbar.background ? $store.state.restaurant.settings.topbar.background : 'rgb(251, 109, 59)'">
                          mdi-plus
                        </v-icon>
                        {{ $t("add_meals2") }}
                      </v-btn>
                    </h2>
                    <v-data-table id="table4"
                        :headers="headersM1"
                        :items="comapny.foods"
                        class="elevation-1"
                        hide-default-footer
                        :search="pretraga"
                        disable-pagination
                        mobile-breakpoint="0"
                    >
                      <template
                          v-for="header in headersM1"
                          v-slot:[`header.${header.value}`]="{ header }"
                      >
                        {{ $t(header.text) }}
                      </template>
                      <template v-for="values in headersM1" v-slot:[`item.${values.value}`]="{ item }">
                        {{ translate(item[values.value]) }}
                      </template>
<!--                      <template v-slot:item.name="{ item }">-->
<!--                        -->
<!--                      </template>-->
                      <template v-slot:item.menu="{ item }">
                        <span @click="showMeal(item.id)">{{item.name == "УКУПНО" ? $t("totalU") : translate(item.name) }}</span>
                      </template>
                      <template v-slot:item.num="{ item, index }">
                        {{ item && item.name && item.name !== "УКУПНО" ? index + 1 : "" }}
                      </template>
                    </v-data-table>
                  </template>
                </div>
              </v-card-text>
            </v-card>
          </v-tab-item>
          <!-- PORUCIOCI-->
          <v-tab-item :style="select_view == 2 ? 'display:none' : 'display:block'" v-if="select_view != 2">
            <v-card flat v-if="tab == 5">
              <!-- <v-btn
              @click="print('table4', true)"
              color="primary"
              small
              class="mt-2"
              ><v-icon>mdi-printer</v-icon>{{ translate("Штампај") }}</v-btn
            > -->
              <v-card-text>
                <v-autocomplete
                    v-model="search2"
                    :items="select_view == 2 ? foodCompanies : desserts4.filter((i) => i.company)"
                    multiple
                    dense
                    filled
                    @input="findMeals"
                    :label="$t('select_company')"
                    item-value="id"
                    :item-text="getItemText"
                    clear
                    chips
                    small-chips
                >
                  <v-btn
                      color="primary"
                      slot="prepend-item"
                      @click="toggle"
                      small
                      class="mx-4"
                  >
                    {{ $t("display_all") }}
                  </v-btn>
                  <v-divider slot="prepend-item" class="mt-2"/>
                </v-autocomplete>

                <template v-for="(meal, i) in meals">
                  <h3>{{ i }}</h3>
                  <v-data-table
                      :search="pretraga"
                      :id="'table6_' + i"
                      :headers="headersP"
                      :items="meal.meals"
                      class="elevation-1 my-4 test_klasa"
                      hide-default-footer
                      disable-pagination
                      mobile-breakpoint="0"
                  >
                    <template v-for="header in headersP" v-slot:[`header.${header.value}`]="{ header }">
                      {{header.value == 'num' || header.value == 'name' || header.value == 'total' ? translate(header.text) : '' }} {{header.value == 'shift_one' && meal.company.shift_one == 1 ? translate(header.text) : header.value == 'shift_two' && meal.company.shift_two == 2 ? translate(header.text) : header.value == 'shift_three' && meal.company.shift_three == 3 ? translate(header.text) : '' }}
                    </template>
                    <template v-slot:item.menu="{ item }">
                      <span @click="showMeal(item.id)">{{item.name == "УКУПНО" ? $t("totalU") : translate(item.name) }}</span>
                    </template>
                    <template v-slot:item.name="{ item }">
                      <span :title="'ID: ' + item.id">{{item.name == "УКУПНО" ? $t("totalU") : translate(item.name) }}</span>
                    </template>
                    <template v-slot:item.shift_one="{ item }">
                      <template v-if="item.name == 'УКУПНО'">
                        <span class="font-weight-bold">{{ meal.company.shift_one == 1 ? item["users"]["1-smena"] : '' }}</span>
                      </template>
                      <template v-else v-for="user in item['users']['1-smena']">
                        <span @click="showUsers(user)" style="cursor: pointer">
                          <span v-if="user.name == 'Обрисан'" style="color: #c20606">
                            {{ user.id }} <span style="font-size: 10px">{{ translate(user.name.toLowerCase()) }}:</span>
                            {{ user.quantity >= 1 ? " " + user.quantity + ", " : "," }}
                          </span>
                          <span v-else>
                              {{ translate(user.name) + " " + translate(user.lastname) }}:
                              <span v-if="user.quantity >= 1">  {{user.quantity >= 1 ? " " + user.quantity + ", " : "," }} </span>
                              <span v-else>0</span>
                          </span>
                        </span>
                      </template>
                      <span v-if="item&&item['users']&&item['users']['1-smena']&&item.name != 'УКУПНО'" style="display: block;font-size: 13px;margin-top: 5px">
                        {{ $t('total') }}:
                        <span style="font-weight: 700">
                          <span>{{totalUserQtu(item['users']['1-smena']) != 0 ? totalUserQtu(item['users']['1-smena']) : 0 }}</span>
                        </span>
                      </span>
                    </template>
                    <template v-slot:item.shift_two="{ item }">
                      <template v-if="item.name == 'УКУПНО'">
                        <span class="font-weight-bold">{{meal.company.shift_two == 2 ? item["users"]["2-smena"] : '' }}</span>
                      </template>
                      <template v-else v-for="(user, i) in item['users']['2-smena']">
                        <span @click="showUsers(user)">
                          <span v-if="user.name == 'Обрисан'" style="color: #c20606">
                            {{ user.id }}
                            <span style="font-size: 10px">{{ translate(user.name.toLowerCase()) }}:</span>
                             {{ user.quantity >= 1 ? "" + user.quantity + ", " : "," }}
                          </span>
                          <span v-else>{{translate(user.name) + " " + translate(user.lastname) }}: {{ user.quantity >= 1 ? "" + user.quantity + ", " : "," }}</span>
                        </span>
                      </template>
                      <span v-if="item&&item['users']&&item['users']['2-smena']&&item.name != 'УКУПНО'" style="display: block;font-size: 13px;margin-top: 5px">
                         {{ $t('total') }}:
                        <span style="font-weight: 700">
                            <span v-for="tot in item['users']['2-smena']" v-if="tot.quantity == 0">
                            <span>0</span>
                          </span>
                          <span>{{totalUserQtu(item['users']['2-smena']) != 0 ? totalUserQtu(item['users']['2-smena']) : '' }}</span>
                        </span>
                      </span>
                    </template>
                    <template v-slot:item.shift_three="{ item }">
                      <template v-if="item.name == 'УКУПНО'">
                        <span class="font-weight-bold">{{meal.company.shift_three == 3 ? item["users"]["3-smena"] : '' }}</span>
                      </template>
                      <template v-else v-for="(user, i) in item['users']['3-smena']">
                        <span @click="showUsers(user)">
                         <span v-if="user.name == 'Обрисан'" style="color: #c20606">
                            {{ user.id }}
                            <span style="font-size: 10px">{{ translate(user.name.toLowerCase()) }}:</span>
                            {{ user.quantity >= 1 ? " " + user.quantity + ", " : "," }}
                          </span>
                          <span v-else>{{translate(user.name) + " " + translate(user.lastname) }}: {{ user.quantity >= 1 ? " " + user.quantity + ", " : "," }}</span>
                        </span>
                      </template>
                      <span v-if="item&&item['users']&&item['users']['3-smena']&&item.name != 'УКУПНО'" style="display: block;font-size: 13px;margin-top: 5px">
                         {{ $t('total') }}:
                        <span style="font-weight: 700">
                          <span v-for="tot in item['users']['3-smena']" v-if="tot.quantity == 0">
                            <span>0</span>
                          </span>
                          <span>{{totalUserQtu(item['users']['3-smena']) != 0 ? totalUserQtu(item['users']['3-smena']) : '' }}</span>
                        </span>
                      </span>
                    </template>
                    <template v-slot:item.total="{ item }">
                      <template>
                        <span class="font-weight-bold">{{ item.total }}</span>
                      </template>
                    </template>
                    <template v-for="values in headers" v-slot:item.num="{ item, index }">
                      {{ item.name !== "УКУПНО" ? index + 1 : "" }}
                    </template>
                  </v-data-table>
                </template>
              </v-card-text>
            </v-card>
          </v-tab-item>
          <!-- PREUZETO-->
          <v-tab-item v-if="select_view != 2">
            <v-card flat>
              <!-- <v-btn
              @click="print('table5')"
              color="primary"
              small
              class="mt-2"
              ><v-icon>mdi-printer</v-icon>{{ translate("Штампај") }}</v-btn
            > -->
              <v-card-text>
                <v-data-table
                    :search="pretraga"
                    id="table5"
                    :headers="headers1"
                    :items="total"
                    :items-per-page="-1"
                    class="elevation-1"
                    hide-default-footer
                    disable-pagination
                    mobile-breakpoint="0"
                >
                  <template v-for="header in headers1" v-slot:[`header.${header.value}`]="{ header }">
                    {{ translate(header.text) }}
                  </template>
                  <template v-for="values in headers1" v-slot:[`item.${values.value}`]="{ item }">
                    <span :title="values.value == 'total' ? $t('ordered_1') + '/' + $t('taken') : ''">{{ translate(item[values.value]) }}</span>
                    <a v-if="values.value == 'month'" @click.prevent="openDialogReportFirm(item)">
                      <v-icon style="font-size: 17px" color="primary">mdi-format-list-bulleted</v-icon>
                    </a>
                  </template>
                  <template v-for="values in headers1" v-slot:item.num="{ item, index }">
                    {{ item.menu !== "УКУПНО" ? index + 1 : "" }}
                  </template>
                </v-data-table>
              </v-card-text>
            </v-card>
          </v-tab-item>
          <!-- RADNICI-->
          <v-tab-item>
            <v-card flat>
              <v-card-text>
                <v-autocomplete
                  v-model="search3"
                  :items="select_view == 2 ? foodCompanies : desserts4.filter((i) => i.company)"
                  dense
                  filled
                  @input="findUsers(select_view == 2 ? 'food': '')"
                  :label="$t('select_company')"
                  :item-text="getItemText"
                  item-value="id"
                  clear
                  chips
                  small-chips
                ></v-autocomplete>
              </v-card-text>
              <v-data-table v-if="search3"
                :headers="headersU"
                :items="users"
                sort-by="status"
                id="table7"
                class="elevation-1"
                dense
                hide-default-footer
                disable-pagination
                mobile-breakpoint="0"
                :search="pretraga"
              >
                <template v-for="header in headersU" v-slot:[`header.${header.value}`]="{ header }">
                  {{ translate(header.text) }}
                </template>
                <template v-for="values in headersU" v-slot:[`item.${values.value}`]="{ item }">
                  <span :title="values.value == 'order_count' ? translate('Поручено оброка') : ''">{{
                      translate(item[values.value])
                    }}</span>
                </template>
                <template v-slot:item.id="{ item }">
                  <span v-if="item.id == 'УКУПНО:'">{{ $t("totalU") }}</span>
                  <span v-else @click.prevent="showUsers(item)">{{ item.id }} </span>
                </template>
                <template v-for="values in headersU" v-slot:item.num="{ item, index }">
                  {{ item.id == 'УКУПНО' ? '' : index + 1 }}
                </template>
                <template v-slot:item.shift="{ item }">
                  <span v-if="date[0] == date[1]">{{item && item.shift ? Number.isInteger(item.shift) ? item.shift : item.shift.replace('-smena', '') : ''}}</span>
                </template>
                <template v-slot:item.date="{ item }">
                  <span :title="translate('Датум, време наручивања')" v-if="date[0] == date[1]">
<!--                    {{ item.id != 'УКУПНО:' && item.order_count > 0 ? moment(item.date).format('DD.MM.  HH:mm') : ''}}-->
                    {{ item.id != 'УКУПНО:' && item.order_count > 0 ? item.date : '' }}
                  </span>
                </template>
                <template v-slot:item.action="{ item }">
                  <a :title="$t('months_report')" v-if="item.id != 'УКУПНО:'" href=""
                     @click.prevent="openDialogReportUser(item, select_view == 2 ? 'food' : '')">
                    <v-icon style="font-size: 17px" color="primary">mdi-format-list-bulleted</v-icon>
                  </a>
                </template>
              </v-data-table>
            </v-card>
          </v-tab-item>
          <!-- НАРУЧЕНО-->
          <v-tab-item :style="select_view == 1 ? 'display:none' : 'display:block'" v-if="select_view == 2">
            <p v-if="date[0] != date[1] && select_view == 2 && tab == 2" class="my-3 text-center py-3 font-weight-black alert alert-danger" style="border-radius:8px;color: #721c24;background-color: #f8d7da;border-color: #f5c6cb;" role="alert">
              {{ $t('daily_report') }}
            </p>
            <v-card v-else flat id="table33">
              <v-card-text style="display: flex;flex-wrap: wrap">
                <v-autocomplete style="width: 70%" v-if="tab == 2"
                  v-model="searchFoodFirm"
                  :items="select_view == 2 ? foodCompanies : desserts4.filter((i) => i.company)"
                  dense
                  filled
                  :label="$t('select_company')"
                  item-text="company"
                  item-value="id"
                  :item-text="getItemText"
                  @change="chooseFoodFirm"
                  clear
                  chips
                  small-chips
                ></v-autocomplete>
                <v-autocomplete style="width: 25%;margin-left: 10px"
                  v-model="searchFoodShift" v-if="tab == 2"
                  :items="Object.keys(filterFoodByCompanyOrders)"
                  dense
                  filled
                  :label="$t('shifts')"
                  item-text="company"
                  item-value="id"
                  clear
                  chips
                  small-chips
                ></v-autocomplete>
              </v-card-text>
              <div style="display: flex;flex-wrap: wrap;" v-if="searchFoodShift != '' && tab == 2">
                <div :class="Object.keys(searchFoodsByShift).length > 1 ? 'third-width' : 'full-width'" v-for="(items, inx) in searchFoodsByShift">
                  <p class="text-center font-weight-bold">
                    {{ inx == 1 ? $t('shift_one') : inx == 2 ? $t('shift_two') : $t('shift_three') }}
                    ({{ Object.keys(items).length }})
                    <span v-if="obrokKojiNijeZaTajDan != ''"><v-icon style="color: red">mdi mdi-alert-octagon-outline</v-icon></span>
                  </p>
                  <div :style="Object.keys(searchFoodsByShift).length > 1 ? '' : 'display:flex;flex-wrap:wrap;'">
                    <template v-for="(item, index) in items">
                      <div :class="Object.keys(searchFoodsByShift).length > 1 ? '1' : 'third-width'" style="padding: 20px;border:1px solid #cfcfcf;border-radius: 6px;">
                        <p :title="'ID:' + item[0].user_id" class="text-center pt-2">
                          <span style="color: #919191">#{{ item[0].order_food_id }}</span>
                          <span class="font-weight-bold pt-2"> {{translate(item[0].name) + ' ' + translate(item[0].lastname) }}</span>
                        </p>
                        <div v-for="(i, ind) in item">
                          <p :style="! menu_oborci.includes(i.food_id) ? 'color:red' : ''" :title="'ID: ' + i.food_id">
                            {{ i.quantity }}<span style="color: #a4a4a4">x</span> {{ translate(i.meal_name) }}
                            <span v-if="! menu_oborci.includes(i.food_id)">
                            <a href="#" @click.prevent="removeFood(i, inx, index, ind)">
                              <v-icon style="color: red;margin-left: 5px;font-size: 19px">mdi-trash-can</v-icon>
                            </a>
                          </span>
                          </p>
                        </div>
                      </div>
                    </template>
                  </div>
                </div>
              </div>
            </v-card>
          </v-tab-item>
          <!-- PORUCIOCI ako je jela iz grupe -->
          <v-tab-item :style="select_view == 1 ? 'display:none' : 'display:block'" v-if="select_view == 2">
            <v-card flat>
              <!-- <v-btn
              @click="print('table4', true)"
              color="primary"
              small
              class="mt-2"
              ><v-icon>mdi-printer</v-icon>{{ translate("Штампај") }}</v-btn
            > -->
              <v-card-text>
                <v-autocomplete v-if="tab == 3"
                  v-model="search2"
                  :items="foodCompanies"
                  multiple
                  dense
                  filled
                  @input="findFoods"
                  :label="$t('select_company')"
                  item-value="id"
                  :item-text="getItemText"
                  clear
                  chips
                  small-chips
                >
                  <v-btn
                      color="primary"
                      slot="prepend-item"
                      @click="toggle"
                      small
                      class="mx-4"
                  >
                    {{ $t("display_all") }}
                  </v-btn>
                  <v-divider slot="prepend-item" class="mt-2"/>
                </v-autocomplete>

                <template v-if="tab == 3" v-for="(meal, i) in meals">
                  <h3>{{ translate(i) }}</h3>
                  <v-data-table
                      :search="pretraga"
                      :id="'table6_' + i"
                      :headers="headersP"
                      :items="meal.meals"
                      class="elevation-1 my-4 test_klasa"
                      hide-default-footer
                      disable-pagination
                      mobile-breakpoint="0"
                  >
                    <template v-for="header in headersP" v-slot:[`header.${header.value}`]="{ header }">
                      {{header.value == 'num' || header.value == 'name' || header.value == 'total' ? translate(header.text) : '' }} {{header.value == 'shift_one' && meal.company.shift_one == 1 ? translate(header.text) : header.value == 'shift_two' && meal.company.shift_two == 2 ? translate(header.text) : header.value == 'shift_three' && meal.company.shift_three == 3 ? translate(header.text) : '' }}
                    </template>
                    <template v-slot:item.menu="{ item }">
                      <span @click="showMeal(item.id)">{{item.name == "УКУПНО" ? $t("totalU") : translate(item.name)}}</span>
                    </template>
                    <template v-slot:item.name="{ item }">
                      <span :title="'ID: ' + item.id">{{item.name == "УКУПНО" ? $t("totalU") : translate(item.name) }}</span>
                    </template>
                    <template v-slot:item.shift_one="{ item }">
                      <template v-if="item.name == 'УКУПНО'">
                        <span class="font-weight-bold">{{ meal.company.shift_one == 1 ? item["users"]["1-smena"] : '' }}</span>
                      </template>
                      <template v-else v-for="user in item['users']['1-smena']">
                        <span @click="showUsers(user)" style="cursor: pointer">
                          <span v-if="user.name == 'Обрисан'" style="color: #c20606">
                            {{ user.id }} <span style="font-size: 10px">{{translate(user.name.toLowerCase())}}: </span>
                            {{ user.quantity >= 1 ? " " + user.quantity + ", " : "," }}
                          </span>
                          <span v-else>
                              {{ translate(user.name) + " " + translate(user.lastname) }}:
                              <span v-if="user.quantity >= 1">   {{user.quantity >= 1 ? " " + user.quantity + ", " : "," }} </span>
                              <span v-else>0</span>
                          </span>
                        </span>
                      </template>
                      <span v-if="item&&item['users']&&item['users']['1-smena']&&item.name != 'УКУПНО'" style="display: block;font-size: 13px;margin-top: 5px">
                        {{ $t('total') }}:
                        <span style="font-weight: 700">
                          <span>{{totalUserQtu(item['users']['1-smena']) != 0 ? totalUserQtu(item['users']['1-smena']) : 0 }}</span>
                        </span>
                      </span>
                    </template>
                    <template v-slot:item.shift_two="{ item }">
                      <template v-if="item.name == 'УКУПНО'">
                        <span class="font-weight-bold">{{meal.company.shift_two == 2 ? item["users"]["2-smena"] : '' }}</span>
                      </template>
                      <template v-else v-for="(user, i) in item['users']['2-smena']">
                        <span @click="showUsers(user)">
                          <span v-if="user.name == 'Обрисан'" style="color: #c20606">
                            {{ user.id }}
                            <span style="font-size: 10px">{{ translate(user.name.toLowerCase()) }}: </span>
                             {{ user.quantity >= 1 ? "" + user.quantity + ", " : "," }}
                          </span>
                          <span v-else>{{ translate(user.name) + " " + translate(user.lastname) }}:
                            {{ user.quantity >= 1 ? "" + user.quantity + ", " : "," }}
                          </span>
                        </span>
                      </template>
                      <span v-if="item&&item['users']&&item['users']['2-smena']&&item.name != 'УКУПНО'" style="display: block;font-size: 13px;margin-top: 5px">
                         {{ $t('total') }}:
                        <span style="font-weight: 700">
                            <span v-for="tot in item['users']['2-smena']" v-if="tot.quantity == 0">
                            <span>0</span>
                          </span>
                          <span>{{totalUserQtu(item['users']['2-smena']) != 0 ? totalUserQtu(item['users']['2-smena']) : '' }}</span>
                        </span>
                      </span>
                    </template>
                    <template v-slot:item.shift_three="{ item }">
                      <template v-if="item.name == 'УКУПНО'">
                        <span class="font-weight-bold">{{meal.company.shift_three == 3 ? item["users"]["3-smena"] : '' }}</span>
                      </template>
                      <template v-else v-for="(user, i) in item['users']['3-smena']">
                        <span @click="showUsers(user)">
                         <span v-if="user.name == 'Обрисан'" style="color: #c20606">
                            {{ user.id }}
                            <span style="font-size: 10px">{{ translate(user.name.toLowerCase()) }}: </span>
                            {{ user.quantity >= 1 ? " " + user.quantity + ", " : "," }}
                          </span>
                          <span v-else>{{ translate(user.name) + " " + translate(user.lastname) }}:
                            {{ user.quantity >= 1 ? " " + user.quantity + ", " : "," }}
                          </span>
                        </span>
                      </template>
                      <span v-if="item&&item['users']&&item['users']['3-smena']&&item.name != 'УКУПНО'" style="display: block;font-size: 13px;margin-top: 5px">
                         {{ $t('total') }}:
                        <span style="font-weight: 700">
                            <span v-for="tot in item['users']['3-smena']" v-if="tot.quantity == 0">
                            <span>0</span>
                          </span>
                          <span>{{totalUserQtu(item['users']['3-smena']) != 0 ? totalUserQtu(item['users']['3-smena']) : '' }}</span>
                        </span>
                      </span>
                    </template>
                    <template v-slot:item.total="{ item }">
                      <template>
                        <span class="font-weight-bold">{{ item.total }}</span>
                      </template>
                    </template>
                    <template v-for="values in headers" v-slot:item.num="{ item, index }">
                      {{ item.name !== "УКУПНО" ? index + 1 : "" }}
                    </template>
                  </v-data-table>
                </template>
              </v-card-text>
            </v-card>
          </v-tab-item>
          <!-- Narucioci II ako je jela iz grupe -->
          <v-tab-item :style="select_view == 1 ? 'display:none' : 'display:block'" v-if="select_view == 2">
            <p v-if="date[0] != date[1] && select_view == 2 && tab == 4" class="my-3 text-center py-3 font-weight-black alert alert-danger" style="border-radius:8px;color: #721c24;background-color: #f8d7da;border-color: #f5c6cb;" role="alert">
              {{ $t('daily_report') }}
            </p>
            <v-card v-else flat id="table555">
              <v-card-text style="display: flex;flex-wrap: wrap">
                <v-autocomplete style="width: 65%"
                  v-model="search8"
                  v-if="tab == 4"
                  :items="foodCompanies"
                  dense
                  filled
                  :label="$t('select_company')"
                  :item-text="getItemText"
                  item-value="id"
                  @change="findUsersByCompany()"
                  clear
                  chips
                  small-chips
                ></v-autocomplete>
                <v-autocomplete style="width: 25%;margin-left: 10px" v-if="tab == 4"
                  v-model="searchByShiftNewReport"
                  :items="smeneZaNoviIzvestaj"
                  dense
                  filled
                  :label="$t('shifts')"
                  item-text="shift"
                  item-value="id"
                  clear
                  chips
                  small-chips
                ></v-autocomplete>
              </v-card-text>
              <v-simple-table class="py-2" v-if="narudzbe2 && search8 && tab == 4">
                <template v-slot:default>
                  <thead>
                  <tr>
                    <th style="border-right: 1px solid #cfcfcf;">{{ $t('name') + ' ' + $t('last_name') }}</th>
                    <th style="border-right: 1px solid #cfcfcf;">{{ $t('date') }} - {{ $t('time') }}</th>
                    <!--                      <th v-for="category in categories[foodCompanies.find(id => id.id == search8).company]" :key="category" style="border-right: 1px solid #cfcfcf">-->
                    <th v-for="category in kategorija" :key="category" style="border-right: 1px solid #cfcfcf">
                      {{ translate(category) }}
                    </th>
                    <th>{{ $t('note') }}</th>
                  </tr>
                  </thead>
                  <tbody>
                  <tr v-for="(dayData, day) in filterByShiftsNarudzbe2" :key="day" style="border-bottom: 1px solid #cfcfcf">
                    <td :title="'User ID: ' + day" style="border-right: 1px solid #cfcfcf;width: 15%">
                      {{narudzbe2.find(id => id.user_id == day) ? translate(narudzbe2.find(id => id.user_id == day).name) : '' }}
                      {{narudzbe2.find(id => id.user_id == day) ? translate(narudzbe2.find(id => id.user_id == day).lastname) : '' }}
                    </td>
                    <td :title="translate('Време поруџбе')" style="border-right: 1px solid #cfcfcf;width: 11%">{{narudzbe2.find(id => id.user_id == day) ? formatDateFood(narudzbe2.find(id => id.user_id == day).created_at) : '' }}
                    </td>
                    <template v-for="(shiftData, shift) in dayData">
                      <td v-for="category in dayData[shift]" style="border-right: 1px solid #cfcfcf;width: 11%">
                        <span :title="'ID: ' + meal.food_id" v-for="meal in category">
                          <span class="font-weight-bold">{{ meal.quantity }}x </span>
                          {{ translate(meal.meal_name) }}<br>
                        </span>
                      </td>
                    </template>
                    <td> {{narudzbe2.find(id => id.user_id == day) ? translate(narudzbe2.find(id => id.user_id == day).note) : '' }}
                    </td>
                  </tr>
                  </tbody>
                </template>
              </v-simple-table>
            </v-card>
          </v-tab-item>
          <!-- PREUZETI OBROCI -->
          <v-tab-item v-if="select_view != 2">
            <v-card flat>
              <v-card-text>
                <v-autocomplete
                    v-model="search"
                    :items="select_view == 2 ? foodCompanies : desserts4.filter((i) => i.company)"
                    dense
                    filled
                    multiple
                    :label="$t('select_company')"
                    :item-text="getItemText"
                    item-value="id"
                    clear
                    chips
                    small-chips
                >
                  <v-btn
                      color="primary"
                      slot="prepend-item"
                      @click="toogleFirms()"
                      small
                      class="mx-4"
                  >
                    {{ $t("display_all") }}
                  </v-btn>
                </v-autocomplete>
                <div id="table8">
                  <template
                      v-for="(comapny, companyKey) in search && search.length > 0
                      ? desserts4.filter((i) => search.includes(i.id))
                      : desserts4"
                      dense
                  >
                    <h2 class="text-center mt-5 mb-2">
                      {{ translate(comapny.company) }}
                    </h2>
                    <v-data-table
                        :search="pretraga"
                        id="table4"
                        :headers="headersM"
                        :items="comapny.foods"
                        class="elevation-1"
                        hide-default-footer
                        disable-pagination
                        mobile-breakpoint="0"
                    >
                      <template
                          v-for="header in headersM"
                          v-slot:[`header.${header.value}`]="{ header }"
                      >
                        {{ $t(header.text) }}
                      </template>
                      <template v-for="values in headersM" v-slot:[`item.${values.value}`]="{ item }">
                        {{ translate(item[values.value]) }}
                      </template>

                      <template v-slot:item.shift_one="{item}">
                        {{ item.shift_one_payed }}/{{ item.shift_one }}
                      </template>
                      <template v-slot:item.shift_two="{item}">
                        {{ item.shift_two_payed }}/{{ item.shift_two }}
                      </template>
                      <template v-slot:item.shift_three="{item}">
                        {{ item.shift_three_payed }}/{{ item.shift_three }}
                      </template>
                      <template v-slot:item.total="{item}">
                        {{ item.total_payed }}/{{ item.total }}
                      </template>
                      <template v-slot:item.menu="{ item }">
                        <span @click="showMeal(item.id)">{{item.name == "УКУПНО" ? $t("totalU") : translate(item.name) }}</span>
                      </template>
                      <template v-slot:item.num="{ item, index }">
                        {{ item.name !== "УКУПНО" ? index + 1 : "" }}
                      </template>
                    </v-data-table>
                  </template>
                </div>
              </v-card-text>
            </v-card>
          </v-tab-item>
          <v-tab-item v-if="select_view != 2">
            <v-card flat>
              <v-card-text>
                <v-autocomplete
                    v-model="search5"
                    :items="desserts6.filter((i) => i.company)"
                    dense
                    filled
                    multiple
                    @input="reservedMeals"
                    :label="$t('select_company')"
                    item-text="company"
                    item-value="id"
                    clear
                    chips
                    small-chips
                >
                  <v-btn
                      color="primary"
                      slot="prepend-item"
                      @click="toogleFirmsReserve()"
                      small
                      class="mx-4"
                  >
                    {{ $t("display_all") }}
                  </v-btn>
                </v-autocomplete>
                <div id="table9">
                  <template
                      v-for="(comapny, companyKey) in search5 && search5.length > 0
                      ? desserts6.filter((i) => search5.includes(i.company))
                      : desserts6"
                      dense
                  >
                    <h2 class="text-center mt-5 mb-2">
                      {{ translate(comapny.company) }}
                    </h2>
                    <v-data-table
                        :search="pretraga"
                        id="table9"
                        :headers="headersS"
                        :items="comapny.foods"
                        class="elevation-1"
                        hide-default-footer
                        disable-pagination
                        mobile-breakpoint="0"
                    >
                      <template
                          v-for="header in headersS"
                          v-slot:[`header.${header.value}`]="{ header }"
                      >
                        {{ translate(header.text) }}
                      </template>
                      <template
                          v-for="values in headersS"
                          v-slot:[`item.${values.value}`]="{ item }"
                      >
                        {{ translate(item[values.value]) }}
                      </template>
                      <template v-slot:item.menu="{ item }">
                        <span @click="showMeal(item.id)">{{translate(item.name) }}</span>
                      </template>
                      <template v-slot:item.num="{ item, index }">
                        {{ item.name !== "УКУПНО" ? index + 1 : "" }}
                      </template>
                    </v-data-table>
                    <!--                    <v-data-table-->
                    <!--                        :search="pretraga"-->
                    <!--                        id="table1"-->
                    <!--                        :headers="headersS"-->
                    <!--                        :items="filterReservedMeals"-->
                    <!--                        class="elevation-1"-->
                    <!--                        hide-default-footer-->
                    <!--                        disable-pagination-->
                    <!--                    >-->
                    <!--                      <template-->
                    <!--                          v-for="header in headers"-->
                    <!--                          v-slot:[`header.${header.value}`]="{ header }"-->
                    <!--                      >-->
                    <!--                        {{ translate(header.text) }}-->
                    <!--                      </template>-->
                    <!--                      <template v-slot:item.num="{ item, index }">-->
                    <!--                        {{ item.name !== "УКУПНО" ? index + 1 : "" }}-->
                    <!--                      </template>-->
                    <!--                      <template v-slot:item.firm="{ item }">-->
                    <!--                        {{ translate(item.firm) }}-->
                    <!--                      </template>-->
                    <!--                      <template v-slot:item.shift_one="{ item }">-->
                    <!--                        {{ translate(item.shift_one) }}-->
                    <!--                      </template>-->
                    <!--                      <template v-slot:item.shift_two="{ item }">-->
                    <!--                        {{ translate(item.shift_two) }}-->
                    <!--                      </template>-->
                    <!--                      <template v-slot:item.shift_three="{ item }">-->
                    <!--                        {{ translate(item.shift_three) }}-->
                    <!--                      </template>-->
                    <!--                      <template v-slot:item.total="{ item }">-->
                    <!--                        {{ translate(item.total) }}-->
                    <!--                      </template>-->
                    <!--                    </v-data-table>-->
                  </template>
                </div>
              </v-card-text>
            </v-card>
          </v-tab-item>
          <v-tab-item v-if="select_view != 2">
            <v-card flat>
              <v-card-text>
                <v-data-table
                    :search="pretraga"
                    id="table9"
                    :headers="headersN"
                    :items="noContractDelivery"
                    class="table-striped elevation-1 my-4"
                    hide-default-footer
                    mobile-breakpoint="0"
                    disable-pagination>
                  <template
                      v-for="header in headersN"
                      v-slot:[`header.${header.value}`]="{ header }">
                    {{ translate(header.text) }}
                  </template>
                  <template v-slot:item.num="{ item, index }">
                    {{ item.name !== "УКУПНО" ? index + 1 : "" }}
                  </template>
                  <template v-slot:item.time="{ item }">
                    <template v-if="item.name == 'УКУПНО'">{{ $t('total') }}</template>
                    <template v-else v-for="(meal,index) in item.order_guest_transactions">
                      <template v-if="index == 0">
                        <span>{{ meal.date }}</span>
                      </template>
                    </template>
                  </template>
                  <template v-slot:item.name="{ item }">
                    <template v-for="meal in item.order_guest_transactions">
                      <span :title="'ID: ' + meal.id">{{ translate(meal.meals.name) }}</span> <br>
                    </template>
                  </template>
                  <template v-slot:item.price="{ item }">
                    <template v-for="meal in item.order_guest_transactions">
                      <template>
                        {{ meal.price }}
                      </template>
                      <br>
                    </template>
                  </template>
                  <template v-slot:item.quantity="{ item }">
                    <template v-if="item.quantity">
                      {{ item.quantity }}
                    </template>
                    <template v-else v-for="meal in item.order_guest_transactions">
                      <template>
                        {{ meal.quantity }}
                      </template>
                      <br>
                    </template>
                  </template>
                  <template v-slot:item.qty="{ item }">
                    <template v-if="item.qty">
                      {{ item.qty }}
                    </template>
                    <template v-else>
                      {{ priceDelivery(item.order_guest_transactions) }}
                    </template>
                  </template>
                </v-data-table>
              </v-card-text>
            </v-card>
          </v-tab-item>
          <!-- RESTORANI-->
          <v-tab-item>
            <v-card flat>
              <v-card-text>
                <v-data-table
                    :search="pretraga"
                    id="table9"
                    :headers="headersK"
                    :items="childRestorans"
                    :items-per-page="-1"
                    class="elevation-1"
                    hide-default-footer
                    disable-pagination
                    mobile-breakpoint="0"
                >
                  <template v-for="header in headers" v-slot:[`header.${header.value}`]="{ header }">
                    {{ translate(header.text) }}
                  </template>
                  <template v-for="values in headers" v-slot:[`item.${values.value}`]="{ item }">
                    {{ translate(item[values.value]) }}
                  </template>
                  <template v-slot:item.num="{ item, index }">
                    {{ item && item.name && item.name !== "УКУПНО" ? index + 1 : "" }}
                  </template>
                  <template v-slot:item.restoran="{ item }">
                    {{ translate(item.name) }}
                  </template>
                  <template v-slot:item.meal_num="{ item }">
                    <p style="text-align: right;width: 90px">
                      {{ translate(item.count) }}
                    </p>
                  </template>
                </v-data-table>
              </v-card-text>
            </v-card>
          </v-tab-item>
        </v-tabs-items>
      </v-card>
    </v-row>
    <v-snackbar
        v-model="snackbar.status"
        :timeout="2000"
        top
        rounded="pill"
        color="#C20606"
    >
      {{ translate(snackbar.text) }}

      <template v-slot:action="{ attrs }">
        <v-btn
            color="white"
            text
            v-bind="attrs"
            @click="snackbar.status = false"
        >
          {{ translate("Затвори") }}
        </v-btn>
      </template>
    </v-snackbar>

    <v-dialog v-model="dialogReport" style="height: 100%" max-width="100%" scrollable fullscreen :scrim="false" transition="dialog-bottom-transition" class="dialogReport">
      <v-container style="padding-bottom: 13px !important; height: 100% !important;max-width: 100% !important;">
        <v-row style="height: 100%">
          <v-col cols="12" style="padding:0">
            <v-card style="height: 100%">
              <div
                  style="background-color: rgb(255, 248, 245);box-shadow: 0 2px 4px -1px rgba(0, 0, 0, 0.2);padding: 10px;display: flex;align-content: center;height: 64px">
                <div style="display: flex;align-items: center;width: 40%">
                  <img src="https://restorani.bitlab.host/img/o.png" alt="image" style="width: 54px;">
                  <div style="font-size: 15px;font-weight: 700;padding-left: 20px">{{ translate(userReportItem.name) }}
                    {{ translate(userReportItem.lastname) }}, ID {{ userReportItem.id }}
                  </div>
                </div>
                <v-btn style="position: absolute;right: 10px;top: 10px;" icon
                       @click="dialogReport = false; printTable='table7'">
                  <v-icon x-medium>mdi-close</v-icon>
                </v-btn>
                <v-card-text align="center" class="title"
                             style="width: 50%;text-align: left;display: flex;align-content: center;margin-top: -7px">
                  <span style='color:#fb6d3b'>
                    <v-icon style='color:#fb6d3b' small>mdi-calendar</v-icon> {{ monthName }} {{ year }}.
                  </span>
                </v-card-text>
              </div>
              <v-card-title style="padding: 6px 0 0 0;display: flex">
                <div style="width: 60%;display: flex">
                  <v-dialog
                      ref="dialog"
                      v-model="modal"
                      :return-value.sync="date1"
                      persistent
                      width="290px"
                  >
                    <template v-slot:activator="{ on, attrs }">
                      <v-text-field style="margin: 40px 0;padding: 0 10px"
                                    v-model="date1"
                                    :label="$t('choose_date_single')"
                                    prepend-inner-icon="mdi-calendar"
                                    readonly
                                    v-bind="attrs"
                                    v-on="on"
                                    hide-details
                                    class="px-2"
                      ></v-text-field>
                    </template>
                    <v-date-picker v-model="date1" type="month" scrollable>
                      <v-spacer></v-spacer>
                      <v-btn text color="primary" @click="modal = false">
                        Cancel
                      </v-btn>
                      <!--                        loadReportFirm(userReportItem)-->
                      <v-btn text color="primary" @click="$refs.dialog.save(date1), loadReport(userReportItem, '')">
                        OK
                      </v-btn>
                    </v-date-picker>
                  </v-dialog>
                  <v-text-field style="margin: 40px 0;padding: 0 10px"
                                v-model="searchR"
                                prepend-inner-icon="mdi-magnify"
                                :label="$t('search')"
                                single-line
                                hide-details
                                class="px-2"
                  ></v-text-field>
                </div>
                <div style="width: 40%;display: flex;align-content: center">
                  <v-checkbox style="padding: 0 10px"
                              :label="$t('show_all')"
                              hide-details
                              v-model="allDay"
                              @change="filterDay"
                  >
                  </v-checkbox>
                  <v-btn style="margin: 10px 20px;padding: 10px;margin-left: auto" @click="printTable = 'table222'; printDialog = true"
                         :style="'border-radius: 8px;border: 1px solid ' + $store.state.restaurant.settings.topbar.background + ';background: none;color: ' + $store.state.restaurant.settings.topbar.background + ';box-shadow: none;text-transform: capitalize'"
                         small class="mt-2">
                    <v-icon :style="'color:' + $store.state.restaurant.settings.topbar.background" class="mr-2">
                      mdi-printer
                    </v-icon>
                    <span id="stampaj">
                    {{ $t("print") }}
                  </span>
                  </v-btn>
                </div>
              </v-card-title>
              <v-simple-table v-if="select_view == 2" id="table222" class="py-2">
                <template v-slot:default>
                  <thead>
                  <tr>
                    <th style="border-right: 1px solid #cfcfcf">{{ $t('date') }}</th>
                    <th style="border-right: 1px solid #cfcfcf">{{ $t('shifts') }}</th>
                    <th v-for="category in kategorije" :key="category" style="border-right: 1px solid #cfcfcf">
                      {{ translate(category) }}
                    </th>
                    <th>{{ $t('quantity') }}</th>
                  </tr>
                  </thead>
                  <tbody>
                  <tr v-for="(dayData, day) in kategorije1" :key="day" style="border-bottom: 1px solid #cfcfcf">
                    <td style="border-right: 1px solid #cfcfcf">{{ day }}.</td>
                    <template v-for="(shiftData, shift) in dayData">
                      <td style="width: 7%;border-right: 1px solid #cfcfcf">
                        <!--                        <span class="font-weight-bold">{{ shift == 1 ? $t('1st_shift') : shift == 2 ? $t('2nd_shift') : $t('3rd_shift') }}</span>-->
                        <span class="font-weight-bold">{{ shift }}</span>
                      </td>
                      <td v-for="category in dayData[shift]" style="border-right: 1px solid #cfcfcf">
                        <span v-for="meal in category">
                          <span class="font-weight-bold">{{ meal.quantity }}x </span>
                          {{ translate(meal.meal_name) }}<br>
                        </span>
                      </td>
                      <td :colspan="kategorije.length + 3" class="text-right">
                        <span class="pr-4">
                          <span class="font-weight-bold">{{ totalFoodByRow(shiftData) }}</span>
                        </span>
                      </td>
                    </template>
                  </tr>
                  <tr>
                    <td colspan="2" class="title">{{ $t('totalU') }}:</td>
                    <td v-for="category in totalByColumn()" class="font-weight-bold">{{ category }}</td>
                    <!--                    :colspan="kategorije.length + 3"-->
                    <td class="title" align="end" style="text-align: right">
                      <span class="mr-3 pr-1">{{ total3 }}</span>
                    </td>
                  </tr>
                  </tbody>
                </template>
              </v-simple-table>
              <v-simple-table v-if="select_view == 1 || select_view == 3" id="table222" class="py-2">
                <template v-slot:default>
                  <thead>
                  <tr>
                    <th class="text-left">{{ $t('date') }}.</th>
                    <th class="text-left" style="display: flex;flex-wrap: wrap;align-items: center">
                      <span class="text-center" style="width: 13%;padding-left:50px">{{ $t('shifts') }}</span>
                      <span style="width: 80%;padding-left:110px">
                          <span> {{ $t('meal') }}</span>
                        </span>
                      <span>{{ $t('quantity') }}</span>
                    </th>
                  </tr>
                  </thead>
                  <tbody>
                  <template v-for="(item, i) in reportSearch">
                    <tr>
                      <td>{{ item.day }}.</td>
                      <td>
                        <table width="100%">
                          <template v-if="item.shiftOne && item.shiftOne.length">
                            <!--                            <th class="px-4" width="200px" style="vertical-align: middle;">{{ $t('1st_shift')}}</th>-->
                            <th class="px-4" width="200px" style="vertical-align: middle;">1</th>
                            <td class="px-4" style="">
                              <!--                              display: flex;justify-content: space-between-->
                              <template v-for="(menu, i) in item.shiftOne">
                                <p :style="item.shiftOne.length > 1 ? 'border-bottom: 1px solid #cfcfcf' : ''"
                                   style="margin: 0;padding: 0;display: flex;justify-content: space-between;width: 100%;">
                                  <span>
                                    {{ translate(menu.meal_name) }}
                                     <span v-if="menu.food_name">{{ translate(menu.food_name) }}</span>
                                  </span>
                                  <span> {{ menu.quantity }}</span>
                                </p>
                                <!--                                <p style="margin: 0;padding: 0" align="center">{{ menu.quantity }} </p>-->
                              </template>
                            </td>
                          </template>
                          <tr style="background: none !important;" v-if="item.shiftTwo && item.shiftTwo.length">
                            <!--                            <th class="px-4" width="200px" style="vertical-align: middle;">{{ $t('2nd_shift')}}</th>-->
                            <th class="px-4" width="200px" style="vertical-align: middle;">2</th>
                            <td class="px-4">
                              <!--                              <template v-for="menu in item.shiftTwo">-->
                              <!--                                <p style="margin: 0;padding: 0"  width="80%">{{menu.meal_name}}</p>-->
                              <!--                                <p style="margin: 0;padding: 0"  align="center">{{menu.quantity}}</p>-->
                              <!--                              </template>-->
                              <template v-for="(menu, i) in item.shiftTwo">
                                <p :style="item.shiftTwo.length > 1 ? 'border-bottom: 1px solid #cfcfcf' : ''"
                                   style="margin: 0;padding: 0;display: flex;justify-content: space-between;width: 100%;">
                                  <span>
                                    {{ translate(menu.meal_name) }}
                                     <span v-if="menu.food_name">{{ translate(menu.food_name) }}</span>
                                  </span>
                                  <span> {{ menu.quantity }}</span>
                                </p>
                                <!--                                <p style="margin: 0;padding: 0" align="center">{{ menu.quantity }} </p>-->
                              </template>
                            </td>
                          </tr>
                          <tr v-if="item.shiftThree && item.shiftThree.length">
                            <!--                            <th class="px-4" width="200px" style="vertical-align: middle;">{{  $t('3rd_shift')}}</th>-->
                            <th class="px-4" width="200px" style="vertical-align: middle;">3</th>
                            <td class="px-4">
                              <!--                              <template v-for="menu in item.shiftThree">-->
                              <!--                                <p style="margin: 0;padding: 0"  width="80%">{{menu.meal_name}}</p>-->
                              <!--                                <p style="margin: 0;padding: 0"  align="center">{{menu.quantity}}</p>-->
                              <!--                              </template>-->
                              <template v-for="(menu, i) in item.shiftThree">
                                <p :style="item.shiftThree.length > 1 ? 'border-bottom: 1px solid #cfcfcf' : ''"
                                   style="margin: 0;padding: 0;display: flex;justify-content: space-between;width: 100%;">
                                  <span>
                                    {{ translate(menu.meal_name) }}
                                      <span v-if="menu.food_name">{{ translate(menu.food_name) }}</span>
                                  </span>
                                  <span> {{ menu.quantity }}</span>
                                </p>
                                <!--                                <p style="margin: 0;padding: 0" align="center">{{ menu.quantity }} </p>-->
                              </template>
                            </td>
                          </tr>
                        </table>
                      </td>
                    </tr>
                  </template>
                  <tr>
                    <td class="title">{{ $t('totalU') }}:</td>
                    <td class="title" align="end" style="text-align: right">
                      <span class="mr-3 pr-1">{{ total3 }}</span>
                    </td>
                  </tr>
                  </tbody>
                </template>
              </v-simple-table>
            </v-card>
          </v-col>
        </v-row>
      </v-container>
    </v-dialog>
    <v-dialog v-model="dialogFirm" max-width="97%" scrollable class="dialogReport">
      <v-container style="padding-bottom: 13px !important; height: 100% !important;max-width: 100% !important;">
        <v-row>
          <v-col cols="12" style="padding:0">
            <v-card>
              <div style="background-color: rgb(255, 248, 245);box-shadow: 0 2px 4px -1px rgba(0, 0, 0, 0.2);padding: 10px;display: flex;align-content: center;height: 64px">
                <div style="display: flex;align-items: center;width: 40%">
                  <img src="https://restorani.bitlab.host/img/o.png" alt="image" style="width: 54px;">
                  <div style="font-size: 15px;font-weight: 700;padding-left: 20px">{{ firmReportItem.menu }}, ID
                    {{ firmReportItem.id }}
                  </div>
                </div>
                <v-btn style="position: absolute;right: 10px;top: 10px;" icon @click="dialogFirm = false">
                  <v-icon x-medium>mdi-close</v-icon>
                </v-btn>
                <v-card-text align="center" class="title" style="width: 50%;text-align: left;display: flex;align-content: center;margin-top: -7px">
                 <span><span v-if="ordered_meals">{{ $t('ordered_12') }}</span> <span v-if="taken_meals && ordered_meals">/</span> <span v-if="taken_meals"> {{ $t('taken1') }}</span> {{ $t('meals').toLowerCase() }}</span>
                  <span style='color:#fb6d3b;margin-left: auto'>
                    <v-icon style='color:#fb6d3b' small>mdi-calendar</v-icon> {{ monthName }} {{ year }}.
                  </span>
                </v-card-text>
              </div>
              <v-card-title style="padding: 6px 0 3px 0;display: flex">
                <div style="width: 45%;display: flex">
                  <v-dialog
                      ref="dialog"
                      v-model="modal"
                      :return-value.sync="date1"
                      persistent
                      width="290px"
                  >
                    <template v-slot:activator="{ on, attrs }">
                      <v-text-field style="margin: 40px 0;padding: 0 10px"
                        v-model="date1"
                        label="Picker in dialog"
                        prepend-inner-icon="mdi-calendar"
                        readonly
                        v-bind="attrs"
                        v-on="on"
                        hide-details
                        class="px-2"
                      ></v-text-field>
                    </template>
                    <v-date-picker v-model="date1" type="month" scrollable>
                      <v-spacer></v-spacer>
                      <v-btn text color="primary" @click="modal = false">
                        Cancel
                      </v-btn>
                      <v-btn text color="primary" @click="$refs.dialog.save(date1), loadReportFirm(firmReportItem)">
                        OK
                      </v-btn>
                    </v-date-picker>
                  </v-dialog>
                  <v-text-field style="margin: 40px 0;padding: 0 10px"
                    v-model="searchR"
                    prepend-inner-icon="mdi-magnify"
                    label="Search"
                    single-line
                    hide-details
                    class="px-2"
                  ></v-text-field>
                </div>
                <div style="width: 55%;display: flex;align-content: center">
                  <v-checkbox style="padding: 0 5px;"
                    label="Prikaži sve dane"
                    hide-details
                    v-model="allDayFirm"
                    @change="filterDayFirm"
                  >
                  </v-checkbox>
                  <v-checkbox style="padding: 0 5px;"
                    :label="$t('ordered_1')"
                    hide-details
                    v-model="ordered_meals"
                    @change="filterDayFirm"
                  >
                  </v-checkbox>
                  <v-checkbox v-if="firmReportItem.settings && firmReportItem.settings.can_see_ordered || $store.state.user.type == 'restaurant'" style="padding: 0 5px;"
                    :label="$t('taken')"
                    hide-details
                    v-model="taken_meals"
                    @change="filterDayFirm"
                  >
                  </v-checkbox>
                  <v-btn style="margin-left: auto" :title="translate('Извезите податке у Excel')" :loading="loading" :style="'border-radius: 8px;background: none;color:rgba(14,105,19,0.56);box-shadow: none;text-transform: capitalize'"
                         @click="customExcelFile = filterFirmsReport;exportToExcel(filterFirmsReport, '', headersFi, firmReportItem.menu)" small class="mt-2">
                    <v-icon style="color:rgba(14,105,19,0.56)" class="mr-2">mdi mdi-microsoft-excel</v-icon>
                  </v-btn>
                  <v-btn :title="translate('Штампајте податке')" style="margin: 10px 20px;padding: 10px;" @click="printTable = 'table222'; printDialog = true"
                         :style="'border-radius: 8px;background: none;color: ' + $store.state.restaurant.settings.topbar.background + ';box-shadow: none;text-transform: capitalize'"
                         small class="mt-2">
                    <v-icon :style="'color:' + $store.state.restaurant.settings.topbar.background" class="mr-2">
                      mdi-printer
                    </v-icon>
                    <span id="stampaj">
<!--                      {{ $t("print") }}-->
                    </span>
                  </v-btn>
                </div>
              </v-card-title>
              <v-data-table
                  :search="pretraga"
                  id="table222"
                  :headers="headersFi"
                  :items="filterFirmsReport"
                  :items-per-page="-1"
                  class="elevation-1"
                  hide-default-footer
                  disable-pagination
              >
                <template v-for="header in headersFi" v-slot:[`header.${header.value}`]="{ header }">
                  <span v-if="header.value !== 'shiftOneP' && header.value !== 'shiftTwoP' && header.value !== 'shiftThreeP' && header.value !== 'totalP' && header.value !== 'diff'">
                    <span v-if="header.value === 'shiftOne' && firmReportItem.shift_one1 !== 0 && ordered_meals">{{ $t(header.text) }}</span>
                    <span v-else-if="header.value === 'shiftTwo' && firmReportItem.shift_two2 !== 0 && ordered_meals">{{ $t(header.text) }}</span>
                    <span v-else-if="header.value === 'shiftThree' && firmReportItem.shift_three3 !== 0 && ordered_meals">{{ $t(header.text) }}</span>
                    <span v-else-if="header.value !== 'totalP' &&  header.value !== 'diff' && header.value !== 'shiftOne' && header.value !== 'shiftTwo' && header.value !== 'shiftThree' && ordered_meals">{{ $t(header.text) }}</span>
                    <span v-else-if="header.value === 'day'">{{ $t(header.text) }}</span>
                    <span v-if="header.value === 'shiftOne' && firmReportItem.shift_one1 !== 0 && ordered_meals"><br>{{ $t('ordered_1') }}</span>
                    <span v-if="header.value === 'shiftTwo' && firmReportItem.shift_two2 !== 0 && ordered_meals"><br>{{ $t('ordered_1') }}</span>
                    <span v-if="header.value === 'shiftThree' && firmReportItem.shift_three3 !== 0 && ordered_meals"><br>{{ $t('ordered_1') }}</span>
                    <span style="font-weight: 900;color:#000" v-if="header.value === 'total' && ordered_meals"><br>{{ $t('ordered_1') }}</span>
                  </span>
                  <span v-else>
                    <span v-if="ordered_meals && taken_meals && header.value === 'diff'">{{ $t(header.text) }}</span>
                    <span v-if="taken_meals && header.value !== 'diff'">
<!--                      {{ $t(header.text) }}-->
                      <span v-if="header.value === 'shiftOneP' && firmReportItem.shift_one1 !== 0">{{ $t(header.text) }}</span>
                      <span v-else-if="header.value === 'shiftTwoP' && firmReportItem.shift_two2 !== 0">{{ $t(header.text) }}</span>
                      <span v-else-if="header.value === 'shiftThreeP' && firmReportItem.shift_three3 !== 0">{{ $t(header.text) }}</span>
                      <span v-else-if="header.value === 'day' || header.value === 'totalP'">{{ $t(header.text) }}</span>
                      <span v-if="header.value === 'shiftOneP' && firmReportItem.shift_one1 !== 0"><br>{{ $t('taken') }}</span>
                      <span v-if="header.value === 'shiftTwoP' && firmReportItem.shift_two2 !== 0"><br>{{ $t('taken') }}</span>
                      <span v-if="header.value === 'shiftThreeP' && firmReportItem.shift_three3 !== 0"><br>{{ $t('taken') }}</span>
                      <span style="font-weight: 900;color:#000" v-if="header.value === 'totalP'"><br>{{ $t('taken') }}</span>
                    </span>
                  </span>
                </template>
                <template v-for="values in headersFi" v-slot:[`item.${values.value}`]="{ item }">
                  {{ $t(item[values.value]) }}
                </template>
                <template v-slot:item.day="{ item }">
                  <span v-if="item.day == 'UKUPNO' || item.day == 'УКУПНО' && taken_meals && firmReportItem.shift_one1 !== 0" style="font-weight: 700">{{item.day }}:</span>
                  <span v-else>{{ item.day }}</span>
                </template>
                <template v-slot:item.shiftOne="{ item }">
                  <span v-if="item.day == 'UKUPNO' || item.day == 'УКУПНО'" style="font-weight: 700;">
                    <span v-if="firmReportItem.shift_one1 !== 0 && ordered_meals">{{ item.shiftOne }}</span>
                  </span>
                  <span v-else>
                    <span v-if="firmReportItem.shift_one1 !== 0 && ordered_meals">
                      {{ item.shiftOne }}
                    </span>
                  </span>
                </template>
                <template v-slot:item.shiftOneP="{ item }">
                  <span v-if="item.day == 'UKUPNO' || item.day == 'УКУПНО'" style="font-weight: 700;">
                    <span v-if="taken_meals && firmReportItem.shift_one1 !== 0">{{ item.shiftOneP }}</span>
                  </span>
                  <span v-else>
                    <span v-if="taken_meals && firmReportItem.shift_one1 !== 0">{{ item.shiftOneP }}</span>
                  </span>
                </template>
                <template v-slot:item.shiftTwo="{ item }">
                  <span v-if="item.day == 'UKUPNO' || item.day == 'УКУПНО'" style="font-weight: 700;">
                    <span v-if="firmReportItem.shift_two2 !== 0 && ordered_meals">{{ item.shiftTwo }}</span>
                  </span>
                  <span v-else>
                    <span v-if="firmReportItem.shift_two2 !== 0 && ordered_meals">{{ item.shiftTwo }}</span>
                  </span>
                </template>
                <template v-slot:item.shiftTwoP="{ item }">
                  <span v-if="item.day == 'UKUPNO' || item.day == 'УКУПНО'" style="font-weight: 700;">
                    <span v-if="taken_meals && firmReportItem.shift_two2 !== 0">{{ item.shiftTwoP }}</span>
                  </span>
                  <span v-else>
                    <span v-if="taken_meals && firmReportItem.shift_two2 !== 0">{{ item.shiftTwoP }}</span>
                  </span>
                </template>
                <template v-slot:item.shiftThree="{ item }">
                  <span v-if="(item.day == 'UKUPNO' || item.day == 'УКУПНО')" style="font-weight: 700;">
                    <span v-if="firmReportItem.shift_three3 !== 0 && ordered_meals">{{ item.shiftThree }}</span>
                  </span>
                  <span v-else>
                     <span v-if="firmReportItem.shift_three3 !== 0 && ordered_meals">{{ item.shiftThree }}</span>
                  </span>
                </template>
                <template v-slot:item.shiftThreeP="{ item }">
                  <span v-if="item.day == 'UKUPNO' || item.day == 'УКУПНО'" style="font-weight: 700;">
                    <span v-if="taken_meals && firmReportItem.shift_three3 !== 0">{{ item.shiftThreeP }}</span>
                  </span>
                  <span v-else>
                    <span v-if="taken_meals && firmReportItem.shift_three3 !== 0">{{ item.shiftThreeP }}</span>
                  </span>
                </template>
                <template v-slot:item.total="{ item }">
                  <span style="font-weight: 700">
                    <span v-if="ordered_meals">{{ item.total }}</span>
                  </span>
                </template>
                <template v-slot:item.totalP="{ item }">
                  <span style="font-weight: 700">
                    <span v-if="taken_meals">{{ item.totalP }}</span>
                  </span>
                </template>
                <template v-slot:item.diff="{ item }">
                  <span style="font-weight: 700">
                    <span v-if="taken_meals && ordered_meals">
                      <span v-if="item.totalP != 0">{{ item.total-item.totalP }}</span>
                      <span v-else>0</span>
                    </span>
                  </span>
                </template>
              </v-data-table>
            </v-card>
          </v-col>
        </v-row>
      </v-container>
    </v-dialog>
    <v-dialog v-model="dialogAddMeal" max-width="95%" scrollable>
      <v-container
          style="padding-bottom: 13px !important; height: 100% !important;max-width: 100% !important;background: #fff">
        <v-row>
          <v-col cols="12" style="padding:0">
            <v-card>
              <div class="px-3" style="display: flex;justify-content: space-between;align-items: center">
                <h2 class="text-left py-5 mb-2">
                  {{ translate(newMealData.company) }}
                </h2>
                <span style="margin-left: -90px">{{ $t('additional_meal') }} <v-icon small class="ml-4"> mdi-calendar </v-icon> {{formatDate(date[0]) }}</span>
                <div style="width: 40%;padding: 3px 0;margin-left: 5px">
                  <v-text-field
                      v-model="addedMealsSearch"
                      append-icon="mdi-magnify"
                      :label="$t('search')"
                      single-line
                      hide-details
                      dense
                  ></v-text-field>
                </div>
                <span>
                   <v-btn style="position: absolute;right: 10px;top: 10px;" icon @click="dialogAddMeal = false">
                     <v-icon x-medium>mdi-close</v-icon>
                    </v-btn>
                </span>
              </div>
              <v-data-table style="width: 95% !important;"
                            :headers="headersS1"
                            :search="addedMealsSearch"
                            :items="newMealAllData"
                            class="elevation-1"
                            item-key="name"
                            hide-default-footer
                            disable-pagination
                            mobile-breakpoint="0"
              >
                <template v-slot:item.num="{ item, index }">
                  {{ item && item.name && (item.name !== "УКУПНО" || item.name !== "UKUPNO") ? index + 1 : "" }}
                </template>
                <template v-if="shift_one_price" v-slot:item.shift_one="{ item, index }">
                  <a style="text-decoration: none"
                     v-if="! shift_one_price[index] && (item.name != 'УКУПНО' && item.name != 'UKUPNO')"
                     @click.prevent="openShift(index, 1)" href="">{{ item.orders.shift_one }}</a>
                  <span style="font-weight: 700"
                        v-if="item.name == 'УКУПНО' || item.name == 'UKUPNO'">{{ item.orders.shift_one }}</span>
                  <v-text-field :key="index" v-if="shift_one_price[index]"
                                @focusout="closeShift(index, 1, item)"
                                type="number"
                                v-model.number="item.orders.shift_one"></v-text-field>
                </template>
                <template v-if="shift_two_price" v-slot:item.shift_two="{ item, index }">
                  <a style="text-decoration: none"
                     v-if="! shift_two_price[index] && (item.name != 'УКУПНО' && item.name != 'UKUPNO')"
                     @click.prevent="openShift(index, 2)" href="">{{ item.orders.shift_two }}</a>
                  <span style="font-weight: 700"
                        v-if="item.name == 'УКУПНО' || item.name == 'UKUPNO'">{{ item.orders.shift_two }}</span>
                  <v-text-field :key="index" v-if="shift_two_price[index]"
                                @focusout="closeShift(index, 2, item)"
                                type="number"
                                v-model.number="item.orders.shift_two"></v-text-field>
                </template>
                <template v-if="shift_three_price" v-slot:item.shift_three="{ item, index }">
                  <a style="text-decoration: none"
                     v-if="! shift_three_price[index] && (item.name != 'УКУПНО' && item.name != 'UKUPNO')"
                     @click.prevent="openShift(index, 3)" href="">{{ item.orders.shift_three }}</a>
                  <span style="font-weight: 700"
                        v-if="item.name == 'УКУПНО' || item.name == 'UKUPNO'">{{ item.orders.shift_three }}</span>
                  <v-text-field :key="index" v-if="shift_three_price[index]"
                                @focusout="closeShift(index, 3, item)"
                                type="number"
                                v-model.number="item.orders.shift_three"></v-text-field>
                </template>
                <template v-slot:item.total="{ item, index }">
                  <span style="font-weight: 700">{{ item.orders.total }}</span>
                </template>
              </v-data-table>
            </v-card>
          </v-col>
        </v-row>
      </v-container>
    </v-dialog>
    <v-dialog v-model="dialog" :max-width="containerClass" scrollable>
      <v-card>
        <v-card-text>
          <v-card elevation="0">
            <v-card-title>
              <v-spacer></v-spacer>
              <v-btn icon @click="dialog = false">
                <v-icon x-large>mdi-close</v-icon>
              </v-btn>
            </v-card-title>
            <v-card-text class="mb-4">
              <v-row>
                <v-col cols="12" sm="6">
                  <v-img v-if="$store.state.restaurant"
                         :src="
                      selectedMeal.image
                        ? conf.apiUrlBase +
                          '/gallery/' +
                          $store.state.restaurant.id +
                          '/medium/' +
                          selectedMeal.image.name
                        : 'https://cdn.vuetifyjs.com/images/cards/cooking.png'
                    "
                  >
                    <template v-slot:placeholder>
                      <v-row
                          class="fill-height ma-0"
                          align="center"
                          justify="center"
                      >
                        <v-progress-circular
                            indeterminate
                            color="grey lighten-5"
                        ></v-progress-circular>
                      </v-row>
                    </template>
                  </v-img>
                </v-col>
                <v-col cols="12" sm="6" class="ml-sm-n16 my-sm-2 mt-sm-16">
                  <v-row align="end" class="fill-height">
                    <v-card elevation="0" class="pa-5">
                      <v-card-title
                      ><h3>ID: {{ selectedMeal.id }} <br> {{ translate(selectedMeal.name) }}</h3></v-card-title>

                      <v-card-text class="mb-4">
                        <v-row no-gutters>
                          <template v-for="(eat, i) in selectedMeal.foods">
                            <v-col cols="12" class="font-weight-bold pt-2">
                              <v-row no-gutters justify="space-between">
                                <span
                                ><h3>{{ translate(eat.name) }}</h3></span
                                >
                              </v-row>
                            </v-col>
                            <v-col cols="12">{{
                                translate(eat.description)
                              }}
                            </v-col>
                          </template>
                        </v-row>
                      </v-card-text>
                    </v-card>
                  </v-row>
                </v-col>
              </v-row>
            </v-card-text>
          </v-card>
        </v-card-text>
      </v-card>
    </v-dialog>
    <v-dialog v-model="dialog2" :max-width="containerClass" scrollable>
      <v-card>
        <v-card-text>
          <v-card elevation="0">
            <v-card-title>
              <v-spacer></v-spacer>
              <v-btn icon @click="dialog2 = false">
                <v-icon x-large>mdi-close</v-icon>
              </v-btn>
            </v-card-title>
            <v-card-text class="mb-4">
              <v-row>
                <v-col cols="12" sm="6">
                  <v-img
                      :src="
                      selectedUser.image_path
                        ? conf.apiUrlBase +
                          '/gallery/' +
                          selectedUser.image_path
                        : 'https://st3.depositphotos.com/13159112/17145/v/600/depositphotos_171453724-stock-illustration-default-avatar-profile-icon-grey.jpg'
                    "
                  >
                    <template v-slot:placeholder>
                      <v-row
                          class="fill-height ma-0"
                          align="center"
                          justify="center"
                      >
                        <v-progress-circular
                            indeterminate
                            color="grey lighten-5"
                        ></v-progress-circular>
                      </v-row>
                    </template>
                  </v-img>
                </v-col>
                <v-col cols="12" sm="6" class="ml-sm-n16 my-sm-2 mt-sm-16">
                  <v-row align="end" id="korisnik" class="fill-height">
                    <v-card elevation="0" class="pa-5">
                      <v-card-title
                      ><h3>
                        {{
                          translate(selectedUser.name) +
                          " " +
                          translate(selectedUser.lastname)
                          + ' (' + selectedUser.id + ')'
                        }}
                      </h3></v-card-title
                      >

                      <v-card-text class="mb-4">
                        <v-row no-gutters>
                          <v-col cols="12" class="font-weight-bold pt-2">
                            <v-row no-gutters justify="space-between">
                              <span><h3>{{ selectedUser.email }}</h3></span>
                            </v-row>
                          </v-col>
                          <v-col cols="12"
                          >{{ translate("Начин примања нотификација: ") }}
                            {{
                              selectedUser.notifications
                                  ? translate(selectedUser.notifications)
                                  : translate("Нема")
                            }}
                          </v-col
                          >
                          <v-col cols="12"> {{ selectedUser.phone }}</v-col>
                          <v-col cols="12">
                            {{ selectedUser.external_code }}
                          </v-col
                          >
                          <v-col cols="12">
                            {{ selectedUser.address }}
                          </v-col>
                          <v-col cols="12">
                            {{
                              selectedUser.is_transferable
                                  ? translate("Може мијењати локацију")
                                  : ""
                            }}
                          </v-col>
                        </v-row>
                      </v-card-text>
                    </v-card>
                  </v-row>
                </v-col>
              </v-row>
            </v-card-text>
          </v-card>
        </v-card-text>
      </v-card>
    </v-dialog>
    <v-dialog v-model="dialogRefresPrint" max-width="35%" scrollable>
      <v-container
          style="padding-bottom: 13px !important; height: 100% !important;max-width: 100% !important;background: #fff">
        <v-row>
          <v-col cols="12" style="padding:0">
            <v-card style="box-shadow: none">
              <div class="px-3" style="display: flex;justify-content: center;align-items: center;width: 100%">
                <div @click="reloadPage()" class="alert alert-danger" role="alert" style="width: 100%;cursor: pointer">
                  <p class="text-center py-5 mb-2"
                     style="margin-top:10px;color: #721c24;background-color: #f8d7da;border: 1px solid transparent;border-color: #f5c6cb;border-radius: .25rem;padding: .75rem 1.25rem;">
                    {{ translate('Moлимо Вас освежите податке.') }}
                    <span style="display: block;text-align: center;margin-top: 5px">
                      <a style="color: #721c24;text-decoration: none" href="#"><v-icon>mdi mdi-reload</v-icon> {{
                          translate('Кликните овде')
                        }}</a>
                    </span>
                  </p>
                </div>
                <span>
                   <v-btn style="position: absolute;right: 10px;top: 10px;" icon @click="dialogRefresPrint = false">
                     <v-icon x-medium>mdi-close</v-icon>
                    </v-btn>
                </span>
              </div>
            </v-card>
          </v-col>
        </v-row>
      </v-container>
    </v-dialog>
    <v-dialog v-model="printDialog" max-width="600">
      <v-card>
        <v-card-title>Podesavanje Margina</v-card-title>
        <v-card-text>
          <v-row>
            <v-col cols="12">
              <v-row>
                <v-col cols="6">
                  <v-text-field label="Font Size" hide-details outlined dense v-model="margin.size" suffix="px"></v-text-field>
                </v-col>
              </v-row>
            </v-col>
            <v-col cols="6">
              <v-text-field label="Top" hide-details outlined dense v-model="margin.top" suffix="px"></v-text-field>
            </v-col>
            <v-col cols="6">
              <v-text-field label="Bottom" hide-details outlined dense v-model="margin.bottom" suffix="px"></v-text-field>
            </v-col>
            <v-col cols="6">
              <v-text-field label="Left" hide-details outlined dense v-model="margin.left" suffix="px"></v-text-field>
            </v-col>
            <v-col cols="6">
              <v-text-field label="Right" hide-details outlined dense v-model="margin.right" suffix="px"></v-text-field>
            </v-col>
          </v-row>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn outlined @click="printDialog = false">Close</v-btn>
          <v-btn @click="print(printTable)" color="primary">Print</v-btn>
          <v-spacer></v-spacer>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import axios from "../plugins/axios";
import moment from "moment";
import conf from "../config";

export default {
  data() {
    return {
      windowSize: {
        x: 0,
        y: 0,
      },
      conf: conf,
      reportG: '',
      totalMeals: [],
      name: "Намирнице",
      pretraga: "",
      search3: "",
      searchFoodFirm: "",
      search4: [],
      searchFoodCompany: "",
      searchFoodShift: "",
      searchByShift: "",
      addedMealsSearch: "",
      search5: "",
      search8: "",
      search2: "",
      searchN: "",
      search: "",
      searchByShiftNewReport: "",
      moment: moment,
      date: [new Date().toISOString().substr(0, 10)],
      date1: new Date().toISOString().substr(0, 7),
      dates: null,
      menu: false,
      ordered_meals: true,
      taken_meals: false,
      shift_one_price: [],
      shift_two_price: [],
      shift_three_price: [],
      change_order_status_popup: false,
      report: null,
      report2: null,
      dialogAddMeal: null,
      dialogReport: null,
      loading: false,
      modal: false,
      reservedItems: [],
      reservedItemsData: '',
      selectedMeal: {
        foods: [],
        name: "",
        image: {
          name: "",
        },
      },
      selectedUser: {
        name: "test",
        lastname: "test",
        image_path: "test",
      },
      total: [],
      printTable: "table3",
      companies: [],
      meals: [],
      users: [],
      obroci: [],
      dessertsR: [],
      dessertsRF: [],
      canSeeAddMeal: false,
      dialog: false,
      dialog2: false,
      dialogFirm: false,
      noContract: '',
      snackbar: {
        status: "",
        text: "",
      },
      dessertsReport: [],
      dessertsReportF: [],
      dessertsReportFirm: [],
      dessertsReportAll: [],
      dessertsReportFirmAll: [],
      newMealData: '',
      userReportItem: '',
      userReportFirm: '',
      allDay: false,
      allDayFirm: false,
      searchR: '',
      firmReports: '',
      firmReportsAll: [],
      firmsReportsAll: [],
      firmReportItem: '',
      headers: [
        {text: this.$t('r_b'), value: "num",},
        {text: this.$t('grocery'), value: "menu"},
        {text: this.$t('1st_shift'), value: "shift_one"},
        {text: this.$t('2nd_shift'), value: "shift_two"},
        {text: this.$t('3rd_shift'), value: "shift_three"},
        {text: this.$t('total'), value: "total"},
      ],
      headers1: [
        {text: this.$t('r_b'), value: "num",},
        {text: this.$t('grocery'), value: "menu"},
        {text: this.$t('1st_shift'), value: "shift_one"},
        {text: this.$t('2nd_shift'), value: "shift_two"},
        {text: this.$t('3rd_shift'), value: "shift_three"},
        {text: this.$t('total'), value: "total"},
        {text: this.$t('months'), value: "month"},
      ],
      headersM: [
        {text: 'r_b', value: "num", sortable: false},
        {text: 'meal', value: "menu"},
        {text: '1st_shift', value: "shift_one"},
        {text: '2nd_shift', value: "shift_two"},
        {text: '3rd_shift', value: "shift_three"},
        {text: 'total', value: "total"},
      ],
      headersM1: [
        {text: 'r_b', value: "num", sortable: false},
        {text: 'meal', value: "name"},
        {text: '1st_shift', value: "shift_one"},
        {text: '2nd_shift', value: "shift_two"},
        {text: '3rd_shift', value: "shift_three"},
        {text: 'total', value: "total"},
      ],
      headersU: [
        {text: this.$t('r_b'), value: "num",},
        {text: "ID", value: "id",},
        {text: this.$t('name'), value: "name",},
        {text: this.$t('last_name'), value: "lastname",},
        {text: this.$t('date') + '/' + this.$t('time'), value: "date",},
        // { text: "Обављена наруџба", value: "ordered",},
        {text: this.$t('shift_one'), value: "shift_one",},
        {text: this.$t('shift_two'), value: "shift_two",},
        {text: this.$t('shift_three'), value: "shift_three",},
        {text: this.$t('ordered'), value: "order_count",},
        // { text: this.$t('taken'), value: "order_payed",},
        {text: this.$t('taken'), value: "order_payed",},
        {text: this.$t('report'), value: "action",},
      ],
      headersP: [
        {text: this.$t('r_b'), value: "num",},
        {text: this.$t('meal'), value: "name"},
        {text: this.$t('1st_shift'), value: "shift_one", sortable: false},
        {text: this.$t('2nd_shift'), value: "shift_two", sortable: false},
        {text: this.$t('3rd_shift'), value: "shift_three", sortable: false},
        {text: this.$t('total'), value: "total"},
      ],
      headersJ: [
        {text: this.$t('r_b'), value: "num",},
        {text: this.$t('m'), value: "menu"},
        {text: this.$t('1st_shift'), value: "shift_one"},
        {text: this.$t('2nd_shift'), value: "shift_two"},
        {text: this.$t('3rd_shift'), value: "shift_three"},
        {text: this.$t('total'), value: "total"},
      ],
      headersG: [
        {text: this.$t('r_b'), value: "num",},
        {text: this.$t('name_surname'), value: "name"},
        {text: this.$t('address'), value: "address"},
        {text: this.$t('time'), value: "time"},
        {text: this.$t('delivery_place'), value: "meal_in_restaurant"},
        {text: this.$t('status'), value: "state"},
        {
          text: this.$t('qty_meal') + " (" + this.$store.state.restaurant !== null && this.$store.state.restaurant.settings ? this.$store.state.restaurant.settings.price : '' + ")",
          value: "qty"
        },
      ],
      headersR: [
        {text: this.$t('r_b'), value: "num",},
        {text: this.$t('name_surname'), value: "name"},
        {text: this.$t('address'), value: "address"},
        {text: this.$t('time'), value: "time"},
        {text: this.$t('status'), value: "state"},
        {
          text: this.$t('qty_meal') + " (" + this.$store.state.restaurant !== null && this.$store.state.restaurant.settings ? this.$store.state.restaurant.settings.price : '' + ")",
          value: "qty"
        },
      ],
      headersN: [
        {text: this.$t('r_b'), value: "num", align: 'start',},
        {text: this.$t('id_order'), value: "id", align: 'start',},
        {text: this.$t('delivery_time'), value: "time", align: 'start',},
        {text: this.$t('meal_meal'), value: "name", align: 'start',},
        {text: this.$t('price'), value: "price", align: 'right',},
        {text: this.$t('quantity'), value: "quantity", align: 'right',},
        {
          text: this.$t('total') + " (" + this.$store.state.restaurant !== null && this.$store.state.restaurant.settings ? this.$store.state.restaurant.settings.price : '' + ")",
          value: "qty",
          align: 'right',
        },
      ],
      headersS: [
        {text: this.$t('r_b'), value: "num",},
        {text: this.$t('meal'), value: "name"},
        {text: this.$t('1st_shift'), value: "shift_one"},
        {text: this.$t('2nd_shift'), value: "shift_two"},
        {text: this.$t('3rd_shift'), value: "shift_three"},
        {text: this.$t('total'), value: "total"},
      ],
      headersS1: [
        {text: this.$t('r_b'), value: "num", width: '10%'},
        {text: this.$t('meal'), value: "name", width: '41%'},
        {text: this.$t('1st_shift'), value: "shift_one", width: '9%'},
        {text: this.$t('2nd_shift'), value: "shift_two", width: '9%'},
        {text: this.$t('3rd_shift'), value: "shift_three", width: '9%'},
        {text: this.$t('total'), value: "total", width: '5%'},
      ],
      headersFi: [
        {text: this.$t('day'), value: "day"},
        {text: this.$t('1st_shift'), value: "shiftOne", align: 'center'},
        {text: this.$t('1st_shift'), value: "shiftOneP", align: 'center'},
        {text: this.$t('2nd_shift'), value: "shiftTwo", align: 'center'},
        {text: this.$t('2nd_shift'), value: "shiftTwoP", align: 'center'},
        {text: this.$t('3rd_shift'), value: "shiftThree", align: 'center'},
        {text: this.$t('3rd_shift'), value: "shiftThreeP", align: 'center'},
        {text: this.$t('total'), value: "total", align: 'center'},
        {text: this.$t('total'), value: "totalP", align: 'center'},
        {text: this.$t('difference'), value: "diff", align: 'center'},
      ],
      headersOrders: [
        {text: this.$t('guest'), value: "name"},
      ],
      headersK: [
        {text: this.$t('r_b'), value: "num",},
        {text: this.$t('restaurant'), value: "restoran"},
        {text: this.$t('meal_num'), value: "meal_num"},
      ],
      headersRe: [
        {text: 'Dan', align: 'start', filterable: false, value: 'day',},
        {text: 'Restoran', align: 'start', filterable: false, value: 'restaurant',},
        {text: 'Firma', align: 'start', filterable: false, value: 'company',},
        {text: 'Naziv', value: 'name'},
        {text: 'Smena 1', value: 'shiftOne.quantity'},
        {text: 'Smena 2', value: 'shiftTwo.quantity'},
        {text: 'Smena 3', value: 'shiftThree.quantity'},
        {text: 'Ukupno', value: 'total'}
      ],
      items: [
        {
          tab: this.$t('groceri'),
          content: "Tab 3 Content",
          print: "table3",
          icon: "mdi-corn",
        },
        {
          tab: this.$t('meals_main'),
          content: "Tab 2 Content",
          print: "table2",
          icon: "mdi-food-drumstick",
        },
        {
          tab: this.$t('meals'),
          content: "Tab 1 Content",
          print: "table1",
          icon: "mdi-bowl-mix",
        },
        {
          tab: 'НАРУЧЕНО',
          content: "Tab 4 Content",
          print: "table34",
          icon: "mdi-grid",
        },
        {
          tab: this.$t('pak'),
          content: "Tab 5 Content",
          print: "table4",
          icon: "mdi-shopping-outline",
        },
        {
          tab: this.$t('meal_worker'),
          content: "Tab 6 Content",
          print: "table6",
          icon: "mdi-format-list-bulleted-type",
        },
        {
          tab: this.$t('taken'),
          content: "Tab 7 Content",
          print: "table5",
          icon: "mdi-truck-delivery",
          heading: this.headers1
        },
        {
          tab: this.$t('employees'),
          content: "Tab 8 Content",
          print: "table7",
          icon: "mdi-account",
          heading: this.headersU,
        },
        {
          tab: this.$t('t_o'),
          content: "Tab 9 Content",
          print: "table8",
          icon: "mdi-check-underline-circle",
        },
        {
          tab: this.$t('r_o'),
          content: "Tab 10 Content",
          print: "table9",
          icon: "mdi-shopping-outline",
        },
        // {
        //   tab: this.$t('companies'),
        //   content: "Tab 9 Content",
        //   print: "table10",
        //   icon: "mdi-company",
        // },
        {
          tab: this.$t('un'),
          content: "Tab 11 Content",
          print: "table9",
          icon: "mdi-shopping-outline",
        },
        // {
        //   tab: "Ресторани",
        //   content: "Tab 9 Content",
        //   print: "table9",
        //   icon: "mdi-shopping-outline",
        // },
      ],
      items1: [
        {
          tab: this.$t('meals_main'),
          content: "Tab 2 Content",
          print: "table2",
          icon: "mdi-food-drumstick",
        },
        {
          tab: this.$t('users'),
          content: "Tab 8 Content",
          print: "table7",
          icon: "mdi-face",
          heading: this.headersU,
        },
        {
          tab: 'НАРУЧЕНО',
          content: "Tab 4 Content",
          print: "table34",
          icon: "mdi-grid",
        },
        {
          tab: this.$t('o'),
          content: "Tab 6 Content",
          print: "table6",
          icon: "mdi-format-list-bulleted-type",
        },
        {
          tab: 'НАРУЧИОЦИ 2',
          content: "Tab 7 Content",
          print: "table7",
          icon: "mdi-format-list-bulleted-type",
        },
        {
          tab: this.$t('restaurants'),
          content: "Tab 7 Content",
          print: "table5555",
          icon: "mdi-format-list-bulleted-type",
        }
      ],
      items2: [
        {
          tab: this.$t('groceri'),
          content: "Tab 3 Content",
          print: "table3",
          icon: "mdi-corn",
        },
        {
          tab: this.$t('meals_main'),
          content: "Tab 2 Content",
          print: "table2",
          icon: "mdi-food-drumstick",
        },
        {
          tab: this.$t('meals'),
          content: "Tab 1 Content",
          print: "table1",
          icon: "mdi-bowl-mix",
        },
        {
          tab: 'НАРУЧЕНО',
          content: "Tab 4 Content",
          print: "table34",
          icon: "mdi-grid",
        },
        {
          tab: this.$t('pak'),
          content: "Tab 5 Content",
          print: "table4",
          icon: "mdi-shopping-outline",
        },
        {
          tab: this.$t('o'),
          content: "Tab 6 Content",
          print: "table6",
          icon: "mdi-format-list-bulleted-type",
        },
        {
          tab: this.$t('taken'),
          content: "Tab 7 Content",
          print: "table5",
          icon: "mdi-truck-delivery",
          // heading: this.headers1
        },
        {
          tab: this.$t('users'),
          content: "Tab 8 Content",
          print: "table7",
          icon: "mdi-face",
          // heading: this.headersU,
        },
        {
          tab: this.$t('t_o'),
          content: "Tab 9 Content",
          print: "table8",
          icon: "mdi-check-underline-circle",
        },
        {
          tab: this.$t('r_o'),
          content: "Tab 10 Content",
          print: "table9",
          icon: "mdi-shopping-outline",
        },
        {
          tab: this.$t('un'),
          content: "Tab 11 Content",
          print: "table9",
          icon: "mdi-shopping-outline",
        },
        {
          tab: this.$t('restaurants'),
          content: "Tab 7 Content",
          print: "table5555",
          icon: "mdi-format-list-bulleted-type",
        },
      ],
      childRestorans: '',
      tab: null,
      newMealAllData: [],
      foodCompanies: [],
      report_food: '',
      reportForFood: '',
      report_food_original: '',
      select_view: 1,
      reportOrginal: '',
      companyFood: [],
      kategorije: '',
      kategorije1: '',
      ukupnoPorudzbina: '',
      foodByOrders: '',
      narudzbe: '',
      menu_oborci: [],
      obrokKojiNijeZaTajDan: '',
      categories: {},
      categoriesByOrder: {},
      narudzbe2: [],
      spec_items: '',
      foodByOrdersOrg: '',
      isRange: true,
      dialogRefresPrint: false,
      over60Seconds: false,
      timer: null,
      margin: {
        top: 100,
        bottom: 100,
        left: 100,
        right: 100,
        size: 16
      },
      printDialog: false,
      excelHeading: [],
      excelRestaurantName: '',
      excelData: '',
      excelTab: '',
      excelComapny: '',
      customExcelFile: '',
      moreRestaurantsData: '',
      companiesMoreRestaurants: [],
      reportMoreRestaurants: ''
    };
  },
  watch: {
    '$i18n.locale'() {
      if (this.$i18n.locale == 'en') {
        this.$forceUpdate()
      }
    },
    date() {
      if (this.date[0] == this.date[1]) {
        // this.headersU.splice(5, 0, {text: this.$t('shifts'), value: "shift"})
      } else {
        // this.headersU.filter((item, index) => {
        //   if (item.value == 'shift') {
        //     this.headersU.splice(index, 1)
        //   }
        // })
      }
    },
    select_view(novi) {
      let indexR = ''
      let indexE = ''
      if (novi == 3) {
        this.headersU.filter((item, index) => {
          if (item.value == 'shift_one') {
            this.headersU.splice(index, 1)
          }
          if (item.value == 'shift_two') {
            this.headersU.splice(index, 1)
          }
          if (item.value == 'shift_three') {
            this.headersU.splice(index, 1)
          }
        })
        this.loadMealsForRestaurantFirms()
      }
      if (novi == 2) {
        this.headersU.filter((item, index) => {
          if (item.value == 'shift_one') {
            this.headersU.splice(index, 1)
          }
          if (item.value == 'shift_two') {
            this.headersU.splice(index, 1)
          }
          if (item.value == 'shift_three') {
            this.headersU.splice(index, 1)
          }
        })
        this.printTable = 'table2'
        this.items.filter(item => {
          if (item.tab == 'Preuzeto' || item.tab == 'Преузето') {
            item.tab = this.$t('users')
          }
        })
        // this.items.splice(0, 1)
        // this.items.splice(1, 1)
        // this.items.splice(2, 1)
        // this.items.splice(2, 1)
        // this.items.splice(3, 1)
        // this.items.splice(3, 1)
        // this.items.splice(3, 1)
        // this.items.splice(3, 1)
        // this.items.splice(3, 1)
        // this.items.splice(1, 1)
        // this.items.splice(2, 0, {
        //   tab: 'НАРУЧЕНО',
        //   content: "Tab 7 Content",
        //   print: "table33",
        //   icon: "mdi-grid",
        // })
        // this.items.splice(3, 0, {
        //   tab: this.$t('o'),
        //   content: "Tab 6 Content",
        //   print: "table6",
        //   icon: "mdi-format-list-bulleted-type",
        // })
        // this.items.splice(11, 0, {
        //   tab: 'НАРУЧИОЦИ II',
        //   content: "Tab 7 Content",
        //   print: "table555",
        //   icon: "mdi-format-list-bulleted-type",
        // })
        // this.items.splice(11, 0, {
        //   tab: this.$t('restaurants'),
        //   content: "Tab 7 Content",
        //   print: "table5555",
        //   icon: "mdi-format-list-bulleted-type",
        // })
        // this.headersU.splice(5, 0, {
        //   text: this.$t('meals_main'),
        //   value: "meals"
        // })
      } else {
        this.headersU.filter((item, index) => {
          if (item.value == 'meals') {
            indexE = index
          }
        })
        if (indexE != '') {
          this.headersU.splice(indexE, 1)
        }
        this.headersU.filter((item, index) => {
          if (item.value == 'shift_one') {
            this.headersU.splice(index, 1)
          }
          if (item.value == 'shift_two') {
            this.headersU.splice(index, 1)
          }
          if (item.value == 'shift_three') {
            this.headersU.splice(index, 1)
          }
        })
        // this.items.splice(0, 0, {
        //   tab: this.$t('groceri'),
        //   content: "Tab 3 Content",
        //   print: "table3",
        //   icon: "mdi-corn",
        // })
        // this.items.splice(2, 0, {
        //   tab: this.$t('meals'),
        //   content: "Tab 1 Content",
        //   print: "table1",
        //   icon: "mdi-bowl-mix",
        // })
        // this.items.splice(3, 0, {
        //   tab: 'НАРУЧЕНО',
        //   content: "Tab 4 Content",
        //   print: "table33",
        //   icon: "mdi-grid",
        // })
        // this.items.splice(4, 0, {
        //   tab: this.$t('pak'),
        //   content: "Tab 3 Content",
        //   print: "table4",
        //   icon: "mdi-shopping-outline",
        // })
        // this.items.splice(5, 0, {
        //   tab: this.$t('o'),
        //   content: "Tab 5 Content",
        //   print: "table6",
        //   icon: "mdi-format-list-bulleted-type",
        // })
        // this.items.splice(6, 0, {
        //   tab: this.$t('taken'),
        //   content: "Tab 4 Content",
        //   print: "table5",
        //   icon: "mdi-truck-delivery",
        // })
        // this.items.splice(7, 0, {
        //   tab: this.$t('users'),
        //   content: "Tab 7 Content",
        //   print: "table7",
        //   icon: "mdi-face",
        // })
        // this.items.splice(8, 0, {
        //   tab: this.$t('t_o'),
        //   content: "Tab 8 Content",
        //   print: "table8",
        //   icon: "mdi-check-underline-circle",
        // })
        // this.items.splice(9, 0, {
        //   tab: this.$t('r_o'),
        //   content: "Tab 9 Content",
        //   print: "table9",
        //   icon: "mdi-shopping-outline",
        // })
        // this.items.splice(10, 0, {
        //   tab: this.$t('un'),
        //   content: "Tab 9 Content",
        //   print: "table9",
        //   icon: "mdi-shopping-outline",
        // })
        // this.items.splice(11)
        this.printTable = 'table3'
      }
    },
  },
  methods: {
    loadMealsForRestaurantFirms() {
      axios.get(
        "/reports/daily/more-restaurant/" +
        this.$store.state.restaurant.id +
        "/reports?date=" +
        moment(this.date[0]).format("YYYY-MM-DD") +
        "&date2=" +
        moment(this.date[1]).format("YYYY-MM-DD")
      ).then((res) => {
        if (res.data) {
          this.moreRestaurantsData = res.data;
          this.reportMoreRestaurants = res.data;
          this.report2 = res.data;
          let com = []
          for (let i in res.data) {
            for (let n in res.data[i]) {
              for (let m in res.data[i][n]) {
                if (! com.includes(i)) {
                  this.companiesMoreRestaurants.push({company: i, id: res.data[i][n][m].company_id})
                  com.push(i)
                }
              }
            }
          }
        }
      })
      axios.get(
        "/reports/daily/total-more-restaurants/" +
        this.$store.state.restaurant.id +
        "?date=" +
        moment(this.date[0]).format("YYYY-MM-DD") +
        "&date2=" +
        moment(this.date[1]).format("YYYY-MM-DD")
      ).then((res) => {
        this.total = res.data;
        this.total.filter(item => {
          item.name = item.menu
        })
      })
      this.$forceUpdate()
    },
    setItemsForExcel(item) {
      this.excelData = ''
      this.excelRestaurantName = this.$store.state.restaurant.name
      this.excelHeading = []
      if (item == 0) {
        this.headers.filter(header => {
          this.excelHeading.push(this.$t(header.text))
        })
        this.excelData = this.desserts3
        this.excelTab = this.translate('Намирнице')
      }

      if (item == 1) {
        this.headersJ.filter(header => {
          this.excelHeading.push(this.$t(header.text))
        })
        if (this.select_view == 2) {
          this.excelData = this.dessertsFood
        } else {
          this.excelData = this.desserts2
          this.excelData[this.excelData.length - 1].menu = this.$t('total');
        }
        this.excelTab = this.translate('Јела')
      }

      if (item == 2) {
        this.headersM.filter(header => {
          this.excelHeading.push(this.$t(header.text))
        })
        this.excelTab = this.translate('Оброци')
      }

      if (item == 6) {
        this.headers1.filter(header => {
          if (header.value == "menu") {
            header.text = this.$t('firm')
          }
          if (header.value != "month") {
            this.excelHeading.push(this.$t(header.text))
          }
        })
        this.excelData = this.total
        this.excelData.forEach(obj => {
          delete obj['name'];
          delete obj['settings'];
          delete obj['shift_one1'];
          delete obj['shift_two2'];
          delete obj['shift_three3'];
        });
        this.excelData.map(obj => {
          return {
            id: obj.id,
            menu: obj.menu,
            shift_one: obj.shift_one,
            shift_two: obj.shift_two,
            shift_three: obj.shift_three,
            total: obj.total,
          }
        });
        this.excelTab = this.translate('Преузето')
      }

      if (item == 7) {
        this.headersU.filter(header => {
          if (header.value != "action" && header.value != "shift" && header.value != "id") {
            this.excelHeading.push(this.$t(header.text))
          }
        })
        this.excelTab = this.translate('Корисници')
      }
      this.$forceUpdate()
    },
    exportToExcel(exportFile, firm, custom, customFirm) {
      let data = this.excelData
      let companyName = firm ? this.desserts4.find(id => id.id == firm).company : customFirm != '' ? customFirm : ''
      let heading = this.excelHeading
      if (custom && custom != '') {
        let podaci = []
        let podaci1 = []
        let podaci2 = []
        let test = custom
        test.filter(header => {
          if (header.value == 'shiftOneP' || header.value == 'shiftTwoP' || header.value == 'shiftThreeP' || header.value == 'totalP') {
            podaci.push(this.$t(header.text) + ' ' + this.$t('taken'))
            podaci1.push(this.$t(header.text) + ' ' + this.$t('taken'))
          } else if (header.value == 'shiftOne' || header.value == 'shiftTwo' || header.value == 'shiftThree' || header.value == 'total') {
            podaci.push(this.$t(header.text) + ' ' + this.$t('ordered_1'))
            podaci1.push(this.$t(header.text) + ' ' + this.$t('ordered_1'))
          } else {
            podaci.push(this.$t(header.text))
            podaci1.push(this.$t(header.text))
          }
        })
        if (this.ordered_meals) {
          test.filter(item => {
            if (item.value == 'day' || item.value == "shiftOne" || item.value == 'shiftTwo' || item.value == 'shiftThree' || item.value == 'total') {
              podaci2.push(this.$t(item.text) + ' ' + this.$t('ordered_1'))
            }
          })
        }
        if (this.taken_meals) {
          test.filter(item1 => {
            if (item1.value == 'day' || item1.value == 'shiftOneP' || item1.value == 'shiftTwoP' || item1.value == 'shiftThreeP' || item1.value == 'totalP') {
              podaci2.push(this.$t(item1.text) + ' ' + this.$t('taken'))
            }
          })
        }
        heading = podaci2
        if (this.ordered_meals && this.taken_meals) {
          heading = podaci1
        }
      }
      if (exportFile && exportFile != '') {
        let prepData = []
        exportFile.filter(item => {
          item.diff = item.total - item.totalP
        })
        if (this.ordered_meals) {
          exportFile.filter(item => {
            if (item.day || item.shiftOne || item.shiftTwo || item.shiftThree || item.total) {
              prepData.push({
                'day': item.day,
                'shiftOne': item.shiftOne,
                'shiftTwo': item.shiftTwo,
                'shiftThree': item.shiftThree,
                'total': item.total,
              })
            }
          })
        }
        if (this.taken_meals) {
          exportFile.filter(item1 => {
            if (item1.day || item1.shiftOneP || item1.shiftTwoP || item1.shiftThreeP || item1.totalP) {
              prepData.push({
                'day': item1.day,
                'shiftOne': item1.shiftOneP,
                'shiftTwo': item1.shiftTwoP,
                'shiftThree': item1.shiftThreeP,
                'total': item1.totalP,
              })
            }
          })
        }
        data = prepData
        if (this.ordered_meals && this.taken_meals) {
          data = exportFile
        }
        // data = exportFile
      }
      if (this.tab == 2 && this.search4 && this.search4 != '' && this.search4.length > 0) {
        companyName = this.search4
      } if (this.tab == 7 && this.search3 && this.search3 != '') {
        companyName = this.foodCompanies.find(id => id.id == this.search3).company
      } else if (this.excelComapny) {
        companyName = this.excelComapny[0]
      }
      let restaurantName = this.excelRestaurantName
      let datum = this.date[0]
      let tab = this.excelTab
      axios.post('/reports/export-excel', {
        data: data,
        firm: companyName.length >= 1 ? companyName : companyName.toUpperCase(),
        heading: heading,
        restaurant: restaurantName.toUpperCase(),
        date: datum,
        tab: tab
      }, { responseType: 'blob' })
        .then(response => {
          const url = window.URL.createObjectURL(new Blob([response.data]));
          const link = document.createElement('a');
          link.href = url;
          link.setAttribute('download', (companyName ? companyName.length > 2 ? 'report' : companyName : restaurantName) +'.xlsx');
          document.body.appendChild(link);

          link.click();
          // Clean up
          window.URL.revokeObjectURL(url);
          document.body.removeChild(link);
        })
        .catch(error => {
          console.error("There was an error downloading the file!", error);
        });
    },
    reloadPage() {
      this.load()
      this.dialogRefresPrint = false
      this.meals = ''
    },
    removeFood(food, inx, index, ind) {
      axios.post('/orders/delete-food-not-in-order', food).then(({data}) => {
        this.searchFoodsByShift[inx][index].splice(ind, 1)
        this.$forceUpdate()
      })
    },
    onResize() {
      this.windowSize = {x: window.innerWidth, y: window.innerHeight}
    },
    chooseFoodFirmReport(firm) {
      this.headersOrders = []
      this.headersOrders.push(
          {text: this.$t('guest'), value: "name"},
      )
      if (this.searchFoodFirm != '') {
        this.searchFoodShift = Object.keys(this.filterFoodByCompanyOrders)[0]
      }

      this.$forceUpdate()
    },
    chooseFoodFirm() {
      if (this.searchFoodFirm != '') {
        this.searchFoodShift = Object.keys(this.filterFoodByCompanyOrders)[0]
        if (this.foodShifts.length) {
          this.searchByShift = this.foodShifts[0]
        }
      }
    },
    foodOrdersOrdered() {
      axios.get('/reports/daily/food-orders-ordered/' +
          this.$store.state.restaurant.id +
          "?date=" +
          moment(this.date[0]).format("YYYY-MM-DD") +
          "&date2=" +
          moment(this.date[1]).format("YYYY-MM-DD")
      ).then(({data}) => {
        let smena = this.groupBy(data, 'shift')
        let priprema = {}
        for (let i in smena) {
          priprema[i] = this.groupBy(smena[i], 'user_id')
        }
        this.foodByOrders = data
        this.foodByOrdersOrg = data
      })
    },
    totalByColumn() {
      let grupa = []
      let podaci = {}
      for (let dayData in this.kategorije1) {
        for (let shiftData in this.kategorije1[dayData]) {
          grupa.push(this.kategorije1[dayData][shiftData])
          for (let n in this.kategorije1[dayData][shiftData]) {
            podaci[n] = []
          }
        }
      }
      grupa.filter(item => {
        for (let i in item) {
          podaci[i].push(item[i])
        }
      })
      let priprema = {}
      for (let n in podaci) {
        priprema[n] = []
        podaci[n].filter(item => {
          item.filter(i => {
            priprema[n].push(i.quantity)
          })
        })
      }
      let totalPoKoloni = {}
      for (let t in priprema) {
        totalPoKoloni[t] = 0
      }
      for (let t in priprema) {
        priprema[t].filter(ukupno => {
          totalPoKoloni[t] += ukupno
        })
      }
      return totalPoKoloni
    },
    totalFoodByRow(data) {
      let total = 0
      for (let cat in data) {
        if (data[cat].length) {
          data[cat].filter(item => {
            total += item.quantity
          })
        }
      }
      return total
    },
    filterFoodByCompany() {
      let foods = {}
      let porudzbeHrana = []
      this.reportForFood = ''
      this.searchFoodCompany.filter(company => {
        for (let shift in this.report_food_original) {
          if (company == shift) {
            foods[shift] = {}
            for (let shift2 in this.report_food_original[shift]) {
              foods[shift][shift2] = {}
              for (let shift3 in this.report_food_original[shift][shift2]) {
                foods[shift][shift2][shift3] = this.report_food_original[shift][shift2][shift3]
              }
            }
          }
        }
        let foodss = this.foodByOrdersOrg
        foodss.filter(item => {
          if (company == item.company_id) {
            porudzbeHrana.push(item)
          }
        })
      })
      this.reportForFood = foods
      this.foodByOrders = porudzbeHrana
      this.$forceUpdate()
    },
    changeView() {
      if (this.select_view == 1) {
        this.report = this.reportOrginal
      }
      if (this.select_view == 2) {
        this.reportForFood = this.report_food
      }
      if (this.select_view == 3) {
        let izvestaj = {}
        if (this.reportOrginal instanceof Object) {
          for (let shift in this.reportOrginal) {
            if (this.report_food[shift]) {
              izvestaj[shift] = this.reportOrginal[shift].concat(this.report_food[shift]);
            } else {
              izvestaj[shift] = this.reportOrginal[shift]
            }
          }
        } else {
          for (let shift in this.report_food) {
            for (let shift1 in this.report_food[shift]) {
              izvestaj[shift] = this.report_food[shift][shift1]
            }
          }
        }
        this.report = izvestaj
      }
      this.load()
      this.$forceUpdate()
    },
    openShift(index, type) {
      if (type == 1) {
        this.shift_one_price[index] = true
      }
      if (type == 2) {
        this.shift_two_price[index] = true
      }
      if (type == 3) {
        this.shift_three_price[index] = true
      }
      this.$forceUpdate()
    },
    closeShift(index, type, item) {
      if (type == 1) {
        this.shift_one_price[index] = false
      }
      if (type == 2) {
        this.shift_two_price[index] = false
      }
      if (type == 3) {
        this.shift_three_price[index] = false
      }
      let data = {}
      data['user'] = this.$store.state.user.id
      data['company'] = this.newMealData.id
      data['meal'] = item
      data['shift'] = type
      let shift = {}
      shift[1] = 'shift_one'
      shift[2] = 'shift_two'
      shift[3] = 'shift_three'
      axios.post('/orders/add-additional-meal', data).then(({data}) => {
        if (data) {
          this.addNewMeal(this.newMealData, this.$store.state.restaurant.id)
          this.$emit('refreshMeal')
        }
      })
      this.$forceUpdate()
    },
    addNewMeal(comapny, restoran_id) {
      this.dialogAddMeal = true
      this.newMealData = comapny
      let data = {}
      data['company_id'] = comapny
      data['restaurant_id'] = restoran_id
      data['date'] = this.date[0]
      data['user'] = this.$store.state.user.id
      axios.post('/menus/get-menu-for-current-date', data).then(({data}) => {
        this.newMealAllData = data
        let total1 = ''
        let total = 0
        let totalOne = 0
        let totalTwo = 0
        let totalThree = 0
        data.filter((item, index) => {
          total1 = item.orders.shift_one + item.orders.shift_two + item.orders.shift_three
          total += item.orders.shift_one + item.orders.shift_two + item.orders.shift_three
          totalOne += item.orders.shift_one
          totalTwo += item.orders.shift_two
          totalThree += item.orders.shift_three
          item.orders.total = total1
          this.shift_one_price.push(false)
          this.shift_two_price.push(false)
          this.shift_three_price.push(false)
        })
        this.newMealAllData.push({
          'name': this.$t('totalU'),
          'orders': {
            'shift_one': totalOne,
            'shift_two': totalTwo,
            'shift_three': totalThree,
            'total': total,
          }
        })

      })
      this.$forceUpdate()
    },
    filterDayF() {
      if (this.allDay) {
        this.dessertsReportF = [...this.dessertsReportAll]
      } else {
        this.dessertsReportF = this.dessertsReportAll.filter(item => {
          if (item.shiftOne && item.shiftOne.length) {
            return item
          }
          if (item.shiftTwo && item.shiftTwo.length) {
            return item
          }
          if (item.shiftThree && item.shiftThree.length) {
            return item
          }
        })
        //this.dessertsReport = this.dessertsReportAll
      }
      this.$forceUpdate()
    },
    filterDay() {
      if (this.allDay) {
        this.dessertsReport = [...this.dessertsReportAll]
      } else {
        this.dessertsReport = this.dessertsReportAll.filter(item => {
          if (item.shiftOne && item.shiftOne.length) {
            return item
          }
          if (item.shiftTwo && item.shiftTwo.length) {
            return item
          }
          if (item.shiftThree && item.shiftThree.length) {
            return item
          }
        })
        //this.dessertsReport = this.dessertsReportAll
      }
      this.$forceUpdate()
    },
    filterDayFirm() {
      if (this.allDayFirm) {
        this.firmReports = this.firmsReportsAll
      } else {
        this.firmReports = this.firmReportsAll
      }
      this.$forceUpdate()
    },
    loadReport(item, food) {
      if (food != '') {
        this.loading = true
        axios.post('/user/food-orders-by-month', {
          month: this.month,
          year: this.year,
          user_id: item.id
        }).then(res => {
          this.dessertsRF = res.data
          let categories = this.groupBy(res.data, 'category_name')
          let datum = this.groupBy(res.data, 'date')
          let podaci = {}
          let ukupnoPorudzbina = {}
          let data = {}
          for (let date in datum) {
            let dan = moment(date).format('D')
            data[dan] = {}
            ukupnoPorudzbina[dan] = {}
            podaci[dan] = this.groupBy(datum[date], 'shift')
            for (let shift in podaci[dan]) {
              data[dan][shift] = {}
              ukupnoPorudzbina[dan][shift] = {}
              let sortirano = Object.keys(categories).slice().sort((a, b) => a.localeCompare(b))
              sortirano.filter(category => {
                data[dan][shift][category] = []
              })
              podaci[dan][shift].filter(it => {
                if (data[dan][shift][it.category_name]) {
                  data[dan][shift][it.category_name].push(it)
                }
              })
              for (let cat in data[dan][shift]) {
                ukupnoPorudzbina[dan][shift] = this.groupBy(data[dan][shift][cat], 'order_food_id')
              }
            }
          }
          this.ukupnoPorudzbina = ukupnoPorudzbina
          this.kategorije = Object.keys(categories).slice().sort((a, b) => a.localeCompare(b))
          this.kategorije1 = data
          this.report3F()
        }).finally(() => {
          this.loading = false
        })
      } else {
        this.loading = true
        axios.post('/user/orders-by-month', {
          month: this.month,
          year: this.year,
          user_id: item.id
        }).then(res => {
          this.dessertsR = res.data
          this.report3()
        }).finally(() => {
          this.loading = false
        })
      }
    },
    loadReportMoreRestaurants(item) {
      this.loading = true
      axios.post('/user/more-restaurants-orders-by-month', {
        month: this.month,
        year: this.year,
        user_id: item.id
      }).then(res => {
        this.dessertsR = res.data
        this.report3()
      }).finally(() => {
        this.loading = false
      })
    },
    report3F() {
      this.dessertsReportF = []
      for (let day = 1; day <= this.daysInMonth; day++) {
        let checks = this.dessertsRF.filter(item => {
          return item.date === moment(this.year + '-' + this.month + '-' + day).format('YYYY-MM-DD')
        })
        if (checks.length) {
          checks.forEach(check => {
            let index = this.dessertsReportF.findIndex(item => item.day === day)
            if (check.shift === 1) {
              if (index > 0) {
                if (!this.dessertsReportF[index].shiftOne) this.dessertsReportF[index].shiftOne = []
                this.dessertsReportF[index].shiftOne.push(check)
                this.dessertsReportF[index].totals = this.dessertsReportF[index].shiftOne.reduce((sum, item) => {
                  return sum + item.quantity;
                }, 0)
              } else {
                this.dessertsReportF.push({day: day, shiftOne: [check], totals: check.quantity})
              }
            } else if (check.shift === 2) {
              if (index > 0) {
                if (!this.dessertsReportF[index].shiftTwo) this.dessertsReportF[index].shiftTwo = []
                this.dessertsReportF[index].shiftTwo.push(check)
                this.dessertsReportF[index].totals = this.dessertsReportF[index].shiftTwo.reduce((sum, item) => {
                  return sum + item.quantity;
                }, 0)
              } else {
                this.dessertsReportF.push({day: day, shiftTwo: [check], totals: check.quantity})
              }
            } else if (check.shift === 3) {
              if (index > 0) {
                if (!this.dessertsReportF[index].shiftThree) this.dessertsReportF[index].shiftThree = []
                this.dessertsReportF[index].shiftThree.push(check)
                this.dessertsReportF[index].totals = this.dessertsReportF[index].shiftThree.reduce((sum, item) => {
                  return sum + item.quantity;
                }, 0)
              } else {
                this.dessertsReportF.push({day: day, shiftThree: [check], totals: check.quantity})
              }
            }
          })
        } else {
          this.dessertsReportF.push({day, shiftOne: [], shiftTwo: [], shiftThree: [], totals: 0})
        }
      }
      this.dessertsReportAll = [...this.dessertsReportF]
      this.filterDayF()
    },
    report3() {
      this.dessertsReport = []
      for (let day = 1; day <= this.daysInMonth; day++) {
        let checks = this.dessertsR.filter(item => {
          return item.date === moment(this.year + '-' + this.month + '-' + day).format('YYYY-MM-DD')
        })
        if (checks.length) {
          checks.forEach(check => {
            let index = this.dessertsReport.findIndex(item => item.day === day)
            if (check.shift === 1) {
              if (index > 0) {
                if (!this.dessertsReport[index].shiftOne) this.dessertsReport[index].shiftOne = []
                this.dessertsReport[index].shiftOne.push(check)
                this.dessertsReport[index].totals = this.dessertsReport[index].shiftOne.reduce((sum, item) => {
                  return sum + item.quantity;
                }, 0)
              } else {
                this.dessertsReport.push({day: day, shiftOne: [check], totals: check.quantity})
              }
            } else if (check.shift === 2) {
              if (index > 0) {
                if (!this.dessertsReport[index].shiftTwo) this.dessertsReport[index].shiftTwo = []
                this.dessertsReport[index].shiftTwo.push(check)
                this.dessertsReport[index].totals = this.dessertsReport[index].shiftTwo.reduce((sum, item) => {
                  return sum + item.quantity;
                }, 0)
              } else {
                this.dessertsReport.push({day: day, shiftTwo: [check], totals: check.quantity})
              }
            } else if (check.shift === 3) {
              if (index > 0) {
                if (!this.dessertsReport[index].shiftThree) this.dessertsReport[index].shiftThree = []
                this.dessertsReport[index].shiftThree.push(check)
                this.dessertsReport[index].totals = this.dessertsReport[index].shiftThree.reduce((sum, item) => {
                  return sum + item.quantity;
                }, 0)
              } else {
                this.dessertsReport.push({day: day, shiftThree: [check], totals: check.quantity})
              }
            }
          })
        } else {
          this.dessertsReport.push({day, shiftOne: [], shiftTwo: [], shiftThree: [], totals: 0})
        }
      }
      this.dessertsReportAll = [...this.dessertsReport]
      this.filterDay()
    },
    report3Firm() {
      this.firmsReportsAll = {}
      for (let day = 1; day <= this.daysInMonth; day++) {
        let checks = Object.keys(this.firmReports).filter(item => {
          return item === moment(this.year + '-' + this.month + '-' + day).format('YYYY-MM-DD')
        })
        if (checks.length) {
          let dd = moment(this.year + '-' + this.month + '-' + day).format('YYYY-MM-DD')
          this.firmsReportsAll[dd] = []
          this.firmReports[checks].forEach(check => {
            if (check.shift === 1) {
              this.firmsReportsAll[dd].push(check)
            } else if (check.shift === 2) {
              this.firmsReportsAll[dd].push(check)
            } else if (check.shift === 3) {
              this.firmsReportsAll[dd].push(check)
            }
          })
        } else {
          let d = moment(this.year + '-' + this.month + '-' + day).format('YYYY-MM-DD')
          this.firmsReportsAll[d] = []
        }
      }
      // this.dessertsReportAll = [...this.firmReports]
      this.filterDayFirm()
    },
    openDialogReportUser(item, food) {
      this.dialogReport = true
      this.userReportItem = item
      this.printTable = 'table11'
      if (this.select_view == 1) {
        this.loadReport(item, '')
      }
      if (this.select_view == 3) {
        this.loadReportMoreRestaurants(item)
      }
    },
    openDialogReportFirm(item) {
      let settingsObject = ''
      axios.get(
          "/reports/daily/restaurant/" +
          this.$store.state.restaurant.id +
          "/firms-reports?date=" +
          moment(this.date1[0]).format("YYYY-MM-DD") +
          "&date2=" +
          moment(this.date1[1]).format("YYYY-MM-DD") + '&company=' + item.id + '&year=' + this.year + '&month=' + this.month
      ).then((res) => {
        if (res.data) {
          this.firmReports = res.data
          let company_settings = Object.values(res.data);
          let settingsString = company_settings[0][0].settings;
          settingsObject = JSON.parse(JSON.parse(settingsString));
          item.settings = settingsObject
          item.shift_one1 = company_settings[0][0].shift_one
          item.shift_two2 = company_settings[0][0].shift_two
          item.shift_three3 = company_settings[0][0].shift_three
          this.firmReportsAll = res.data
          this.report3Firm()
        }
      });

      this.dialogFirm = true
      this.firmReportItem = item
      this.printTable = 'table15'
    },
    openDialogReportFirmMoreRestaurants(item) {
      let settingsObject = ''
      axios.get(
          "/reports/daily/more-restaurant/" +
          this.$store.state.restaurant.id +
          "/firms-reports?date=" +
          moment(this.date[0]).format("YYYY-MM-DD") +
          "&date2=" +
          moment(this.date[1]).format("YYYY-MM-DD") + '&company=' + item.id + '&year=' + this.year + '&month=' + this.month
      ).then((res) => {
        if (res.data) {
          this.firmReports = res.data
          let company_settings = Object.values(res.data);
          let settingsString = company_settings[0][0].settings;
          settingsObject = JSON.parse(JSON.parse(settingsString));
          item.settings = settingsObject
          item.shift_one1 = company_settings[0][0].shift_one
          item.shift_two2 = company_settings[0][0].shift_two
          item.shift_three3 = company_settings[0][0].shift_three
          this.firmReportsAll = res.data
          this.report3Firm()
        }
      });

      this.dialogFirm = true
      this.firmReportItem = item
      this.printTable = 'table15'
    },
    loadReportFirm(firmReportItem) {
      if (this.select_view == 2) {
        this.kategorije = ''
        this.kategorije1 = ''
        axios.post('/user/food-orders-by-month', {
          month: this.month,
          year: this.year,
          user_id: firmReportItem.id
        }).then(res => {
          this.dessertsRF = res.data
          let categories = this.groupBy(res.data, 'category_name')
          let datum = this.groupBy(res.data, 'date')
          let podaci = {}
          let ukupnoPorudzbina = {}
          let data = {}
          for (let date in datum) {
            let dan = moment(date).format('D')
            data[dan] = {}
            ukupnoPorudzbina[dan] = {}
            podaci[dan] = this.groupBy(datum[date], 'shift')
            for (let shift in podaci[dan]) {
              data[dan][shift] = {}
              ukupnoPorudzbina[dan][shift] = {}
              let sortirano = Object.keys(categories).slice().sort((a, b) => a.localeCompare(b))
              //podaci[dan][shift].slice().sort((a, b) => a.category_name.localeCompare(b.category_name))
              sortirano.filter(category => {
                data[dan][shift][category] = []
              })
              podaci[dan][shift].filter(it => {
                if (data[dan][shift][it.category_name]) {
                  data[dan][shift][it.category_name].push(it)
                }
              })
              for (let cat in data[dan][shift]) {
                ukupnoPorudzbina[dan][shift] = this.groupBy(data[dan][shift][cat], 'order_food_id')
              }
            }
          }
          this.ukupnoPorudzbina = ukupnoPorudzbina
          this.kategorije = Object.keys(categories).slice().sort((a, b) => a.localeCompare(b))
          this.kategorije1 = data
          this.report3F()
        }).finally(() => {
          this.loading = false
        })
      } else {
        axios.get(
            "/reports/daily/restaurant/" +
            this.$store.state.restaurant.id +
            "/firms-reports?date=" +
            moment(this.date1[0]).format("YYYY-MM-DD") +
            "&date2=" +
            moment(this.date1[1]).format("YYYY-MM-DD") + '&company=' + firmReportItem.id + '&year=' + this.year + '&month=' + this.month
        ).then((res) => {
          this.firmReports = res.data
        });
      }
    },
    totalUserQtu(shift) {
      let total = 0;
      shift.filter(item => {
        total += item.quantity
      })

      return total
    },
    capitalizeFirstLetter(str) {
      return str.charAt(0).toUpperCase() + str.slice(1);
    },
    getAllFirmsForReservedMeal() {
      axios.get('/reports/daily/reserved-meals/' + this.$store.state.restaurant.id + '/firms').then((data) => {
        this.reservedItems = data.data
      })
    },
    reservedMeals() {
      axios.get(
          "/reports/daily/restaurant/" +
          this.$store.state.restaurant.id +
          "/rezervni-obroci?company=" + this.search5.join() + "&date=" +
          moment(this.date[0]).format("YYYY-MM-DD") +
          "&date2=" +
          moment(this.date[1]).format("YYYY-MM-DD")
      ).then((data) => {
        this.reservedItemsData = data.data
      })
    },
    getItemText(item) {
      return this.translate(`${item.company}`);
    },
    obrok() {
      let rep = ''
      this.obroci = ''
      this.loading = true

      axios.get(
          "/reports/daily/restaurant/" +
          this.$store.state.restaurant.id +
          "/obrok?company=" + this.search4 + "&date=" +
          moment(this.date[0]).format("YYYY-MM-DD") +
          "&date2=" +
          moment(this.date[1]).format("YYYY-MM-DD")
      ).then((res) => {
        rep = res.data;
        let arr2 = [];
        this.loading = false
        if (res.data) {
          this.excelComapny = this.search4
          for (let company in res.data) {
            arr2.push({company: company, foods: []});
            for (let shift in res.data[company]) {
              for (let shift2 in res.data[company][shift]) {
                let index = arr2.findIndex((i) => i.company === company);
                let index2 = arr2[index].foods.findIndex(
                    (i) => i.name === shift2
                );
                if (index2 > -1) {
                  arr2[index].foods[index2].shift_one += shift === "1-smena" ? rep[company][shift][shift2].quantity : 0;
                  arr2[index].foods[index2].shift_one_payed += shift === "1-smena" ? rep[company][shift][shift2].quantity_payed : 0;
                  arr2[index].foods[index2].shift_two += shift === "2-smena" ? rep[company][shift][shift2].quantity : 0;
                  arr2[index].foods[index2].shift_two_payed += shift === "2-smena" ? rep[company][shift][shift2].quantity_payed : 0;
                  (arr2[index].foods[index2].shift_three += shift === "3-smena" ? rep[company][shift][shift2].quantity : 0),
                  (arr2[index].foods[index2].shift_three_payed += shift === "3-smena" ? rep[company][shift][shift2].quantity_payed : 0),
                  (arr2[index].foods[index2].total += rep[company][shift][shift2].quantity),
                  (arr2[index].foods[index2].total_payed += rep[company][shift][shift2].quantity_payed);
                } else {
                  arr2[index].foods.push({
                    name: shift2,
                    company_id: rep[company][shift][shift2].company_id,
                    user_id: rep[company][shift][shift2].user_id,
                    user: rep[company][shift][shift2].user,
                    id: rep[company][shift][shift2].id,
                    shift_one: shift === "1-smena" ? rep[company][shift][shift2].quantity : 0,
                    shift_one_payed: shift === "1-smena" ? rep[company][shift][shift2].quantity_payed : 0,
                    shift_two: shift === "2-smena" ? rep[company][shift][shift2].quantity : 0,
                    shift_two_payed: shift === "2-smena" ? rep[company][shift][shift2].quantity_payed : 0,
                    shift_three: shift === "3-smena" ? rep[company][shift][shift2].quantity : 0,
                    shift_three_payed: shift === "3-smena" ? rep[company][shift][shift2].quantity_payed : 0,
                    total: rep[company][shift][shift2].quantity,
                    total_payed: rep[company][shift][shift2].quantity_payed,
                  });
                }
              }
            }
          }
          arr2.forEach((company, index) => {
            company.foods.push({
              name: "УКУПНО",
              shift_one: company.foods.reduce((sum, item) => {
                return sum + item.shift_one;
              }, 0),
              shift_one_payed: company.foods.reduce((sum, item) => {
                return sum + item.shift_one_payed;
              }, 0),
              shift_two: company.foods.reduce((sum, item) => {
                return sum + item.shift_two;
              }, 0),
              shift_two_payed: company.foods.reduce((sum, item) => {
                return sum + item.shift_two_payed;
              }, 0),
              shift_three: company.foods.reduce((sum, item) => {
                return sum + item.shift_three;
              }, 0),
              shift_three_payed: company.foods.reduce((sum, item) => {
                return sum + item.shift_three_payed;
              }, 0),
              total:
                company.foods.reduce((sum, item) => {
                  return sum + item.shift_one;
                }, 0) +
                company.foods.reduce((sum, item) => {
                  return sum + item.shift_two;
                }, 0) +
                company.foods.reduce((sum, item) => {
                  return sum + item.shift_three;
                }, 0),
              total_payed:
                company.foods.reduce((sum, item) => {
                  return sum + item.shift_one_payed;
                }, 0) +
                company.foods.reduce((sum, item) => {
                  return sum + item.shift_two_payed;
                }, 0) +
                company.foods.reduce((sum, item) => {
                  return sum + item.shift_three_payed;
                }, 0),
            });
          });
        }
        this.obroci = arr2
        this.$forceUpdate()
      })
    },
    obrokMoreRestaurnats() {
      let rep = ''
      this.obroci = ''
      this.loading = true
      let kompanije = []
      this.companiesMoreRestaurants.filter(company => {
        if (this.search4.includes(this.translate(company.company))) {
          kompanije.push(company.id)
        }
      })
      axios.get(
          "/reports/daily/more-restaurant/" +
          this.$store.state.restaurant.id +
          "/obrok?company=" + kompanije + "&date=" +
          moment(this.date[0]).format("YYYY-MM-DD") +
          "&date2=" +
          moment(this.date[1]).format("YYYY-MM-DD")
      ).then((res) => {
        rep = res.data;
        let arr2 = [];
        this.loading = false
        if (res.data) {
          this.excelComapny = this.search4
          for (let company in res.data) {
            arr2.push({company: company, foods: []});
            for (let shift in res.data[company]) {
              for (let shift2 in res.data[company][shift]) {
                let index = arr2.findIndex((i) => i.company === company);
                let index2 = arr2[index].foods.findIndex(
                    (i) => i.name === shift2
                );
                if (index2 > -1) {
                  arr2[index].foods[index2].shift_one += shift === "1-smena" ? rep[company][shift][shift2].quantity : 0;
                  arr2[index].foods[index2].shift_one_payed += shift === "1-smena" ? rep[company][shift][shift2].quantity_payed : 0;
                  arr2[index].foods[index2].shift_two += shift === "2-smena" ? rep[company][shift][shift2].quantity : 0;
                  arr2[index].foods[index2].shift_two_payed += shift === "2-smena" ? rep[company][shift][shift2].quantity_payed : 0;
                  (arr2[index].foods[index2].shift_three += shift === "3-smena" ? rep[company][shift][shift2].quantity : 0),
                  (arr2[index].foods[index2].shift_three_payed += shift === "3-smena" ? rep[company][shift][shift2].quantity_payed : 0),
                  (arr2[index].foods[index2].total += rep[company][shift][shift2].quantity),
                  (arr2[index].foods[index2].total_payed += rep[company][shift][shift2].quantity_payed);
                } else {
                  arr2[index].foods.push({
                    // name: shift2,
                    name: res.data[company][shift][shift2] && res.data[company][shift][shift2].name ? res.data[company][shift][shift2].name : shift2,
                    user_id: rep[company][shift][shift2].user_id,
                    user: rep[company][shift][shift2].user,
                    id: rep[company][shift][shift2].id,
                    shift_one:
                        shift === "1-smena" ? rep[company][shift][shift2].quantity : 0,
                    shift_one_payed:
                        shift === "1-smena" ? rep[company][shift][shift2].quantity_payed : 0,
                    shift_two:
                        shift === "2-smena" ? rep[company][shift][shift2].quantity : 0,
                    shift_two_payed:
                        shift === "2-smena" ? rep[company][shift][shift2].quantity_payed : 0,
                    shift_three:
                        shift === "3-smena" ? rep[company][shift][shift2].quantity : 0,
                    shift_three_payed:
                        shift === "3-smena" ? rep[company][shift][shift2].quantity_payed : 0,
                    total: rep[company][shift][shift2].quantity,
                    total_payed: rep[company][shift][shift2].quantity_payed,
                  });
                }
              }
            }
          }
          arr2.forEach((company, index) => {
            company.foods.push({
              name: "УКУПНО",
              shift_one: company.foods.reduce((sum, item) => {
                return sum + item.shift_one;
              }, 0),
              shift_one_payed: company.foods.reduce((sum, item) => {
                return sum + item.shift_one_payed;
              }, 0),
              shift_two: company.foods.reduce((sum, item) => {
                return sum + item.shift_two;
              }, 0),
              shift_two_payed: company.foods.reduce((sum, item) => {
                return sum + item.shift_two_payed;
              }, 0),
              shift_three: company.foods.reduce((sum, item) => {
                return sum + item.shift_three;
              }, 0),
              shift_three_payed: company.foods.reduce((sum, item) => {
                return sum + item.shift_three_payed;
              }, 0),
              total:
                company.foods.reduce((sum, item) => {
                  return sum + item.shift_one;
                }, 0) +
                company.foods.reduce((sum, item) => {
                  return sum + item.shift_two;
                }, 0) +
                company.foods.reduce((sum, item) => {
                  return sum + item.shift_three;
                }, 0),
              total_payed:
                company.foods.reduce((sum, item) => {
                  return sum + item.shift_one_payed;
                }, 0) +
                company.foods.reduce((sum, item) => {
                  return sum + item.shift_two_payed;
                }, 0) +
                company.foods.reduce((sum, item) => {
                  return sum + item.shift_three_payed;
                }, 0),
            });
          });
        }
        this.obroci = arr2
      })
    },
    priceDelivery(meals) {
      let price = 0
      if (meals) {
        meals.filter(meal => {
          price += meal.quantity * meal.price
        })
      }
      return price
    },
    formatDateFood(date) {
      return moment(date).format('DD.MM. - hh:mm')
    },
    formatDate(date) {
      return moment(date).format('DD.MM.YYYY.')
    },
    formatTime(time) {
      if (time) {
        let formatedTime = time.split(':')
        return formatedTime[0] + ':' + formatedTime[1]
      }
    },
    changeOrderState(item) {
      axios.post('/reports/change-order-status', item).then(({data}) => {
        if (data) {
          this.change_order_status_popup = false
        }
      })
    },
    priceMeals(meal, item) {
      let total = 0;
      meal.filter(m => {
        if (m.prilog && m.prilog.length) {
          m.prilog.filter(pr => {
            total += (parseFloat(pr.price) * pr.quantity)
          })
        }
        if (m.meals && m.meals.price) {
          total += (parseFloat(m.meals.price) * m.quantity)
        } else if (m.food && m.food.price) {
          total += (parseFloat(m.food.price) * m.quantity)
        }
      })
      return total
    },
    toogleFirms() {
      if (this.search.length > 0) {
        this.search = []
      } else if (this.search.length == 0) {
        this.search = []
        this.desserts4.filter(item => {
          // this.search.push(item.company)
          this.search.push(item.id)
        })
      }
    },
    toogleAllFoods() {
      if (this.searchFoodCompany.length > 0) {
        this.searchFoodCompany = []
        this.filterFoodByCompany()
      } else if (this.searchFoodCompany.length == 0) {
        this.searchFoodCompany = []
        // for(let company in this.report_food_original) {
        //   this.searchFoodCompany.push(company)
        // }
        this.report_food_original.companies.filter(com => {
          this.searchFoodCompany.push(com.id)
        })
        this.filterFoodByCompany()
      }
      this.$forceUpdate()
    },
    toogleFirmsObroci() {
      if (this.search4.length > 0) {
        this.search4 = []
      } else if (this.search4.length == 0) {
        this.search4 = []
        this.desserts4.filter(item => {
          this.search4.push(this.translate(item.id))
        })
        this.obrok()
      }
    },
    toogleFirmsObrociMoreRestaurants() {
      if (this.search4.length > 0) {
        this.search4 = []
      } else if (this.search4.length == 0) {
        this.search4 = []
        this.desserts4.filter(item => {
          this.search4.push(item.company)
        })
        this.obrokMoreRestaurnats()
      }
    },
    toogleFirmsReserve() {
      if (this.search5.length > 0) {
        this.search5 = []
      } else if (this.search5.length == 0) {
        this.search5 = []
        this.desserts6.filter(item => {
          this.search5.push(item.company)
        })
      }
    },
    selectAllFirms(item) {
      if (item == 'Оброци ') {
        this.search = []
        this.desserts4.filter(item => {
          this.search.push(item.company)
        })
      }
      if ((item == 'Паковање' || item == 'Pakovanje') && this.date[0] == this.date[1]) {
        this.canSeeAddMeal = true
      }
      if (item == 'Поручиоци') {
        this.search2 = ""
      }
      // if(item == 'Резервни оброци') {
      //   this.getAllFirmsForReservedMeal()
      // }
      this.$forceUpdate()
    },
    showMeal(id) {
      axios.get("/meals/" + id).then((res) => {
        this.selectedMeal = res.data;
        this.dialog = true;
        // //console.log(this.selectedMeal);
      });
    },
    showUsers(user) {
      this.selectedUser = user;
      ////console.log(this.selectedUser);
      this.dialog2 = true;
    },
    toggle() {
      if (this.tab == 3) {
        if (this.search2.length > 0) {
          this.search2 = []
          this.findFoods()
        } else if (this.search2.length == 0) {
          this.search2 = []
          this.report_food_original.companies.filter(com => {
            this.search2.push(com.id)
          })
          this.findFoods()
        }
      } else {
        if (this.search2.length === this.desserts4.filter((i) => i.company).length) {
          this.search2 = [];
        } else {
          this.search2 = []
          this.search2 = this.desserts4
              .filter((i) => i.company)
              .map((i) => i.id);
        }
        this.findMeals();
      }
    },
    findUsersByCompany() {
      this.headersOrders = [
        {text: this.$t('guest'), value: "name"},
        {text: '', value: "food"},
      ]
      let comp = this.foodCompanies.find(item => item.id == this.search8).company
      this.categories[comp].sort()
      this.categories[comp].filter(item => {
        this.headersOrders.push({
          text: this.translate(item),
          value: item,
        })
      })
      axios.get("/reports/daily/company/" + this.search8 + "/food-korisnici-by-day?date=" + moment(this.date[0]).format("YYYY-MM-DD") + "&date2=" + moment(this.date[1]).format("YYYY-MM-DD"))
          .then(({data}) => {
            this.narudzbe2 = data
            this.searchByShift = Object.keys(this.narudzbe2)[0]
          })
    },
    findUsers(food) {
      this.excelData = ''
      if (food != '') {
        axios
            .get(
                "/reports/daily/company/" + this.search3 + "/food-korisnici?date=" + moment(this.date[0]).format("YYYY-MM-DD") + "&date2=" + moment(this.date[1]).format("YYYY-MM-DD")
            )
            .then((res) => {
              let ids = []
              let order_count_total = 0;
              let order_payed_total = 0;
              let order_meals_total = 0;
              res.data.users.filter(user => {
                order_count_total += user.order_count
                order_payed_total += user.order_payed
                order_meals_total += user.meals
              })
              res.data.users.push({
                'id': 'УКУПНО:',
                'name': ' ',
                'lastname': ' ',
                'date': ' ',
                'order_count': order_count_total,
                'order_payed': order_payed_total,
                'meals': order_meals_total
              })
              this.users = res.data.users;
              res.data.users.filter(item => {
                this.narudzbe2.push(item)
              })
            });
      } else {
        axios
          .get(
              "/reports/daily/company/" + this.search3 + "/korisnici?date=" + moment(this.date[0]).format("YYYY-MM-DD") + "&date2=" + moment(this.date[1]).format("YYYY-MM-DD")
          )
          .then((res) => {
            let ids = []
            let order_count_total = 0;
            let order_payed_total = 0;
            let shift_one = 0;
            let shift_two = 0;
            let shift_three = 0;
            res.data.users.filter(user => {
              order_count_total += user.order_count
              order_payed_total += user.order_payed
              shift_one += user.shift_one
              shift_two += user.shift_two
              shift_three += user.shift_three
            })
            if (res.data.credit && res.data.credit != 0) {
              let exists = this.headersU.some(header => header.value === "credit");
              if (!exists) {
                this.headersU.splice(10, 0,
                    {text: this.$t('credit_only'), value: "credit"}
                );
              }
            }
            res.data.users.push({
              'id': 'УКУПНО:',
              'name': ' ',
              'lastname': ' ',
              'date': ' ',
              'shift_one': shift_one,
              'shift_two': shift_two,
              'shift_three': shift_three,
              'order_count': order_count_total,
              'order_payed': order_payed_total
            })
            this.users = res.data.users;
            if (this.tab == 7) {
              this.excelData = this.users
              this.excelData.forEach(obj => {
                obj.date = obj.date && obj.date != ' ' ? moment(obj.date).format('DD.MM.  HH:mm') : ''
                delete obj['ordered'];
                delete obj['phone'];
                delete obj['shift'];
              });
            }
            this.$forceUpdate()
          });
      }
    },
    findUsersMoreRestaurants() {
      this.excelData = ''
      axios
      .get("/reports/daily/company-more-restaurants/" + this.search3 + "/korisnici?date=" + moment(this.date[0]).format("YYYY-MM-DD") + "&date2=" + moment(this.date[1]).format("YYYY-MM-DD"))
      .then((res) => {
        let ids = []
        let order_count_total = 0;
        let order_payed_total = 0;
        let shift_one = 0;
        let shift_two = 0;
        let shift_three = 0;
        res.data.users.filter(user => {
          order_count_total += user.order_count
          order_payed_total += user.order_payed
          shift_one += user.shift_one
          shift_two += user.shift_two
          shift_three += user.shift_three
        })
        if (res.data.credit && res.data.credit != 0) {
          let exists = this.headersU.some(header => header.value === "credit");
          if (!exists) {
            this.headersU.splice(10, 0,
                {text: this.$t('credit_only'), value: "credit"}
            );
          }
        }
        res.data.users.push({
          'id': 'УКУПНО:',
          'name': ' ',
          'lastname': ' ',
          'date': ' ',
          'shift_one': shift_one,
          'shift_two': shift_two,
          'shift_three': shift_three,
          'order_count': order_count_total,
          'order_payed': order_payed_total
        })
        this.users = res.data.users;
        if (this.tab == 7) {
          this.excelData = this.users
          this.excelData.forEach(obj => {
            obj.date = obj.date && obj.date != ' ' ? moment(obj.date).format('DD.MM.  HH:mm') : ''
            delete obj['ordered'];
            delete obj['phone'];
            delete obj['shift'];
          });
        }
        this.$forceUpdate()
      });
    },
    findFoods() {
      if (this.search2.length) {
        this.meals = ''
        axios.get(
            "/reports/daily/company/" +
            this.search2.join() +
            "/userlistfood?date=" +
            moment(this.date[0]).format("YYYY-MM-DD") +
            "&date2=" +
            moment(this.date[1]).format("YYYY-MM-DD")
        ).then((res) => {
          let t = {}
          let korisnici = []
          for (let i in res.data) {
            if (i == 'company') {
              t[res.data.company.name] = res.data
            } else if (res.data[i].company) {
              t[res.data[i].company.name] = res.data[i]
            }
            if (res.data.meals) {
              res.data.meals.filter(meal => {
                if (meal.name != 'УКУПНО') {
                  if (meal.users['1-smena']) {
                    meal.users['1-smena'].filter(user => {
                      korisnici.push({
                        'meal_name': meal.name,
                        'quantity': user.quantity,
                        'shift': 1,
                        'name': user.name,
                        'lastname': user.lastname,
                        'user_id': user.id,
                        'order_id': user.order_id
                      })
                    })
                  } else if (meal.users['1']) {
                    meal.users['1-smena'] = []
                    meal.users['1'].filter(user => {
                      meal.users['1-smena'].push(user)
                      korisnici.push({
                        'meal_name': meal.name,
                        'quantity': user.quantity,
                        'shift': 1,
                        'name': user.name,
                        'lastname': user.lastname,
                        'user_id': user.id,
                        'order_id': user.order_id
                      })
                    })
                  }
                  if (meal.users['2-smena']) {
                    meal.users['2-smena'].filter(user => {
                      korisnici.push({
                        'meal_name': meal.name,
                        'quantity': user.quantity,
                        'shift': 2,
                        'name': user.name,
                        'lastname': user.lastname,
                        'user_id': user.id,
                        'order_id': user.order_id
                      })
                    })
                  } else if (meal.users['2']) {
                    meal.users['2-smena'] = []
                    meal.users['2'].filter(user => {
                      meal.users['2-smena'].push(user)
                      korisnici.push({
                        'meal_name': meal.name,
                        'quantity': user.quantity,
                        'shift': 1,
                        'name': user.name,
                        'lastname': user.lastname,
                        'user_id': user.id,
                        'order_id': user.order_id
                      })
                    })
                  }
                  if (meal.users['3-smena']) {
                    meal.users['3-smena'].filter(user => {
                      korisnici.push({
                        'meal_name': meal.name,
                        'quantity': user.quantity,
                        'shift': 3,
                        'name': user.name,
                        'lastname': user.lastname,
                        'user_id': user.id,
                        'order_id': user.order_id
                      })
                    })
                  } else if (meal.users['3']) {
                    meal.users['3-smena'] = []
                    meal.users['3'].filter(user => {
                      meal.users['3-smena'].push(user)
                      korisnici.push({
                        'meal_name': meal.name,
                        'quantity': user.quantity,
                        'shift': 1,
                        'name': user.name,
                        'lastname': user.lastname,
                        'user_id': user.id,
                        'order_id': user.order_id
                      })
                    })
                  }
                }
              })
            }
          }
          let podaci = []
          let smena = this.groupBy(korisnici, 'shift')
          let priprema = {}
          let pr = {}

          for (let i in smena) {
            priprema[i] = this.groupBy(smena[i], 'user_id')
            pr[i] = {}
            for (let n in priprema[i]) {
              pr[i][priprema[i][n][0].name + ' ' + priprema[i][n][0].lastname] = priprema[i][n]
            }
          }
          this.meals = t;
        })
      }
    },
    findMeals() {
      if (this.search2.length) {
        // this.narudzbe = ''
        this.meals = ''
        this.loading = true
        axios.get(
            "/reports/daily/company/" +
            this.search2.join() +
            "/userlist?date=" +
            moment(this.date[0]).format("YYYY-MM-DD") +
            "&date2=" +
            moment(this.date[1]).format("YYYY-MM-DD")
        )
            .then((res) => {
              this.loading = false
              let obroci = {}
              let korisnici = []
              for (let i in res.data) {
                if (i == 'company') {
                  obroci[res.data.company.name] = res.data
                } else if (res.data[i].company) {
                  obroci[res.data[i].company.name] = res.data[i]
                }
                if (res.data.meals) {
                  res.data.meals.filter(meal => {
                    if (meal.name != 'УКУПНО') {
                      if (meal.users['1-smena']) {
                        meal.users['1-smena'].filter(user => {
                          korisnici.push({
                            'meal_name': meal.name,
                            'quantity': user.quantity,
                            'shift': 1,
                            'name': user.name,
                            'lastname': user.lastname,
                            'user_id': user.id,
                            'order_id': user.order_id
                          })
                        })
                      }
                      if (meal.users['2-smena']) {
                        meal.users['2-smena'].filter(user => {
                          korisnici.push({
                            'meal_name': meal.name,
                            'quantity': user.quantity,
                            'shift': 2,
                            'name': user.name,
                            'lastname': user.lastname,
                            'user_id': user.id,
                            'order_id': user.order_id
                          })
                        })
                      }
                      if (meal.users['3-smena']) {
                        meal.users['3-smena'].filter(user => {
                          korisnici.push({
                            'meal_name': meal.name,
                            'quantity': user.quantity,
                            'shift': 3,
                            'name': user.name,
                            'lastname': user.lastname,
                            'user_id': user.id,
                            'order_id': user.order_id
                          })
                        })
                      }
                    }
                  })
                }
              }

              let podaci = []
              let smena = this.groupBy(korisnici, 'shift')
              let priprema = {}
              let pr = {}
              for (let i in smena) {
                priprema[i] = this.groupBy(smena[i], 'user_id')
                pr[i] = {}
                for (let n in priprema[i]) {
                  pr[i][priprema[i][n][0].name + ' ' + priprema[i][n][0].lastname] = priprema[i][n]
                }
              }
              this.meals = obroci;
              // this.narudzbe = pr
            });
      }
    },
    findMealsMoreRestaurnats() {
      if (this.search2.length) {
        // this.narudzbe = ''
        this.meals = ''
        axios.get(
            "/reports/daily/more-restaurants-company/" +
            this.search2.join() +
            "/userlist?date=" +
            moment(this.date[0]).format("YYYY-MM-DD") +
            "&date2=" +
            moment(this.date[1]).format("YYYY-MM-DD")
        )
            .then((res) => {
              let obroci = {}
              let korisnici = []
              for (let i in res.data) {
                if (i == 'company') {
                  obroci[res.data.company.name] = res.data
                } else if (res.data[i].company) {
                  obroci[res.data[i].company.name] = res.data[i]
                }
                if (res.data.meals) {
                  res.data.meals.filter(meal => {
                    if (meal.name != 'УКУПНО') {
                      if (meal.users['1-smena']) {
                        meal.users['1-smena'].filter(user => {
                          korisnici.push({
                            'meal_name': meal.name,
                            'quantity': user.quantity,
                            'shift': 1,
                            'name': user.name,
                            'lastname': user.lastname,
                            'user_id': user.id,
                            'order_id': user.order_id
                          })
                        })
                      }
                      if (meal.users['2-smena']) {
                        meal.users['2-smena'].filter(user => {
                          korisnici.push({
                            'meal_name': meal.name,
                            'quantity': user.quantity,
                            'shift': 2,
                            'name': user.name,
                            'lastname': user.lastname,
                            'user_id': user.id,
                            'order_id': user.order_id
                          })
                        })
                      }
                      if (meal.users['3-smena']) {
                        meal.users['3-smena'].filter(user => {
                          korisnici.push({
                            'meal_name': meal.name,
                            'quantity': user.quantity,
                            'shift': 3,
                            'name': user.name,
                            'lastname': user.lastname,
                            'user_id': user.id,
                            'order_id': user.order_id
                          })
                        })
                      }
                    }
                  })
                }
              }

              let podaci = []
              let smena = this.groupBy(korisnici, 'shift')
              let priprema = {}
              let pr = {}
              for (let i in smena) {
                priprema[i] = this.groupBy(smena[i], 'user_id')
                pr[i] = {}
                for (let n in priprema[i]) {
                  pr[i][priprema[i][n][0].name + ' ' + priprema[i][n][0].lastname] = priprema[i][n]
                }
              }
              this.meals = obroci;
              // this.narudzbe = pr
            });
      }
    },
    findMealsNarudzbe() {
      let niz = []
      if (Array.isArray(this.searchN)) {
        niz = this.searchN
      } else {
        niz.push(this.searchN)
      }
      this.narudzbe = ''
      this.loading = true
      axios.get(
          "/reports/daily/company/" +
          niz.join() +
          "/userlist?date=" +
          moment(this.date[0]).format("YYYY-MM-DD") +
          "&date2=" +
          moment(this.date[1]).format("YYYY-MM-DD")
      )
          .then((res) => {
            let t = {}
            this.loading = false
            let korisnici = []
            for (let i in res.data) {
              if (i == 'company') {
                t[res.data.company.name] = res.data
              } else if (res.data[i].company) {
                t[res.data[i].company.name] = res.data[i]
              }
              if (res.data.meals) {
                res.data.meals.filter(meal => {
                  if (meal.name != 'УКУПНО') {
                    if (meal.users['1-smena']) {
                      meal.users['1-smena'].filter(user => {
                        korisnici.push({
                          'meal_name': meal.name,
                          'company_id': res.data.company.id,
                          'meal_id': meal.id,
                          'quantity': user.quantity,
                          'shift': 1,
                          'name': user.name,
                          'lastname': user.lastname,
                          'user_id': user.id,
                          'order_id': meal.order_id
                        })
                      })
                    }
                    if (meal.users['2-smena']) {
                      meal.users['2-smena'].filter(user => {
                        korisnici.push({
                          'meal_name': meal.name,
                          'company_id': res.data.company.id,
                          'meal_id': meal.id,
                          'quantity': user.quantity,
                          'shift': 2,
                          'name': user.name,
                          'lastname': user.lastname,
                          'user_id': user.id,
                          'order_id': meal.order_id
                        })
                      })
                    }
                    if (meal.users['3-smena']) {
                      meal.users['3-smena'].filter(user => {
                        korisnici.push({
                          'meal_name': meal.name,
                          'company_id': res.data.company.id,
                          'meal_id': meal.id,
                          'quantity': user.quantity,
                          'shift': 3,
                          'name': user.name,
                          'lastname': user.lastname,
                          'user_id': user.id,
                          'order_id': meal.order_id
                        })
                      })
                    }
                  }
                })
              }
            }

            let podaci = []
            let smena = this.groupBy(korisnici, 'shift')
            let priprema = {}
            let pr = {}
            for (let i in smena) {
              priprema[i] = this.groupBy(smena[i], 'user_id')
              pr[i] = {}
              for (let n in priprema[i]) {
                // pr[i][priprema[i][n][0].name + ' ' + priprema[i][n][0].lastname] = priprema[i][n]
                // pr[i][priprema[i][n][0].name + ' ' + priprema[i][n][0].lastname] = this.groupBy(priprema[i][n], 'meal_name')
                pr[i][priprema[i][n][0].user_id] = this.groupBy(priprema[i][n], 'meal_name')
              }
            }
            this.narudzbe = pr
            this.searchByShift = Object.keys(this.narudzbe)[0]
          })
    },
    findMealsNarudzbeMoreRestaurants() {
      let niz = []
      if (Array.isArray(this.searchN)) {
        niz = this.searchN
      } else {
        niz.push(this.searchN)
      }
      this.narudzbe = ''
      this.loading = true
      axios.get(
          "/reports/daily/more-restaurants-company/" +
          niz.join() +
          "/userlist?date=" +
          moment(this.date[0]).format("YYYY-MM-DD") +
          "&date2=" +
          moment(this.date[1]).format("YYYY-MM-DD")
      )
        .then((res) => {
          let t = {}
          this.loading = false
          let korisnici = []
          for (let i in res.data) {
            if (i == 'company') {
              t[res.data.company.name] = res.data
            } else if (res.data[i].company) {
              t[res.data[i].company.name] = res.data[i]
            }
            if (res.data.meals) {
              res.data.meals.filter(meal => {
                if (meal.name != 'УКУПНО') {
                  if (meal.users['1-smena']) {
                    meal.users['1-smena'].filter(user => {
                      korisnici.push({
                        'meal_name': meal.name,
                        'company_id': res.data.company.id,
                        'meal_id': meal.id,
                        'quantity': user.quantity,
                        'shift': 1,
                        'name': user.name,
                        'lastname': user.lastname,
                        'user_id': user.id,
                        'order_id': meal.order_id
                      })
                    })
                  }
                  if (meal.users['2-smena']) {
                    meal.users['2-smena'].filter(user => {
                      korisnici.push({
                        'meal_name': meal.name,
                        'company_id': res.data.company.id,
                        'meal_id': meal.id,
                        'quantity': user.quantity,
                        'shift': 2,
                        'name': user.name,
                        'lastname': user.lastname,
                        'user_id': user.id,
                        'order_id': meal.order_id
                      })
                    })
                  }
                  if (meal.users['3-smena']) {
                    meal.users['3-smena'].filter(user => {
                      korisnici.push({
                        'meal_name': meal.name,
                        'company_id': res.data.company.id,
                        'meal_id': meal.id,
                        'quantity': user.quantity,
                        'shift': 3,
                        'name': user.name,
                        'lastname': user.lastname,
                        'user_id': user.id,
                        'order_id': meal.order_id
                      })
                    })
                  }
                }
              })
            }
          }

          let podaci = []
          let smena = this.groupBy(korisnici, 'shift')
          let priprema = {}
          let pr = {}
          for (let i in smena) {
            priprema[i] = this.groupBy(smena[i], 'user_id')
            pr[i] = {}
            for (let n in priprema[i]) {
              // pr[i][priprema[i][n][0].name + ' ' + priprema[i][n][0].lastname] = priprema[i][n]
              // pr[i][priprema[i][n][0].name + ' ' + priprema[i][n][0].lastname] = this.groupBy(priprema[i][n], 'meal_name')
              pr[i][priprema[i][n][0].user_id] = this.groupBy(priprema[i][n], 'meal_name')
            }
          }
          this.narudzbe = pr
          this.searchByShift = Object.keys(this.narudzbe)[0]
        })
    },
    print(id, type = false) {
      if (this.over60Seconds) {
        this.dialogRefresPrint = true
        return
      }
      const currentDate = moment().format("DD.MM.YYYY HH:mm:ss");
      if (id === "table6") {
        const WinPrint = window.open(
            "",
            "",
            "left=100,top=0,width=800,height=900,toolbar=0,scrollbars=0,status=0"
        );
        WinPrint.document.write(`<head>`);
        for (let index in this.meals) {
          const prtHtml = document.getElementById(`table6_${index}`).innerHTML;
          let stylesHtml = "";
          for (const node of [
            ...document.querySelectorAll('link[rel="stylesheet"], style'),
          ]) {
            stylesHtml += node.outerHTML;
          }
          WinPrint.document.write(` ${stylesHtml}`);
        }
        WinPrint.document.write(`
          <style>
            table {
              font-family: arial, sans-serif;
              border-collapse: collapse;
              width: 100%;
            }
            td, th {
              border: 1px solid #dddddd;
              text-align: left;
              padding: 8px;
            }
            .test_klasa td {
              vertical-align: unset !important;
              padding-top: 7px !important;
              padding-bottom: 7px !important;
            }
            @media print {
              body {
                margin: 0;
                padding: 0;
              }
              table {
                width: 100%; /* Ensure table spans full width */
              }
              td span, td.text-start {
                font-size: ${this.margin.size}px!important;
              }
              @page {
                size: A4;
                margin-top: ${this.margin.top}px;
                margin-bottom: ${this.margin.bottom}px;
                margin-left: ${this.margin.left}px;
                margin-right: ${this.margin.right}px
              }
            }
          </style>
          </head>`);

        for (let index in this.meals) {
          const prtHtml = document.getElementById(`table6_${index}`).innerHTML;
          let stylesHtml = "";
          for (const node of [
            ...document.querySelectorAll('link[rel="stylesheet"], style'),
          ]) {
            stylesHtml += node.outerHTML;
          }

          WinPrint.document.write(`
            <body>
              ${this.$store.state.restaurant.name}: ${index} ${moment(this.date[0]).format("DD.MM.YYYY.") + '-' + moment(this.date[1]).format("DD.MM.YYYY.")}
            <strong>${prtHtml}</strong>
            <div class="print-date" style="margin-top: 20px; text-align: left;font-weight: 700">
              ${this.translate('Датум и време креирања извештаја')}: ${currentDate}
            </div>
          </body>`);

          WinPrint.document.write('<p style="page-break-after:always;"></p>');
        }
        // WinPrint.document.close();
        // WinPrint.focus();
        // WinPrint.print();
        // WinPrint.close();
        WinPrint.document.close();
        WinPrint.focus();

        WinPrint.onload = function () {
          WinPrint.print();
          WinPrint.close();
        }
      } else if (id === "table11") {
        const currentDate = moment().format("DD.MM.YYYY HH:mm:ss");
        const prtHtml = document.getElementById(id).innerHTML;
        let stylesHtml = "";
        for (const node of [...document.querySelectorAll('link[rel="stylesheet"], style')]) {
          stylesHtml += node.outerHTML;
        }
        const WinPrint = window.open("", "", "left=100,top=0,width=800,height=900,toolbar=0,scrollbars=0,status=0");
        WinPrint.document.write(`<!DOCTYPE html>
          <html>
            <head>
              ${stylesHtml}
              <style>
                table {
                  font-family: arial, sans-serif;
                  border-collapse: collapse;
                  width: 100%;
                }
                td, th {
                  border: 1px solid #dddddd;
                  text-align: left;
                  padding: 8px;
                }
                 .test_klasa td {
                  vertical-align: unset !important;
                  padding-top: 7px !important;
                  padding-bottom: 7px !important;
                }

                @media print {
                  body {
                    margin: 0;
                    padding: 0;
                  }
                  table {
                    width: 100%; /* Ensure table spans full width */
                  }
                  td span, td.text-start {
                    font-size: ${this.margin.size}px!important;
                  }
                  @page {
                    size: A4;
                    margin-top: ${this.margin.top}px;
                    margin-bottom: ${this.margin.bottom}px;
                    margin-left: ${this.margin.left}px;
                    margin-right: ${this.margin.right}px
                  }
                }
                </style>
            </head>
            <body>
              ${this.userReportItem.name}: ${this.userReportItem.lastname} (ID: ${this.userReportItem.id}) - ${this.monthName} ${this.year}.
              <strong>${prtHtml}</strong>
               <div class="print-date" style="margin-top: 20px; text-align: left;font-weight: 700">
                  ${this.translate('Датум и време креирања извештаја')}: ${currentDate}
                </div>
            </body>
          </html>`);
        WinPrint.document.close();
        WinPrint.focus();

        WinPrint.onload = function () {
          WinPrint.print();
          WinPrint.close();
        };
      } else if (id === "table4") {
        const currentDate = moment().format("DD.MM.YYYY HH:mm:ss");
        const prtHtml = document.getElementById(id).innerHTML;
        let stylesHtml = "";
        for (const node of [...document.querySelectorAll('link[rel="stylesheet"], style')]) {
          stylesHtml += node.outerHTML;
        }
        const WinPrint = window.open("", "", "left=100,top=0,width=800,height=900,toolbar=0,scrollbars=0,status=0");
        WinPrint.document.write(`<!DOCTYPE html>
          <html>
            <head>
              ${stylesHtml}
              <style>
                h2 {
                    margin-bottom: 3px;
                    margin-top: 15px;
                }
                #table4 td, th, tr {
                    font-size: 17px !important;
                }
                table {
                    margin-top: 3px;
                }
                @media print {
                    body {
                      margin: 0;
                      padding: 0;
                    }
                     table {
                        width: 100%;
                     }
                     #table4 td, th, tr {
                         font-size: 17px !important;
                         border: 1px solid #cfcfcf;
                    }
                    td span, td.text-start {
                      font-size: ${this.margin.size}px!important;
                    }
                    @page {
                      size: A4;
                      margin-top: ${this.margin.top}px;
                      margin-bottom: ${this.margin.bottom}px;
                      margin-left: ${this.margin.left}px;
                      margin-right: ${this.margin.right}px
                    }
                }
              </style>
            </head>
            <body>
             <div style="margin-top: 10px;font-weight: 700">
                ${this.$store.state.restaurant.name}: ${this.name}   ${moment(this.date[0]).format("DD.MM.YYYY.") + '-' + moment(this.date[1]).format("DD.MM.YYYY.")}
             </div>
             <strong>${prtHtml}</strong>
             <div class="print-date" style="margin-top: 20px; text-align: left;font-weight: 700">
                ${this.translate('Датум и време креирања извештаја')}: ${currentDate}
             </div>
            </body>
          </html>`);
        WinPrint.document.close();
        WinPrint.focus();

        WinPrint.onload = function () {
          WinPrint.print();
          WinPrint.close();
        };
      } else {
        if (this.tab == 1) {
          id = 'table7'
        }
        const prtHtml = document.getElementById(id).innerHTML;
        let stylesHtml = "";
        for (const node of [...document.querySelectorAll('link[rel="stylesheet"], style')]) {
          stylesHtml += node.outerHTML;
        }
        // Open the print window
        const WinPrint = window.open("", "", "left=100,top=0,width=800,height=900,toolbar=0,scrollbars=0,status=0");
        WinPrint.document.write(`<!DOCTYPE html>
        <html>
          <head>
            ${stylesHtml}
            <style>
             table {
              font-family: arial, sans-serif;
              border-collapse: collapse;
              width: 100%;
            }
            td, th {
              border: 1px solid #dddddd;
              text-align: left;
              padding: 8px;
            }

             .test_klasa td {
              vertical-align: unset !important;
              padding-top: 7px !important;
              padding-bottom: 7px !important;
            }
            @media print {
              body {
                margin: 0;
                padding: 0;
              }
              table {
                width: 100%; /* Ensure table spans full width */
              }
              td span, td.text-start {
                font-size: ${this.margin.size}px!important;
              }

              @page {
                size: A4;
                margin-top: ${this.margin.top}px;
                margin-bottom: ${this.margin.bottom}px;
                margin-left: ${this.margin.left}px;
                margin-right: ${this.margin.right}px;
              }
            }
          </style>
          </head>
          <body>
            <div style="margin-top: 10px;font-weight: 700">
              ${this.$store.state.restaurant.name}: ${this.name} ${moment(this.date[0]).format("DD.MM.YYYY.") + '-' + moment(this.date[1]).format("DD.MM.YYYY.")}
            </div>
            <strong>${prtHtml}</strong>
            <div class="print-date" style="margin-top: 20px; text-align: left;font-weight: 700">
              ${this.translate('Датум и време креирања извештаја')}: ${currentDate}
            </div>
          </body>
        </html>`);

        // WinPrint.document.close();
        // WinPrint.focus();
        // WinPrint.print();
        // WinPrint.close();
        // Wait for the content to be fully loaded before printing
        WinPrint.document.close();
        WinPrint.focus();

        WinPrint.onload = function () {
          WinPrint.print();
          WinPrint.close();
        };
      }
    },
    load() {
      this.search = "";
      this.search2 = "";
      this.search3 = "";
      this.searchFoodFirm = ""
      this.search4 = "";
      this.search5 = "";
      this.search8 = "";
      this.reportForFood = ''
      this.searchFoodCompany = ''
      this.menu_oborci = []
      this.dialogRefresPrint = false
      this.over60Seconds = false
      this.timer = null
      this.meals = ''
      this.refreshTimer();
      if (this.date && this.date.length == 1) {
        this.date.push(this.date[0]);
      }
      if (this.date[0] == this.date[1]) {
        this.canSeeAddMeal = true
      }
      if (this.date && this.date[0] > this.date[1])
        [this.date[0], this.date[1]] = [this.date[1], this.date[0]];
      if (this.$store.state.restaurant) {
        if (this.select_view == 1) {
          axios.get(
            "/reports/daily/restaurant/" +
            this.$store.state.restaurant.id +
            "?date=" +
            moment(this.date[0]).format("YYYY-MM-DD") +
            "&date2=" +
            moment(this.date[1]).format("YYYY-MM-DD")
          )
            .then((res) => {
              this.report = res.data;
              this.reportOrginal = res.data;
            })
        }
        if (this.select_view == 3) {
          this.loadMealsForRestaurantFirms()
          this.$forceUpdate()
          return
        }
        this.companyFood = []
        this.foodCompanies = []
        this.report_food = '';
        this.report_food_original = '';
        // if (this.select_view == 2) {
        axios.get('/reports/daily/get-restaurant-companies/' + this.$store.state.restaurant.id + "?date=" +
            moment(this.date[0]).format("YYYY-MM-DD") +
            "&date2=" +
            moment(this.date[1]).format("YYYY-MM-DD")).then(({data}) => {
          this.report_food = data;
          this.report_food_original = data;
          let podaci = '';
          data.companies.filter(item => {
            this.foodCompanies.push({
              'company': item.name,
              'id': item.id
            })
            this.categories[item.name] = []
            this.categoriesByOrder[item.name] = []

            if (data.menu[item.name]) {
              podaci = data.menu[item.name].meals[0]
            }
            if (podaci && podaci.foods) {
              podaci.foods.filter(it => {
                if (!this.categories[item.name].includes(it.category.name)) {
                  this.categories[item.name].push(it.category.name)
                  this.categoriesByOrder[item.name].push({'name': it.category.name, 'order': it.category.poredak})
                }
              })
            }
          })
          let obroci = []
          if (podaci.foods) {
            podaci.foods.filter(it => {
              obroci.push(it.id)
            })
          }
          this.menu_oborci = obroci
        })
        this.narudzbe = ''
        this.searchByShift = ''
        axios.get(
          "/reports/daily/restaurant/" +
          this.$store.state.restaurant.id +
          "/companies?date=" +
          moment(this.date[0]).format("YYYY-MM-DD") +
          "&date2=" +
          moment(this.date[1]).format("YYYY-MM-DD")
        ).then((res) => {
          this.report2 = res.data;
        })

        axios.get(
            "/reports/daily/total/" +
            this.$store.state.restaurant.id +
            "?date=" +
            moment(this.date[0]).format("YYYY-MM-DD") +
            "&date2=" +
            moment(this.date[1]).format("YYYY-MM-DD")
        )
            .then((res) => {
              this.total = res.data;
              this.total.filter(item => {
                item.name = item.menu
              })
            })

        let ruta = ''
        if (this.select_view == 2) {
          ruta = "/reports/daily/restaurant/" + this.$store.state.restaurant.id + "/child-restaurant?date=" + moment(this.date[0]).format("YYYY-MM-DD") + "&date2=" + moment(this.date[1]).format("YYYY-MM-DD") + "&food=" + true
        } else {
          ruta = "/reports/daily/restaurant/" + this.$store.state.restaurant.id + "/child-restaurant?date=" + moment(this.date[0]).format("YYYY-MM-DD") + "&date2=" + moment(this.date[1]).format("YYYY-MM-DD")
        }

        axios.get(ruta)
          .then((data) => {
            let ne = true
            if (data.data.data != 'Restoran nema decu') {
              this.items.filter(itm => {
                if (itm.tab == "Ресторани" || itm.tab == "Restorani") {
                  ne = false
                }
              })
              if (ne) {
                this.items.push({
                  tab: this.$t('restaurants'),
                  content: "Tab 9 Content",
                  print: "table9",
                  icon: "mdi-shopping-outline",
                })
              }
            }
            this.childRestorans = data.data
          })

        let mealFood = []
        axios.get("/reports/daily/company/" +
            this.$store.state.restaurant.id +
            "/no-contract?date=" +
            moment(this.date[0]).format("YYYY-MM-DD") +
            "&date2=" +
            moment(this.date[1]).format("YYYY-MM-DD")).then((res) => {
          this.noContract = res.data;
          for (let i in res.data) {
            if (res.data[i].length) {
              res.data[i].filter(order => {
                order.orders_guest.filter(o => {
                  o.order_guest_transactions.filter(ord => {
                    mealFood.push(ord)
                  })
                })
                order.orders = mealFood
              })
            }
          }
        })

        axios.get(
            "/reports/daily/restaurant/" +
            this.$store.state.restaurant.id +
            "/rezervni-obroci?company=" + this.search5 + "&date=" +
            moment(this.date[0]).format("YYYY-MM-DD") +
            "&date2=" +
            moment(this.date[1]).format("YYYY-MM-DD")
        ).then((data) => {
          this.reservedItemsData = data.data
        })

        if (this.$store.state.user.type == 'report_guest') {
          this.items = []
          this.headers = this.headersG
          this.reportG = this.noContractCompanies
          this.name = " Гости "
          this.tab = 0
          this.printTable = "table9"
          this.items.push({
            tab: this.$t('guests'),
            content: "Tab 9 Content",
            print: "table9",
            icon: "mdi-shopping-outline",
          })
        }
        this.foodOrdersOrdered()
      }
      this.$forceUpdate()
    },
    findShift(num) {
      switch (num) {
        case "1-smena":
          return "shift_one";
          break;
        case "2-smena":
          return "shift_two";
          break;
        case "3-smena":
          return "shift_three";
          break;
      }
    },
    groupBy(array, key) {
      const result = {}
      array.forEach(item => {
        if (!result[item[key]]) {
          result[item[key]] = []
        }
        result[item[key]].push(item)
      })
      return result
    },
    refreshTimer() {
      this.timer = setTimeout(() => {
        if (this.date[0] >= moment().format('YYYY-MM-DD')) {
          this.over60Seconds = true;
          console.log('User has been on the page for over 5 min');
        }
      }, 300000);
    }
  },
  mounted() {
    this.onResize()
    this.timer = setTimeout(() => {
      if (this.date[0] >= moment().format('YYYY-MM-DD')) {
        this.over60Seconds = true;
        console.log('User has been on the page for over 5 min');
      }
    }, 300000); // 20000 milliseconds = 20 seconds
  },
  beforeDestroy() {
    // Clear the timer when the component is destroyed
    if (this.timer) {
      clearTimeout(this.timer);
    }
  },
  created() {
    this.$on('refreshMeal', () => {
      axios.get(
          "/reports/daily/restaurant/" +
          this.$store.state.restaurant.id +
          "/companies?date=" +
          moment(this.date[0]).format("YYYY-MM-DD") +
          "&date2=" +
          moment(this.date[1]).format("YYYY-MM-DD")
      )
          .then((res) => {
            this.report2 = res.data;
          });
      this.$forceUpdate()
    })
    this.$root.$on('changeWebSite', () => {
      if (this.tab == 11) {
        let ruta = ''
        if (this.select_view == 2) {
          ruta = "/reports/daily/restaurant/" + this.$store.state.restaurant.id + "/child-restaurant?date=" + moment(this.date[0]).format("YYYY-MM-DD") + "&date2=" + moment(this.date[1]).format("YYYY-MM-DD") + "&food=" + true
        } else {
          ruta = "/reports/daily/restaurant/" + this.$store.state.restaurant.id + "/child-restaurant?date=" + moment(this.date[0]).format("YYYY-MM-DD") + "&date2=" + moment(this.date[1]).format("YYYY-MM-DD")
        }
        axios.get(ruta)
            .then((data) => {
              let ne = true
              if (data.data.data != 'Restoran nema decu') {
                this.items.filter(itm => {
                  if (itm.tab == "Ресторани" || itm.tab == "Restorani") {
                    ne = false
                  }
                })
                if (ne) {
                  this.items.push({
                    tab: this.$t('restaurants'),
                    content: "Tab 9 Content",
                    print: "table9",
                    icon: "mdi-shopping-outline",
                  })
                }
              }
              this.childRestorans = data.data
            })
      }
    });
    this.load();
  },
  computed: {
    containerClass() {
      if (window.innerWidth <= 768) {
        return '98%';
      } else if (window.innerWidth <= 1024) {
        return '75%';
      } else {
        return '75%';
      }
    },
    narudzbe2Pretraga() {
      if (this.search8 && this.pretraga) {
        let arr = []
        let ime = []
        let prezime = []
        let jelo = []
        this.narudzbe2.filter(item => {
          if (item.name && this.translate(item.name).toLowerCase().includes(this.translate(this.pretraga))) {
            ime.push(item)
          } else if (ime.length == 0 && item.lastname && this.translate(item.lastname).toLowerCase().includes(this.translate(this.pretraga))) {
            prezime.push(item)
          } else if (ime.length == 0 && prezime.length == 0 && item.meal_name && this.translate(item.meal_name).toLowerCase().includes(this.translate(this.pretraga))) {
            jelo.push(item)
          }
        })
        if (ime.length > 0) {
          arr = ime
        }
        if (ime.length == 0 && prezime.length > 0) {
          arr = prezime
        }
        if (ime.length == 0 && prezime.length == 0 && jelo.length > 0) {
          arr = jelo
        }
        let imena = {}
        arr.filter(item => {
          item.user = item.name + ' ' + item.lastname
          imena[item.name + ' ' + item.lastname] = item
        })
        let comp = this.foodCompanies.find(id => id.id == this.search8).company
        let categoriesArray = this.categoriesByOrder[comp].sort((a, b) => a.order - b.order);
        let sortedNames = categoriesArray.map(category => category.name);
        let categories = sortedNames
        let datum = this.groupBy(arr, 'user_id')
        let podaci = {}
        let ukupnoPorudzbina = {}
        let data = {}
        for (let date in datum) {
          let dan = date
          data[dan] = {}
          ukupnoPorudzbina[dan] = {}
          podaci[dan] = this.groupBy(datum[date], 'shift')
          for (let shift in podaci[dan]) {
            data[dan][shift] = {}
            ukupnoPorudzbina[dan][shift] = {}
            let sortirano = categories
            sortirano.filter(category => {
              if (category != 'null') {
                data[dan][shift][category] = []
              }
            })
            podaci[dan][shift].sort((a, b) => a.category_order - b.category_order)
            podaci[dan][shift].filter(it => {
              if (data[dan][shift][it.category_name]) {
                data[dan][shift][it.category_name].push(it)
              }
            })
          }
        }
        return data
      } else {
        return this.foodsByCompany ? this.foodsByCompany : []
      }
    },
    kategorija() {
      let sortedNames = ''
      let comp = this.foodCompanies.find(id => id.id == this.search8).company
      if (comp) {
        let categoriesArray = this.categoriesByOrder[comp].sort((a, b) => a.order - b.order);
        if (categoriesArray) {
          sortedNames = categoriesArray.map(category => category.name);
        }
      }

      return sortedNames
    },
    filterByShiftsNarudzbe2() {
      let orginal = this.narudzbe2Pretraga
      let podaci = {}
      if (this.searchByShiftNewReport) {
        for (let i in this.narudzbe2Pretraga) {
          for (let n in this.narudzbe2Pretraga[i]) {
            if (n == this.searchByShiftNewReport) {
              podaci[i] = {}
              podaci[i][n] = this.narudzbe2Pretraga[i][n]
            }
          }
        }
      }
      return podaci
    },
    smeneZaNoviIzvestaj() {
      if (this.narudzbe2) {
        let smene = this.groupBy(this.narudzbe2, 'shift')
        let pripremaSmene = []
        for (let i in smene) {
          pripremaSmene.push({
            'shift': parseInt(i),
            'id': parseInt(i)
          })
        }
        this.searchByShiftNewReport = pripremaSmene.length ? pripremaSmene[0].id : ''
        this.$forceUpdate()
        return pripremaSmene
      }
    },
    foodsByCompany() {
      let imena = {}
      this.narudzbe2.filter(item => {
        item.user = item.name + ' ' + item.lastname
        imena[item.name + ' ' + item.lastname] = item
      })
      let comp = this.foodCompanies.find(id => id.id == this.search8).company
      let categoriesArray = this.categoriesByOrder[comp].sort((a, b) => a.order - b.order);
      let sortedNames = categoriesArray.map(category => category.name);
      // let categories = this.groupBy(this.narudzbe2, 'category_name')
      // let categories = this.categories[comp]
      let categories = sortedNames
      let datum = this.groupBy(this.narudzbe2, 'user_id')
      let podaci = {}
      let ukupnoPorudzbina = {}
      let data = {}
      for (let date in datum) {
        let dan = date
        data[dan] = {}
        ukupnoPorudzbina[dan] = {}
        podaci[dan] = this.groupBy(datum[date], 'shift')
        for (let shift in podaci[dan]) {
          data[dan][shift] = {}
          ukupnoPorudzbina[dan][shift] = {}
          // let sortirano = Object.keys(categories).slice().sort((a, b) => a.localeCompare(b))
          let sortirano = categories
          sortirano.filter(category => {
            if (category != 'null') {
              data[dan][shift][category] = []
            }
          })
          podaci[dan][shift].sort((a, b) => a.category_order - b.category_order)
          podaci[dan][shift].filter(it => {
            if (data[dan][shift][it.category_name]) {
              data[dan][shift][it.category_name].push(it)
            }
          })
          for (let cat in data[dan][shift]) {
            ukupnoPorudzbina[dan][shift] = this.groupBy(data[dan][shift][cat], 'order_food_id')
          }
        }
      }

      return data
    },
    foodShifts() {
      let smene = []
      this.desserts4.filter((item, index) => {
        if (item.shift_one > 0) {
          smene.push(1)
        }
        if (item.shift_two > 0) {
          smene.push(2)
        }
        if (item.shift_three > 0) {
          smene.push(3)
        }
      })
      let podaci = [...new Set(smene)]
      return podaci
    },
    searchFoodsByShift() {
      let byShift = {}
      this.obrokKojiNijeZaTajDan = ''
      if (this.searchFoodShift) {
        byShift[this.searchFoodShift] = {}
        byShift[this.searchFoodShift] = this.filterFoodByCompanyOrders[this.searchFoodShift]
        if (byShift[this.searchFoodShift]) {
          let pod = Object.keys(byShift[this.searchFoodShift])
          let ima = []
          let nema = []
          pod.filter(item => {
            byShift[this.searchFoodShift][item].filter(obrok => {
              ima.push(obrok.food_id)
            })
          })
          let sviObroci = [...new Set(ima)].filter(element => {
            return element !== null;
          }).sort();
          if (this.menu_oborci.length) {
            this.menu_oborci.sort()
            sviObroci.filter(i => {
              if (!this.menu_oborci.includes(i)) {
                nema.push(i)
              }
            })
          }

          this.obrokKojiNijeZaTajDan = nema
          return byShift
        }
      }
    },
    searcByShift() {
      let byShift = {}
      if (this.searchByShift) {
        byShift[this.searchByShift] = {}
        byShift[this.searchByShift] = this.narudzbe[this.searchByShift]
      }
      return byShift
    },
    filterFoodByCompanyOrders() {
      let podaci = []
      let priprema = {}
      let pr = {}
      this.foodByOrders.filter(item => {
        if (this.searchFoodFirm == item.company_id) {
          podaci.push(item)
        }
      })
      let smena = this.groupBy(podaci, 'shift')
      for (let i in smena) {
        priprema[i] = this.groupBy(smena[i], 'user_id')
        pr[i] = {}
        for (let n in priprema[i]) {
          // pr[i][priprema[i][n][0].name + ' ' + priprema[i][n][0].lastname] = priprema[i][n]
          pr[i][priprema[i][n][0].user_id] = priprema[i][n]
        }
      }
      return pr
    },
    searchMonthlyReportGroupMeal() {
      let searchTerm = this.searchR.toLowerCase();
      let grupa = []
      let podaci = {}
      for (let dayData in this.kategorije1) {
        for (let shiftData in this.kategorije1[dayData]) {
          grupa.push(this.kategorije1[dayData][shiftData])
          for (let n in this.kategorije1[dayData][shiftData]) {
            podaci[n] = []
          }
        }
      }
      grupa.filter(item => {
        for (let i in item) {
          podaci[i].push(item[i])
        }
      })
      let gotovo = {}
      for (let i in podaci) {
        gotovo[i] = []
        podaci[i].filter(podatak => {
          podatak.filter(p => {
            if (p.meal_name.toLowerCase().includes(searchTerm)) {
              gotovo[i].push(podatak)
            }
          })
        })
      }
      return podaci
    },
    filterFirmsReport() {
      let arr = {}
      let arr1 = {}
      for (let i in this.firmReports) {
        arr[i.split('-')[2]] = {}
        arr1[i.split('-')[2]] = {}

        arr[i.split('-')[2]]['shiftOne'] = []
        arr[i.split('-')[2]]['shiftTwo'] = []
        arr[i.split('-')[2]]['shiftThree'] = []
        arr[i.split('-')[2]]['shiftOneP'] = []
        arr[i.split('-')[2]]['shiftTwoP'] = []
        arr[i.split('-')[2]]['shiftThreeP'] = []

        arr1[i.split('-')[2]]['shiftOne'] = []
        arr1[i.split('-')[2]]['shiftTwo'] = []
        arr1[i.split('-')[2]]['shiftThree'] = []
        if (this.firmReports[i]) {
          this.firmReports[i].filter(item => {
            if (item.quantity > 0) {
              if (item.shift == 1) {
                arr[i.split('-')[2]]['shiftOne'].push(item)
              }
              if (item.shift == 2) {
                arr[i.split('-')[2]]['shiftTwo'].push(item)
              }
              if (item.shift == 3) {
                arr[i.split('-')[2]]['shiftThree'].push(item)
              }
            }

            if (item.quantity_payed > 0) {
              if (item.shift == 1) {
                arr1[i.split('-')[2]]['shiftOne'].push(item)
                arr[i.split('-')[2]]['shiftOneP'].push(item)
              }
              if (item.shift == 2) {
                arr1[i.split('-')[2]]['shiftTwo'].push(item)
                arr[i.split('-')[2]]['shiftTwoP'].push(item)
              }
              if (item.shift == 3) {
                arr1[i.split('-')[2]]['shiftThree'].push(item)
                arr[i.split('-')[2]]['shiftThreeP'].push(item)
              }
            }
          })
        }
      }
      for (let n in arr) {
        arr[n].shiftOne = arr[n].shiftOne.length
        arr[n].shiftOneP = arr[n].shiftOneP.length
        arr[n].shiftTwo = arr[n].shiftTwo.length
        arr[n].shiftTwoP = arr[n].shiftTwoP.length
        arr[n].shiftThree = arr[n].shiftThree.length
        arr[n].shiftThreeP = arr[n].shiftThreeP.length
        arr[n].total = arr[n].shiftOne + arr[n].shiftTwo + arr[n].shiftThree
        arr[n].totalP = arr[n].shiftOneP + arr[n].shiftTwoP + arr[n].shiftThreeP
      }
      for (let m in arr1) {
        arr1[m].shiftOne = arr1[m].shiftOne.length
        arr1[m].shiftTwo = arr1[m].shiftTwo.length
        arr1[m].shiftThree = arr1[m].shiftThree.length
        arr1[m].total = arr1[m].shiftOne + arr1[m].shiftTwo + arr1[m].shiftThree
      }
      let arr2 = []
      for (let m in arr) {
        arr2.push({
          'day': m + '.',
          'shiftOne': arr[m].shiftOne,
          'shiftOneP': arr[m].shiftOneP,
          'shiftTwo': arr[m].shiftTwo,
          'shiftTwoP': arr[m].shiftTwoP,
          'shiftThree': arr[m].shiftThree,
          'shiftThreeP': arr[m].shiftThreeP,
          'total': arr[m].total,
          'totalP': arr[m].totalP,
        })
      }

      arr2.sort((a, b) => {
        return a.day - b.day;
      });
      let shiftOneT = 0
      let shiftTwoT = 0
      let shiftThreeT = 0
      let shiftOneP = 0
      let shiftTwoP = 0
      let shiftThreeP = 0
      arr2.filter(item => {
        shiftOneT += item.shiftOne
        shiftTwoT += item.shiftTwo
        shiftThreeT += item.shiftThree
        shiftOneP += item.shiftOneP
        shiftTwoP += item.shiftTwoP
        shiftThreeP += item.shiftThreeP
      })
      arr2.push({
        'day': this.$t('totalU'),
        'shiftOne': shiftOneT,
        'shiftOneP': shiftOneP,
        'shiftTwo': shiftTwoT,
        'shiftTwoP': shiftTwoP,
        'shiftThree': shiftThreeT,
        'shiftThreeP': shiftThreeP,
        'total': shiftOneT + shiftTwoT + shiftThreeT,
        'totalP': shiftOneP + shiftTwoP + shiftThreeP,
      })

      return arr2
    },
    reportSearch() {
      let data = []
      if (this.searchR != '') {
        this.dessertsReport.filter(rep => {
          if (rep.shiftOne && rep.shiftOne.length > 0) {
            rep.shiftOne.filter(item => {
              if (item.meal_name.toLowerCase().includes(this.searchR.toLowerCase())) {
                data.push(rep)
              }
            })
          }
          if (rep.shiftTwo && rep.shiftTwo.length > 0) {
            rep.shiftTwo.filter(item2 => {
              if (item2.meal_name.toLowerCase().includes(this.searchR.toLowerCase())) {
                data.push(rep)
              }
            })
          }
          if (rep.shiftThree && rep.shiftThree.length > 0) {
            rep.shiftThree.filter(item3 => {
              if (item3.meal_name.toLowerCase().includes(this.searchR.toLowerCase())) {
                data.push(rep)
              }
            })
          }
        })
      }
      if (this.select_view == 2) {
        return this.dessertsReportF
      }

      return data.length ? data : this.dessertsReport
    },
    reportSearchF() {
      let data = []
      if (this.searchR != '') {
        this.dessertsReportF.filter(rep => {
          if (rep.shiftOne && rep.shiftOne.length > 0) {
            rep.shiftOne.filter(item => {
              if (item.meal_name.toLowerCase().includes(this.searchR.toLowerCase())) {
                data.push(rep)
              }
            })
          }
          if (rep.shiftTwo && rep.shiftTwo.length > 0) {
            rep.shiftTwo.filter(item2 => {
              if (item2.meal_name.toLowerCase().includes(this.searchR.toLowerCase())) {
                data.push(rep)
              }
            })
          }
          if (rep.shiftThree && rep.shiftThree.length > 0) {
            rep.shiftThree.filter(item3 => {
              if (item3.meal_name.toLowerCase().includes(this.searchR.toLowerCase())) {
                data.push(rep)
              }
            })
          }
        })
      }
      // if (this.select_view == 2) {
      //   this.dessertsReportF.filter(item => {
      //     let a = item.shiftOne ? this.groupBy(item.shiftOne, 'meal_name') : ''
      //     let b = item.shiftTwo ? this.groupBy(item.shiftTwo, 'meal_name') : ''
      //     let c = item.shiftThree ? this.groupBy(item.shiftThree, 'meal_name') : ''
      //     if (a != '') {
      //       item.shiftOne = []
      //       item.shiftOne.push(a)
      //     }
      //     if (b != '') {
      //       item.shiftTwo = []
      //       item.shiftTwo.push(b)
      //     }
      //     if (c != '') {
      //       item.shiftThree = []
      //       item.shiftThree.push(c)
      //     }
      //   })
      //   return this.dessertsReportF
      // }
      // return data.length ? data : this.dessertsReport
    },
    reportSearchFirm() {
      let data = []
      if (this.searchR != '') {
        this.firmReportsAll.filter(rep => {
          if (rep.shiftOne && rep.shiftOne.length > 0) {
            rep.shiftOne.filter(item => {
              if (item.meal_name.toLowerCase().includes(this.searchR.toLowerCase())) {
                data.push(rep)
              }
            })
          }
          if (rep.shiftTwo && rep.shiftTwo.length > 0) {
            rep.shiftTwo.filter(item2 => {
              if (item2.meal_name.toLowerCase().includes(this.searchR.toLowerCase())) {
                data.push(rep)
              }
            })
          }
          if (rep.shiftThree && rep.shiftThree.length > 0) {
            rep.shiftThree.filter(item3 => {
              if (item3.meal_name.toLowerCase().includes(this.searchR.toLowerCase())) {
                data.push(rep)
              }
            })
          }
        })
      }

      return data.length ? data : this.firmReports
    },
    total3() {
      let total = 0
      let data = ''
      if (this.select_view == 2) {
        data = this.dessertsReportF
      } else {
        data = this.dessertsReport
      }
      data.forEach(item => {
        if (item.shiftOne && item.shiftOne.length) {
          item.shiftOne.forEach(menu => {
            total += menu.quantity
          })
        }
        if (item.shiftTwo && item.shiftTwo.length) {
          item.shiftTwo.forEach(menu => {
            total += menu.quantity
          })
        }
        if (item.shiftThree && item.shiftThree.length) {
          item.shiftThree.forEach(menu => {
            total += menu.quantity
          })
        }
      })
      return total
    },
    daysInMonth() {
      return moment(this.month).daysInMonth()
    },
    monthName() {
      return moment(this.month, "M").format('MMMM').toUpperCase()
    },
    month() {
      return parseInt(this.date1.split('-')[1])
    },
    year() {
      return parseInt(this.date1.split('-')[0])
    },
    user() {
      return this.$store.state.user
    },
    desserts6() {
      let arr2 = [];
      if (this.reservedItemsData) {
        for (let company in this.reservedItemsData) {
          arr2.push({company: company, foods: []});
          for (let shift in this.reservedItemsData[company]) {
            for (let shift2 in this.reservedItemsData[company][shift]) {
              let index = arr2.findIndex((i) => i.company === company);
              let index2 = arr2[index].foods.findIndex(
                  (i) => i.name === shift2
              );
              if (index2 > -1) {
                arr2[index].foods[index2].shift_one += shift === "1-smena" ? this.reservedItemsData[company][shift][shift2].quantity : 0;
                arr2[index].foods[index2].shift_one_payed += shift === "1-smena" ? this.reservedItemsData[company][shift][shift2].quantity_payed : 0;
                arr2[index].foods[index2].shift_two += shift === "2-smena" ? this.reservedItemsData[company][shift][shift2].quantity : 0;
                arr2[index].foods[index2].shift_two_payed += shift === "2-smena" ? this.reservedItemsData[company][shift][shift2].quantity_payed : 0;
                (arr2[index].foods[index2].shift_three += shift === "3-smena" ? this.reservedItemsData[company][shift][shift2].quantity : 0),
                (arr2[index].foods[index2].shift_three_payed += shift === "3-smena" ? this.reservedItemsData[company][shift][shift2].quantity_payed : 0),
                (arr2[index].foods[index2].total += this.reservedItemsData[company][shift][shift2].quantity),
                (arr2[index].foods[index2].total_payed += this.reservedItemsData[company][shift][shift2].quantity_payed);
              } else {
                arr2[index].foods.push({
                  name: shift2,
                  id: this.reservedItemsData[company][shift][shift2].id,
                  shift_one:
                      shift === "1-smena"
                          ? this.reservedItemsData[company][shift][shift2].quantity
                          : 0,
                  shift_one_payed:
                      shift === "1-smena"
                          ? this.reservedItemsData[company][shift][shift2].quantity_payed
                          : 0,
                  shift_two:
                      shift === "2-smena"
                          ? this.reservedItemsData[company][shift][shift2].quantity
                          : 0,
                  shift_two_payed:
                      shift === "2-smena"
                          ? this.reservedItemsData[company][shift][shift2].quantity_payed
                          : 0,
                  shift_three:
                      shift === "3-smena"
                          ? this.reservedItemsData[company][shift][shift2].quantity
                          : 0,
                  shift_three_payed:
                      shift === "3-smena"
                          ? this.reservedItemsData[company][shift][shift2].quantity_payed
                          : 0,
                  total: this.reservedItemsData[company][shift][shift2].quantity,
                  total_payed: this.reservedItemsData[company][shift][shift2].quantity_payed,
                });
              }
            }
          }
        }

        arr2.forEach((company, index) => {
          company.foods.push({
            name: "УКУПНО",
            shift_one: company.foods.reduce((sum, item) => {
              return sum + item.shift_one;
            }, 0),
            shift_one_payed: company.foods.reduce((sum, item) => {
              return sum + item.shift_one_payed;
            }, 0),
            shift_two: company.foods.reduce((sum, item) => {
              return sum + item.shift_two;
            }, 0),
            shift_two_payed: company.foods.reduce((sum, item) => {
              return sum + item.shift_two_payed;
            }, 0),
            shift_three: company.foods.reduce((sum, item) => {
              return sum + item.shift_three;
            }, 0),
            shift_three_payed: company.foods.reduce((sum, item) => {
              return sum + item.shift_three_payed;
            }, 0),
            total:
                company.foods.reduce((sum, item) => {
                  return sum + item.shift_one;
                }, 0) +
                company.foods.reduce((sum, item) => {
                  return sum + item.shift_two;
                }, 0) +
                company.foods.reduce((sum, item) => {
                  return sum + item.shift_three;
                }, 0),
            total_payed:
                company.foods.reduce((sum, item) => {
                  return sum + item.shift_one_payed;
                }, 0) +
                company.foods.reduce((sum, item) => {
                  return sum + item.shift_two_payed;
                }, 0) +
                company.foods.reduce((sum, item) => {
                  return sum + item.shift_three_payed;
                }, 0),
          });
        });
      }

      return arr2;
    },
    desserts5() {
      let arr2 = [];
      let a = {}
      if (this.reservedItems) {
        this.reservedItems.filter((item) => {
          a[item.name] = item
        })
      }
      for (let i in a) {
        arr2.push({'company': i})
      }

      return arr2;
    },
    noContractCompanies() {
      let data = []
      for (let i in this.noContract) {
        data.push({company: i})
      }
      return data
    },
    noContractDelivery() {
      let test = []
      let meals = []
      for (let i in this.noContract) {
        if (i == 'IsporukaZaNeregistrovane') {
          this.noContract[i][0].orders_guest.filter(meal => {
            test.push(meal)
            meal.order_guest_transactions.filter(food => {
              food.date = meal.date + ' | ' + meal.delivery_time
              meals.push(food)
            })
          })
        }
      }
      let qty = 0;
      let pr = 0;
      test.filter(itm => {
        itm.order_guest_transactions.filter(q => {
          qty += q.quantity
          pr += q.price * q.quantity
        })
      })
      test.push({
        name: "УКУПНО",
        price: '',
        quantity: qty,
        qty: pr
      })
      // meals.push({
      //   name: "УКУПНО",
      //   price: '',
      //   quantity: meals.reduce((sum, item) => {
      //     return sum + item.quantity;
      //   }, 0),
      //   total: meals.reduce((sum, item) => {
      //     return sum + (item.price * item.quantity);
      //   }, 0)
      // })
      return test
    },
    filterReservedMeals() {
      let arr2 = []
      if (this.search5.length > 0) {
        if (this.reservedItemsData) {
          this.search5.filter((company) => {
            this.reservedItemsData.filter(item => {
              if (item.company.name == company) {
                item.meals.foods.filter((food, ind) => {
                  arr2.push({
                    'id': food.id,
                    'name': food.name,
                    'shift_one': item.shift == '1-smena' ? item.meals.shift_one : 0,
                    'shift_two': item.shift == '2-smena' ? item.meals.shift_two : 0,
                    'shift_three': item.shift == '3-smena' ? item.meals.shift_three : 0,
                    'total': food.total
                  })
                })
              }
            })
          })
        }
      }

      let newArr = this.groupBy(arr2, 'name')

      let name = ''
      let id = ''
      let finalArray = []
      for (let i in newArr) {
        let shift_one = 0
        let shift_two = 0
        let shift_three = 0
        let totalN = 0
        newArr[i].filter((item) => {
          if (item.name == i) {
            name = i;
            id = item.id;
            shift_one += item.shift_one;
            shift_two += item.shift_two;
            shift_three += item.shift_three;
            totalN += item.shift_one + item.shift_two + item.shift_three;
          }
        })
        finalArray.push({
          'id': id,
          'name': name,
          'shift_one': shift_one,
          'shift_two': shift_two,
          'shift_three': shift_three,
          'total': totalN,
        })

      }
      let result = [...finalArray];
      let total_shift_one = 0
      let total_shift_two = 0
      let total_shift_three = 0
      let total_totalN = 0
      result.filter(itm => {
        total_shift_one += itm.shift_one;
        total_shift_two += itm.shift_two;
        total_shift_three += itm.shift_three;
        total_totalN += itm.shift_one + itm.shift_two + itm.shift_three;
      })
      result.push({
        'id': '',
        'name': 'УКУПНО',
        'shift_one': total_shift_one,
        'shift_two': total_shift_two,
        'shift_three': total_shift_three,
        'total': total_totalN,
      })

      // const index = result.findIndex(e => e.name === 'УКУПНО')
      // result.push(result.splice(index, 1)[0])
      return result
    },
    filterMealsTotal() {
      let arr2 = []
      if (this.search.length > 0) {
        this.search.filter((company) => {
          this.desserts4.filter(item => {
            if (item.company == company) {
              item.foods.filter((food, ind) => {
                arr2.push({
                  'id': food.id,
                  'name': food.name,
                  'shift_one': food.shift_one,
                  'shift_two': food.shift_two,
                  'shift_three': food.shift_three,
                  'total': food.total
                })
              })
            }
          })
        })
      }
      let newArr = this.groupBy(arr2, 'name')

      let name = ''
      let id = ''
      let finalArray = []
      for (let i in newArr) {
        let shift_one = 0
        let shift_two = 0
        let shift_three = 0
        let totalN = 0
        newArr[i].filter((item) => {
          if (item.name == i) {
            name = i;
            id = item.id;
            shift_one += item.shift_one;
            shift_two += item.shift_two;
            shift_three += item.shift_three;
            totalN += item.total;
          }
        })
        finalArray.push({
          'id': id,
          'name': name,
          'shift_one': shift_one,
          'shift_two': shift_two,
          'shift_three': shift_three,
          'total': totalN,
        })
      }
      let result = [...finalArray];

      const index = result.findIndex(e => e.name === 'УКУПНО')
      result.push(result.splice(index, 1)[0])
      return result
    },
    filterObrok() {
      let arr2 = []
      if (this.search4.length > 0) {
        this.search4.filter((company) => {
          this.obroci.filter(item => {
            // if (this.translate(item.company) == company) {
            if (item.foods[0].company_id == company) {
              item.foods.filter((food, ind) => {
                arr2.push({
                  'id': food.id,
                  'name': food.name,
                  'user_id': food.user_id ? food.user_id : '',
                  'user': food.user ? food.user : '',
                  'shift_one': food.shift_one,
                  'shift_two': food.shift_two,
                  'shift_three': food.shift_three,
                  'total': food.total
                })
              })
            }
          })
        })
      }
      let newArr = this.groupBy(arr2, 'name')

      let name = ''
      let user = ''
      let userName = ''
      let id = ''
      let finalArray = []
      for (let i in newArr) {
        let shift_one = 0
        let shift_two = 0
        let shift_three = 0
        let totalN = 0
        newArr[i].filter((item) => {
          if (item.name == i) {
            name = i;
            user = item.user_id;
            userName = item.user;
            id = item.id;
            shift_one += item.shift_one;
            shift_two += item.shift_two;
            shift_three += item.shift_three;
            totalN += item.total;
          }
        })
        finalArray.push({
          'id': id,
          'menu': name,
          'user_name': userName,
          'user': user,
          'shift_one': shift_one,
          'shift_two': shift_two,
          'shift_three': shift_three,
          'total': totalN,
        })
      }
      let result = [...finalArray];

      const index = result.findIndex(e => e.menu === 'УКУПНО')
      result.push(result.splice(index, 1)[0])
      if (this.tab == 2) {
        this.excelData = result
        this.excelData.forEach(item => {
          if (item) {
            delete item.user;
            delete item.user_name;
          }
        })
        // console.log(this.excelData)
        // console.log(this.excelData[0])
        // if (this.excelData[0] != 'undefined') {
        //   this.excelData[this.excelData.length - 1].id = '.'
        // }
      }
      return result
    },
    filterObrokMoreRestaurants() {
      let arr2 = []
      if (this.search4.length > 0) {
        this.search4.filter((company) => {
          this.obroci.filter(item => {
            if (this.translate(item.company) == company) {
              item.foods.filter((food, ind) => {
                arr2.push({
                  'id': food.id,
                  'name': food.name,
                  'user_id': food.user_id ? food.user_id : '',
                  'user': food.user ? food.user : '',
                  'shift_one': food.shift_one,
                  'shift_two': food.shift_two,
                  'shift_three': food.shift_three,
                  'total': food.total
                })
              })
            }
          })
        })
      }
      let newArr = this.groupBy(arr2, 'name')

      let name = ''
      let user = ''
      let userName = ''
      let id = ''
      let finalArray = []
      for (let i in newArr) {
        let shift_one = 0
        let shift_two = 0
        let shift_three = 0
        let totalN = 0
        newArr[i].filter((item) => {
          if (item.name == i) {
            name = i;
            user = item.user_id;
            userName = item.user;
            id = item.id;
            shift_one += item.shift_one;
            shift_two += item.shift_two;
            shift_three += item.shift_three;
            totalN += item.total;
          }
        })
        finalArray.push({
          'id': id,
          'menu': name,
          'user_name': userName,
          'user': user,
          'shift_one': shift_one,
          'shift_two': shift_two,
          'shift_three': shift_three,
          'total': totalN,
        })
      }
      let result = [...finalArray];

      const index = result.findIndex(e => e.menu === 'УКУПНО')
      result.push(result.splice(index, 1)[0])
      // if (this.tab == 2) {
      //   this.excelData = result
      //   this.excelData.forEach(item => {
      //     delete item.user;
      //     delete item.user_name;
      //   })
      //   this.excelData[this.excelData.length -1].id = '.'
      // }
      return result
    },
    dateRangeText() {
      if (this.date.length == 1 || this.date[0] == this.date[1]) {
        return moment(this.date[0]).format("DD.MM.YYYY");
      }
      return (
          moment(this.date[0]).format("DD.MM.YYYY") +
          " - " +
          moment(this.date[1]).format("DD.MM.YYYY")
      );
    },
    dessertsMoreRestaurnats() {
      let arr2 = [];
      if (this.report2) {
        for (let company in this.report2) {
          arr2.push({company: company, foods: []});
          for (let shift in this.report2[company]) {
            for (let shift2 in this.report2[company][shift]) {
              let index = arr2.findIndex((i) => i.company === company);
              let index2 = ''
              if (this.report2[company][shift][shift2].meal_name) {
                index2 = arr2[index].foods.findIndex((i) => i.name === this.report2[company][shift][shift2].meal_name);
              } else {
                index2 = arr2[index].foods.findIndex((i) => i.name === shift2);
              }
              // let index2 = arr2[index].foods.findIndex((i) => i.name === shift2);
              if (index2 > -1) {
                arr2[index].id = this.report2[company][shift].company_id
                arr2[index].foods[index2].shift_one += shift === "1-smena" ? this.report2[company][shift][shift2].quantity : 0;
                arr2[index].foods[index2].shift_one_payed += shift === "1-smena" ? this.report2[company][shift][shift2].quantity_payed : 0;
                arr2[index].foods[index2].shift_two += shift === "2-smena" ? this.report2[company][shift][shift2].quantity : 0;
                arr2[index].foods[index2].shift_two_payed += shift === "2-smena" ? this.report2[company][shift][shift2].quantity_payed : 0;
                (arr2[index].foods[index2].shift_three += shift === "3-smena" ? this.report2[company][shift][shift2].quantity : 0),
                (arr2[index].foods[index2].shift_three_payed += shift === "3-smena" ? this.report2[company][shift][shift2].quantity_payed : 0),
                (arr2[index].foods[index2].total += this.report2[company][shift][shift2].quantity),
                (arr2[index].foods[index2].total_payed += this.report2[company][shift][shift2].quantity_payed);
              } else {
                // arr2[index].company = this.report2[company][shift].company
                arr2[index].id = this.report2[company][shift][shift2].company_id
                arr2[index].foods.push({
                  // name: Array.isArray(this.report2[company][shift][shift2].foods) && this.report2[company][shift][shift2].foods.length > 0 ? this.report2[company][shift][shift2].foods[0].name : this.report2[company][shift][shift2] && this.report2[company][shift][shift2].meal_name ? this.report2[company][shift][shift2].meal_name : shift2,
                  name: this.report2[company][shift][shift2] && this.report2[company][shift][shift2].meal_name ? this.report2[company][shift][shift2].meal_name : shift2,
                  id: this.report2[company][shift][shift2].id,
                  shift: shift,
                  shift_one: shift === "1-smena" ? this.report2[company][shift][shift2].quantity : 0,
                  shift_one_payed: shift === "1-smena" ? this.report2[company][shift][shift2].quantity_payed : 0,
                  shift_two: shift === "2-smena" ? this.report2[company][shift][shift2].quantity : 0,
                  shift_two_payed: shift === "2-smena" ? this.report2[company][shift][shift2].quantity_payed : 0,
                  shift_three: shift === "3-smena" ? this.report2[company][shift][shift2].quantity : 0,
                  shift_three_payed: shift === "3-smena" ? this.report2[company][shift][shift2].quantity_payed : 0,
                  total: this.report2[company][shift][shift2].quantity,
                  total_payed: this.report2[company][shift][shift2].quantity_payed,
                });
              }
            }
          }
        }

        arr2.forEach((company, index) => {
          company.foods.push({
            name: "УКУПНО",
            id: " ",
            shift_one: company.foods.reduce((sum, item) => {
              return sum + item.shift_one;
            }, 0),
            shift_one_payed: company.foods.reduce((sum, item) => {
              return sum + item.shift_one_payed;
            }, 0),
            shift_two: company.foods.reduce((sum, item) => {
              return sum + item.shift_two;
            }, 0),
            shift_two_payed: company.foods.reduce((sum, item) => {
              return sum + item.shift_two_payed;
            }, 0),
            shift_three: company.foods.reduce((sum, item) => {
              return sum + item.shift_three;
            }, 0),
            shift_three_payed: company.foods.reduce((sum, item) => {
              return sum + item.shift_three_payed;
            }, 0),
            total:
              company.foods.reduce((sum, item) => {
                return sum + item.shift_one;
              }, 0) +
              company.foods.reduce((sum, item) => {
                return sum + item.shift_two;
              }, 0) +
              company.foods.reduce((sum, item) => {
                return sum + item.shift_three;
              }, 0),
            total_payed:
              company.foods.reduce((sum, item) => {
                return sum + item.shift_one_payed;
              }, 0) +
              company.foods.reduce((sum, item) => {
                return sum + item.shift_two_payed;
              }, 0) +
              company.foods.reduce((sum, item) => {
                return sum + item.shift_three_payed;
              }, 0),
          });
        });
      }

      return arr2;
    },
    desserts2MoreRestaurnats() {
      let arr = [];
      if (this.reportMoreRestaurants) {
        for (let shift in this.reportMoreRestaurants) {
          for (let shift1 in this.reportMoreRestaurants[shift]) {
            for (let shift2 in this.reportMoreRestaurants[shift][shift1]) {
              this.reportMoreRestaurants[shift][shift1][shift2].foods.forEach((food) => {
                let index = arr.findIndex((i) => i.menu === food.name);
                if (index > -1) {
                  arr[index].shift_one += shift1 === "1-smena" ? this.reportMoreRestaurants[shift][shift1][shift2].quantity : 0;
                  arr[index].shift_two += shift1 === "2-smena" ? this.reportMoreRestaurants[shift][shift1][shift2].quantity : 0;
                  arr[index].shift_three += shift1 === "3-smena" ? this.reportMoreRestaurants[shift][shift1][shift2].quantity : 0;
                  arr[index].total += this.reportMoreRestaurants[shift][shift1][shift2].quantity;
                } else {
                  arr.push({
                    id: food.id,
                    menu: food.name,
                    shift_one:
                      shift1 === "1-smena" ? this.reportMoreRestaurants[shift][shift1][shift2].quantity : 0,
                    shift_two:
                      shift1 === "2-smena" ? this.reportMoreRestaurants[shift][shift1][shift2].quantity : 0,
                    shift_three:
                      shift1 === "3-smena" ? this.reportMoreRestaurants[shift][shift1][shift2].quantity : 0,
                    total: this.reportMoreRestaurants[shift][shift1][shift2].quantity,
                  });
                }
              });
            }
          }
        }

        arr.push({
          menu: "УКУПНО",
          id: " ",
          shift_one: arr.reduce((sum, item) => {
            return sum + item.shift_one;
          }, 0),
          shift_two: arr.reduce((sum, item) => {
            return sum + item.shift_two;
          }, 0),
          shift_three: arr.reduce((sum, item) => {
            return sum + item.shift_three;
          }, 0),
          total:
            arr.reduce((sum, item) => {
              return sum + item.shift_one;
            }, 0) +
            arr.reduce((sum, item) => {
              return sum + item.shift_two;
            }, 0) +
            arr.reduce((sum, item) => {
              return sum + item.shift_three;
            }, 0),
        });
      }

      return arr;
    },
    desserts() {
      let arr = [];
      if (this.report) {
        for (let shift in this.report) {
          for (let shift2 in this.report[shift]) {
            let index = arr.findIndex((i) => i.menu === shift2);

            if (index > -1) {
              arr[index].shift_one +=
                  shift === "1-smena" ? this.report[shift][shift2].quantity : 0;
              arr[index].shift_two +=
                  shift === "2-smena" ? this.report[shift][shift2].quantity : 0;
              arr[index].shift_three +=
                  shift === "3-smena" ? this.report[shift][shift2].quantity : 0;
              arr[index].total += this.report[shift][shift2].quantity;
            } else {
              arr.push({
                menu: shift2,
                id: this.report[shift][shift2].id,
                shift_one:
                    shift === "1-smena" ? this.report[shift][shift2].quantity : 0,
                shift_two:
                    shift === "2-smena" ? this.report[shift][shift2].quantity : 0,
                shift_three:
                    shift === "3-smena" ? this.report[shift][shift2].quantity : 0,
                total: this.report[shift][shift2].quantity,
              });
            }
          }
        }

        arr.push({
          menu: "УКУПНО",
          shift_one: arr.reduce((sum, item) => {
            return sum + item.shift_one;
          }, 0),
          shift_two: arr.reduce((sum, item) => {
            return sum + item.shift_two;
          }, 0),
          shift_three: arr.reduce((sum, item) => {
            return sum + item.shift_three;
          }, 0),
          total:
              arr.reduce((sum, item) => {
                return sum + item.shift_one;
              }, 0) +
              arr.reduce((sum, item) => {
                return sum + item.shift_two;
              }, 0) +
              arr.reduce((sum, item) => {
                return sum + item.shift_three;
              }, 0),
        });
      }
      //console.log(arr);
      return arr;
    },
    desserts2() {
      let arr = [];
      if (this.report) {
        for (let shift in this.report) {
          for (let shift2 in this.report[shift]) {
            this.report[shift][shift2].foods.forEach((food) => {
              let index = arr.findIndex((i) => i.menu === food.name);
              if (index > -1) {
                arr[index].shift_one +=
                    shift === "1-smena" ? this.report[shift][shift2].quantity : 0;
                arr[index].shift_two +=
                    shift === "2-smena" ? this.report[shift][shift2].quantity : 0;
                arr[index].shift_three +=
                    shift === "3-smena" ? this.report[shift][shift2].quantity : 0;
                arr[index].total += this.report[shift][shift2].quantity;
              } else {
                arr.push({
                  id: food.id,
                  menu: food.name,
                  shift_one:
                      shift === "1-smena"
                          ? this.report[shift][shift2].quantity
                          : 0,
                  shift_two:
                      shift === "2-smena"
                          ? this.report[shift][shift2].quantity
                          : 0,
                  shift_three:
                      shift === "3-smena"
                          ? this.report[shift][shift2].quantity
                          : 0,
                  total: this.report[shift][shift2].quantity,
                });
              }
            });
          }
        }

        arr.push({
          menu: "УКУПНО",
          id: " ",
          shift_one: arr.reduce((sum, item) => {
            return sum + item.shift_one;
          }, 0),
          shift_two: arr.reduce((sum, item) => {
            return sum + item.shift_two;
          }, 0),
          shift_three: arr.reduce((sum, item) => {
            return sum + item.shift_three;
          }, 0),
          total:
              arr.reduce((sum, item) => {
                return sum + item.shift_one;
              }, 0) +
              arr.reduce((sum, item) => {
                return sum + item.shift_two;
              }, 0) +
              arr.reduce((sum, item) => {
                return sum + item.shift_three;
              }, 0),
        });
      }

      return arr;
    },
    desserts3() {
      let arr = [];

      if (this.report) {
        for (let shift in this.report) {
          for (let shift2 in this.report[shift]) {
            this.report[shift][shift2].foods.forEach((food) => {
              if (typeof food.groceries_total != "undefined" && Array.isArray(food.groceries_total)) {

                food.groceries_total.forEach((grocery) => {
                  let index = arr.findIndex((i) => i.menu === grocery.name);

                  if (index > -1) {
                    arr[index].shift_one += shift === "1-smena" ? grocery.quantity : 0;
                    arr[index].shift_two += shift === "2-smena" ? grocery.quantity : 0;
                    arr[index].shift_three += shift === "3-smena" ? grocery.quantity : 0;
                    arr[index].total += grocery.quantity;
                  } else {
                    arr.push({
                      id: grocery.id,
                      menu: grocery.name,
                      shift_one: shift === "1-smena" ? grocery.quantity : 0,
                      shift_two: shift === "2-smena" ? grocery.quantity : 0,
                      shift_three: shift === "3-smena" ? grocery.quantity : 0,
                      total: grocery.quantity,
                    });
                  }
                });
              }
            });
          }
        }
      }

      return arr;
    },
    desserts4() {
      let arr2 = [];
      if (this.report2) {
        for (let company in this.report2) {
          arr2.push({company: company, foods: []});
          for (let shift in this.report2[company]) {
            for (let shift2 in this.report2[company][shift]) {
              let index = arr2.findIndex((i) => i.company === company);
              let index2 = ''
              if (this.report2[company][shift][shift2].meal_name) {
                index2 = arr2[index].foods.findIndex((i) => i.name === this.report2[company][shift][shift2].meal_name);
              } else {
                index2 = arr2[index].foods.findIndex((i) => i.name === shift2);
              }
              // let index2 = arr2[index].foods.findIndex((i) => i.name === shift2);
              if (index2 > -1) {
                arr2[index].id = this.report2[company][shift][shift2].company_id
                arr2[index].foods[index2].shift_one += shift === "1-smena" ? this.report2[company][shift][shift2].quantity : 0;
                arr2[index].foods[index2].shift_one_payed += shift === "1-smena" ? this.report2[company][shift][shift2].quantity_payed : 0;
                arr2[index].foods[index2].shift_two += shift === "2-smena" ? this.report2[company][shift][shift2].quantity : 0;
                arr2[index].foods[index2].shift_two_payed += shift === "2-smena" ? this.report2[company][shift][shift2].quantity_payed : 0;
                (arr2[index].foods[index2].shift_three += shift === "3-smena" ? this.report2[company][shift][shift2].quantity : 0),
                (arr2[index].foods[index2].shift_three_payed += shift === "3-smena" ? this.report2[company][shift][shift2].quantity_payed : 0),
                (arr2[index].foods[index2].total += this.report2[company][shift][shift2].quantity),
                (arr2[index].foods[index2].total_payed += this.report2[company][shift][shift2].quantity_payed);
              } else {
                arr2[index].id = this.report2[company][shift][shift2].company_id
                arr2[index].foods.push({
                  // name: Array.isArray(this.report2[company][shift][shift2].foods) && this.report2[company][shift][shift2].foods.length > 0 ? this.report2[company][shift][shift2].foods[0].name : this.report2[company][shift][shift2] && this.report2[company][shift][shift2].meal_name ? this.report2[company][shift][shift2].meal_name : shift2,
                  name: this.report2[company][shift][shift2] && this.report2[company][shift][shift2].meal_name ? this.report2[company][shift][shift2].meal_name : shift2,
                  id: this.report2[company][shift][shift2].id,
                  shift: shift,
                  shift_one: shift === "1-smena" ? this.report2[company][shift][shift2].quantity : 0,
                  shift_one_payed: shift === "1-smena" ? this.report2[company][shift][shift2].quantity_payed : 0,
                  shift_two: shift === "2-smena" ? this.report2[company][shift][shift2].quantity : 0,
                  shift_two_payed: shift === "2-smena" ? this.report2[company][shift][shift2].quantity_payed : 0,
                  shift_three: shift === "3-smena" ? this.report2[company][shift][shift2].quantity : 0,
                  shift_three_payed: shift === "3-smena" ? this.report2[company][shift][shift2].quantity_payed : 0,
                  total: this.report2[company][shift][shift2].quantity,
                  total_payed: this.report2[company][shift][shift2].quantity_payed,
                });
              }
            }
          }
        }

        arr2.forEach((company, index) => {
          company.foods.push({
            name: "УКУПНО",
            id: " ",
            shift_one: company.foods.reduce((sum, item) => {
              return sum + item.shift_one;
            }, 0),
            shift_one_payed: company.foods.reduce((sum, item) => {
              return sum + item.shift_one_payed;
            }, 0),
            shift_two: company.foods.reduce((sum, item) => {
              return sum + item.shift_two;
            }, 0),
            shift_two_payed: company.foods.reduce((sum, item) => {
              return sum + item.shift_two_payed;
            }, 0),
            shift_three: company.foods.reduce((sum, item) => {
              return sum + item.shift_three;
            }, 0),
            shift_three_payed: company.foods.reduce((sum, item) => {
              return sum + item.shift_three_payed;
            }, 0),
            total:
              company.foods.reduce((sum, item) => {
                return sum + item.shift_one;
              }, 0) +
              company.foods.reduce((sum, item) => {
                return sum + item.shift_two;
              }, 0) +
              company.foods.reduce((sum, item) => {
                return sum + item.shift_three;
              }, 0),
            total_payed:
              company.foods.reduce((sum, item) => {
                return sum + item.shift_one_payed;
              }, 0) +
              company.foods.reduce((sum, item) => {
                return sum + item.shift_two_payed;
              }, 0) +
              company.foods.reduce((sum, item) => {
                return sum + item.shift_three_payed;
              }, 0),
          });
        });
      }

      return arr2;
    },
    dessertsFood() {
      let arr = [];
      let arr1 = [];
      // if (this.reportForFood) {
      //   for (let shift in this.reportForFood) {
      //     for (let shift2 in this.reportForFood[shift]) {
      //       for (let shift3 in this.reportForFood[shift][shift2]) {
      //         let index = arr.findIndex((i) => i.menu === shift3);
      //         if (index > -1) {
      //           arr[index].shift_one += shift2 === "1-smena" ? this.reportForFood[shift][shift2][shift3].quantity : 0;
      //           arr[index].shift_two += shift2 === "2-smena" ? this.reportForFood[shift][shift2][shift3].quantity : 0;
      //           arr[index].shift_three += shift2 === "3-smena" ? this.reportForFood[shift][shift2][shift3].quantity : 0;
      //           arr[index].total += this.reportForFood[shift][shift2][shift3].quantity;
      //         } else {
      //           arr.push({
      //             menu: shift3,
      //             shift_one: shift2 === "1-smena" ? this.reportForFood[shift][shift2][shift3].quantity : 0,
      //             shift_two: shift2 === "2-smena" ? this.reportForFood[shift][shift2][shift3].quantity : 0,
      //             shift_three: shift2 === "3-smena" ? this.reportForFood[shift][shift2][shift3].quantity : 0,
      //             total: this.reportForFood[shift][shift2][shift3].quantity,
      //           });
      //         }
      //       }
      //     }
      //   }
      //
      //   arr.push({
      //     menu: "УКУПНО",
      //     shift_one: arr.reduce((sum, item) => {
      //       return sum + item.shift_one;
      //     }, 0),
      //     shift_two: arr.reduce((sum, item) => {
      //       return sum + item.shift_two;
      //     }, 0),
      //     shift_three: arr.reduce((sum, item) => {
      //       return sum + item.shift_three;
      //     }, 0),
      //     total:
      //       arr.reduce((sum, item) => {
      //         return sum + item.shift_one;
      //       }, 0) +
      //       arr.reduce((sum, item) => {
      //         return sum + item.shift_two;
      //       }, 0) +
      //       arr.reduce((sum, item) => {
      //         return sum + item.shift_three;
      //       }, 0),
      //   });
      //   this.$forceUpdate()
      // }

      if (this.foodByOrders) {
        this.foodByOrders.filter((item) => {
          let index1 = arr1.findIndex((i) => i.menu === item.meal_name);

          if (index1 > -1) {
            arr1[index1].shift_one += item.shift === 1 ? item.quantity : 0;
            arr1[index1].shift_two += item.shift === 2 ? item.quantity : 0;
            arr1[index1].shift_three += item.shift === 3 ? item.quantity : 0;
            arr1[index1].total += item.quantity;
          } else {
            arr1.push({
              menu: item.meal_name,
              shift_one: item.shift === 1 ? item.quantity : 0,
              shift_two: item.shift === 2 ? item.quantity : 0,
              shift_three: item.shift === 3 ? item.quantity : 0,
              total: item.quantity,
            });
          }
        })
        arr1.push({
          menu: "УКУПНО",
          shift_one: arr1.reduce((sum, item) => {
            return sum + item.shift_one;
          }, 0),
          shift_two: arr1.reduce((sum, item) => {
            return sum + item.shift_two;
          }, 0),
          shift_three: arr1.reduce((sum, item) => {
            return sum + item.shift_three;
          }, 0),
          total:
            arr1.reduce((sum, item) => {
              return sum + item.shift_one;
            }, 0) +
            arr1.reduce((sum, item) => {
              return sum + item.shift_two;
            }, 0) +
            arr1.reduce((sum, item) => {
              return sum + item.shift_three;
            }, 0),
        });
        this.$forceUpdate()
      }

      // return arr;
      return arr1;
    },
  },
};
</script>
<style>
.third-width {
  width: 33%;
}

.full-width {
  width: 100%;
}

.headline-width {
  width: 85%;
}

.subheadline-width {
  width: 15%;
}

.test_klasa td {
  vertical-align: unset !important;
  padding-top: 7px !important;
  padding-bottom: 7px !important;
}
</style>
<style scoped>

.v-data-table >>> tr:nth-of-type(odd) {
  background-color: rgba(0, 0, 0, .05) !important;
}

table tbody tr:nth-of-type(odd) {
  background-color: rgba(0, 0, 0, .05) !important;
}

div.v-select__selections span.v-size--small span.v-chip__content {
  font-size: 10px !important;
}

.v-alert__icon.v-icon {
  font-size: 15px !important;
}

</style>
<style>
@media screen and (max-width: 1230px) and (min-width: 929px) {
  .responsive-margin {
    margin-top: -30px;
    margin-bottom: -30px;
  }

  .button-margin {
    margin-top: -10px !important;
  }

  .phone-margin {
    margin-top: 0;
  }
  .dodatna-margina {
    margin-top: 20px !important;
  }
}
@media screen and (max-width: 960px) and (min-width: 931px) {
  .phone-margin {
    margin-top: 0;
  }
}
@media screen and (max-width: 930px) and (min-width: 768px) {
  .col-sm-12, .col-md-4, .col-lg-4 {
    width: 33.333% !important;
    flex: unset;
  }

  .responsive-margin {
    margin-top: -30px;
    margin-bottom: -30px;
  }

  .button-margin {
    margin-top: -10px !important;
  }


  .phone-margin {
    margin-top: 0;
  }
}

@media (max-width: 767px) {
  .third-width {
    width: 50%;
  }

  .v-text-field {
    padding-top: 14px !important;
  }

  .headline-width {
    width: 60%;
    text-align: left;
  }

  .subheadline-width {
    width: 40%;
  }

  .col-sm-12, .col-md-4, .col-lg-4 {
    width: 100% !important;
  }

  #izv .v-data-table__mobile-row__cell {
    text-align: left !important;
    width: 100%;
  }

  #korisnik > div {
    padding: 0 !important;
  }

  #korisnik .v-card__title, #korisnik .v-card__text {
    font-size: 16px !important;
    padding: 0 !important;
  }

  #stampaj {
    display: none;
  }

  .v-application .my-6 {
    margin-top: 15px !important;
  }

  .button-margin {
    margin-top: -10px !important;
  }

  .phone-margin .pt-2 {
    padding-top: 22px !important;
  }
}

@media only screen and (min-width: 600px) and (max-width: 650px) {
  div.telefon-row div.phone-margin {
    margin-top: 2px !important;
  }

  div.telefon-row div.phone-margin div.pt-5 {
    padding-top: 2px !important;
  }

  div.telefon-row div.button-margin.my-6 {
    margin-top: 0 !important;
    margin-bottom: 5px !important;
  }

  div.telefon-row div.button-margin div button {
    width: 48% !important;
  }
}

@media print {
  div.v-data-table__mobile-row__header {
    display: none;
  }

  @page {
    margin: 1in;
  }

  .firm-margin {
    margin-top: 10px;
    margin-bottom: 10px;
  }

  .no-print {
    display: none;
  }

  .printable {
    width: 100%;
  }

  table td {
    font-size: 15px;
  }

  tr:nth-child(even) {
    background-color: #fff;
  }

  @-moz-document url-prefix() {
    table {
      width: 100% !important;
      border: 1px solid #ddd !important;
    }
    th, td {
      border: 1px solid #ddd !important;
    }
  }
}
</style>
