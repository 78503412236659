<template>
  <v-container>
    <v-row>
      <v-col cols="12" class="title">
        FCM Notifikacije <span>({{ restaurant ? restaurant.name : 'Restoran nije selektovan' }})</span>
      </v-col>
      <v-col cols="12">
        <v-row>
          <v-col cols="4">
            <v-row>
              <v-col cols="12">
                <v-select
                  :items="projects"
                  v-model="notification.project"
                  label="Projekat"
                  filled
                  hide-details
                  dense
                  item-text="name"
                  item-id="code"
                  return-object
                >
                </v-select>
                <br>
                <v-radio-group
                  v-model="row"
                  row
                >
                  <v-radio
                    label="Restoran"
                    value="restaurant"
                  ></v-radio>
                  <v-radio
                    label="Komapnije"
                    value="company"
                  ></v-radio>
                </v-radio-group>
              </v-col>
              <v-col cols="12">
                <v-row>
                  <v-col cols="12">
                    <v-text-field solo dense label="Title" hide-details v-model="title"></v-text-field>
                  </v-col>
                  <v-col cols="12">
                    <v-textarea solo dense label="Message" hide-details v-model="message"></v-textarea>
                  </v-col>
                </v-row>
              </v-col>
            </v-row>
          </v-col>
          <v-col cols="8">
            <v-row>
              <v-col cols="12" v-if="row === 'company'">
                <v-row>
                  <v-col>
                    <v-select
                      :items="companies"
                      v-model="selectedCompanies"
                      label="Company"
                      filled
                      hide-details
                      dense
                      item-text="name"
                      item-id="id"
                      return-object
                      chips
                      multiple
                      :append-icon="selectedCompanies.length ? 'mdi-minus-box-outline' : 'mdi-plus-box-outline'"
                      @click:append="selectedCompanies.length ? selectedCompanies = [] : selectedCompanies = companies"
                    ></v-select>
                  </v-col>
                  <v-col cols="auto">
                    <v-btn @click="getUsers" color="primary" height="52px" class="mx-2">Trazi</v-btn>
                  </v-col>
                </v-row>
              </v-col>
              <v-col cols="12">
                <v-data-table
                  v-model="selected"
                  :headers="headers"
                  :items="users"
                  :items-per-page="5"
                  class="elevation-1"
                  item-key="id"
                  show-select
                >
                  <template v-slot:item.user.messageStatus="{ item }">
                    {{ item.messageStatus || 'pending' }}
                  </template>
                </v-data-table>
              </v-col>
            </v-row>
          </v-col>
        </v-row>
      </v-col>
      <v-col cols="12">
        <v-btn @click="send" color="primary" :loading="loading" :disable="loading">Send</v-btn>
      </v-col>
    </v-row>
  </v-container>
</template>


<script>

  import axios from '../plugins/axios'

  export default {
    data() {
      return {
        selected: [],
        notification: {
          project: {code: 'ordera-e6eb8', name: 'Ordera'}
        },
        projects: [
          {code: 'ordera-e6eb8', name: 'Ordera'},
          {code: 'order-notification-8f5f2', name: 'Restorani'}
        ],
        headers: [
          {
            text: 'Name',
            align: 'start',
            sortable: false,
            value: 'name',
          },
          { text: 'Lastname', value: 'lastname' },
          { text: 'Email', value: 'email' },
          { text: 'Tip', value: 'type' },
          { text: 'State', value: 'messageStatus' }
        ],
        users: [],
        loading: false,
        info: {
          state: false,
          list: []
        },
        title: '',
        message: '',
        companies: [],
        selectedCompanies: [],
        row: 'restaurant'
      }
    },
    created() {
      this.getUsers()
      this.loadCompanies()
    },
    methods: {
      loadCompanies() {
        axios.get('/companies?restaurants').then(res => {
          let companies = res.data
          companies.forEach(item => {
            if(item.children && item.children.length) {
              this.companies = this.companies.concat(item.children)
            }
            else {
              this.companies.push(item)
            }
          })
        })
      },
      getUsers() {
        let data = ''
        if(this.row === 'restaurant') {
          data = 'restaurant_ids=' + this.restaurant.id
        }
        else {
          data = 'company_ids=' + this.selectedCompanies.map(i => i.id)
        }
        axios.get('https://app.ordera.app/api/devices/get-users?project=' + this.notification.project.code + '&row=' + this.row + '&' + data).then(res => {
          this.users = res.data
        })
      },
      send() {
        this.loading = true
        axios.post('https://app.ordera.app/api/devices/send-fcm', {
          'project': this.notification.project.code,
          'user_ids': this.selected.map(user => user.id),
          'title': this.title,
          'body': this.message
        }).then(res => {
          this.info.list = res.data
          this.users = this.users.map(user => {
            let newUser = this.info.list.find(i => i.user_id === user.id)
            if(newUser) {
              user.messageStatus = newUser.response.error ? 'failed' : 'ok'
            }
            return user;
          })
        }).finally(() => {
          this.loading = false
          this.info.state = true
        })
      }
    },
    computed: {
      restaurant() {
        return this.$store.state.restaurant
      }
    },
    watch: {
      restaurant() {
        this.getUsers()
        this.loadCompanies()
      },
      'notification.project'() {
        this.getUsers()
      },
      row() {
        this.getUsers()
      }
    }
  }

</script>
