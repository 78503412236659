<template>
  <v-row class="py-4 home-page">
    <template>
      <v-dialog persistent v-model="current_location" max-width="60%" class="p-2">
        <v-card>
          <v-card-title class="error position-relative">
            <span class="headline white--text">
              {{ $t("delivery_address") }}
            </span>
            <v-btn v-if="user_location != ''" color="primary" text style="position: absolute;top: 5px;color: white !important;right: 5px" @click="current_location = false">
              {{ translate("X") }}
            </v-btn>
          </v-card-title>

          <v-col cols="12">
            <v-row>
              <v-col cols="6">
                <div>
                  <p style="font-size: 13px" v-if="user_location">{{ $t('all_your_locations') }}</p>
                  <p class="mb-1" style="font-size: 13px;font-weight: 400;display: flex;justify-content: space-between;align-items: center;border-bottom: 1px solid #cfcfcf;padding: 2px 0" v-for="address in allUserLocation">
                    <span>{{ address.address }}</span>
                    <span @click.prevent="deleteLocation(address)" style="font-size: 11px"><v-icon style="color: #c20606;cursor:pointer;">mdi-trash-can-outline</v-icon></span>
                  </p>
                  <br>
                </div>
                <div>
                  <p style="font-size: 13px" class="mb-0" v-if="user_location">{{ $t('add_new_location') }}</p>
                  <v-text-field label="Укуцај адресу" v-model="new_user_location.address"></v-text-field>
                  <p style="font-size: 13px" class="mb-0">{{ $t('coordinates') }} :</p>
                  <v-col cols="12" class="mt-0">
                    <v-row>
                      <v-text-field label="Латитуда" class="pr-2" v-model="new_user_location.latitude"></v-text-field>
                      <v-text-field label="Лонгитуда" v-model="new_user_location.longitude"></v-text-field>
                    </v-row>
                  </v-col>
                  <div class="text-center">
                    <v-btn @click.prevent="addUserLocation()" style="background: #FB6D3B;color: #fff;font-weight: 700;font-size: 10px;border-radius: 5px">{{ $t('add_new_location') }}</v-btn>
                  </div>
                </div>
              </v-col>
              <v-col cols="6">
                <p>Удаљеност од ресторана: <span v-if="user_location">{{ user_location.distance }}</span></p>
                <l-map style="height: 230px;" :zoom="zoom" :center="center">
                  <l-tile-layer :url="url" :attribution="attribution"></l-tile-layer>
                  <l-marker :lat-lng="markerLatLng"></l-marker>
                </l-map>
              </v-col>
            </v-row>
          </v-col>
          <div class="pb-7 text-center">
            <v-btn color="gray" dark @click="current_location = false">
              {{ $t("close") }}
            </v-btn>
<!--            <v-btn class="ml-3" color="success" dark @click.prevent="addNewLocation()">-->
<!--              {{ user_location == '' ? 'Додај локацију' : 'Измени локацију'}}-->
<!--            </v-btn>-->
          </div>
        </v-card>
      </v-dialog>
      <v-dialog v-if="guestErrorMessagePopup" v-model="guestErrorMessagePopup" max-width="60%" class="p-2">
        <v-card>
          <v-card-title class="error position-relative">
            <span class="headline white--text">
              {{ $t("delivery_time_not_set") }}
            </span>
            <v-btn color="primary" text style="position: absolute;top: 5px;color: white !important;right: 5px" @click="guestErrorMessagePopup = false">
              {{ translate("X") }}
            </v-btn>
          </v-card-title>

          <v-col cols="12">
            <p class="text-center">{{ guestErrorMessage }}</p>
          </v-col>
          <div class="pb-7 text-center">
            <v-btn color="gray" dark @click="guestErrorMessagePopup = false">
              {{ $t("close") }}
            </v-btn>
          </div>
        </v-card>
      </v-dialog>
      <v-dialog v-model="dialogRestoran" max-width="70%" class="p-2">
        <v-card>
          <v-card-title class="primary position-relative">
              <span class="headline white--text"
              >{{ $t("select_restaurant") }}</span
              >
            <v-btn color="primary" text style="position: absolute;top: 5px;color: white !important;right: 5px" @click="dialogRestoranClose">
              {{ translate("X") }}
            </v-btn>
          </v-card-title>
          <br><br>
          <v-col cols="12">
            <v-row class="align-content-center justify-content-center">
              <div class="card text-center" style="width: 33%;padding: 10px 5px;" v-for="res in allRestorants">
                <div style="box-shadow: 0 1px 14px 2px #cfcfcf;border-radius: 7px;height: 230px;position: relative;">
                  <img onerror="javascript:this.src='https://app.restorani.bitlab.host/gallery/no-image-restaurant.jpg'" style="cursor: pointer" @click="selectRes(res)" class="card-img-top " width="100%" height="75%" :src="res.settings ?  conf.apiUrlBase + '/gallery/' + res.id + '/' + res.settings.logo : ''" alt="Logo">
                  <div class="card-body">
                    <h5 class="card-title">{{ res.name }}</h5>
                    <p style="font-size: 11px;" class="card-text mb-0">
                      <span>{{ res.address }}</span>
                    </p>
                    <p style="font-size: 11px;" class="card-text mb-0 font-weight-bold">
                      <span>{{ res.city ? res.city.name : '' }}</span>
                    </p>
<!--                    <a @click="selectRes(res)" style="font-size: 10px;" href="#" class="btn-primary">Изабери</a>-->
                  </div>
                </div>
              </div>
            </v-row>
            <!--                <v-select-->
            <!--                    v-model="restoran_id"-->
            <!--                    :items="allRestorants"-->
            <!--                    :label="translate('Ресторан')"-->
            <!--                    item-text="name"-->
            <!--                    item-id="id"-->
            <!--                    return-object-->
            <!--                ></v-select>-->
          </v-col>
          <br>
          <br>
          <!--              <div class="pb-7 text-center">-->
          <!--                <v-btn color="success" dark @click="chooseRestoran">-->
          <!--                  {{ translate("Изабери ресторан") }}-->
          <!--                </v-btn>-->
          <!--              </div>-->
        </v-card>
      </v-dialog>
      <v-dialog v-model="dialogNewRestoran" persistent max-width="80%" class="p-2">
        <v-card>
          <v-card-title class="primary position-relative">
              <span class="headline white--text"
              >{{ $t("select_restaurant") }}</span
              >
          </v-card-title>
          <br><br>
          <v-col cols="12">
            <v-row class="align-content-center justify-content-center">
              <div class="card text-center" style="width: 33%;padding: 10px 5px;" v-for="res in chooseNewRestoran">
                <div style="box-shadow: 0 1px 14px 2px #cfcfcf;border-radius: 7px;height: 230px;position: relative;">
                  <img onerror="javascript:this.src='https://app.restorani.bitlab.host/gallery/no-image-restaurant.jpg'" @error="backupImage" style="cursor: pointer;border-top-left-radius: 10px;border-top-right-radius: 10px;" @click="selectNewRes(res)" class="card-img-top" width="100%" height="80%" :src="res.settings ?  conf.apiUrlBase + '/gallery/' + res.id + '/' + res.settings.header : backupImage" alt="Logo">
                  <div class="card-body">
                    <h5 class="card-title">{{ res.name }}</h5>
                    <p style="font-size: 11px;" class="card-text mb-0 font-weight-bold">
                      <span>
                          <v-icon small class="mr-2">
                            mdi-edit_location
                          </v-icon>
                        {{ res.city ? res.city.name : '' }}
                      </span>
                    </p>
                  </div>
                </div>
              </div>
            </v-row>
          </v-col>
          <br><br>
        </v-card>
      </v-dialog>
      <v-row justify="center" >
        <div v-if="restaurant && restaurant.settings && restaurant.settings.header"
          class="hero"
          :style="`background: url('https://app.restorani.bitlab.host/gallery/${restaurant.id}/${restaurant.settings.header}'); background-size: cover; width:100vw; background-repeat: no-repeat;`"
        >
          <img alt="hero=image" class="hero-logo" :src="conf.apiUrlBase + '/gallery/' + restaurant.id + '/' + restaurant.settings.logo"/>
        </div>
        <v-col class="px-5">
          <v-card-title>
            <v-row cols="12" align="center" justify="center" class="mx-1">
              <strong style="text-align: center;padding: 20px 0;font-family: 'Poppins', Helvetica, sans-serif !important;" class="rezervisati div-okolo">
                <span v-if="restaurant && restaurant.settings.rok_sati">
                  <span style="color: #606060;font-weight: 400">{{$t("ordering_cancelling_shift_change_before")}}</span>
                  <span style="color: #000000;">
                    {{switchDay(restaurant.settings.dan).toUpperCase()}} ДО {{restaurant.settings.broj_sati + ':00' }}</span>
<!--                    {{translate("САТИ")}}<br>-->
                </span>
                <span v-if="restaurant && restaurant.settings.rok_dana">
                  <span v-if="restaurant.settings.order_before > 0">
                    <span style="color: #606060;font-weight: 400">{{ $t("order_cancel_change_shift_mandatory") }}</span>
                    <span style="color: #000000;">
                      {{restaurant.settings.order_before}}
                      {{(restaurant.settings.order_before==1)?translate("дан"):translate("дана")}}
                      {{$t("early")}}{{restaurant.settings.order_before_hours > 0 ?  ', ' + $t("to").toLowerCase() + ' '+ restaurant.settings.order_before_hours + ' ' + $t("hours").toLowerCase() : ''}}
                    </span>
                  </span>
                  <span v-else>{{$t("order_cancel_change_shift")}}</span>
                </span>
              </strong>
            </v-row>
          </v-card-title>
          <!-- todo comapny.contract treba sa API ja dodje cast -->
          <v-row v-if="comapny.contract == 4" class="ma-5">
              <v-col cols="6" md="3" class="card text-center" v-for="(res, i) in partners" :key="i">
                <v-card @click="openOrdera(res)" style="border-top-left-radius:12px;border-top-right-radius:12px;">
                  <img style="cursor: pointer;border-top-left-radius:12px;border-top-right-radius:12px;" class="card-img-top " width="100%" height="75%" :src="res.settings && res.settings.logo ?  'https://app.restorani.bitlab.host/gallery/' + res.id + '/' + res.settings.logo : './pexels-lumn-1410235.jpg'" alt="Logo">
                  <div class="card-body">
                    <h5 class="text-h6">
                      {{res.name}}
                    </h5>
                    <p class="text-body-2 pb-4">
                      <span>{{res.city ? res.city.name : ''}}</span>
                    </p>
                  </div>
                </v-card>
              </v-col>
          </v-row>
          <template v-else>
            <v-progress-linear indeterminate v-if="menues.length === 0 && !menuEmpty"></v-progress-linear>
            <v-row justify="center" align="center" class="text-h5 pa-4" v-if="menuEmpty && comapny.type !== 'more_restaurants'">
              Nema menija
            </v-row>
            <template v-for="(item,index) in menues">
              <span class="mb-1 d-block" :id="index" :ref="index"></span>
              <div class="relative" style="width: 98%;text-align: center;">
                <v-row no-gutters class="pa-2" style="border:1px solid rgba(0, 0, 0, 0.1);border-radius: 12px">
                  <v-col cols="12" class="pa-0">
                    <p class="mb-0" v-if="index == 0" style="background: #FFFBF9;padding: 15px 0;border-bottom: 1px solid rgba(0, 0, 0, 0.1);">{{ $t('choose_day') }}</p>
                  </v-col>
                  <v-col sm="4" cols="12" class="pa-0"></v-col>
                  <v-col sm="4" cols="12" class="pa-0" align="center">
                    <v-row no-gutters justify="center">
                      <div @click="scrollMeBack(index)" style="cursor: pointer">
                        <span class="strelice"> < </span>
                      </div>
                      <p style="color:#000; font-family: 'Poppins', Helvetica, sans-serif !important; font-weight: 700" align="center" class="px-2 datum mb-2 mt-2 dan-u-nedelji">
                        {{ $t(changeDay(item.name.split(' ')[0])) + ' ' + item.name.split(' ')[1] + '.' }}
                      </p>
                      <div @click="scrollMeTo(index)" style="cursor: pointer">
                        <span class="strelice"> > </span>
                      </div>
                    </v-row>
                    <v-autocomplete
                      class="pt-4"
                      :items="availableCompanies"
                      v-model="selectedCompany"
                      label="Odaberi Kompaniju"
                      v-if="availableCompanies.length && availableCompanies.length > 1"
                      item-value="id"
                      item-text="name"
                      outlined
                      return-object
                    >
                    </v-autocomplete>
                  </v-col>
                  <v-col sm="4" cols="12" class="pa-0" :align="isMobile ? 'center' : 'end'">
                    <v-btn
                      v-if="$store.state.user.type === 'company'"
                      @click="show_order = true;show_order_details = item"
                      color="#FB6D3B" dark small
                      style="border-radius:8px; text-transform: none"
                      elevation="0"
                      class="mr-2"
                    >
                      {{ $t('show_order') }}
                    </v-btn>
                  </v-col>
                </v-row>
                <div v-if="$store.state.user.type === 'guest'" class="position-relative">
                  <p style="position:absolute;top: 47px;left: calc(50% - 60px);">{{ $t('time_of_delivery') }}</p>
                  <v-row class="mt-3">
                    <v-col cols="1"> </v-col>
                    <v-col cols="5">
                      <v-select
                          :items="setHoures"
                          item-value="val"
                          v-model="delivery_from[index]"
                          label="Сати"
                          @change="setDeliveryTime(index)"
                          outlined>
                      </v-select>
                    </v-col>
                    <v-col cols="5">
                      <v-select
                          :items="setMinutes"
                          @change="setDeliveryTime(index)"
                          item-value="val"
                          v-model="delivery_to[index]"
                          :label="$t('minutes')"
                          outlined>
                      </v-select>
                    </v-col>
                  </v-row>
                </div>
                <p v-if="mealOrderdErrorMessage && item.date == errorDate" class="text-center font-weight-bold my-0 py-0" style="color: #c20606;font-size: 19px">Не можете отказати преузети оброк</p>
  <!-- Ne znam za sta sluzi -->
  <!--              <p style="position: absolute;top: 6px;right: -400px;">-->
  <!--                <v-tooltip bottom>-->
  <!--                  <template v-slot:activator="{ on, attrs }">-->
  <!--                    <v-btn-->
  <!--                      v-if="!item.disabled"-->
  <!--                      class="trash-icon"-->
  <!--                      icon-->
  <!--                      color="primary"-->
  <!--                      @click="-->
  <!--                        $store.state.user.type === 'customer'-->
  <!--                          ? revert(item)-->
  <!--                          : revertMore(item)-->
  <!--                      "-->
  <!--                      v-bind="attrs"-->
  <!--                      v-on="on"-->
  <!--                    >-->
  <!--                      <v-icon style="color: #c20606">mdi-trash-can-outline</v-icon>-->
  <!--                    </v-btn>-->
  <!--                  </template>-->
  <!--                  <span>{{ $t("delete_order") }}</span>-->
  <!--                </v-tooltip>-->
  <!--              </p>-->
                <div class="text-right" v-if="$store.state.user.type === 'guest'" style="position: absolute;top: 90px;right: -390px;font-size: 23px;">
                  <span class="font-weight-bold">Σ - {{ total[item.date] }},00 {{ $store.state.company.restaurant.settings.price }}</span>
                  <div class="d-flex" style="font-size: 11px; background: #fff;padding: 3px;border:1px solid #cfcfcf;border-radius: 6px;align-items: center">
                    <div class="text-right"></div>
                    <div class="mr-3">
                      <a v-if="! set_user_location[item.date]" @click.prevent="showSelect = true" style="padding: 4px 15px;color: #a9a8a8;font-weight: 700;text-decoration: none;display: flex" href="">
                        <v-icon
                            large
                            style="font-size: 17px"
                            color="green darken-2"
                        >mdi-domain
                        </v-icon>
                        <span v-if="!showSelect" class="pl-3">
                          {{ $t('delivery_address') }}?
                        </span>
                        <select v-if="showSelect" @change="changeAddress(set_user_location[item.date], item.date)" v-model="set_user_location[item.date]" style="width: 100%;padding: 0 15px;border-radius: 1px;cursor: pointer;color: #656565">
                          <option v-for="address in allUserLocation" :value="address">{{ address.address }}</option>
                        </select>
                      </a>
                      <span v-else style="padding: 0 15px;color: #a9a8a8;font-weight: 700;text-decoration: none;display: flex" class="pl-3" >
                        <v-icon
                            large
                            style="font-size: 17px"
                            color="green darken-2"
                        >mdi-domain
                      </v-icon>
                      <span v-if="set_user_location[item.date]">
                        {{ set_user_location[item.date].address }}
                        <select @change="changeAddress(set_user_location[item.date], item.date)" v-model="set_user_location[item.date]" style="width: 100%;padding: 0 15px;border-radius: 1px;cursor: pointer;color: #656565">
                          <option :selected="set_user_location[item.date].id" v-for="address in allUserLocation" :value="address">{{ address.address }}</option>
                        </select>
                      </span>
                    </span>
                    </div>
                    <div style="border-left: 1px solid #cfcfcf">
                      <v-icon>mdi-location-radius</v-icon>
                      <a @click.prevent="current_location = true, locatorButtonPressed()" style="padding: 4px 15px;font-weight: 700;text-decoration: none" href="">
                        <v-icon style="font-size: 17px"
                                color="green darken-2">mdi-call_split</v-icon>
                        {{ user_location && user_location.address ? "Измени тренутну локацију" : "Промени тренутну локацију"}}
                      </a>
                    </div>
                  </div>
                </div>
              </div>
              <div style="display: flex;align-items: center" class="transfer-lista" v-if="$store.state.company.parent_id&&$store.state.user.type!=3&&$store.state.user.is_transferable">
                <label v-if="!item.disabled" style="margin-bottom: 25px; padding-right: 7px;text-transform: uppercase;font-weight: 700" for="">{{ $t('location') }}: </label>
                <v-select
                 v-if="!item.disabled"
                  v-model="item.location"
                  :items="locations"
                  filled
                  max-width="60%"
                ></v-select>
              </div>
              <!-- <span v-html="item.name"></span> -->
              <div>


                <v-row justify="center" class="mt-3 px-4">
                  <template v-for="header in headers">
                    <v-col :cols="withDimension" align="center" class="py-0">
                      <div style="background: rgb(255, 251, 249);padding: 8px 0px;border: 1px solid rgba(0, 0, 0, 0.1); border-radius: 12px; font-weight: 600">
                      {{ $t(changeDay(header.text)) }}
                      </div>
                    </v-col>
                  </template>
                </v-row>
                <v-row align="start" class="px-4">
                  <template v-for="shift in [item]">
                    <v-col :cols="withDimension" v-if="comapny.shift_one" class="pt-0">
                      <template v-for="meal in shift.shift_one">
                        <food-order
                          @update="update"
                          :params="{
                              selectedMeal,
                              selectedItem,
                              meal,
                              item,
                              restaurant,
                              dayTo,
                              checkTime,
                              shift: {num: 1, text: 'one'},
                              selShift2,
                              selectedDateImage,
                              delivery_from,
                              delivery_to,
                              index,
                              dialog
                            }">
                        </food-order>
                      </template>
                    </v-col>

                    <v-col :cols="withDimension" v-if="comapny.shift_two" class="pt-0">
                      <template v-for="meal in shift.shift_two">
                        <food-order
                          @update="update"
                          :params="{
                              selectedMeal,
                              selectedItem,
                              meal,
                              item,
                              restaurant,
                              dayTo,
                              checkTime,
                              shift: {num: 2, text: 'two'},
                              selShift2,
                              selectedDateImage,
                              delivery_from,
                              delivery_to,
                              index,
                              dialog
                            }">
                        </food-order>
                      </template>
                    </v-col>

                    <v-col :cols="withDimension" v-if="comapny.shift_three" class="pt-0">
                      <template v-for="meal in shift.shift_three">
                        <food-order
                          @update="update"
                          :params="{
                              selectedMeal,
                              selectedItem,
                              meal,
                              item,
                              restaurant,
                              dayTo,
                              checkTime,
                              shift: {num: 3, text: 'three'},
                              selShift2,
                              selShift,
                              selectedDateImage,
                              delivery_from,
                              delivery_to,
                              index,
                              dialog
                            }">
                        </food-order>
                      </template>
                    </v-col>
                  </template>
                </v-row>

              </div>
            </template>
          </template>
        </v-col>
      </v-row>
    </template>

    <v-snackbar
      v-model="snackbar.status"
      :timeout="2000"
      top
      rounded="pill"
      color="#fb6d3b"
    >
      {{ translate(snackbar.text) }}

      <template v-slot:action="{ attrs }">
        <v-btn
          color="white"
          text
          v-bind="attrs"
          @click="snackbar.status = false"
        >
          {{ translate("Затвори") }}
        </v-btn>
      </template>
    </v-snackbar>

    <v-dialog v-model="dialog1" max-width="500px" class="p-2">
      <v-card>
        <v-card-title class="error position-relative">
                      <span class="headline white--text"
                      >{{ $t('time_of_delivery') }}</span
                      >
          <v-btn color="primary" text style="position: absolute;top: 5px;color: white !important;right: 5px" @click="dialog1 = false">
            {{ translate("X") }}
          </v-btn>
        </v-card-title>
        <v-col cols="12">
          <v-row>
            <v-col cols="6">
              <v-select
                  :items="setHoures"
                  item-value="val"
                  v-model="delivery_from"
                  label="Сати"
                  outlined
              >
             </v-select>
            </v-col>
            <v-col cols="6">
              <v-select
                  :items="setMinutes"
                  item-value="val"
                  v-model="delivery_to"
                  :label="$t('minutes')"
                  outlined
              >
              </v-select>
            </v-col>
          </v-row>
          <div class="pb-7 text-center">
            <v-btn color="success" dark @click="saveTime">
              {{ $t("save") }}
            </v-btn>
          </div>
        </v-col>
      </v-card>
    </v-dialog>

    <v-dialog v-model="dialog" max-width="601px" scrollable>
      <v-card>
        <v-card-text>
          <v-card elevation="0">
            <v-card-title>
              <v-spacer></v-spacer>
              <div style="display: flex;justify-content: space-between;width: 100%;border-bottom: 1px solid #cfcfcf;padding-top: 10px">
                <p
                  style="font-family: 'Poppins', Helvetica, sans-serif !important;font-weight: 700;color: #2C2C2C"
                  class="title"
                >
                  {{ $t('order_details') }}
                </p>
                <v-btn icon @click="dialog = false">
                  <v-icon style="font-size: 16px" x-large>mdi-close</v-icon>
                </v-btn>
              </div>
            </v-card-title>
            <v-card-text class="mb-4">
              <v-row>
                <v-col cols="auto">
                  <v-img style="border-radius: 12px" width="150" height="150"
                    :src="
                      selectedMeal.image
                        ? conf.apiUrlBase +
                          '/gallery/' +
                          selectedMeal.image.restaurant_id +
                          '/medium/' +
                          selectedMeal.image.name
                        : 'https://cdn.vuetifyjs.com/images/cards/cooking.png'
                    "
                  >
                    <template v-slot:placeholder>
                      <v-row
                        class="fill-height ma-0"
                        align="center"
                        justify="center"
                      >
                        <v-progress-circular
                          indeterminate
                          color="grey lighten-5"
                        ></v-progress-circular>
                      </v-row>
                    </template>
                  </v-img>
                </v-col>
                <v-col>
                  <v-row align="end" class="fill-height">
                    <v-card elevation="0">
                      <v-card-title style="padding: 0 15px">
                        <h3 style="font-family: 'Poppins', Helvetica, sans-serif !important;font-weight: 700;color: #2C2C2C;font-size: 22px;line-height: 25px">{{ translate(selectedMeal.name) }}</h3>
                      </v-card-title>
                      <v-card-text class="mb-4">
                        <v-row no-gutters>
                          <template v-for="(eat, i) in selectedMeal.foods">
                            <v-col cols="12" class="font-weight-bold pt-2">
                              <v-row no-gutters justify="space-between">
                                <h3 style="font-family: 'Poppins', Helvetica, sans-serif !important;font-weight: 700;color: rgba(0,0,0,0.4);font-size: 16px">{{ translate(eat.name) }}</h3>
                              </v-row>
                            </v-col>
                          </template>
                        </v-row>
                      </v-card-text>
                    </v-card>
                  </v-row>
                </v-col>
                <v-col cols="12" v-if="selectedMeal.description">
                  <v-alert color="#fb6d3b" text>
                    <span>***</span>
                    <span class="black--text">
                      {{ translate(selectedMeal.description) }}
                    </span>
                  </v-alert>
                </v-col>
              </v-row>
              <v-col v-if="selectedMeal.foods && selectedMeal.foods.length && selectedMeal.foods[0] && selectedMeal.foods[0].description" style="background: rgba(251, 109, 59, 0.1);border-radius: 8px;padding: 5px;margin-top: 15px">
                <v-col col="12">
                  <template v-for="(eat, i) in selectedMeal.foods">
                    <v-col cols="12" style="color: #2C2C2C"><span style="color: #FB6D3B">***</span>{{ translate(eat.description) }}</v-col>
                  </template>
                </v-col>
              </v-col>
              <div v-if="! $store.state.user.type === 'company' || ($store.state.user.type === 'customer' && $store.state.user.companies[0].is_credit != 1)" style="margin-top: 5px;display: flex;border-bottom: 1px solid #cfcfcf">
                <p style="margin-bottom: 4px" class="dialog_tekst">{{ translate('Име радника') }}</p>
                <p style="margin-bottom: 4px" class="dialog_korisnik">{{ translate($store.state.user.name + ' ' + $store.state.user.lastname) }}</p>
              </div>
              <div v-if="! $store.state.user.type === 'company' || ($store.state.user.type === 'customer' && $store.state.user.companies[0].is_credit != 1)" style="margin-top: 5px;display: flex;border-bottom: 1px solid #cfcfcf">
                <p style="margin-bottom: 4px" class="dialog_tekst">{{ translate('Датум испоруке') }}</p>
                <p style="margin-bottom: 4px" class="dialog_korisnik">{{ selectedDateImage }}</p>
              </div>
              <div v-if="! $store.state.user.type === 'company' || ($store.state.user.type === 'customer' && $store.state.user.companies[0].is_credit != 1)" style="margin-top: 5px;display: flex;border-bottom: 1px solid #cfcfcf">
                <p style="margin-bottom: 4px" class="dialog_tekst">{{ translate('Смена') }}</p>
                <p style="margin-bottom: 4px" class="dialog_korisnik">{{ $t(changeDay(selShift)) }}</p>
              </div>
              <br>
              <div style="display: flex;align-items: center;flex-wrap: wrap;margin-top: 10px;">
                <div :style="! $store.state.user.type === 'company' || ($store.state.user.type === 'customer' && $store.state.user.companies[0].is_credit != 1) ? 'width: 100%' : 'width: 25%'" style="width: 25%;border: 1px solid #cfcfcf;padding: 5px;border-radius: 12px;" v-if="$store.state.user.type === 'company' || $store.state.user.companies[0].is_credit == 1">
                  <v-text-field
                    style="border: none;" class="ukinuti_liniju"
                    v-model="selectedMeal.orders.find((i) => i.shift === selShift).quantity"
                    :disabled="selectedItem.disabled || (dayTo > selectedItem.date && ! checkTime)"
                    type="number"
                    v-if="selectedMeal != ''"
                    prepend-icon="mdi-minus"
                    min="0"
                    hide-details
                    dense
                    append-icon="mdi-plus"
                    readonly
                    @change="$store.state.user.companies[0].is_credit == 1 ? reserveMoreCredit(selectedMeal.orders.find((i) => i.shift === selShift).quantity, selectedItem, selectedMeal, selShift, delivery_from[index], delivery_to[index]) : selectedItem.test === selectedMeal.id + selShift2 &&$store.state.user.type === 'customer'? revert(selectedItem, selectedMeal.id) : reserveCredit(selectedMeal.id, selShift, selectedItem)"
                    @click:prepend="decrement(selectedMeal, selShift, null, index)"
                    @click:append="increment(selectedMeal, selShift, null, index)"
                  ></v-text-field>
                </div>
                <div :style="! $store.state.user.type === 'company' || ($store.state.user.type === 'customer' && $store.state.user.companies[0].is_credit != 1) ? 'width: 100%' : 'width: 75%;padding-left: 15px'">
                  <div v-if="$store.state.user.type === 'customer'">
                    <button class="potvrdi_por"
                      @click="$store.state.user.companies[0].is_credit == 1
                        ? reserveMoreCredit(selectedMeal.orders.find((i) => i.shift === selShift).quantity, selectedItem, selectedMeal, selShift, delivery_from[index], delivery_to[index])
                        : selectedItem.test === selectedMeal.id + selShift2 && $store.state.user.type === 'customer'
                          ? revert(selectedItem, selectedMeal.id)
                          : reserveCredit(selectedMeal.id, selShift, selectedItem)
                       "
                    >
                      {{ selectedItem.test === selectedMeal.id + selShift2 && $store.state.user.type === 'customer' ? $t('cancel_order') : $t('confirm_order') }}
                    </button>
                    <button class="otkazi_por"
                      :style="! $store.state.user.type === 'company' || ($store.state.user.type === 'customer' && $store.state.user.companies[0].is_credit != 1) ? 'margin-top: 10px' : 'margin-top: 0'"
                      v-if="! $store.state.user.type === 'company' || ($store.state.user.type === 'customer' && $store.state.user.companies[0].is_credit != 1) && ! (selectedItem.test === selectedMeal.id + selShift2 && $store.state.user.type === 'customer')"
                      @click="dialog = false">
                      {{ $t('cancel') }}
                    </button>
                  </div>
                  <button v-else class="potvrdi_por" @click="dialog = false">
                    {{ $t('confirm') }}
                  </button>
                </div>
              </div>
            </v-card-text>
          </v-card>
        </v-card-text>
      </v-card>
    </v-dialog>

    <v-dialog v-model="show_order" max-width="602px" scrollable>
      <v-card v-if="show_order_details">
        <v-card-title>
          <v-spacer></v-spacer>
          <div style="display: flex;justify-content: space-between;width: 100%;padding-top: 10px">
            <p style="font-size: 20px;font-family: 'Poppins', Helvetica, sans-serif !important;font-weight: 700;color: #2C2C2C">
                {{ $t('order_details') }}, {{$t(changeDay(show_order_details.name.split(' ')[0])) + ' ' + show_order_details.name.split(' ')[1] + '.'}}
            </p>
            <v-btn icon @click="show_order = false">
              <v-icon style="font-size: 16px" x-large>mdi-close</v-icon>
            </v-btn>
          </div>
        </v-card-title>
        <v-card-text class="mb-4">
          <v-col class="pa-0" cols="12" v-if="comapny.shift_one">
            <p class="text-center font-weight-bold text-h6 my-2" v-if="showTitle('shift_one', '1-smena')" style="background: rgb(255, 251, 249);padding: 8px 0px;border: 1px solid rgba(0, 0, 0, 0.1); border-radius: 12px">
              {{ $t('shift_one')}}
            </p>
            <div v-for="meals in show_order_details.shift_one">
              <v-row :key="index" v-for="(meal, index) in meals.orders" v-if="meal.shift == '1-smena' && meal.quantity > 0">
                <v-col cols="auto">
                  <v-img style="border-radius: 12px" width="100" height="100"
                   :src="
                    meals.image
                      ? conf.apiUrlBase +
                        '/gallery/' +
                        meals.image.restaurant_id +
                        '/medium/' +
                        meals.image.name
                      : 'https://cdn.vuetifyjs.com/images/cards/cooking.png'
                    ">
                    <template v-slot:placeholder>
                      <v-row
                          class="fill-height ma-0"
                          align="center"
                          justify="center"
                      >
                        <v-progress-circular
                            indeterminate
                            color="grey lighten-5"
                        ></v-progress-circular>
                      </v-row>
                    </template>
                  </v-img>
                </v-col>
                <v-col class="px-0 mx-0" >
                  <v-card elevation="0" class="pa-1">
                    <v-card-title style="padding: 0 15px">
                      <h3 style="font-family: 'Poppins', Helvetica, sans-serif !important;font-weight: 700;color: #2C2C2C;font-size: 16px;line-height: 20px">{{ translate(meals.name) }}</h3>
                    </v-card-title>
                    <v-card-text class="mb-4">
                      <v-row no-gutters>
                        <v-col>
                          <v-row no-gutters>
                            <template v-for="(eat, i) in meals.foods">
                              <v-col cols="12" class="font-weight-bold pt-2">
                                <v-row no-gutters justify="space-between">
                                  <h3 style="font-family: 'Poppins', Helvetica, sans-serif !important;font-weight: 700;color: rgba(0,0,0,0.4);font-size: 13px">{{ translate(eat.name) }}</h3>
                                </v-row>
                              </v-col>
                              <v-col cols="12">{{ translate(eat.description) }}</v-col>
                            </template>
                          </v-row>
                        </v-col>
                        <v-col cols="auto">
                          <div style="width: 140px;border: 1px solid #cfcfcf;padding: 5px;border-radius: 12px;">
                            <v-text-field
                              style="border: none;" class="ukinuti_liniju"
                              v-model="meal.quantity"
                              type="number"
                              prepend-icon="mdi-minus"
                              min="0"
                              hide-details
                              dense
                              append-icon="mdi-plus"
                              readonly
                              @click:prepend="meal.quantity > 0 && meal.quantity--; reserveMore(meal.quantity, meal, meals, '1-smena')"
                              @click:append="meal.quantity++; reserveMore(meal.quantity, meal, meals, '1-smena')"
                            ></v-text-field>
                          </div>
                        </v-col>
<!--                        <p class="mb-0 pb-0 font-weight-bold" style="font-size: 13px">{{ $t('quantity') }}: {{ meal.quantity }}</p>-->
                      </v-row>
                    </v-card-text>
                  </v-card>
                </v-col>
              </v-row>
            </div>
          </v-col>
          <v-col class="pa-0" cols="12" v-if="comapny.shift_two">
            <p class="text-center font-weight-bold text-h6 my-2" v-if="showTitle('shift_two', '2-smena')" style="background: rgb(255, 251, 249);padding: 8px 0px;border: 1px solid rgba(0, 0, 0, 0.1); border-radius: 12px">
              {{ $t('shift_two')}}
            </p>
            <div v-for="meals in show_order_details.shift_two">
              <v-row :key="inx" v-for="(meal, inx) in meals.orders" v-if="meal.shift == '2-smena' && meal.quantity > 0">
                <v-col cols="auto">
                  <v-img style="border-radius: 12px" width="100" height="100"
                         :src="
                meals.image
                  ? conf.apiUrlBase +
                    '/gallery/' +
                    meals.image.restaurant_id +
                    '/medium/' +
                    meals.image.name
                  : 'https://cdn.vuetifyjs.com/images/cards/cooking.png'
              "
                  >
                    <template v-slot:placeholder>
                      <v-row
                          class="fill-height ma-0"
                          align="center"
                          justify="center"
                      >
                        <v-progress-circular
                            indeterminate
                            color="grey lighten-5"
                        ></v-progress-circular>
                      </v-row>
                    </template>
                  </v-img>
                </v-col>
                <v-col class="px-0 mx-0" >
                  <v-card elevation="0" class="pa-1">
                    <v-card-title style="padding: 0 15px">
                      <h3 style="font-family: 'Poppins', Helvetica, sans-serif !important;font-weight: 700;color: #2C2C2C;font-size: 16px;line-height: 25px">{{ translate(meals.name) }}</h3>
                    </v-card-title>
                    <v-card-text class="mb-4">
                      <v-row no-gutters>
                        <v-col>
                          <v-row no-gutters>
                            <template v-for="(eat, i) in meals.foods">
                              <v-col cols="12" class="font-weight-bold pt-2">
                                <v-row no-gutters justify="space-between">
                                  <h3 style="font-family: 'Poppins', Helvetica, sans-serif !important;font-weight: 700;color: rgba(0,0,0,0.4);font-size: 13px">{{ translate(eat.name) }}</h3>
                                </v-row>
                              </v-col>
                              <v-col cols="12">{{ translate(eat.description) }}</v-col>
                            </template>
                          </v-row>
                        </v-col>
                        <v-col cols="auto">
                          <div style="width: 140px;border: 1px solid #cfcfcf;padding: 5px;border-radius: 12px;">
                            <v-text-field
                              style="border: none;" class="ukinuti_liniju"
                              v-model="meal.quantity"
                              type="number"
                              prepend-icon="mdi-minus"
                              min="0"
                              hide-details
                              dense
                              append-icon="mdi-plus"
                              readonly
                              @click:prepend="meal.quantity > 0 && meal.quantity--; reserveMore(meal.quantity, meal, meals, '2-smena')"
                              @click:append="meal.quantity++; reserveMore(meal.quantity, meal, meals, '2-smena')"
                            ></v-text-field>
                          </div>
                        </v-col>
<!--                        <p class="mb-0 pb-0 font-weight-bold" style="font-size: 13px">{{ $t('quantity') }}: {{ meal.quantity }}</p>-->
                      </v-row>
                    </v-card-text>
                  </v-card>
                </v-col>
              </v-row>
            </div>
          </v-col>
          <v-col class="pa-0" cols="12" v-if="comapny.shift_three">
            <p class="text-center font-weight-bold text-h6 my-2" v-if="showTitle('shift_three', '3-smena')" style="background: rgb(255, 251, 249);padding: 8px 0px;border: 1px solid rgba(0, 0, 0, 0.1); border-radius: 12px">
              {{ $t('shift_three')}}
            </p>
            <div v-for="meals in show_order_details.shift_three">
              <v-row :key="i" v-for="(meal, i) in meals.orders" v-if="meal.shift == '3-smena' && meal.quantity > 0">
                <v-col cols="auto">
                  <v-img style="border-radius: 12px" width="100" height="100"
                         :src="
                meals.image
                  ? conf.apiUrlBase +
                    '/gallery/' +
                    meals.image.restaurant_id +
                    '/medium/' +
                    meals.image.name
                  : 'https://cdn.vuetifyjs.com/images/cards/cooking.png'
              "
                  >
                    <template v-slot:placeholder>
                      <v-row
                          class="fill-height ma-0"
                          align="center"
                          justify="center"
                      >
                        <v-progress-circular
                            indeterminate
                            color="grey lighten-5"
                        ></v-progress-circular>
                      </v-row>
                    </template>
                  </v-img>
                </v-col>
                <v-col class="px-0 mx-0" >
                  <v-card elevation="0" class="pa-1">
                    <v-card-title style="padding: 0 15px">
                      <h3 style="font-family: 'Poppins', Helvetica, sans-serif !important;font-weight: 700;color: #2C2C2C;font-size: 16px;line-height: 25px">{{ translate(meals.name) }}</h3>
                    </v-card-title>
                    <v-card-text class="mb-4">
                      <v-row no-gutters>
                        <v-col>
                          <v-row no-gutters>
                            <template v-for="(eat, i) in meals.foods">
                              <v-col cols="12" class="font-weight-bold pt-2">
                                <v-row no-gutters justify="space-between">
                                  <h3 style="font-family: 'Poppins', Helvetica, sans-serif !important;font-weight: 700;color: rgba(0,0,0,0.4);font-size: 13px">{{ translate(eat.name) }}</h3>
                                </v-row>
                              </v-col>
                              <v-col cols="12">{{ translate(eat.description) }}</v-col>
                            </template>
                          </v-row>
                        </v-col>
                        <v-col cols="auto">
                          <div style="width: 140px;border: 1px solid #cfcfcf;padding: 5px;border-radius: 12px;">
                            <v-text-field
                              style="border: none;" class="ukinuti_liniju"
                              v-model="meal.quantity"
                              type="number"
                              prepend-icon="mdi-minus"
                              min="0"
                              hide-details
                              dense
                              append-icon="mdi-plus"
                              readonly
                              @click:prepend="meal.quantity > 0 && meal.quantity--; reserveMore(meal.quantity, meal, meals, '3-smena')"
                              @click:append="meal.quantity++; reserveMore(meal.quantity, meal, meals, '3-smena')"
                            ></v-text-field>
                          </div>
                        </v-col>
<!--                        <p class="mb-0 pb-0 font-weight-bold" style="font-size: 13px">{{ $t('quantity') }}: {{ meal.quantity }}</p>-->
                      </v-row>
                    </v-card-text>
                  </v-card>
                </v-col>
              </v-row>
            </div>
          </v-col>
          <v-col class="pa-0 title" cols="12" v-if="
            show_order_details.shift_one.reduce((sum, meals) => sum + (meals.orders || []).reduce((sum2, meal) => sum2 + (meal.shift == '1-smena' ? meal.quantity : 0), 0), 0) +
            show_order_details.shift_two.reduce((sum, meals) => sum + (meals.orders || []).reduce((sum2, meal) => sum2 + (meal.shift == '2-smena' ? meal.quantity : 0), 0), 0) +
            show_order_details.shift_three.reduce((sum, meals) => sum + (meals.orders || []).reduce((sum2, meal) => sum2 + (meal.shift == '3-smena' ? meal.quantity : 0), 0), 0)
            === 0
          ">
            <v-alert color="#fb6d3b" text>
              <span>***</span>
              <span class="black--text">
                Nema narudzbi za izabrani dan
              </span>
            </v-alert>
          </v-col>
          <div v-else style="margin-top: 10px;">
            <div :style="'width: 100%'">
              <button class="otkazi_por" style="background: #FB6D3B; color: #fff"
                      :style="! $store.state.user.type === 'company' || ($store.state.user.type === 'customer' && $store.state.user.companies[0].is_credit != 1) ? 'margin-top: 10px' : 'margin-top: 0'"
                      @click="show_order = false">
                {{ $t('confirm') }}
              </button>
            </div>
          </div>
        </v-card-text>
      </v-card>
    </v-dialog>

    <v-dialog v-model="dialogSuccess" max-width="600px" scrollable>
      <v-card>
        <v-card-text>
          <v-card elevation="0">
            <v-card-text class="mb-4">
              <p class="text-center mt-7 mb-7">
                <span style="background: #00E417;border-radius: 40px;padding: 25px 8px">
                  <v-icon style="color: #fff;font-size: 50px">mdi-check</v-icon>
                </span>
              </p>

              <h3 class="text-center" style="font-size: 24px;font-family: 'Poppins', Helvetica, sans-serif !important;line-height: 28px;margin-bottom: 30px">{{ $t('success_order')}}</h3>
                <p class="text-center" style="font-size: 14px;font-family: 'Poppins', Helvetica, sans-serif !important;line-height: 28px;margin-bottom: 20px">
                  {{ $t('order_message') }} <br> {{  $t('order_message1') }}
                  <br> {{  $t('order_message2') }}
                </p>
                <button @click.prevent="dialogSuccess = false" class="potvrdi_por">
                  {{ $t('close') }}
                </button>

            </v-card-text>
          </v-card>
        </v-card-text>
      </v-card>
    </v-dialog>


    <v-dialog v-model="dialogError" max-width="600px" scrollable>
      <v-card>
        <v-card-title>
          <v-spacer></v-spacer>
          <v-btn icon @click="dialogError = false">
            <v-icon>mdi-close</v-icon>
          </v-btn>
        </v-card-title>
        <v-card-text>
          <v-card elevation="0">
            <v-card-text class="mb-4">
              <p class="text-center mt-7 mb-7">
                <v-icon color="error" size="62">mdi-timer-alert-outline</v-icon>
              </p>
              <h3 class="text-center" style="font-size: 24px;font-family: 'Poppins', Helvetica, sans-serif !important;line-height: 28px;margin-bottom: 30px">Rok za naručivanje  za odabrani datum je istekao.</h3>
              <p class="text-center" style="font-size: 14px;font-family: 'Poppins', Helvetica, sans-serif !important;line-height: 28px;margin-bottom: 20px">
                Molimo te da izabereš jedan od narednih dana za naručivanje.
              </p>
              <button @click.prevent="dialogNextDay" class="potvrdi_por">
                Izaberi naredni dan
              </button>

            </v-card-text>
          </v-card>
        </v-card-text>
      </v-card>
    </v-dialog>
    <v-dialog v-model="dialogErrorDelete" max-width="600px" scrollable>
      <v-card>
        <v-card-title>
          <v-spacer></v-spacer>
          <v-btn icon @click="dialogErrorDelete = false">
            <v-icon>mdi-close</v-icon>
          </v-btn>
        </v-card-title>
        <v-card-text>
          <v-card elevation="0">
            <v-card-text class="mb-4">
              <p class="text-center mt-7 mb-7">
                <v-icon color="error" size="62">mdi-timer-alert-outline</v-icon>
              </p>
              <h3 class="text-center" style="font-size: 24px;font-family: 'Poppins', Helvetica, sans-serif !important;line-height: 28px;margin-bottom: 30px">Rok za brisanje  za odabrani datum je istekao.</h3>
            </v-card-text>
          </v-card>
        </v-card-text>
      </v-card>
    </v-dialog>
  </v-row>
</template>

<script>
import axios from "../plugins/axios";
import conf from "../config";
import moment from "moment";
import { latLng, Icon } from "leaflet";
import { LMap, LTileLayer, LMarker, LIconDefault } from "vue2-leaflet";
import FoodOrder from '../components/cards/FoodOrder'
import store from "../store";
var convertCyToLa = require("cyrillic-to-latin");

delete Icon.Default.prototype._getIconUrl;
Icon.Default.mergeOptions({
  iconRetinaUrl: require('leaflet/dist/images/marker-icon-2x.png'),
  iconUrl: require('leaflet/dist/images/marker-icon.png'),
  shadowUrl: require('leaflet/dist/images/marker-shadow.png'),
});

export default {
  data() {
    return {
      dialogError: false,
      dialogErrorDelete: false,
      dialogErrorDate: null,
      convertCyToLa: convertCyToLa,
      partners: [],
      loaded: false,
      moment: moment,
      te:'',
      url: 'https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png',
      attribution: '',
      zoom: 15,
      center: [44.78624721128064, 17.216139767293512],
      markerLatLng: [44.78624721128064, 17.216139767293512],
      conf: conf,
      value: 0,
      loading: false,
      current_location: false,
      dialogRestoran: false,
      openTimer: {},
      location: [0],
      locations: [],
      selectedDate: {
        name: "rucak",
        date: new Date().toISOString().substr(0, 10),
        shift: {
          text: "1-smena",
          key: "shift_one",
          value: 1,
        },
      },
      lockedAll: false,
      meals: [],
      menues: [],
      snackbar: {
        status: false,
        text: null,
      },
      dialog: false,
      dialog1: false,
      dialogSuccess: false,
      selectedMeal: '',
      rok_sati: false,
      dayTo:'',
      mealOrderdErrorMessage: '',
      errorDate: '',
      time: '',
      sati: '',
      menuTime: [],
      delivery_time: {},
      total: {},
      openTime: '',
      delivery_from: [],
      delivery_to: [],
      totalTime: {},
      index: '',
      user_location: '',
      locationForUser: '',
      noLocation: false,
      allRestorants: '',
      newUserLocation: {
        entity_id: this.$store.state.user.id,
        coordinates: {
          longitude: '',
          latitude: ''
        },
        entity_type: 3,
        address: ''
      },
      buyList: [],
      listItems: {
        user_id: '',
        date: '',
        type: '',
        shifts: {
          shift_one: [],
          shift_two: [],
          shift_three: [],
        },
        delivery_time: '',
        location: {
          longitude: '',
          latitude: '',
          address: '',
          id: ''
        },
      },
      guestErrorMessage: '',
      guestErrorMessagePopup: false,
      deliveryRestoran: '',
      allOrderOrders: [],
      chooseNewRestoran: '',
      dialogNewRestoran: false,
      buttonIndex: {},
      selectedUserLocation: '',
      allUserLocation: '',
      current_user_location: '',
      new_user_location: {
        address: '',
        latitude: '',
        longitude: '',
      },
      set_user_location: '',
      showSelect: false,
      selectedShift: '',
      selectedDateImage: '',
      selectedItem: '',
      selShift: '',
      selShift2: '',
      show_order: false,
      show_order_details: '',
      isMobile: false,
      restaurantOrderTime: null,
      availableCompanies: [],
      selectedCompany: null,
      firstLoadSelectedCompany: false,
      menuEmpty: false
    };
  },
  components: {
    LMap,
    LTileLayer,
    LMarker,
    LIconDefault,
    FoodOrder
  },
  mounted() {
    setTimeout(() => {
      let a = false
      this.menues.forEach((menu, index) => {
        if(!a) {
          let disable = this.checkDisabled(menu.date)
          //console.log(index, disable)
          if(!disable) {
            let dokle = document.getElementById(index);
            dokle.scrollIntoView({
              block: 'start',
              behavior: "smooth",
              inline: "nearest"
            });
            a = true
          }
        }
      })
      // this.menues.filter((menu, index) => {
      //   if (a) {
      //     return;
      //   }
      //
      //   let sati = this.restaurant.settings.broj_sati <= 9 ? + '0' + this.restaurant.settings.broj_sati : this.restaurant.settings.broj_sati
      //
      //   if (this.restaurant.settings.rok_sati) {
      //     let date = this.getDate(this.restaurant.settings.dan, sati)
      //     this.dayTo = moment(date).format('YYYY-MM-DD');
      //
      //     if (menu.date == moment(date).format('YYYY-MM-DD')) {
      //       a = true
      //       const dokle = document.getElementById(index);
      //       dokle.scrollIntoView({
      //         block: 'start',
      //         behavior: "smooth",
      //         inline: "nearest"
      //       });
      //     }
      //     return;
      //   }
      //   if (this.restaurant.settings.rok_dana) {
      //     if (menu.disabled == false) {
      //       this.dayTo = menu.date
      //       a = true
      //       const dokle = document.getElementById(index);
      //       dokle.scrollIntoView({
      //         block: 'start',
      //         behavior: "smooth",
      //         inline: "nearest"
      //       });
      //     }
      //   }
      //
      //   if (menu.disabled == false) {
      //     // this.dayTo = ''
      //     this.dayTo = menu.date
      //     a = true
      //     const dokle = document.getElementById(index);
      //     dokle.scrollIntoView({
      //       block: 'start',
      //       behavior: "smooth",
      //       inline: "nearest"
      //     });
      //   }
      // })
    }, 1200)

    this.isMobile = window.innerWidth < 960

    this.availableCompanies = this.$store.state.companies
    if(this.availableCompanies && this.availableCompanies.length && this.availableCompanies.length > 1) {
      this.selectedCompany = this.$store.state.companies[0]
    }

    if(!this.partners.length && this.comapny && this.comapny.id) {
      axios.get('/companies/'+ this.comapny.id +'/show-restaurants').then(res => {
        this.partners = res.data
        if(this.partners.length === 1) {
          this.openOrdera(this.partners[0])
        }
      })
    }
  },

  created() {
    this.getMenues();
    this.getRestaurantSettings()

    // this.getUserLocation()
    // this.locatorButtonPressed()
    // this.getEntityCoordinates()
    // this.getAllRestorans()
    // this.selectRes()
    // this.oldOrders()
  },
  methods: {
    dialogNextDay() {
      this.dialogError = false
      let index = this.menues.findIndex(menu => menu.date === this.dialogErrorDate)
      if(index > -1) this.scrollMeTo(index)
    },
    setCookie(name,value,hours) {
      let expires = "";
      if (hours) {
        let date = new Date();
        date.setTime(date.getTime() + (hours*60*60*1000));
        expires = "; expires=" + date.toUTCString();
      }
      document.cookie = name + "=" + (value || "")  + expires + "; path=/; domain=.ordera.app; secure; SameSite=Lax";
    },
    openOrdera(res) {
        let token = localStorage.getItem('restoran-token')
        this.setCookie('restoran-token', token, 1)
        this.$nextTick();
        let url = 'https://ordera.app/sr/' + res.city.country.code +'/'+ res.city.code +'/'+ convertCyToLa(res.name.toLowerCase()).replace(' ', '-') +'/'+ res.id
        window.location.replace(url)
    },
    getRestaurantSettings() {
      if(JSON.parse(this.$store.state.companies[0].settings) && JSON.parse(this.$store.state.companies[0].settings).global_restaurant_time_settings) {
        this.restaurantOrderTime = JSON.parse(this.$store.state.companies[0].settings).days
      } else {
        axios.get('/restaurants/' + this.restaurantId + '/get-new-times')
          .then(res => {
            this.restaurantOrderTime = res.data.data

            let currentDate = this.moment('2023-05-19').format('dddd').toLowerCase()
          })
      }
    },
    update(event) {
      this.selectedMeal = event.selectedMeal
      this.selectedItem = event.selectedItem
      this.meal = event.meal
      this.item = event.item
      //this.restaurant = event.restaurant
      this.dayTo = event.dayTo
      //this.checkTime = event.checkTime
      this.selShift2 = event.selShift2
      this.selShift = event.selShift
      this.selectedDateImage = event.selectedDateImage
      this.delivery_from = event.delivery_from
      this.delivery_to = event.delivery_to
      this.index = event.index
      this.dialog = event.dialog
      this.$forceUpdate()
    },
    showTitle(shift, type) {
      return this.show_order_details[shift].some(meals => (meals.orders || []).some(meal => meal.shift == type && meal.quantity > 0))
    },
    increment(item, shift, params = null, index = null) {
      item.orders.find((i) => i.shift === shift).quantity++;
      if(params) {
        this.reserveMore(params.meal.orders.find((i) => i.shift === `${params.shift.num}-smena`).quantity, params.item, params.meal, `${params.shift.num}-smena`, params.delivery_from[params.index], params.delivery_to[params.index])
      }
      else if(index) {
        this.reserveMore(this.selectedMeal.orders.find((i) => i.shift === this.selShift).quantity, this.selectedItem, this.selectedMeal, this.selShift, this.delivery_from[index], this.delivery_to[index])
      }
    },
    decrement(item, shift, params = null, index = null) {
      if (item.orders.find((i) => i.shift === shift).quantity > 0) {
        item.orders.find((i) => i.shift === shift).quantity--;
        if(params) {
          this.reserveMore(params.meal.orders.find((i) => i.shift === `${params.shift.num}-smena`).quantity, params.item, params.meal, `${params.shift.num}-smena`, params.delivery_from[params.index], params.delivery_to[params.index])
        }
        else if (index) {
          this.reserveMore(this.selectedMeal.orders.find((i) => i.shift === this.selShift).quantity, this.selectedItem, this.selectedMeal, this.selShift, this.delivery_from[index], this.delivery_to[index])
        }
      }
    },
    changeDay(day) {
      let days = {}
      days["Понедјељак"] = "monday"
      days["Уторак"] = "tuesday"
      days["Сриједа"] = "wednesday"
      days["Четвртак"] = "thursday"
      days["Петак"] = "friday"
      days["Субота"] = "saturday"
      days["Недјеља"] = "sunday"

      days["Понедељак"] = "monday"
      days["Среда"] = "wednesday"
      days["Недеља"] = "sunday"

      days["Прва смјена"] = "first_shift"
      days["Друга смјена"] = "second_shift"
      days["Трећа смјена"] = "third_shift"

      days["Прва смена"] = "first_shift"
      days["Друга смена"] = "second_shift"
      days["Трећа смена"] = "third_shift"

      days["1. смјена"] = "first_shift"
      days["2. смјена"] = "second_shift"
      days["3. смјена"] = "third_shift"

      days["1. смена"] = "first_shift"
      days["2. смена"] = "second_shift"
      days["3. смена"] = "third_shift"

      days["1-smena"] = "first_shift"
      days["2-smena"] = "second_shift"
      days["3-smena"] = "third_shift"

       return days[day]
    },
    changeAddress(id, date) {
      this.listItems.location.id = id.id
      this.listItems.location.address = id.address
      this.$forceUpdate()
    },
    addUserLocation() {
      let data = {}
      data['user_id'] = this.$store.state.user.id
      data['new_user_location'] = this.new_user_location
      axios.post('/user/add-new-user-location', data).then(({data}) => {
        this.allUserLocation = data.data
        this.new_user_location.address = ''
        this.new_user_location.latitude = ''
        this.new_user_location.longitude = ''
        this.$forceUpdate()
      })
    },
    deleteLocation(address) {
      axios.post('/user/delete-location', {
        'address': address
      }).then(({data}) => {
        this.allUserLocation = data.data
        this.$forceUpdate()
      })
    },
    removeFromList(list) {
      let indexOfObject = this.buyList.findIndex(object => {
        return object.id === list.id;
      });
      this.$delete(this.buttonIndex, list.date, 1)
      this.buyList.splice(indexOfObject, 1);
    },
    oldOrders() {
      if (this.$store.state.user.type == 'guest') {
        axios.post('/orders/guest-old-orders', {
          'user_id': this.$store.state.user.id
        }).then(({data}) => {
          let podaci = {}
          data.filter(item => {
            podaci[item.date] = []
          })
          data.filter(item => {
            podaci[item.date].push(item)
          })

          this.allOrderOrders = podaci
        })
      }
    },
    qty(meal, type) {
      if (type == 'minus' && (meal.qty > 0 || (meal.quantity  && meal.quantity >= 0))) {
        if (meal.qty) {
          meal.qty--
        } else {
          meal.quantity = meal.quantity - 1
        }
        meal.price -= Number(meal.price)
      }
      if (type == 'plus' && (meal.qty > 0 || (meal.quantity  && meal.quantity >= 0))) {
        if (meal.qty) {
          meal.qty++
        } else {
          meal.quantity = meal.quantity + 1
        }
        meal.price += Number(meal.price)
      }
    },
    setDeliveryTime(index) {
      this.listItems.delivery_time = this.delivery_from[index] + ':' + this.delivery_to[index]
    },
    addToBuyList(meal, item, shift, index) {
      this.buttonIndex[item.date] = true
      if (this.buyList.find((i,index) => [i.id].includes(meal.id))) {
        this.buyList.filter((item, index1) => {
          if (item.id == meal.id) {
            this.buyList[index1].qty++
          }
        })
        // this.buyList[index].shifts[shift].qty++
      } else {
        let data = {}
        data['user_id'] = this.$store.state.user.id
        data['date'] = item.date
        data['type'] = 'ручак'
        data['qty'] = 1
        data['name'] = meal.name
        data['id'] = meal.id
        data['price'] = meal.price
        data['shifts'] = {}
        data['shifts'][shift] = {}
        data['shifts'][shift]['meals'] = []
        data['shifts'][shift]['meals'].push({
          'meal': meal,
          'qty': 1
        })

        this.listItems.user_id = this.$store.state.user.id
        this.listItems.date = item.date
        this.listItems.type = 'ручак'
        this.listItems.location.address = this.user_location.address ? this.user_location.address : ''
        this.listItems.location.id = this.user_location.id
        this.listItems.location.longitude = this.user_location.coordinates ? this.user_location.coordinates.longitude : ''
        this.listItems.location.latitude = this.user_location.coordinates ? this.user_location.coordinates.latitude : ''
        this.listItems.delivery_time = this.delivery_from[index] + ':' + this.delivery_to[index]
        this.listItems.shifts[shift].push({
          'meal_id': meal.id,
          'name': meal.name,
          'price': meal.price,
          'qty': 1
        })
        this.buyList.push(data)
      }
    },
    sentOrder() {
      this.guestErrorMessage = ''
      this.guestErrorMessagePopup = false
      if (this.listItems.delivery_time == ':') {
        this.guestErrorMessagePopup = true
        this.guestErrorMessage = 'Молимо Вас да изаберете време доставе...'
        return this.guestErrorMessage
      }
      // if (this.listItems.location.latitude == '' || this.listItems.location.longitude == '') {
      //   this.guestErrorMessagePopup = true
      //   this.guestErrorMessage = 'Молимо Вас да изаберете локацију...'
      //   return this.guestErrorMessage
      // }
      axios.post('/orders/guest-orders', this.listItems).then(({data}) => {
        console.log(data)
      })
    },
    rowClass(item) {
      var rowClass = 'myclass'
      return rowClass;
    },
    backupImage() {
      return conf.apiUrlBase + '/gallery/no-image-restaurant.jpg'
    },
    getEntityCoordinates() {
      let data = {}
      data['entity_id'] = this.restaurant.id
      data['entity_type'] = 1
      axios.post('/get-entity-coordinates', data).then(({data}) => {
        console.log(data)
      })
    },
    addNewLocation() {
      axios.post('/user/add-user-location', this.newUserLocation).then(({data}) => {
        if(data.status == 'success') {
          this.deliveryRestoran = data
          this.newUserLocation.entity_id = data.entity_id
          this.newUserLocation.coordinates.longitude = ''
          this.newUserLocation.coordinates.latitude = ''
          this.newUserLocation.entity_type = data.entity_type
          this.newUserLocation.address = data.address
        }
      })
    },
    selectRes(res = null) {
      if (res == null && this.restaurant && this.$store.state.user.type == 'guest') {
        let data = {};
        data['user_id'] = this.$store.state.user.id
        data['restoran_id'] = this.restaurant.id
        axios.post('/choose-restaurant', data).then(({data}) => {
          if(data.status == "success") {
            this.deliveryRestoran = data.data
          }
        })
      } else {
        if (res && this.$store.state.user.type == 'guest') {
          let data = {};
          data['user_id'] = this.$store.state.user.id
          data['restoran_id'] = res.id
          axios.post('/choose-restaurant', data).then(({data}) => {
            if(data.status == 'success') {
              this.dialogRestoran = false
            }
            console.log(data)
          })
        }
      }

    },
    dialogRestoranClose() {
      this.dialogRestoran = false;
    },
    getAllRestorans() {
      axios.get('/all-restaurants').then(({data}) => {
        this.allRestorants = data.data
        if (this.$store.state.user.type == 'guest')
        this.dialogRestoran = true
      })
    },
    locatorButtonPressed() {
      navigator.geolocation.getCurrentPosition(
          position => {
            console.log(position.coords.latitude);
            console.log(position.coords.longitude);
          },
          error => {
            console.log(error.message);
          },
      )
    },
    getUserLocation() {
      this.set_user_location = {}
      if(this.$store.state.user && this.$store.state.user.type == 'guest') {
        axios.post('/user/get-user-location', {
          'user_id': this.$store.state.user.id
        }).then(({data}) => {
          this.set_user_location = data.current_location
          // let podaci = {}
          // data.current_location.filter(item => {
          //   podaci[item.date] = []
          // })
          // data.current_location.filter(item => {
          //   podaci[item.date].push(item)
          //   this.set_user_location[item.date] = item
          // })
          //
          //
          // this.user_location = podaci
          this.current_user_location = data.current_location
          this.allUserLocation = data.data
          this.newUserLocation.entity_id = data.data.entity_id
          this.newUserLocation.coordinates.longitude = ''
          this.newUserLocation.coordinates.latitude = ''
          this.newUserLocation.entity_type = data.data.entity_type
          this.newUserLocation.address = data.data.address
          this.current_location = false
          this.dialogRestoran = true
        }).catch((error) => {
          if (error.response.data.status == "failed" && error.response.data.message == "Korisnik nema lokaciju") {
            this.current_location = true
            this.dialogRestoran = false
          }
        });
      }

    },
    saveTime() {
      this.totalTime[this.index] = this.delivery_from + ':' + this.delivery_to
      this.dialog1 = false
    },
    openDialogTime(index) {
      this.dialog1 = true
      this.index = index
    },
    getDate(setLimitDay, setLimitHour){
      let orderDay;
      let limitDay = moment().day(setLimitDay); // cetvrtak  ( Thursday )
      let currentDay = limitDay.diff(moment().day(moment().format('dddd')), 'days');

      // srediti ako je vikend, ako ne postoji subota prebaci u ponedeljak
      let limitTime = (currentDay <= 0 && moment().hour() >= setLimitHour) ? false : true;
      let allowTime = (currentDay >= 0 && limitTime === true) ? true : false;
      // trenutni dan manji od setovanog dana
      if (moment().format('E') < setLimitDay) {
        orderDay = moment().startOf('isoWeek').add(1, 'week'); // Prvi ponedeljak
      }
      // trenutno dan jednak setovanom danu
      else if (moment().format('E') == setLimitDay) {
        // setovani sat manji od trenutnog sata
        if (setLimitHour < moment().format('H')) {
          orderDay = moment().startOf('isoWeek').add(2, 'week'); // Drugi ponedeljak
        }
        // setovani sat jednak trenutnom satu
        else if(setLimitHour == moment().format('H')) {
          // trenutni minuti veci od setvanih (0)
          if (moment().format('m') == 0) {
            orderDay = moment().startOf('isoWeek').add(1, 'week'); // Prvi ponedeljak
          } else {
            orderDay = moment().startOf('isoWeek').add(2, 'week'); // Drugi ponedeljak
          }
        }
        // setovan sat veci od trenutnog
        else {
          orderDay = moment().startOf('isoWeek').add(1, 'week'); // Prvi ponedeljak
        }
      }
      // trenutni dan veci od setovanog
      else {
        orderDay = moment().startOf('isoWeek').add(2, 'week'); // Drugi ponedeljak
      }
      // if(parseInt(currentDay) >= 0 && allowTime === true){
      //   orderDay = moment().startOf('isoWeek').add(1, 'week'); // Prvi ponedeljak
      // }else{
      //   orderDay = moment().startOf('isoWeek').add(2, 'week'); // Drugi ponedeljak
      // }

      orderDay.set('hour', setLimitHour);
      orderDay.set('minute', 0);

      return orderDay;
    },
    getMonday(days) {
      let date = new Date()
      let dateCopy = new Date(date.getTime());

      return new Date(
          dateCopy.setDate(
              dateCopy.getDate() + ((days - dateCopy.getDay() + 1) % days || days),
          ),
      );
    },
    getNextMonday(date = new Date()) {
      let dateCopy = new Date(date.getTime());
      let nextMonday = new Date(
          dateCopy.setDate(
              dateCopy.getDate() + ((7 - dateCopy.getDay() + 1) % 7 || 7),
          ),
      );
      return nextMonday;
    },
    getNext2Monday(date = new Date()) {
      let dateCopy = new Date(date.getTime());
      let nextMonday = new Date(
          dateCopy.setDate(
              dateCopy.getDate() + ((14 - dateCopy.getDay() + 1) % 14 || 14),
          ),
      );

      return nextMonday;
    },
    switchDay(dani) {
      let dan = ''

      if (dani == 1) {
        dan = 'Понедељакa'
      }
      if (dani == 2) {
        dan = 'Уторкa'
      }
      if (dani == 3) {
        dan = 'Средe'
      }
      if (dani == 4) {
        dan = 'Четврткa'
      }
      if (dani == 5) {
        dan = 'Петкa'
      }
      if (dani == 6) {
        dan = 'Суботe'
      }
      if (dani == 7) {
        dan = 'Недељe'
      }
      return dan
    },
    getNextDayOfTheWeek(dayName, excludeToday = true, refDate = new Date()) {
      const dayOfWeek = ["sun","mon","tue","wed","thu","fri","sat"]
          .indexOf(dayName.slice(0,3).toLowerCase());
      if (dayOfWeek < 0) return;
      refDate.setHours(0,0,0,0);
      refDate.setDate(refDate.getDate() + +!!excludeToday +
          (dayOfWeek + 7 - refDate.getDay() - +!!excludeToday) % 7);
      return refDate ;
    },
    scrollMeBack(refName) {
      if (refName - 1 != -1) {
        let element = this.$refs[refName - 1];
        element[0].scrollIntoView({ behavior: 'smooth' });
      }
      else {
        this.getMenues('back');
      }
    },
    scrollMeTo(refName) {
      let element = this.$refs[refName + 1];
      if(element) {
        element[0].scrollIntoView({ behavior: 'smooth' });
      }
      else {
        this.getMenues('next');
      }
    },
    revertMore(item) {
      item.shift_one.forEach((item) => {
        axios
          .delete(
            `/orders/${
              item.orders.find((order) => order.shift === "1-smena").id
            }`
          )
          .then((res) => {
            let order = item.orders.find((order) => order.shift === "1-smena");
            order.id = null;
            order.quantity = 0;
          });
      });
      item.shift_two.forEach((item) => {
        axios
          .delete(
            `/orders/${
              item.orders.find((order) => order.shift === "2-smena").id
            }`
          )
          .then((res) => {
            let order = item.orders.find((order) => order.shift === "2-smena");
            order.id = null;
            order.quantity = 0;
          });
      });
      item.shift_three.forEach((item) => {
        axios
          .delete(
            `/orders/${
              item.orders.find((order) => order.shift === "3-smena").id
            }`
          )
          .then((res) => {
            let order = item.orders.find((order) => order.shift === "3-smena");
            order.id = null;
            order.quantity = 0;
          });
      });
    },
    reserveMore(val, item, i, ii, delivery_from, delivery_to) {
      if (item.disabled) return;
      let data = {
        user_id: this.$store.state.user.id,
        meal_id: i.id,
        date: item.date,
        type: this.selectedDate.name,
        quantity: val,
        quantity_payed: 0,
        shift: ii,
        delivery_time: {
          delivery_from: delivery_from ? delivery_from : null,
          delivery_to: delivery_to ? delivery_to : null,
        },
        location: this.newUserLocation.coordinates
      };
      if(this.$store.state.user.is_transferable&&item.location[0]&&item.location[0]!==this.$store.state.company.id)data.company_id=item.location[0];
      if (i.orders.find((order) => order.shift === ii).id) {
        // if(i.orders.find((order) => order.shift === ii).date == item.date && i.orders.find((order) => order.shift === ii).orginal_qty > val) {
        //   alert('Ne mozete smanjiti obrok')
        //   i.orders.find((order) => order.shift === ii).quantity = i.orders.find((order) => order.shift === ii).orginal_qty
        //   return;
        // }
        axios
          .put(
            `/orders/${i.orders.find((order) => order.shift === ii).id}`,
            data
          )
          .then((res) => {
            if(res.data.data) {
              i.orders.find((order) => order.shift === ii).quantity = res.data.data.quantity;
              i.orders.find((order) => order.shift === ii).orginal_qty = res.data.data.quantity;
              i.orders.find((order) => order.shift === ii).id = res.data.data.id;
              i.orders.find((order) => order.shift === ii).meal_id = res.data.data.meal_id;
            }
            else {
              i.orders.find((order) => order.shift === ii).id = null;
              i.orders.find((order) => order.shift === ii).quantity = 0
              i.orders.find((order) => order.shift === ii).orginal_qty = 0
              i.orders.find((order) => order.shift === ii).meal_id = 0;
              item.test = null
            }
              this.snackbar.status = true;
              this.snackbar.text = this.translate("Сачувано");
              if (this.$store.state.user.type === 'guest') {
                this.getMenues();
              }
          });
      } else {
        axios.post("/orders", data).then((res) => {
          i.orders.find((order) => order.shift === ii).quantity =
              res.data.data.quantity;
          i.orders.find((order) => order.shift === ii).id = res.data.data.id;
          i.orders.find((order) => order.shift === ii).quantity = res.data.data.quantity;
          i.orders.find((order) => order.shift === ii).orginal_qty = res.data.data.quantity;
          i.orders.find((order) => order.shift === ii).meal_id = res.data.data.meal_id;
          item.test = res.data.data.meal_id + '_' + ii
          i.isOrder = true
          // this.snackbar.status = true;
          // this.snackbar.text = this.translate("Сачувано");

          if(this.$store.state.user.type === 'company') {
            this.snackbar.status = true
            this.snackbar.text = this.translate("Сачувано")
          }
          else {
            this.dialogSuccess = true
          }

          setTimeout(() => {
            this.dialogSuccess = false
          }, 1500)
        }).catch(err => {
          this.snackbar.text = err?.response?.data?.message
          this.snackbar.status = true
        });
      }
    },
    reserveMoreCredit(val, item, i, ii, delivery_from, delivery_to) {
      if (item.disabled) return;
      let data = {
        user_id: this.$store.state.user.id,
        can_credit: this.$store.state.user.companies[0].is_credit == 1 ? 1 : null,
        meal_id: i.id,
        date: item.date,
        type: this.selectedDate.name,
        quantity: val,
        quantity_payed: 0,
        shift: ii,
        delivery_time: {
          delivery_from: delivery_from ? delivery_from : null,
          delivery_to: delivery_to ? delivery_to : null,
        },
        location: this.newUserLocation.coordinates
      };
      if(this.$store.state.user.is_transferable&&item.location[0]&&item.location[0]!==this.$store.state.company.id)data.company_id=item.location[0];
      if (i.orders.find((order) => order.shift === ii).id) {
        axios.put(`/orders/credit/${i.orders.find((order) => order.shift === ii).id}`, data).then((res) => {
          if(res.data.data) {
            i.orders.find((order) => order.shift === ii).quantity = res.data.data.quantity;
            i.orders.find((order) => order.shift === ii).orginal_qty = res.data.data.quantity;
            i.orders.find((order) => order.shift === ii).id = res.data.data.id;
            i.orders.find((order) => order.shift === ii).meal_id = res.data.data.meal_id;
            this.dialogSuccess = true
            setTimeout(() => {
              this.dialogSuccess = false
            }, 1500)
          }
          else {
            i.orders.find((order) => order.shift === ii).id = null;
            i.orders.find((order) => order.shift === ii).quantity = 0
            i.orders.find((order) => order.shift === ii).orginal_qty = 0
            i.orders.find((order) => order.shift === ii).orginal_qty = 0
            i.orders.find((order) => order.shift === ii).meal_id = 0;
            item.test = null
          }
          this.$root.$emit("change-credit-emit", 'remove');
          // this.snackbar.status = true;
          // this.snackbar.text = this.translate("Сачувано");
        });
        this.$forceUpdate()
      } else {
        if(this.$store.state.user.type == "guest") {
          axios.post('/orders/guest-order-pay', data).then(({data}) => {
            console.log(data)
          })
        } else {
          axios.post("/orders/credit", data).then((res) => {
            i.orders.find((order) => order.shift === ii).quantity = res.data.data.quantity;
            i.orders.find((order) => order.shift === ii).orginal_qty = res.data.data.quantity;
            i.orders.find((order) => order.shift === ii).id = res.data.data.id;
            i.orders.find((order) => order.shift === ii).meal_id = res.data.data.meal_id;
            item.test = res.data.data.meal_id + '_' + ii
            // this.snackbar.status = true;
            // this.snackbar.text = this.translate("Сачувано");
            this.dialogSuccess = true
            setTimeout(() => {
              this.dialogSuccess = false
            }, 1500)
          });
          this.$root.$emit("change-credit-emit", 'add');
        }
        this.$forceUpdate()
      }

    },
    revert(item, meal) {
      let date = item.date
      this.mealOrderdErrorMessage = ''
      this.errorDate = ''
      axios.delete(`/orders/${item.id}`).then((res) => {
        let index = this.menues.findIndex((i) => i.id === item.id);
        this.menues[index].test = null;
        this.menues[index].id = null;
        this.menues[index].shift_one.filter(item => {
          item.orders[0].quantity = 0
          item.orders[0].date = ''
          item.orders[0].meal_id = ''
        })
        this.menues[index].shift_two.filter(item1 => {
          item1.orders[1].quantity = 0
          item1.orders[1].date = ''
          item1.orders[1].meal_id = ''
        })
        this.menues[index].shift_three.filter(item2 => {
          item2.orders[2].quantity = 0
          item2.orders[2].date = ''
          item2.orders[2].meal_id = ''
        })
      }).catch((error) => {
        if (error.response && error.response.data && error.response.data.status == "failed") {
          this.mealOrderdErrorMessage = error.response.data.message
          this.errorDate = date
        }
        if (error.response && error.response.status == 406 && error.response.data.message == 'Rok za otkazivanje je istekao') {
          this.dialogErrorDelete = true
          setTimeout(() => {
            this.dialogErrorDelete = false
          }, 5000)
        }
      });
    },
    getMenues(type = null) {
      if (this.selectedDate.name) {
        this.menues = []
        if(type == 'back') {
          this.selectedDate.date = moment(this.selectedDate.date)
            .subtract(1, "days")
            .format("YYYY-MM-DD");
        }
        // if(type == 'next') {
        //   this.selectedDate.date = moment(this.selectedDate.date)
        //     .add(1, "days")
        //     .format("YYYY-MM-DD");
        // }

        let newDate = moment(this.selectedDate.date)
          .add(30, "days")
          .format("YYYY-MM-DD");
        // axios.get(`/menus/web-apppp?from=${this.selectedDate.date}&to=${newDate}&type=${this.selectedDate.name}`)
        //     .then(({data}) => {
        //       console.log(data)
        //     })
        this.menuEmpty = false
        axios.get(`/menus/web-app?from=${this.selectedDate.date}&to=${newDate}&type=${this.selectedDate.name}`)
          .then((res) => {
            if(res.data.length === 0) this.menuEmpty = true
            res.data.forEach((menu, i) => {
              this.totalTime[i] = ''
              this.delivery_from[i] = ''
              this.delivery_to[i] = ''
              this.totalTime[i] = ''
              let check = null
              if (menu && menu.meals) {
                check = menu.meals.find(
                    (i) => i.shift_one || i.shift_two || i.shift_three
                );
              }


              if (check) {
                let datum = moment(this.selectedDate.date).add(this.restaurant.settings.dan, 'days')
                let shiftOne = 0;
                let shiftTwo = 0;
                let shiftThree = 0;
                let meal =
                  menu.type === "rucak"
                    ? menu.meals.find((meal) => meal.orders.length)
                    : null;

                this.menues.push({
                  id: meal ? meal.orders[0].id : null,
                  location: [this.$store.state.company.id,this.$store.state.company.name],
                  name:
                    this.convertNameOfDay(moment(menu.date).format("dddd")) +
                    " " /* '<br>' */ +
                    moment(menu.date).format("DD.MM.YYYY"),
                  shift_one: menu.meals
                    .filter((i) => i[this.findShift(this.comapny.shift_one)])
                    .map((i) => {
                      if (
                        i &&
                        (!i.orders.length ||
                          !i.orders.find((i) => i.shift === "1-smena"))
                      )
                      i.orders.push({ quantity: 0, shift: "1-smena" })
                      i.orders.find((i) => i.shift === "1-smena").orginal_qty = i.orders.find((i) => i.shift === "1-smena").quantity ? i.orders.find((i) => i.shift === "1-smena").quantity : 0
                      i.orders.find((i) => i.shift === "1-smena").quantity_payed = i.orders.find((i) => i.shift === "1-smena").quantity_payed ? i.orders.find((i) => i.shift === "1-smena").quantity_payed : 0
                      shiftOne += (Number(i.price) * i.orders.find((i) => i.shift === "1-smena").orginal_qty)
                      this.total[menu.date] = ''
                      i.isOrder = i.orders.find(order => order.shift === "1-smena" && order.meal_id === i.id) ? true : false
                      return i;
                    }),
                  shift_two: menu.meals
                    .filter((i) => i[this.findShift(this.comapny.shift_two)])
                    .map((i) => {
                      if (
                        i &&
                        (!i.orders.length ||
                          !i.orders.find((i) => i.shift === "2-smena"))
                      )
                      i.orders.push({ quantity: 0, shift: "2-smena" });
                      i.orders.find((i) => i.shift === "2-smena").orginal_qty = i.orders.find((i) => i.shift === "2-smena").quantity ? i.orders.find((i) => i.shift === "2-smena").quantity : 0
                      i.orders.find((i) => i.shift === "2-smena").quantity_payed = i.orders.find((i) => i.shift === "2-smena").quantity_payed ? i.orders.find((i) => i.shift === "2-smena").quantity_payed : 0
                      shiftTwo += (Number(i.price) * i.orders.find((i) => i.shift === "2-smena").orginal_qty)
                      i.isOrder = i.orders.find(order => order.shift === "2-smena" && order.meal_id === i.id) ? true : false
                      return i;
                    }),
                  shift_three: menu.meals
                    .filter((i) => i[this.findShift(this.comapny.shift_three)])
                    .map((i) => {
                      if (i && (!i.orders.length || !i.orders.find((i) => i.shift === "3-smena")))
                      i.orders.push({ quantity: 0, shift: "3-smena" });
                      i.orders.find((i) => i.shift === "3-smena").orginal_qty = i.orders.find((i) => i.shift === "3-smena").quantity ? i.orders.find((i) => i.shift === "3-smena").quantity : 0
                      i.orders.find((i) => i.shift === "3-smena").quantity_payed = i.orders.find((i) => i.shift === "3-smena").quantity_payed ? i.orders.find((i) => i.shift === "3-smena").quantity_payed : 0
                      shiftOne += (Number(i.price) * i.orders.find((i) => i.shift === "3-smena").orginal_qty)
                      i.isOrder = i.orders.find(order => order.shift === "3-smena" && order.meal_id === i.id) ? true : false
                      return i;
                    }),
                  // poredi zadati dan sa trenutnim danom u meniju
                  // disabled: this.comapny.restaurant.settings.rok_dana ? (moment(this.selectedDate.date + ' ' + + ' ' + moment().format('HH:mm')).add(
                  //         this.comapny.restaurant.settings.order_before,
                  //         "days"
                  //     ) > moment(menu.date).set("hour", (this.comapny.restaurant.settings.order_before_hours ? this.comapny.restaurant.settings.order_before_hours : 0))
                  //         ? true
                  //         : false) : this.comapny.restaurant.settings.rok_sati ? (datum >= moment(menu.date) ? false : false) : false,
                  disabled: this.checkDisabled(menu.date),

                  // sto se trazi ID na ovaj nacin
                  test: meal ? meal.id + "_" + meal.orders[0].shift : null,
                  // test: menu.id,
                  date: menu.date,
                });

                this.total[menu.date] = shiftOne + shiftTwo + shiftThree
                if (this.$store.state.user.type === "company" || this.$store.state.user.companies[0].is_credit == 1) {
                  if (this.$store.state.company.shift_three)
                      this.menues[this.menues.length - 1].shift_three.push({
                        name: "Укупно",
                      });
                  if (this.$store.state.company.shift_two)
                      this.menues[this.menues.length - 1].shift_two.push({
                        name: "Укупно",
                      });
                  if (this.$store.state.company.shift_one)
                      this.menues[this.menues.length - 1].shift_one.push({
                        name: "Укупно",
                      });
                }
              }
            });

            //console.log(this.menues)

            /*this.menus.meals.forEach((item) => (item.quantity = 1));
            if (this.menus.meals.find((item) => item.lock) && this.menus.one)
              this.lockedAll = true;*/
          });
        axios.get(`/all-restaurants`)
          .then((res) => {
          // console.log(res.data)
        });
        if (this.$store.state.user.is_transferable) {
          let cId=0;
          cId = this.$store.state.company.parent_id;
          if (!cId) cId = this.$store.state.company.id;
          axios
            .get("/companies/list/" + cId)
            .then((res) => {
              this.locations = res.data;
              this.locations.forEach((item) => {
                if(item[0]===this.$store.state.company.id)this.location=item;
              })
            });
        }
      }
    },
    checkDisabled(menuDate) {
      if(
        (this.comapny && this.comapny.hasOwnProperty('no_time_limit') && this.comapny.no_time_limit)
        &&
        (this.$store.state.user && this.$store.state.user.hasOwnProperty('no_time_limit_user') && this.$store.state.user.no_time_limit_user)
      ) {
        return false
      }

      let day = this.moment().format('dddd').toLowerCase()
      let newDay = this.restaurantOrderTime[day]

      if(this.moment().isBefore(this.moment().set("hour", newDay.time.time_hour).set("minutes", newDay.time.time_minut))) {
        let addDay = newDay.day_before_limit
        if(this.moment().add('days', addDay).set("hour", 0).set("minutes", 0).isSameOrBefore(this.moment(menuDate).format('YYYY-MM-DD'), 'day') == 0) {
          return true
        }
        else {
          return false
        }
      }
      else {
        let addDay = newDay.day_after_limit
        if(this.moment().add('days', addDay).set("hour", 0).set("minutes", 0).isSameOrBefore(this.moment(menuDate).format('YYYY-MM-DD'), 'day') == 0) {
          return true
        }
        else {
          return  false
        }
      }
    },
    findShift(num) {
      switch (num) {
        case 1:
          return "shift_one";
          break;
        case 2:
          return "shift_two";
          break;
        case 3:
          return "shift_three";
          break;
      }
    },
    reserveCredit(meal_id, shift_id, item_date) {
      let id = meal_id;
      let shift = shift_id;

      let data = {
        user_id: this.$store.state.user.id,
        meal_id: id,
        date: item_date.date,
        type: this.selectedDate.name,
        quantity: 1,
        quantity_payed: 0,
        shift: shift,
      };
      if(this.$store.state.user.is_transferable&&item_date.location[0]&&item_date.location[0]!==this.$store.state.company.id)data.company_id=item_date.location[0];

      if (item_date.id) {
        axios.put(`/orders/${item_date.id}`, data).then((res) => {
          item_date.id = res.data.data.id;
          item_date.test = meal_id + '_' + shift_id
          this.snackbar.status = true;
          this.snackbar.text = "Сачувано";
        });
      } else {
        axios.post("/orders", data).then((res) => {
          item_date.id = res.data.data.id;
          item_date.test = meal_id + '_' + shift_id

          this.dialogSuccess = true
          this.dialog = false
          setTimeout(() => {
            this.dialogSuccess = false
          }, 1500)
          if (shift == "1-smena") {
            this.menues.filter(menu => {
              if (menu.date == res.data.data.date) {
                menu.shift_one.filter(smena => {
                  if (smena.id == res.data.data.meal_id) {
                    smena.orders[0].quantity = res.data.data.quantity
                    smena.orders[0].date = res.data.data.date
                    smena.orders[0].meal_id = res.data.data.meal_id
                  }
                })
              }
            })
            this.$forceUpdate()
          }
          if (shift == "2-smena") {
            this.menues.filter(menu => {
              if (menu.date == res.data.data.date) {
                menu.shift_one.filter(smena => {
                  if (smena.id == res.data.data.meal_id) {
                    smena.orders[0].quantity = res.data.data.quantity
                    smena.orders[0].date = res.data.data.date
                    smena.orders[0].meal_id = res.data.data.meal_id
                  }
                })
              }
            })
            this.$forceUpdate()
          }
          if (shift == "3-smena") {
            this.menues.filter(menu => {
              if (menu.date == res.data.data.date) {
                menu.shift_one.filter(smena => {
                  if (smena.id == res.data.data.meal_id) {
                    smena.orders[0].quantity = res.data.data.quantity
                    smena.orders[0].date = res.data.data.date
                    smena.orders[0].meal_id = res.data.data.meal_id
                  }
                })
              }
            })
            this.$forceUpdate()
          }
        }).catch(err => {
          this.snackbar.text = err?.response?.data?.message
          this.snackbar.status = true
        });
      }
    },
    reserve(val, item, type = false, meal_id) {
      if (item.disabled) return;
      let arr = "";

      if (type) {
        // item.test = val.target.parentNode.offsetParent.querySelector(
        //   "input"
        // ).value;
        item.test = meal_id
        // arr = val.target.parentNode.offsetParent
        //   .querySelector("input")
        //   .value.split("_");
        arr = meal_id.split("_");
      } else {
        arr = val.split("_");
      }

      let id = arr[0];
      let shift = arr[1];

      let data = {
        user_id: this.$store.state.user.id,
        meal_id: id,
        date: item.date,
        type: this.selectedDate.name,
        quantity: 1,
        quantity_payed: 0,
        shift: shift,
        app_type: 1
      };
      if(this.$store.state.user.is_transferable&&item.location[0]&&item.location[0]!==this.$store.state.company.id)data.company_id=item.location[0];

      if (item.id) {
        axios.put(`/orders/${item.id}`, data).then((res) => {
          item.id = res.data.data.id;
          this.snackbar.status = true;
          this.snackbar.text = "Сачувано";
        });
      } else {

        if(this.checkDisabled(item.date)) {
          item.test = null
          this.snackbar.text = 'Vreme za narucivanje je isteklo (code: 001)'
          this.snackbar.status = true
          this.dialogErrorDate = item.date
          this.dialogError = true
          return
        }

        axios.post("/orders", data).then((res) => {
          item.id = res.data.data.id;
          // this.snackbar.status = true;
          // this.snackbar.text = "Сачувано";
          this.dialogSuccess = true
          setTimeout(() => {
            this.dialogSuccess = false
          }, 1500)
          if (shift == "1-smena") {
            item.shift_one.filter(smena => {
              smena.orders[0].quantity = res.data.data.quantity
              smena.orders[0].date = res.data.data.date
              smena.orders[0].meal_id = res.data.data.meal_id
            })
            this.$forceUpdate()
          }
          if (shift == "2-smena") {
            item.shift_two.filter(smena => {
              smena.orders[1].quantity = res.data.data.quantity
              smena.orders[1].date = res.data.data.date
              smena.orders[1].meal_id = res.data.data.meal_id
            })
            this.$forceUpdate()
          }
          if (shift == "3-smena") {
            item.shift_three.filter(smena => {
              smena.orders[2].quantity = res.data.data.quantity
              smena.orders[2].date = res.data.data.date
              smena.orders[2].meal_id = res.data.data.meal_id
            })
            this.$forceUpdate()
          }
        }).catch(err => {
          item.test = null
          this.snackbar.text = err?.response?.data?.message
          this.snackbar.status = true
          if(err.response.status == 406) {
            this.dialogErrorDate = item.date
            this.dialogError = true
          }
        });
      }
    },
    selectNewRes(res) {
      let data = {};
      data['user_id'] = this.$store.state.user.id
      data['restoran_id'] = res.id
      axios.post('/choose-restaurant', data).then(({data}) => {
        if(data.data) {
          this.dialogNewRestoran = false;
          this.getMenues()
        }
      })
    },
    convertNameOfDay(name) {
      switch (name) {
        case "Sunday":
          return "Недјеља";
          break;
        case "Monday":
          return "Понедјељак";
          break;
        case "Tuesday":
          return "Уторак";
          break;
        case "Wednesday":
          return "Сриједа";
          break;
        case "Thursday":
          return "Четвртак";
          break;
        case "Friday":
          return "Петак";
          break;
        case "Saturday":
          return "Субота";
          break;
      }
    },
  },
  computed: {
    setMinutes() {
      let minutes = []
      if (this.restaurant) {
        minutes.push(Number(this.restaurant.settings.delivery_to))
        if(this.restaurant.settings.delivery_to1 == "15") {
          minutes.push(15)
        } else if(this.restaurant.settings.delivery_to1 == "30") {
          minutes.push(15, 30)
        } else if(this.restaurant.settings.delivery_to1 == "45") {
          minutes.push(15, 30, 45)
        }
      }

      return minutes
    },
    setHoures() {
      let hours = []
      if (this.restaurant) {
        for (let i = this.restaurant.settings.delivery_from; i <= this.restaurant.settings.delivery_from1; i++) {
          hours.push(Number(i))
        }
      }
      return hours
    },
    checkTime() {
      return moment(this.time, 'HH:mm').isBefore(moment(this.sati,'HH:mm'))
    },
    topBarColor() {
      return this.site && this.site.settings
          ? this.site.settings.topbar
              ? this.site.settings.topbar.background
              : "primary"
          : this.$store.state.company &&
          this.$store.state.company.restaurant.settings
              ? this.$store.state.company.restaurant.settings.topbar.background
              : "primary";
    },
    checkDate() {
      let daysArray = ['Недеља', 'Понедељак', 'Уторак', 'Среда', 'Четвртак', 'Петак', 'Субота'];
      let day = new Date(this.selectedDate.date).getDay();
      let dayName = daysArray[day];
      let selektovaniDatum = daysArray.indexOf(dayName);
      let noviDatum = daysArray.indexOf(this.restaurant.settings.dan);
      let postavitiDatum = noviDatum - selektovaniDatum
      let result = new Date(this.selectedDate.date);
      result.setDate(result.getDate() + postavitiDatum);
      let prepMonth = (result.getMonth()+1) < 9 ?  "-0" + (result.getMonth()+1) : '-' + (result.getMonth()+1)
      let prepDay = result.getDate() <= 9 ?  "-0" + result.getDate() : '-' + result.getDate()
      let datestring = result.getFullYear() + prepMonth + prepDay

      return datestring
    },
    comapny() {
      //console.log(this.$store.state.company);
      return this.$store.state.company;
    },
    restaurantId() {
        if (this.$store.state.company) {
          return this.$store.state.company.restaurant.id
        } else
        if (this.$store.state.restaurant) {
          return this.$store.state.restaurant.id
        } else
        return null;
        // return this.$store.state.company ? this.$store.state.company.restaurant.id : this.$store.state.restaurant.id;
    },
    // sta je ovo metoda u computed
    restaurant() {
      if(this.$store.state.restaurant == null && this.$store.state.company == null) {
        axios.get('/all-restaurants').then(({data}) => {
          this.chooseNewRestoran = data.data
          this.dialogNewRestoran = true
        })
      } else{
        return this.$store.state.restaurant
            ? this.$store.state.restaurant
            : this.$store.state.company.restaurant;
      }

    },

    shifts() {
      let data = [];
      if (this.$store.state.company) {
        if (this.$store.state.company.shift_one)
          data.push({ text: "1-smena", value: 1, key: "shift_one" });
        if (this.$store.state.company.shift_two)
          data.push({ text: "2-smena", value: 2, key: "shift_two" });
        if (this.$store.state.company.shift_three)
          data.push({ text: "3-smena", value: 3, key: "shift_three" });
      } else {
        data.push({ text: "1-smena", value: 1, key: "shift_one" });
        data.push({ text: "2-smena", value: 2, key: "shift_two" });
        data.push({ text: "3-smena", value: 3, key: "shift_three" });
      }
      if (this.$store.state.user.type == 'guest') {
        data.push({text: "korpa", value: 4, key: "korpa"});
      }
      return data;
    },
    headers() {
      let data = [];
      if (this.$store.state.company.shift_one || !this.isMobile)
        data.push({
          text: "Прва смјена",
          value: "shift_one",
          sortable: false,
          align: "center",
          width: "33%",
        });
      if (this.$store.state.company.shift_two || !this.isMobile)
        data.push({
          text: "Друга смјена",
          value: "shift_two",
          sortable: false,
          align: "center",
          width: "33%",
        });
      if (this.$store.state.company.shift_three || !this.isMobile)
        data.push({
          text: "Трећа смјена",
          value: "shift_three",
          sortable: false,
          align: "center",
          width: "33%",
        });
      if (this.$store.state.user.type == 'guest') {
        data.push({
          text: "Твоја наруџба",
          value: "korpa",
          sortable: false,
          align: "center",
          width: "15%",
        });
      }

      return data;
    },
    withDimension() {
      // if(this.comapny.shift_one + this.comapny.shift_two + this.comapny.shift_three < 3) {
      //   return true
      // }
      if(this.isMobile) {
        switch (this.comapny.shift_one + this.comapny.shift_two + this.comapny.shift_three) {
          case 1: return 12;
          break;
          case 3:
          case 4:
          case 5: return 6;
          break;
          case 6: return 4;
          break
        }
      }
      else {
        return 4
      }
    }
  },
  watch: {
    '$i18n.locale'() {
      if(this.loaded)
        this.getMenues()
      else
        this.loaded = true
    },
    selectedCompany() {
      if(!this.firstLoadSelectedCompany) {
        this.firstLoadSelectedCompany = true
        return
      }
      if(this.selectedCompany) {
        this.$store.commit('SAVE_COMPANY', this.selectedCompany)
      }
      this.getRestaurantSettings()
      this.getMenues()
    }
  }
};
</script>

<style scoped>

.theme--light.v-data-table > div.v-data-table__wrapper > table > tbody > tr.myclass:not(.v-data-table__expanded__content):not(.v-data-table__empty-wrapper) {
  vertical-align: unset !important;
}
.home-page .v-data-table__mobile-row {
  display: flex!important;
  flex-direction: column!important;
}
tbody tr:nth-child(even):hover {
  background-color: transparent !important;
}
tbody td.text-center:nth-child(n + 2) {
  vertical-align: center;
}
tbody td.text-right {
  padding: 0px !important;
}
tbody tr:nth-child(odd) {
  /* background: #f3f4f4; */
}

.theme--light.v-data-table > .v-data-table__wrapper > table > tbody > tr:hover:not(.v-data-table__expanded__content):not(.v-data-table__empty-wrapper) {
  background: white !important;
}
.row {
  align-items: center;
}
.v-input--selection-controls.v-input {
  margin-top: 0;
}

td.text-center {
  padding-top: 20px !important;
  padding-bottom: 20px !important;
}
</style>

<style>
.strelice {
  padding: 4px 12px;
  border: 1px solid #cfcfcf;
  border-radius: 6px;
  color: #FB6D3B;
  margin-top: 4px;
  display: inline-block;
  font-weight: 700;
}
.strelice:hover {
  background: #FB6D3B;
  color: #fff;
}
.otkazi_por {
  padding: 13px 0;
  width: 100%;
  border:2px solid #FB6D3B;
  border-radius: 12px;
  color:#FB6D3B;
  font-size: 16px;
  font-family: 'Poppins', Helvetica, sans-serif !important;
}
.potvrdi_por {
  padding: 13px 0;
  width: 100%;
  border:2px solid #FB6D3B;
  border-radius: 12px;
  background: #FB6D3B;
  color:#fff;
  font-size: 16px;
  font-family: 'Poppins', Helvetica, sans-serif !important;
}
.dialog_tekst {
  width: 50%;
  padding: 1px 0;
  color: rgba(44,44,44,0.5);
  font-size: 16px;font-family: 'Poppins', Helvetica, sans-serif !important;
  margin-bottom: 4px !important;
}
.dialog_korisnik {
  width: 50%;
  padding: 3px 0;
  text-align: right;
  font-family: 'Poppins', Helvetica, sans-serif !important;
  font-weight: 700;
  font-size: 16px;
  color: #2C2C2C;
}
.ukinuti_liniju .v-input__slot::before {
  border-style: none !important;
  border-width: 0 !important;
}
.ukinuti_liniju .v-text-field__slot input {
  text-align: center !important;
}
.ukinuti_liniju .v-input__icon--prepend,
.ukinuti_liniju .v-input__icon--append {
  padding: 0 !important;
  /*border: 1px solid #cfcfcf !important;*/
  /*border-radius: 10px !important;*/
  width: 35px !important;
  height: 35px !important;
  background: rgba(98, 52, 5, 0.02) !important;
  border: 1px solid #DEE0E3;
  border-radius: 6px;

}
.ukinuti_liniju .v-input__icon--prepend button,
.ukinuti_liniju .v-input__icon--append button {
  color: #FB6D3B;
}
.ukinuti_liniju .v-input__icon--prepend {
  margin-top: -5px;
  margin-right: 7px;
}
.dugmic i.v-icon {
  opacity: 0 !important;
}
.dugmic label {
  margin-left: -15px !important;
}
#kartica .v-input__slot {
  margin-bottom: 0 !important;
}
#kartica .v-messages {
  display: none;
}
div.v-input--selection-controls__input {
  margin-top: -10px !important;
}
.theme--light.v-data-table > div.v-data-table__wrapper > table > tbody > tr.myclass:not(.v-data-table__expanded__content):not(.v-data-table__empty-wrapper) {
  vertical-align: top !important;
}
.kapica {
  position: absolute;
  left: -21px;
  top: -12px;
  width: 30px;
  transform: rotate(-30deg);
}
.shift-title {
  font-family: "Gagalin", Helvetica, sans-serif;
  font-size: 31px;
  font-weight: 400;
}
.div-okolo {
  border: 1px solid rgba(0, 0, 0, 0.1);
  width: 100%;
  padding: 5px;
  border-radius: 12px;
  background: rgba(251, 109, 59, 0.05);
}
.shift-title-p {
  font-family: "Poppins", Helvetica, sans-serif;
  font-size: 18px;
  font-weight: 700;
}

.relative {
  position: relative;
  width: max-content;
  margin-left: 50%;
  transform: translateX(-50%);
  margin-top: 20px;
}
.shift1 {
  color: #2C2C2C !important;
}
.shift2 {
  color: #2C2C2C !important;
}
.shift3 {
  color: #2C2C2C !important;
}
.datum {
  color: #000 !important;
  font-family: "Poppins", Helvetica, sans-serif !important;
  font-size: 18px !important;
  font-weight: 600 !important;
  padding-left: 33vw;
  text-align: center;
  margin-top: 13px;
}
img.datumico {
  width: 30px;
  margin-left: 50%;
  transform: translateX(-50%);
  margin-top: 15px;
}

button.trash-icon {
  margin-left: 50%;
  transform: translateX(-50%);
}


.datum-value {
  color: #c20606;
  font-size: 20px;
  font-weight: 700;
  line-height: 1.4;
}
.meal-wrapper {
  display: flex;
  /*background: #efefef;*/
  /*padding: 10px;*/
  border-radius: 10px;
  justify-content: space-between;
  /*gap: 10px;*/
}
.meal-wrapper h3 {
  color: #5d3f27;
  font-size: 18px;
  width: 70%;
  text-align: left;
  cursor: pointer;
}
.meal-wrapper div {
  /*width: 100%;*/
  color: white;
  /*padding: 10px;*/
  border-radius: 10px;
  height: 35px;
  line-height: 1;
  font-size: 17px;
}
.meal-wrapper .naruci {
  background: #99ad16;
  margin-left: auto;
}
.meal-wrapper .otkazi {
  background: #c20606;
  margin-left: auto;
}
.v-input--selection-controls .v-input__slot > .v-label,
.v-input--selection-controls .v-radio > .v-label {
  color: #5d3f27;
  font-size: 18px;
  width: 70%;
  text-align: left;
  cursor: pointer;
  font-weight: 700;
}
.v-application--is-ltr .v-input--selection-controls__input {
  display: none;
}
.v-input--selection-controls.v-input {
  margin-top: 10px;
  margin-left: 5px;
}
.disabled-meal {
  opacity: 0.5;
  pointer-events: none;
}
.transfer-lista {
  width: 30%;
  margin: 0 auto;
}
@media only screen and (-webkit-min-device-pixel-ratio: 3) {
  .dan-u-nedelji {
    font-size: 21px;
  }
  .mobile-image {
    width: 30px !important;
  }
  .hero {
    height: unset;
    width: unset;
  }
  .row {
    width: 100%;
    margin: 0;
  }
  .hero-logo {
    width: 80%;
  }
  .col, .v-application {
    padding: 0 !important;;
  }
  .home-page {
    padding-top: 0 !important;;
  }
  .transfer-lista {
    width: 80%;
  }
}
@media (max-width: 768px) {
  .v-data-table__mobile-row__cell {
    width: 100%;
  }
  .datum-value {
    font-size: 16px;
    margin-top: 20px;
  }

  img.datumico {
    display: none !important;
  }

  .meal-wrapper .otkazi {
    margin-left: auto;
  }

  .rezervisati {
    font-size: 16px !important;
    text-align: left;
    line-height: 1.4;
  }
  .dan-u-nedelji {
    font-size: 21px;
  }
  .mobile-image {
    width: 30px !important;
  }
  .hero {
    height: unset;
    width: unset;
  }
  .row {
    width: 100%;
    margin: 0;
  }
  .hero-logo {
    width: 80%;
  }
  .col, .v-application {
    padding: 0 !important;;
  }
  .home-page {
    padding-top: 0 !important;;
  }
  .transfer-lista {
    width: 80%;
  }
}
@media only screen and (min-width: 480px) and (max-width: 760px) {
  .dan-u-nedelji {
    font-size: 21px;
  }
  .mobile-image {
    width: 30px !important;
  }
  .hero {
    height: unset;
    width: unset;
  }
  .row {
    width: 100%;
    margin: 0;
  }
  .hero-logo {
    width: 80%;
  }
  .col, .v-application {
    padding: 0 !important;;
  }
  .home-page {
    padding-top: 0 !important;
  }
  .transfer-lista {
    width: 80%;
  }
}
</style>
