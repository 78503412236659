<template>
  <v-container>
    <v-row>
      <v-col cols="12" sm="6" md="4" lg="3">
        <v-menu
          ref="menu"
          v-model="menu"
          :close-on-content-click="false"
          :return-value.sync="date"
          transition="scale-transition"
          offset-y
          max-width="290px"
          min-width="290px"
        >
          <template v-slot:activator="{ on, attrs }">
            <v-text-field
              v-model="dateRangeText"
              :label="$t('date')"
              prepend-icon="mdi-calendar"
              readonly
              v-bind="attrs"
              v-on="on"
              hide-details
            ></v-text-field>
          </template>
          <v-card>
            <strong style="padding: 5px 10px 0 3px">{{$t("choose_date_single") }}</strong>
              <br />
            <v-date-picker v-model="date"  no-title scrollable>
              <v-spacer></v-spacer>
              <v-btn text color="primary" @click="menu = false"> Cancel </v-btn>
              <v-btn text color="primary" @click="$refs.menu.save(date);load();">OK</v-btn>
            </v-date-picker>
          </v-card>
        </v-menu>
      </v-col>
      <v-col cols="12" sm="6" md="4" lg="3">
        <v-text-field
          class="pt-5"
          v-model="pretraga"
          append-icon="mdi-magnify"
          :label="$t('search')"
          single-line
          hide-details
          dense
        ></v-text-field>
      </v-col>
      <v-col sm="12" lg="2" class="mt-2">
<!--        <v-btn :style="'border-radius: 8px;border: 1px solid ' + $store.state.restaurant.settings.topbar.background + ';background: none;color: ' + $store.state.restaurant.settings.topbar.background + ';box-shadow: none;text-transform: capitalize'" @click="print(printTable)" small class="mt-2">-->
<!--          <v-icon :style="'color:' + $store.state.restaurant.settings.topbar.background ? $store.state.restaurant.settings.topbar.background : 'rgb(251, 109, 59)'" class="mr-2">mdi-printer</v-icon>-->
<!--          <span id="stampaj">-->
<!--            {{ $t("print") }}-->
<!--          </span>-->
<!--        </v-btn>-->
      </v-col>

      <v-card style="width: 100%" elevation="0">
        <v-tabs style="display: none" v-model="tab">
          <v-tab v-for="item in items" :key="item.tab">
          </v-tab>
        </v-tabs>

        <v-tabs-items id="izv" v-model="tab">
          <v-tab-item>
            <v-card flat>
              <v-card-text>
                <div id="table8">
                  <template
                    v-for="(comapny, companyKey) in search && search.length > 0
                      ? desserts4.filter((i) => search.includes(i.company))
                      : desserts4"
                    dense
                  >
                    <h2 class="text-center mt-5 mb-2">
                        {{ $t('report') }} {{ $t('taken')}} / <span style="color: rgb(251, 109, 59)">{{ $t('ordered')}}</span>
                    </h2>
                    <v-data-table
                      :search="pretraga"
                      id="table4"
                      :headers="headersM"
                      :items="comapny.foods"
                      class="elevation-1"
                      hide-default-footer
                      disable-pagination
                    >
                      <template
                        v-for="header in headers"
                        v-slot:[`header.${header.value}`]="{ header }"
                      >
                        {{ translate(header.text) }}
                      </template>
                      <template
                        v-for="values in headers"
                        v-slot:[`item.${values.value}`]="{ item }"
                      >
                        {{ translate(item[values.value]) }}
                      </template>
                        <template v-slot:item.name="{ item, index }">
                            {{ item.name }}
                        </template>
                      <template v-slot:item.shift_one="{item}">
                        <span v-if="item.name == 'УКУПНО'">
                          <span style="font-weight: 700">{{item.shift_one_payed}}</span> / <span style="color: rgb(251, 109, 59);font-weight: 700">{{item.shift_one}}</span>
                        </span>
                        <span v-else>
                         {{item.shift_one_payed}} / <span style="color: rgb(251, 109, 59)">{{item.shift_one}}</span>
                        </span>
                      </template>
                      <template v-slot:item.shift_two="{item}">
                        <span v-if="item.name == 'УКУПНО'">
                          <span style="font-weight: 700">{{item.shift_two_payed}}</span> / <span style="color: rgb(251, 109, 59);font-weight: 700">{{item.shift_two}}</span>
                        </span>
                        <span v-else>
                          {{item.shift_two_payed}} / <span style="color: rgb(251, 109, 59)">{{item.shift_two}}</span>
                        </span>
                      </template>
                      <template v-slot:item.shift_three="{item}">
                        <span v-if="item.name == 'УКУПНО'" >
                          <span style="font-weight: 700">{{item.shift_three_payed}}</span> / <span style="color: rgb(251, 109, 59);font-weight: 700">{{item.shift_three}}</span>
                        </span>
                        <span v-else>
                          {{item.shift_three_payed}} / <span style="color: rgb(251, 109, 59)">{{item.shift_three}}</span>
                        </span>
                      </template>
                      <template v-slot:item.total="{item}">
                        <span v-if="item.name == 'УКУПНО'">
                          <span style="font-weight: 700">{{item.total_payed}}</span> / <span style="color: rgb(251, 109, 59);font-weight: 700">{{item.total}}</span>
                        </span>
                        <span v-else>
                          <span style="font-weight: 700">{{item.total_payed}}</span> / <span style="color: rgb(251, 109, 59)">{{item.total}}</span>
                        </span>
                      </template>
                      -->
                      <template v-slot:item.menu="{ item }">
                        <span @click="showMeal(item.id)">
                            <span v-if="item.name == 'УКУПНО'" style="font-weight: 700">{{ $t("totalU") }}</span>
                            <span v-else>{{ translate(item.name) }}</span>
                        </span>
                      </template>
                      <template v-slot:item.num="{ item, index }">
                        {{ item.name!== "УКУПНО" ? index + 1 : "" }}
                      </template>
                    </v-data-table>
                  </template>
                </div>
              </v-card-text>
            </v-card>
          </v-tab-item>
        </v-tabs-items>
      </v-card>
    </v-row>
    <v-snackbar
      v-model="snackbar.status"
      :timeout="2000"
      top
      rounded="pill"
      color="#C20606"
    >
      {{ translate(snackbar.text) }}

      <template v-slot:action="{ attrs }">
        <v-btn
          color="white"
          text
          v-bind="attrs"
          @click="snackbar.status = false"
        >
          {{ translate("Затвори") }}
        </v-btn>
      </template>
    </v-snackbar>
  </v-container>
</template>

<script>
import axios from "../plugins/axios";
import moment from "moment";
import conf from "../config";
export default {
  data() {
    return {
      conf: conf,
      reportG: '',
      name: "Намирнице",
      pretraga: "",
      search: "",
      moment: moment,
      date: new Date().toISOString().substr(0, 10),
      dates: null,
      menu: false,
      change_order_status_popup: false,
      report: null,
      report2: null,
      reservedItems: [],
      reservedItemsData: '',
      selectedMeal: {
        foods: [],
        name: "",
        image: {
          name: "",
        },
      },
      selectedUser: {
        name: "test",
        lastname: "test",
        image_path: "test",
      },
      total: [],
      printTable: "table8",
      companies: [],
      meals: [],
      users: [],
      snackbar: {
        status: "",
        text: "",
      },
      headers: [
          { text: this.$t('r_b'), value: "num",},
          { text: this.$t('grocery'), value: "menu" },
          { text: this.$t('1st_shift'), value: "shift_one" },
          { text: this.$t('2nd_shift'), value: "shift_two" },
          { text: this.$t('3rd_shift'), value: "shift_three" },
          { text: this.$t('total'), value: "total" },
      ],
      headersM: [
        { text: this.$t('r_b'), value: "num", sortable: false },
        { text: this.$t('meal'), value: "name" },
        { text: this.$t('1st_shift'), value: "shift_one" },
        { text: this.$t('2nd_shift'), value: "shift_two" },
        { text: this.$t('3rd_shift'), value: "shift_three" },
        { text: this.$t('total'), value: "total" },
      ],
      tab: '',
      items: [
        {
          tab: this.$t('t_o'),
          content: "Tab 8 Content",
          print: "table8",
          icon: "mdi-check-underline-circle",
        },
      ],
    };
  },
  methods: {
    totalUserQtu(shift) {
      let total = 0;
      shift.filter(item => {
          total += item.quantity
      })

      return total
    },
    capitalizeFirstLetter(str) {
      return str.charAt(0).toUpperCase() + str.slice(1);
    },
    reservedMeals() {
      axios.get(
          "/reports/daily/restaurant/" +
          this.$store.state.restaurant.id +
          "/rezervni-obroci?company=" + this.search5.join() + "&date=" +
          moment(this.date[0]).format("YYYY-MM-DD") +
          "&date2=" +
          moment(this.date[1]).format("YYYY-MM-DD")
      ).then((data) => {
        this.reservedItemsData = data.data
      })
    },
    priceDelivery(meals) {
      let price = 0
      if (meals) {
        meals.filter(meal => {
          price += meal.quantity * meal.price
        })
      }
      return price
    },
    formatTime(time) {
      if(time) {
        let formatedTime = time.split(':')
        return formatedTime[0] + ':' + formatedTime[1]
      }
    },
    changeOrderState(item) {
      axios.post('/reports/change-order-status', item).then(({data}) => {
        if(data) {
          this.change_order_status_popup = false
        }
      })
    },
    priceMeals(meal,item) {
      let total = 0;
      meal.filter(m => {
        if (m.prilog && m.prilog.length) {
          m.prilog.filter(pr => {
            console.log(pr.price)
            total += (parseFloat(pr.price) * pr.quantity)
          })
        }
        if (m.meals && m.meals.price) {
          total += (parseFloat(m.meals.price) * m.quantity)
        } else if (m.food && m.food.price) {
          total += (parseFloat(m.food.price) * m.quantity)
        }
      })
      return total
    },
    toogleFirms() {
        if (this.search.length > 0) {
            this.search = []
        } else
        if (this.search.length == 0) {
            this.search = []
            this.desserts4.filter(item => {
                this.search.push(item.company)
            })
        }
    },
    showMeal(id) {
      axios.get("/meals/" + id).then((res) => {
        this.selectedMeal = res.data;
        this.dialog = true;
      });
    },
    selectAllFirms(item) {
      if(item == 'Оброци ') {
        this.search = []
        this.desserts4.filter(item => {
          this.search.push(item.company)
        })
      }
    },
    toggle() {
      if (
        this.search2.length === this.desserts4.filter((i) => i.company).length
      ) {
        this.search2 = [];
      } else {
        this.search2 = []
        this.search2 = this.desserts4
          .filter((i) => i.company)
          .map((i) => i.id);
      }

      this.findMeals();
    },
    print(id, type = false) {
      if (id === "table6") {
        const WinPrint = window.open(
          "",
          "",
          "left=100,top=0,width=800,height=900,toolbar=0,scrollbars=0,status=0"
        );

        WinPrint.document.write(`<head>`);

        for (let index in this.meals) {
          const prtHtml = document.getElementById(`table6_${index}`).innerHTML;
          let stylesHtml = "";
          for (const node of [
            ...document.querySelectorAll('link[rel="stylesheet"], style'),
          ]) {
            stylesHtml += node.outerHTML;
          }

          WinPrint.document.write(` ${stylesHtml}`);
        }

        WinPrint.document.write(`<style>
                                                table {
                                                  font-family: arial, sans-serif;
                                                  border-collapse: collapse;
                                                  width: 100%;
                                                }
                                                td, th {
                                                  border: 1px solid #dddddd;
                                                  text-align: left;
                                                  padding: 8px;
                                                }
                                                tr:nth-child(even) {
                                                  background-color: #f1f1f1;
                                                }
                                              </style>
                                            </head>`);

        for (let index in this.meals) {
          const prtHtml = document.getElementById(`table6_${index}`).innerHTML;
          let stylesHtml = "";
          for (const node of [
            ...document.querySelectorAll('link[rel="stylesheet"], style'),
          ]) {
            stylesHtml += node.outerHTML;
          }

          WinPrint.document.write(`
                                          <body>
                                            ${
                                              this.$store.state.restaurant.name
                                            }: ${index}   ${moment(
            this.date[0]
          ).format("DD.MM.YYYY.") + '-' + moment(this.date[1]).format("DD.MM.YYYY.")}
                                            <strong>${prtHtml}</strong>
                                          </body>`);

          WinPrint.document.write('<p style="page-break-after:always;"></p>');
        }

        WinPrint.document.close();
        WinPrint.focus();
        WinPrint.print();
        WinPrint.close();
      } else {
        const prtHtml = document.getElementById(id).innerHTML;
        let stylesHtml = "";
        for (const node of [
          ...document.querySelectorAll('link[rel="stylesheet"], style'),
        ]) {
          stylesHtml += node.outerHTML;
        }
        // //console.log(stylesHtml);
        // Open the print window
        const WinPrint = window.open(
          "",
          "",
          "left=100,top=0,width=800,height=900,toolbar=0,scrollbars=0,status=0"
        );

        WinPrint.document.write(`<!DOCTYPE html>
                                      <html>
                                        <head>
                                          ${stylesHtml}
                                          <style>
                                         table {
                                          font-family: arial, sans-serif;
                                          border-collapse: collapse;
                                          width: 100%;
                                        }

                                        td, th {
                                          border: 1px solid #dddddd;
                                          text-align: left;
                                          padding: 8px;
                                        }

                                        tr:nth-child(even) {
                                          background-color: #f1f1f1;
                                        }
                                        </style>

                                        </head>
                                        <body>
                                          ${
                                            this.$store.state.restaurant.name
                                          }: ${this.name}   ${moment(
            this.date[0]
        ).format("DD.MM.YYYY.") + '-' + moment(this.date[1]).format("DD.MM.YYYY.")}
                                          <strong>${prtHtml}</strong>
                                        </body>
                                      </html>`);

        WinPrint.document.close();
        WinPrint.focus();
        WinPrint.print();
        WinPrint.close();
      }
    },
    load() {
      this.search = "";
      if (this.$store.state.company.restaurant) {
        axios
          .get(
            "/reports/daily/restaurant/" +
            this.$store.state.company.restaurant.id +
            "/companies?date=" +
            moment(this.date).format("YYYY-MM-DD") +
            "&date2=" +
            moment(this.date).format("YYYY-MM-DD")
          )
          .then((res) => {
            this.report2 = res.data;
          });

      }
    },
    findShift(num) {
      switch (num) {
        case "1-smena":
          return "shift_one";
          break;
        case "2-smena":
          return "shift_two";
          break;
        case "3-smena":
          return "shift_three";
          break;
      }
    },
    groupBy(array, key){
      const result = {}
      array.forEach(item => {
        if (!result[item[key]]){
          result[item[key]] = []
        }
        result[item[key]].push(item)
      })
      return result
    }
  },
  created() {
    this.load();
  },
  computed: {
    dateRangeText() {
      return moment(this.date).format("DD.MM.YYYY.");
    },
    desserts4() {
      let arr2 = [];
      if (this.report2) {
        for (let company in this.report2) {
          arr2.push({ company: company, foods: []});
          for (let shift in this.report2[company]) {
            for (let shift2 in this.report2[company][shift]) {
              let index = arr2.findIndex((i) => i.company === company);
              let index2 = ''
              if (this.report2[company][shift][shift2].meal_name) {
                index2 = arr2[index].foods.findIndex((i) => i.name === this.report2[company][shift][shift2].meal_name);
              } else {
                index2 = arr2[index].foods.findIndex((i) => i.name === shift2);
              }
              // let index2 = arr2[index].foods.findIndex((i) => i.name === shift2);
              if (index2 > -1) {
                arr2[index].id = this.report2[company][shift][shift2].company_id
                arr2[index].foods[index2].shift_one +=
                  shift === "1-smena"
                    ? this.report2[company][shift][shift2].quantity
                    : 0;
                arr2[index].foods[index2].shift_one_payed +=
                  shift === "1-smena"
                    ? this.report2[company][shift][shift2].quantity_payed
                    : 0;
                arr2[index].foods[index2].shift_two +=
                  shift === "2-smena"
                    ? this.report2[company][shift][shift2].quantity
                    : 0;
                arr2[index].foods[index2].shift_two_payed +=
                  shift === "2-smena"
                    ? this.report2[company][shift][shift2].quantity_payed
                    : 0;
                (arr2[index].foods[index2].shift_three +=
                  shift === "3-smena"
                    ? this.report2[company][shift][shift2].quantity
                    : 0),
                    (arr2[index].foods[index2].shift_three_payed +=
                  shift === "3-smena"
                    ? this.report2[company][shift][shift2].quantity_payed
                    : 0),
                  (arr2[index].foods[index2].total += this.report2[company][
                    shift
                  ][shift2].quantity),
                  (arr2[index].foods[index2].total_payed += this.report2[company][
                    shift
                  ][shift2].quantity_payed);
              } else {
                arr2[index].id = this.report2[company][shift][shift2].company_id
                arr2[index].foods.push({
                  // name: shift2,
                  name: this.report2[company][shift][shift2] && this.report2[company][shift][shift2].meal_name ? this.report2[company][shift][shift2].meal_name : shift2,
                  id: this.report2[company][shift][shift2].id,
                  shift_one:
                    shift === "1-smena"
                      ? this.report2[company][shift][shift2].quantity
                      : 0,
                  shift_one_payed:
                    shift === "1-smena"
                      ? this.report2[company][shift][shift2].quantity_payed
                      : 0,
                  shift_two:
                    shift === "2-smena"
                      ? this.report2[company][shift][shift2].quantity
                      : 0,
                  shift_two_payed:
                    shift === "2-smena"
                      ? this.report2[company][shift][shift2].quantity_payed
                      : 0,
                  shift_three:
                    shift === "3-smena"
                      ? this.report2[company][shift][shift2].quantity
                      : 0,
                  shift_three_payed:
                    shift === "3-smena"
                      ? this.report2[company][shift][shift2].quantity_payed
                      : 0,
                  total: this.report2[company][shift][shift2].quantity,
                  total_payed: this.report2[company][shift][shift2].quantity_payed,
                });
              }
            }
          }
        }

        arr2.forEach((company, index) => {
          company.foods.push({
            name: this.$t('totalU'),
            shift_one: company.foods.reduce((sum, item) => {
              return sum + item.shift_one;
            }, 0),
            shift_one_payed: company.foods.reduce((sum, item) => {
              return sum + item.shift_one_payed;
            }, 0),
            shift_two: company.foods.reduce((sum, item) => {
              return sum + item.shift_two;
            }, 0),
            shift_two_payed: company.foods.reduce((sum, item) => {
              return sum + item.shift_two_payed;
            }, 0),
            shift_three: company.foods.reduce((sum, item) => {
              return sum + item.shift_three;
            }, 0),
            shift_three_payed: company.foods.reduce((sum, item) => {
              return sum + item.shift_three_payed;
            }, 0),
            total:
              company.foods.reduce((sum, item) => {
                return sum + item.shift_one;
              }, 0) +
              company.foods.reduce((sum, item) => {
                return sum + item.shift_two;
              }, 0) +
              company.foods.reduce((sum, item) => {
                return sum + item.shift_three;
              }, 0),
            total_payed:
              company.foods.reduce((sum, item) => {
                return sum + item.shift_one_payed;
              }, 0) +
              company.foods.reduce((sum, item) => {
                return sum + item.shift_two_payed;
              }, 0) +
              company.foods.reduce((sum, item) => {
                return sum + item.shift_three_payed;
              }, 0),
          });
        });
      }
      if (arr2.length == 1) {
          this.search = arr2[0].company
      }
      return arr2;
    },
  },
};
</script>
<style>

</style>
<style scoped>

.v-data-table >>> tr:nth-of-type(odd) {
  background-color: rgba(0, 0, 0, .05) !important;
}
table tbody tr:nth-of-type(odd) {
  background-color: rgba(0, 0, 0, .05) !important;
}
div.v-select__selections span.v-size--small span.v-chip__content {
  font-size: 10px !important;
}
.v-alert__icon.v-icon {
  font-size: 15px !important;
}

</style>
<style>

@media(max-width: 767px) {
  #izv .v-data-table__mobile-row__cell {
    text-align: left !important;
    width: 100%;
  }
  #korisnik > div {
    padding: 0 !important;
  }
  #korisnik .v-card__title, #korisnik .v-card__text {
    font-size: 16px !important;
    padding: 0 !important;
  }
  #stampaj {
    display: none;
  }
}
</style>
