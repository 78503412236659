<template>
  <div style="width: 95%;margin: 10px auto">
    <div style="display: flex">
      <v-toolbar-title class="mt-6">
        {{$t('gallery')}}
      </v-toolbar-title>
      <v-divider
          class="mx-4"
          inset
          vertical
      ></v-divider>
      <div style="width: 50%;margin-left: auto">
      <v-text-field
          class="pr-10 pb-2 mt-6 mr-4"
          v-model="filter"
          append-icon="mdi-magnify"
          :label="$t('search')"
          single-line
          hide-details
          dense
      ></v-text-field>
      </div>
      <input type="file" id="upload" hidden @change="upload" />
      <v-btn class="mt-4" :style="'margin-top: -8px;border-radius: 8px;border: 1px solid ' + $store.state.restaurant.settings.topbar.background + ';background: none;box-shadow: none;text-transform: capitalize'">
        <label :style="'color: ' + $store.state.restaurant.settings.topbar.background" class="ma-3" for="upload">
          <v-icon>mdi-plus</v-icon>
          {{ $t("add_image") }}
        </label>
      </v-btn>
      <!-- <v-col cols="12"></v-col> -->
    </div>
    <div class="row mt-9">
      <div
        v-for="image in imageList"
        :key="image.id"
        class="col-lg-2 col-md-4 col-sm-4 col-xs-4"
      >
        <v-row>
          <v-col cols="12" class="pb-0">
            <v-img
              :src="
                conf.apiUrlBase +
                '/gallery/' +
                $store.state.restaurant.id +
                '/medium/' +
                image.name
              "
              aspect-ratio="1"
              class="grey lighten-2"
            >
              <template v-slot:placeholder>
                <v-row class="fill-height ma-0" align="center" justify="center">
                  <v-progress-circular
                    indeterminate
                    color="grey lighten-5"
                  ></v-progress-circular>
                </v-row>
              </template>
            </v-img>
          </v-col>
          <v-col cols="12" class="pt-0 text-center">
            {{ image.name | imageName }}
            <v-icon small @click="deleteImage(image)"> mdi-delete </v-icon>
          </v-col>
        </v-row>
      </div>
    </div>
    <v-dialog v-model="dialogDelete" max-width="500px">
      <v-card>
        <v-card-title class="headline">{{
          $t("delete_confirmation")
        }}</v-card-title>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="blue darken-1" text @click="closeDelete">{{$t('cancel')}}</v-btn>
          <v-btn color="blue darken-1" text @click="confirmDelete"
            >{{$t('confirm')}}</v-btn
          >
          <v-spacer></v-spacer>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <v-dialog v-model="dialogWrongImageType" max-width="500px">
      <v-card>
        <v-card-title class="headline text-center">{{ translate('Погрешан формат') }}</v-card-title>
        <v-card-actions>
          <div>
            <p>{{ translate('Погрешан формат слике, само JPG, JPEG, PNG, WEBP типови су подржани.') }}</p>
          </div>
          <v-spacer></v-spacer>
          <v-btn color="red darken-1" text @click="closeDialogWrongType()">{{$t('cancel')}}</v-btn>
          <v-spacer></v-spacer>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import axios from "../plugins/axios";
import conf from "../config";

export default {
  data() {
    return {
      conf: conf,
      filter: null,
      images: [],
      editedImage:null,
      dialogDelete:false,
      dialogWrongImageType:false,
      deletedIndex:null,
    };
  },
  created() {
    this.load();
  },
  methods: {
    closeDialogWrongType() {
      this.dialogWrongImageType = false
      this.$forceUpdate()
    },
    upload(item) {
      let data = new FormData();
      let allowedTypes = ["image/jpg", "image/jpeg", "image/png", "image/webp"];
      if (!allowedTypes.includes(event.target.files[0].type)) {
        this.dialogWrongImageType = true
        setTimeout(() => {
          this.dialogWrongImageType = false
        }, 5000)
        return;
      }
      data.append("image", event.target.files[0]);

      axios.post("/gallery", data).then((res) => {
        this.images.push(res.data);
      });
    },
    load() {
      axios.get("/gallery").then((res) => {
        this.images = res.data;
      });
    },
    deleteImage(item) {
      this.deletedIndex = this.images.indexOf(item);
      this.deletedImage = Object.assign({}, item);
      this.dialogDelete = true;
    },
    closeDelete(){
      this.deletedIndex=null;
      this.dialogDelete=false;
      this.deletedImage=null;
    },
    confirmDelete(item){
      let _deletedIndex = this.deletedIndex;
      axios.delete("/gallery/" + this.deletedImage.id).then((res) => {
        this.images.splice(_deletedIndex, 1);
        this.closeDelete();
      });
    }
  },
  filters: {
    imageName(name) {
      if (typeof name == 'string') {
        let imageName = name.split(".");
        if (imageName[0].indexOf("_"))
          imageName[0] = imageName[0].substring(imageName[0].indexOf("_") + 1);
        if (imageName[0].substring(0, 2) === "to")
          imageName[0] = imageName[0].substring(2);
        return imageName[0].replace(/-/g, " ");
      }
    },
  },
  computed: {
    imageList() {
      if (!this.filter) return this.images;
      const re = RegExp(
        `.*${this.filter
          .replace(/\s+/g, "-")
          .toLowerCase()
          .split("")
          .join(".*")}.*`
      );
      return this.images.filter((i) => i.name.toLowerCase().match(re));
    },
  },
};
</script>

<style scoped>
label {
  display: inline-block;
  color: white;
  padding: 0.5rem;
  font-family: sans-serif;
  border-radius: 0.3rem;
  cursor: pointer;
  margin-top: 1rem;
}

@media (max-width: 767px) {
  .col-sm-4 {
    max-width: 33.333%;
  }
}
</style>